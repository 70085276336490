import React, {useCallback, useEffect, createRef, useState, useRef} from "react";
import "./style.less";
import {Card, Col, Divider, Form, Row, Switch, Checkbox, InputNumber, Typography} from "antd";
import {
  setSurfaceCoatingAnodizing,
  setSurfaceCoatingGalvanicZincPlating, setSurfaceCoatingGalvanizing,
  setSurfaceCoatingNickelPlating, setSurfaceCoatingPainting,
  setSurfaceCoatingPowderPainting, setSurfaceCoatingService,
  setSurfaceCoatingBlasting, setSurfaceCoatingHeatTreatment,
} from "../../../../../actions/surfaceCoatingActions";
import {useDispatch, useSelector} from "react-redux";
import {debounce} from "lodash";
import {InfoCircleOutlined} from "@ant-design/icons";

export const SurfaceCoatingTab = (props) => {
  const {
    selectedTab,
    isFetchingSurfaceCoatingData, surfaceCoatingData,
    fetchData, changePaintingParams,
    changePowderPaintingParams, changeGalvanizingParams,
    changeNickelPlatingParams, changeAnodizingParams,
    changeGalvanicZincPlatingParams, changeHardeningParams,
    changeCaseHardeningParams, changeAnnealingParams,
    changeGlassBeadBlastingParams, changeSandBlastingParams,

    setPaintingData, setPowderPaintingData,
    setGalvanizingData, setNickelPlatingData,
    setAnodizingData, setGalvanicZincPlatingData,
    setBlastingData, setHeatTreatmentData,
    isGelso, headerHeight = 0, editDefaultMode = false,
  } = props;

  const [disabledSwitching, setDisabledSwitching] = useState(false);
  const [disabledPainting, setDisabledPainting] = useState(false);
  const [disabledPowderPainting, setDisabledPowderPainting] = useState(false);
  const [disabledGalvanizing, setDisabledGalvanizing] = useState(false);
  const [disabledNickelPlating, setDisabledNickelPlating] = useState(false);
  const [disabledAnodizing, setDisabledAnodizing] = useState(false);
  const [disabledGalvanicZincPlating, setDisabledGalvanicZincPlating] = useState(false);
  const [disabledBlasting, setDisabledBlasting] = useState(false);
  const [disabledHeatTreatment, setDisabledHeatTreatment] = useState(false);

  const selectedRegion = useSelector(state => state.defaultMaterialsData.selectedRegion)

  let showFallbackParams = isGelso && !editDefaultMode;
  let showOfferService = !isGelso && !editDefaultMode;
  let showEditInfoText = editDefaultMode;

  const dispatch = useDispatch()

  const paintingForm = createRef()
  const powderPaintingForm = createRef()
  const galvanizingForm = createRef()
  const nickelPlatingForm = createRef()
  const anodizingForm = createRef()
  const galvanicZincPlatingForm = createRef()
  const blastingForm = createRef()
  const heatTreatmentForm = createRef()
  const initialRegionMount = useRef(true);
  const selectedRegionRef = useRef(selectedRegion)

  const formatData = (data) => {
    return editDefaultMode ? {...data, region: selectedRegionRef.current} : data
  }

  const reloadTabKey = useSelector(state => state.defaultMaterialsData.reloadTabKey)
  useEffect(() => {
    if (reloadTabKey === selectedTab) {
      initialize()
    }
  }, [reloadTabKey])

  const initialize = () => {
    if(selectedTab === "6") {
      dispatch(fetchData(formatData({})))
    }
  }

  useEffect(()=>{
    initialize()
  }, [selectedTab])

  useEffect(() => {
    selectedRegionRef.current = selectedRegion

    if (initialRegionMount.current) {
      initialRegionMount.current = false;
    } else {
      initialize()
    }
  }, [selectedRegion])

  const triggerSlideToggle = (value) => {
    setDisabledSwitching(true);
    dispatch(setSurfaceCoatingService({value})).then(()=>{
      setDisabledSwitching(false);
      if(value) {
        dispatch(fetchData(formatData({})))
      }
    })
  }

  /*----------- Painting -----------*/

  const setPainting = (value) => {
    setDisabledPainting(true)
    dispatch(setSurfaceCoatingPainting({value})).then(()=>{
      setDisabledPainting(false)
    })
  }

  const changePaintingData = (data) => {
    dispatch(setPaintingData(formatData({...data, hasPrimer: isGelso ? true : data.hasPrimer})))
    changePaintingParams(formatData({...data, hasPrimer: isGelso ? true : data.hasPrimer}))
  }

  const debouncedPainting = useCallback(
    debounce(data => changePaintingData(data), 500),
    []
  );

  const changePainting = () => {
    debouncedPainting({
      ...paintingForm.current.getFieldsValue()
    })
  }

  const setPrimerPainting = () => {
    changePaintingData({
      ...paintingForm.current.getFieldsValue()
    })
  }

  /*----------- Powder Painting -----------*/

  const setPowderPainting = (value) => {
    setDisabledPowderPainting(true)
    dispatch(setSurfaceCoatingPowderPainting({value})).then(()=>{
      setDisabledPowderPainting(false)
    })
  }

  const changePowderPaintingData = (data) => {
    dispatch(setPowderPaintingData(formatData({...data, hasPrimer: isGelso ? true : data.hasPrimer})))
    changePowderPaintingParams(formatData({...data, hasPrimer: isGelso ? true : data.hasPrimer}))
  }

  const debouncedPowderPainting  = useCallback(
    debounce(data => changePowderPaintingData(data), 500),
    []
  );

  const changePowderPainting  = () => {
    debouncedPowderPainting({
      ...powderPaintingForm.current.getFieldsValue()
    })
  }

  const setPrimerPowderPainting  = () => {
    changePowderPaintingData({
      ...powderPaintingForm.current.getFieldsValue()
    })
  }

  /*----------- Galvanizing -----------*/

  const setGalvanizing = (value) => {
    setDisabledGalvanizing(true)
    dispatch(setSurfaceCoatingGalvanizing({value})).then(()=>{
      setDisabledGalvanizing(false)
    })
  }

  const changeGalvanizingData = (data) => {
    dispatch(setGalvanizingData(formatData(data)))
    changeGalvanizingParams(formatData(data))
  }

  const debouncedGalvanizing = useCallback(
    debounce(data => changeGalvanizingData(data), 500),
    []
  );

  const changeGalvanizing  = () => {
    debouncedGalvanizing({
      ...galvanizingForm.current.getFieldsValue()
    })
  }

  /*--------- Nickel Plating ----------*/

  const setNickelPlating = (value) => {
    setDisabledNickelPlating(true)
    dispatch(setSurfaceCoatingNickelPlating({value})).then(()=>{
      setDisabledNickelPlating(false)
    })
  }

  const changeNickelPlatingData = (data) => {
    dispatch(setNickelPlatingData(formatData(data)))
    changeNickelPlatingParams(formatData(data))
  }

  const debouncedNickelPlating = useCallback(
    debounce(data => changeNickelPlatingData(data), 500),
    []
  );

  const changeNickelPlating = () => {
    debouncedNickelPlating({
      ...nickelPlatingForm.current.getFieldsValue()
    })
  }

  /*------------ Anodizing ------------*/

  const setAnodizing = (value) => {
    setDisabledAnodizing(true)
    dispatch(setSurfaceCoatingAnodizing({value})).then(()=>{
      setDisabledAnodizing(false)
    })
  }

  const changeAnodizingData = (data) => {
    dispatch(setAnodizingData(formatData(data)))
    changeAnodizingParams(formatData(data))
  }

  const debouncedAnodizing = useCallback(
    debounce(data => changeAnodizingData(data), 500),
    []
  );

  const changeAnodizing = () => {
    debouncedAnodizing({
      ...anodizingForm.current.getFieldsValue()
    })
  }

  /*------------ Galvanic Zinc Plating ------------*/

  const setGalvanicZincPlating = (value) => {
    setDisabledGalvanicZincPlating(true)
    dispatch(setSurfaceCoatingGalvanicZincPlating({value})).then(()=>{
      setDisabledGalvanicZincPlating(false)
    })
  }

  const changeGalvanicZincPlatingData = (data) => {
    dispatch(setGalvanicZincPlatingData(formatData(data)))
    changeGalvanicZincPlatingParams(formatData(data))
  }

  const debouncedGalvanicZincPlating = useCallback(
    debounce(data => changeGalvanicZincPlatingData(data), 500),
    []
  );

  const changeGalvanicZincPlating = () => {
    debouncedGalvanicZincPlating({
      ...galvanicZincPlatingForm.current.getFieldsValue()
    })
  }

  /*------------ Heat Treatment ------------*/

  const setHeatTreatment = (value) => {
    setDisabledHeatTreatment(true)
    dispatch(setSurfaceCoatingHeatTreatment({value})).then(() => {
      setDisabledHeatTreatment(false)
    })
  }

  /*-------------------- Hardening --------------------*/

  const changeHardeningData = (data) => {
    changeHardeningParams(formatData(data))
    dispatch(setHeatTreatmentData(formatData({
      ...surfaceCoatingData.heatTreatment,
      hardening: {
        ...data
      }
    })))
  }

  // function customDebounce(func, setter, delay) { // TODO: Fix for potential debounce issue
  //   let timerId;
  //   return function () {
  //     const context = this;
  //     const args = arguments;
  //     setter(true)
  //     clearTimeout(timerId);
  //     timerId = setTimeout(function () {
  //       func.apply(context, args);
  //       setter(false)
  //     }, delay);
  //   };
  // }

  const debouncedHardening = useCallback(
    debounce(data => changeHardeningData(data), 500),
    []
  )

  const changeHardening = () => {
    debouncedHardening({
      "hardeningPrice" : heatTreatmentForm.current.getFieldValue("hardeningPrice"),
      "hasHardening": isGelso ? true : surfaceCoatingData.heatTreatment.hardening.hasHardening
    })
  }

  const changeHasHardening = (value) => {
    changeHardeningData({
      "hardeningPrice": heatTreatmentForm.current.getFieldValue("hardeningPrice"),
      "hasHardening": value,
    })
  }

  /*------------------ Case Hardening (Tempering) ------------------*/

  const changeCaseHardeningData = (data) => {
    changeCaseHardeningParams(formatData(data))
    dispatch(setHeatTreatmentData(formatData({
      ...surfaceCoatingData.heatTreatment,
      caseHardening: {
        ...data
      }
    })))
  }

  const debouncedCaseHardening = useCallback(
    debounce(data => changeCaseHardeningData(data), 500),
    []
  )

  const changeCaseHardening = () => {
    debouncedCaseHardening({
      "caseHardeningPrice" : heatTreatmentForm.current.getFieldValue("caseHardeningPrice"),
      "hasCaseHardening": isGelso ? true : surfaceCoatingData.heatTreatment.caseHardening.hasCaseHardening
    })
  }

  const changeHasCaseHardening = (value) => {
    changeCaseHardeningData({
      "caseHardeningPrice": surfaceCoatingData.heatTreatment.caseHardening.caseHardeningPrice,
      "hasCaseHardening": value,
    })
  }

  /*------------------ Annealing ------------------*/

  const changeAnnealingData = (data) => {
    changeAnnealingParams(formatData(data))
    dispatch(setHeatTreatmentData(formatData({
      ...surfaceCoatingData.heatTreatment,
      annealing: {
        ...data
      }
    })))
  }

  const debouncedAnnealing = useCallback(
    debounce(data => changeAnnealingData(data), 500),
    []
  )

  const changeAnnealing = () => {
    debouncedAnnealing({
      "annealingPrice" : heatTreatmentForm.current.getFieldValue("annealingPrice"),
      "hasAnnealing": isGelso ? true : surfaceCoatingData.heatTreatment.annealing.hasAnnealing
    })
  }

  const changeHasAnnealing = (value) => {
    changeAnnealingData({
      "annealingPrice": surfaceCoatingData.heatTreatment.annealing.annealingPrice,
      "hasAnnealing": value,
    })
  }

  /*-------------------- Blasting --------------------*/

  const setBlasting = (value) => {
    setDisabledBlasting(true)
    dispatch(setSurfaceCoatingBlasting({value})).then(() => {
      setDisabledBlasting(false)
    })
  }

  /*------------ Glass Bead Blasting ------------*/

  const changeGlassBeadBlastingData = (data) => {
    changeGlassBeadBlastingParams(formatData(data))
    dispatch(setBlastingData(formatData({
      ...surfaceCoatingData.blasting,
      glassBeadBlasting: {
        ...data
      }
    })))
  }

  const debouncedGlassBeadBlasting = useCallback(
    debounce(data => changeGlassBeadBlastingData(data), 500),
    []
  );

  const changeGlassBeadBlasting = () => {
    debouncedGlassBeadBlasting({
      "glassBeadBlastingPrice" : blastingForm.current.getFieldValue("glassBeadBlastingPrice"),
      "hasGlassBeadBlasting": isGelso ? true : surfaceCoatingData.blasting.glassBeadBlasting.hasGlassBeadBlasting
    })
  }

  const changeHasGlassBeadBlasting = (value) => {
    changeGlassBeadBlastingData({
      "glassBeadBlastingPrice" : surfaceCoatingData.blasting.glassBeadBlasting.glassBeadBlastingPrice,
      "hasGlassBeadBlasting": value
    })
  }

  /*------------ Sand Blasting (Ceramic Bead Blasting) ------------*/

  const changeSandBlastingData = (data) => {
    changeSandBlastingParams(formatData(data))
    dispatch(setBlastingData(formatData({
      ...surfaceCoatingData.blasting,
      sandBlasting: {
        ...data
      }
    })))
  }

  const debouncedSandBlasting = useCallback(
    debounce(data => changeSandBlastingData(data), 500),
    []
  );

  const changeSandBlasting = () => {
    debouncedSandBlasting({
      "sandBlastingPrice" : blastingForm.current.getFieldValue("sandBlastingPrice"),
      "hasSandBlasting": isGelso ? true : surfaceCoatingData.blasting.sandBlasting.hasSandBlasting
    })
  }

  const changeHasSandBlasting = (value) => {
    changeSandBlastingData({
      "sandBlastingPrice" : surfaceCoatingData.blasting.sandBlasting.sandBlastingPrice,
      "hasSandBlasting": value
    })
  }


  useEffect(()=>{
    if(!isFetchingSurfaceCoatingData) {
      paintingForm.current?.setFieldsValue({
        paintingPrice: surfaceCoatingData.painting.paintingPrice,
        hasPrimer: surfaceCoatingData.painting.hasPrimer,
        paintingPriceWithPrimer: surfaceCoatingData.painting.paintingPriceWithPrimer,
      })
      powderPaintingForm.current?.setFieldsValue({
        powderPaintingPrice: surfaceCoatingData.powderPainting.powderPaintingPrice,
        hasPrimer: surfaceCoatingData.powderPainting.hasPrimer,
        powderPaintingPriceWithPrimer: surfaceCoatingData.powderPainting.powderPaintingPriceWithPrimer
      })
      galvanizingForm.current?.setFieldsValue({
        hotGalvanizingPrice: surfaceCoatingData.galvanizing.hotGalvanizingPrice
      })
      anodizingForm.current?.setFieldsValue({
        anodizingPrice: surfaceCoatingData.anodizing.anodizingPrice
      })
      nickelPlatingForm.current?.setFieldsValue({
        nickelPlatingPrice: surfaceCoatingData.nickelPlating.nickelPlatingPrice
      })
      galvanicZincPlatingForm.current?.setFieldsValue({
        galvanicZincPlatingPrice: surfaceCoatingData.galvanicZincPlating.galvanicZincPlatingPrice
      })
      blastingForm.current?.setFieldsValue({
        hasBeadBlasting: surfaceCoatingData.blasting.glassBeadBlasting.hasGlassBeadBlasting,
        glassBeadBlastingPrice: surfaceCoatingData.blasting.glassBeadBlasting.glassBeadBlastingPrice,

        hasSandBlasting: surfaceCoatingData.blasting.sandBlasting.hasSandBlasting,
        sandBlastingPrice: surfaceCoatingData.blasting.sandBlasting.sandBlastingPrice,
      })
      heatTreatmentForm.current?.setFieldsValue({
        hasHardening: surfaceCoatingData.heatTreatment.hardening.hasHardening,
        hardeningPrice: surfaceCoatingData.heatTreatment.hardening.hardeningPrice,

        hasCaseHardening: surfaceCoatingData.heatTreatment.caseHardening.hasCaseHardening,
        caseHardeningPrice: surfaceCoatingData.heatTreatment.caseHardening.caseHardeningPrice,

        hasAnnealing: surfaceCoatingData.heatTreatment.annealing.hasAnnealing,
        annealingPrice: surfaceCoatingData.heatTreatment.annealing.annealingPrice,
      })
    }
  }, [isFetchingSurfaceCoatingData])

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"}>
        <div style={{height: `calc(100vh - 100px - ${headerHeight}px)`, overflowY: "auto", overflowX: "hidden", paddingRight: 12}}>
          <Row>
            <Col span={24}>
              <div className={"switchContainer"}>
                {showFallbackParams && <span style={{marginRight: 30}}>Fallback Surface Treatment parameters:</span>}
                {showOfferService &&
                  <div>
                    <span style={{marginRight: 30, marginBottom: 6}}>Do you offer Surface Treatment service?</span>
                    <span>No</span>
                    <Switch checked={surfaceCoatingData.hasSurfaceCoating}
                            onChange={triggerSlideToggle}
                            style={{marginLeft: 6, marginRight: 6}}
                            disabled={disabledSwitching}
                    />
                    <span>Yes</span>
                  </div>
                }
                {showEditInfoText &&
                  <Card className={"defaultMachineCard"}>
                    <InfoCircleOutlined className={"editMaterialPageInfoIcon"}/>
                    <div style={{ marginLeft: 6, fontWeight: 500 }}>
                      This page represents the default regional parameters for surface coating.
                    </div>
                  </Card>
                }
              </div>
            </Col>
          </Row>

          <Divider style={{width: "50%", minWidth: "0%", margin: "8px 0 8px 0"}}/>
          {surfaceCoatingData.hasSurfaceCoating &&
          <div>
            <div className={"mainForm"}>
              {/*------------------------------------------------------------------------------------------------*/}
              <Row gutter={16}>
                <Col xl={12} span={24}>
                  <Form>
                    <div style={{ display: "flex", alignItems: "center"}}>
                      {isGelso ?
                        (<span className={"mainFromLabel"} style={{color: "white"}}>Painting:</span>)
                        :
                        (
                          <Form.Item
                            label={<span className={"mainFromLabel"}>Painting</span>}
                            valuePropName={"checked"}
                          >
                            <Checkbox
                              checked={surfaceCoatingData.painting.hasPainting}
                              onChange={(event) => {
                                setPainting(event.target.checked)
                              }}
                              disabled={disabledPainting}
                            />
                          </Form.Item>
                        )
                      }
                      <Divider
                        className={"lightDivider"}
                        style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}
                      />
                    </div>
                  </Form>
                  <span className={"processDescription"}>(Standard Painting with frequently-used colors)</span>
                  <Form
                    ref={paintingForm}
                  >
                    <Card className={"inputInfoText fieldGroupCard"}>
                      <div className={surfaceCoatingData.painting.hasPainting ? "" : "disabledField"} style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Painting price"}
                          name={"paintingPrice"}
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={surfaceCoatingData.painting.paintingPrice}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changePainting}
                            disabled={!surfaceCoatingData.painting.hasPainting}
                          />
                        </Form.Item>
                        <Typography.Text style={{ marginLeft: 12}}>€/m<sup>2</sup></Typography.Text>
                      </div>
                      {/*+++++++++++++++++++++++++++++++++++++++++*/}
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {!isGelso &&
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                          <div className={surfaceCoatingData.painting?.hasPainting ? "" : "disabledField"}
                               style={{display: "flex", alignItems: "center"}}
                          >
                            <Form.Item
                              name={"hasPrimer"}
                              style={{marginBottom: 5, marginTop: 5}}
                              valuePropName={"checked"}
                              initialValue={surfaceCoatingData?.painting?.hasPrimer}
                            >
                              <Checkbox
                                className={"fieldGroupCheckbox"}
                                style={{ marginBottom: 0 }}
                                onChange={setPrimerPainting}
                                disabled={!surfaceCoatingData.painting.hasPainting}
                              >
                                Has Painting with Primer
                              </Checkbox>
                            </Form.Item>
                          </div>
                          <Divider type={"vertical"} style={{ height: "24px", margin: "0 8px 0 0", borderColor: "#838f94" }} />
                        </div>
                        }
                        <div className={!(surfaceCoatingData.painting?.hasPrimer && surfaceCoatingData.painting?.hasPainting) ? "disabledField" : ""}
                             style={{display: "flex", alignItems: "center"}}
                        >
                          <Form.Item
                            label={"Price with primer"}
                            name={"paintingPriceWithPrimer"}
                            style={{marginBottom: 5, marginTop: 5}}
                            initialValue={surfaceCoatingData.painting.paintingPriceWithPrimer}
                          >
                            <InputNumber
                              style={{minWidth: 30, marginLeft: 5}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changePainting}
                              disabled={!(surfaceCoatingData.painting.hasPrimer && surfaceCoatingData.painting.hasPainting)}
                            />
                          </Form.Item>
                          <Typography.Text style={{ marginLeft: 12}}>€/m<sup>2</sup></Typography.Text>
                        </div>
                      </div>
                      {/*+++++++++++++++++++++++++++++++++++++++++*/}
                    </Card>
                  </Form>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <Form>
                    <div style={{ display: "flex", alignItems: "center"}}>
                      {isGelso ?
                        (<span className={"mainFromLabel"} style={{color: "white"}}>Powder Painting:</span>)
                        :
                        (
                          <Form.Item
                            label={<span className={"mainFromLabel"}>Powder Painting</span>}
                            valuePropName={"checked"}
                          >
                            <Checkbox
                              checked={surfaceCoatingData.powderPainting.hasPowderPainting}
                              onChange={(event) => {
                                setPowderPainting(event.target.checked)
                              }}
                              disabled={disabledPowderPainting}
                            />
                          </Form.Item>
                        )
                      }
                      <Divider
                        className={"lightDivider"}
                        style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}
                      />
                    </div>
                  </Form>
                  <span className={"processDescription"}>(Standard Powder Painting with frequently-used colors)</span>
                  <Form
                    ref={powderPaintingForm}
                  >
                    <Card className={"inputInfoText fieldGroupCard"}>
                      <div className={(surfaceCoatingData.powderPainting.hasPowderPainting) ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}
                      >
                        <Form.Item
                          label={"Powder Painting price"}
                          name={"powderPaintingPrice"}
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={surfaceCoatingData.powderPainting.powderPaintingPrice}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changePowderPainting}
                            disabled={!surfaceCoatingData.powderPainting.hasPowderPainting}
                          />
                        </Form.Item>
                        <Typography.Text style={{ marginLeft: 12}}>€/m<sup>2</sup></Typography.Text>
                      </div>

                      {/*+++++++++++++++++++++++++++++++++++++++++*/}
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {!isGelso &&
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                          <div className={surfaceCoatingData.powderPainting?.hasPowderPainting ? "" : "disabledField"}
                               style={{display: "flex", alignItems: "center"}}
                          >
                            <Form.Item
                              name={"hasPrimer"}
                              style={{marginBottom: 5, marginTop: 5}}
                              valuePropName={"checked"}
                              initialValue={surfaceCoatingData?.powderPainting?.hasPrimer}
                            >
                              <Checkbox
                                className={"fieldGroupCheckbox"}
                                style={{ marginBottom: 0 }}
                                onChange={setPrimerPowderPainting}
                                disabled={!surfaceCoatingData.powderPainting.hasPowderPainting}
                              >
                                Has Powder Painting with Primer
                              </Checkbox>
                            </Form.Item>
                          </div>
                          <Divider type={"vertical"} style={{ height: "24px", margin: "0 8px 0 0", borderColor: "#838f94" }} />
                        </div>
                        }
                        <div className={!(surfaceCoatingData.powderPainting?.hasPrimer && surfaceCoatingData.powderPainting?.hasPowderPainting) ? "disabledField" : ""}
                             style={{display: "flex", alignItems: "center"}}
                        >
                          <Form.Item
                            label={"Price with primer"}
                            name={"powderPaintingPriceWithPrimer"}
                            style={{marginBottom: 5, marginTop: 5}}
                            initialValue={surfaceCoatingData.powderPainting.powderPaintingPriceWithPrimer}
                          >
                            <InputNumber
                              style={{minWidth: 30, marginLeft: 5}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changePowderPainting}
                              disabled={!(surfaceCoatingData.powderPainting.hasPrimer && surfaceCoatingData.powderPainting.hasPowderPainting)}
                            />
                          </Form.Item>
                          <Typography.Text style={{ marginLeft: 12}}>€/m<sup>2</sup></Typography.Text>
                        </div>
                      </div>
                      {/*+++++++++++++++++++++++++++++++++++++++++*/}
                    </Card>
                  </Form>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <Form>
                    <div style={{ display: "flex", alignItems: "center"}}>
                      {isGelso ?
                        (<span className={"mainFromLabel"} style={{color: "white"}}>Hot-Dip Galvanizing:</span>)
                        :
                        (
                          <Form.Item
                            label={<span className={"mainFromLabel"}>Galvanizing</span>}
                            valuePropName={"checked"}
                          >
                            <Checkbox
                              checked={surfaceCoatingData.galvanizing.hasGalvanizing}
                              onChange={(event) => {
                                setGalvanizing(event.target.checked)
                              }}
                              disabled={disabledGalvanizing}
                            />
                          </Form.Item>
                        )
                      }
                      <Divider
                        className={"lightDivider"}
                        style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}
                      />
                    </div>
                  </Form>
                  <span className={"processDescription"}>(Standard Hot-Dip Galvanizing process)</span>
                  <Form
                    ref={galvanizingForm}
                  >
                    <Card className={"inputInfoText fieldGroupCardSmall"}>
                      <div className={surfaceCoatingData.galvanizing.hasGalvanizing ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Price of Hot-Dip Galvanizing"}
                          name={"hotGalvanizingPrice"}
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={surfaceCoatingData.galvanizing.hotGalvanizingPrice}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeGalvanizing}
                            disabled={!(surfaceCoatingData.galvanizing.hasGalvanizing)}
                          />
                        </Form.Item>
                        <Typography.Text style={{ marginLeft: 12}}>€/dm<sup>2</sup></Typography.Text>
                      </div>
                    </Card>
                  </Form>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <Form>
                    <div style={{ display: "flex", alignItems: "center"}}>
                      {isGelso ?
                        (<span className={"mainFromLabel"} style={{color: "white"}}>Chemical Nickel Plating:</span>)
                        :
                        (
                          <Form.Item
                            label={<span className={"mainFromLabel"}>Chemical Nickel Plating</span>}
                            valuePropName={"checked"}
                          >
                            <Checkbox
                              checked={surfaceCoatingData.nickelPlating.hasNickelPlating}
                              onChange={(event) => {
                                setNickelPlating(event.target.checked)
                              }}
                              disabled={disabledNickelPlating}
                            />
                          </Form.Item>
                        )
                      }
                      <Divider
                        className={"lightDivider"}
                        style={{width: "65%", minWidth: "0%", margin: "8px 0 8px 10px"}}
                      />
                    </div>
                  </Form>
                  <span className={"processDescription"}>(Standard Chemical Nickel Plating process)</span>
                  <Form
                    ref={nickelPlatingForm}
                  >
                    <Card className={"inputInfoText fieldGroupCardSmall"}>
                      <div
                        className={surfaceCoatingData.nickelPlating.hasNickelPlating ? "" : "disabledField"}
                        style={{display: "flex", alignItems: "center"}}
                      >
                        <Form.Item
                          label={"Price of Chemical Nickel Plating"}
                          name={"nickelPlatingPrice"}
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={0}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeNickelPlating}
                            disabled={!surfaceCoatingData.nickelPlating.hasNickelPlating}
                          />
                        </Form.Item>
                        <Typography.Text style={{ marginLeft: 12}}>€/dm<sup>2</sup></Typography.Text>
                      </div>
                    </Card>
                  </Form>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <Form>
                    <div style={{ display: "flex", alignItems: "center"}}>
                      {isGelso ?
                        (<span className={"mainFromLabel"} style={{color: "white"}}>Anodizing:</span>)
                        :
                        (
                          <Form.Item
                            label={<span className={"mainFromLabel"}>Anodizing</span>}
                            valuePropName={"checked"}
                          >
                            <Checkbox
                              checked={surfaceCoatingData.anodizing.hasAnodizing}
                              onChange={(event) => {
                                setAnodizing(event.target.checked)
                              }}
                              disabled={disabledAnodizing}
                            />
                          </Form.Item>
                        )
                      }
                      <Divider
                        className={"lightDivider"}
                        style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}
                      />
                    </div>
                  </Form>
                  <span className={"processDescription"}>(Standard Anodizing process)</span>
                  <Form
                    ref={anodizingForm}
                  >
                    <Card className={"inputInfoText fieldGroupCardSmall"}>
                      <div
                        className={surfaceCoatingData.anodizing.hasAnodizing ? "" : "disabledField"}
                        style={{display: "flex", alignItems: "center"}}
                      >
                        <Form.Item
                          label={"Price of Anodizing"}
                          name={"anodizingPrice"}
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={0}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeAnodizing}
                            disabled={!surfaceCoatingData.anodizing.hasAnodizing}
                          />
                        </Form.Item>
                        <Typography.Text style={{ marginLeft: 12}}>€/dm<sup>2</sup></Typography.Text>
                      </div>
                    </Card>
                  </Form>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <Form>
                    <div style={{ display: "flex", alignItems: "center"}}>
                      {isGelso ?
                        (<span className={"mainFromLabel"} style={{color: "white"}}>Galvanic Zinc Plating:</span>)
                        :
                        (
                          <Form.Item
                            label={<span className={"mainFromLabel"}>Galvanic Zinc Plating</span>}
                            valuePropName={"checked"}
                          >
                            <Checkbox
                              checked={surfaceCoatingData.galvanicZincPlating.hasGalvanicZincPlating}
                              onChange={(event) => {
                                setGalvanicZincPlating(event.target.checked)
                              }}
                              disabled={disabledGalvanicZincPlating}
                            />
                          </Form.Item>
                        )
                      }
                      <Divider
                        className={"lightDivider"}
                        style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}
                      />
                    </div>
                  </Form>
                  <span className={"processDescription"}>(Standard Galvanic Zinc Plating process)</span>
                  <Form
                    ref={galvanicZincPlatingForm}
                  >
                    <Card className={"inputInfoText fieldGroupCardSmall"}>
                      <div
                        className={surfaceCoatingData.galvanicZincPlating.hasGalvanicZincPlating ? "" : "disabledField"}
                        style={{display: "flex", alignItems: "center"}}
                      >
                        <Form.Item
                          label={"Price of Galvanic Zinc Plating"}
                          name={"galvanicZincPlatingPrice"}
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={0}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeGalvanicZincPlating}
                            disabled={!surfaceCoatingData.galvanicZincPlating.hasGalvanicZincPlating}
                          />
                        </Form.Item>
                        <Typography.Text style={{ marginLeft: 12}}>€/dm<sup>2</sup></Typography.Text>
                      </div>
                    </Card>
                  </Form>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <Form>
                    <div style={{ display: "flex", alignItems: "center"}}>
                      {isGelso ?
                        (<span className={"mainFromLabel"} style={{color: "white"}}>Thermal Treatment:</span>)
                        :
                        (
                          <Form.Item
                            label={<span className={"mainFromLabel"}>Thermal Treatment</span>}
                            valuePropName={"checked"}
                          >
                            <Checkbox
                              checked={surfaceCoatingData.heatTreatment?.hasHeatTreatment}
                              onChange={(event) => {
                                setHeatTreatment(event.target.checked)
                              }}
                              disabled={disabledHeatTreatment}
                            />
                          </Form.Item>
                        )
                      }
                      <Divider
                        className={"lightDivider"}
                        style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}
                      />
                    </div>
                  </Form>
                  <span className={"processDescription"}>(Standard Thermal Treatment processes)</span>
                  <Form
                    ref={heatTreatmentForm}
                  >
                    <Card className={"inputInfoText fieldGroupCardLarger"}>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {!isGelso &&
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                          <div className={!surfaceCoatingData.heatTreatment?.hasHeatTreatment ? "disabledField" : ""}
                               style={{display: "flex", alignItems: "center"}}
                          >
                            <Form.Item
                              name={"hasHardening"}
                              style={{marginBottom: 5, marginTop: 5}}
                              valuePropName={"checked"}
                              value={surfaceCoatingData.heatTreatment?.hardening?.hasHardening}
                            >
                              <Checkbox
                                className={"fieldGroupCheckbox"}
                                onChange={(e) => {
                                  changeHasHardening(e.target.checked)
                                }}
                                disabled={!surfaceCoatingData.heatTreatment?.hasHeatTreatment}
                              >
                                Has Hardening
                              </Checkbox>
                            </Form.Item>
                          </div>
                          <Divider type={"vertical"} style={{ height: "24px", margin: "0 8px 0 0", borderColor: "#838f94" }} />
                        </div>
                        }
                        <div className={!(surfaceCoatingData.heatTreatment?.hardening?.hasHardening && surfaceCoatingData.heatTreatment?.hasHeatTreatment) ? "disabledField" : ""}
                             style={{display: "flex", alignItems: "center"}}
                        >
                          <Form.Item
                            label={"Price of Hardening"}
                            name={"hardeningPrice"}
                            style={{marginBottom: 5, marginTop: 5}}
                            initialValue={surfaceCoatingData.heatTreatment?.hardening?.hardeningPrice}
                          >
                            <InputNumber
                              style={{minWidth: 30, marginLeft: 5}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changeHardening}
                              disabled={!(surfaceCoatingData.heatTreatment?.hardening?.hasHardening && surfaceCoatingData.heatTreatment?.hasHeatTreatment)}
                            />
                          </Form.Item>
                          <Typography.Text style={{ marginLeft: 12}}>€/kg</Typography.Text>
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {!isGelso &&
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                          <div className={surfaceCoatingData.heatTreatment?.hasHeatTreatment ? "" : "disabledField"}
                               style={{display: "flex", alignItems: "center"}}
                          >
                            <Form.Item
                              name={"hasCaseHardening"}
                              style={{marginBottom: 5, marginTop: 5}}
                              valuePropName={"checked"}
                              initialValue={surfaceCoatingData.heatTreatment?.caseHardening?.hasCaseHardening}
                            >
                              <Checkbox
                                className={"fieldGroupCheckbox"}
                                onChange={(e) => {
                                  changeHasCaseHardening(e.target.checked)
                                }}
                                disabled={!surfaceCoatingData.heatTreatment?.hasHeatTreatment}
                              >
                                Has Tempering
                              </Checkbox>
                            </Form.Item>
                          </div>
                          <Divider type={"vertical"} style={{ height: "24px", margin: "0 8px 0 0", borderColor: "#838f94" }} />
                        </div>
                        }
                        <div className={!(surfaceCoatingData.heatTreatment?.caseHardening?.hasCaseHardening && surfaceCoatingData.heatTreatment?.hasHeatTreatment) ? "disabledField" : ""}
                             style={{display: "flex", alignItems: "center"}}
                        >
                          <Form.Item
                            label={"Price of Tempering"}
                            name={"caseHardeningPrice"}
                            style={{marginBottom: 5, marginTop: 5}}
                            initialValue={surfaceCoatingData.heatTreatment?.caseHardening?.caseHardeningPrice}
                          >
                            <InputNumber
                              style={{minWidth: 30, marginLeft: 5}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changeCaseHardening}
                              disabled={!(surfaceCoatingData.heatTreatment?.caseHardening?.hasCaseHardening && surfaceCoatingData.heatTreatment?.hasHeatTreatment)}
                            />
                          </Form.Item>
                          <Typography.Text style={{ marginLeft: 12}}>€/kg</Typography.Text>
                        </div>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {!isGelso &&
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                          <div className={surfaceCoatingData.heatTreatment?.hasHeatTreatment ? "" : "disabledField"}
                               style={{display: "flex", alignItems: "center"}}
                          >
                            <Form.Item
                              name={"hasAnnealing"}
                              style={{marginBottom: 5, marginTop: 5}}
                              valuePropName={"checked"}
                              initialValue={surfaceCoatingData.heatTreatment?.annealing?.hasAnnealing}
                            >
                              <Checkbox
                                className={"fieldGroupCheckbox"}
                                onChange={(e) => {
                                  changeHasAnnealing(e.target.checked)
                                }}
                                disabled={!surfaceCoatingData.heatTreatment?.hasHeatTreatment}
                              >
                                Has Annealing
                              </Checkbox>
                            </Form.Item>
                          </div>
                          <Divider type={"vertical"} style={{ height: "24px", margin: "0 8px 0 0", borderColor: "#838f94" }} />
                        </div>
                        }
                        <div className={!(surfaceCoatingData.heatTreatment?.annealing?.hasAnnealing && surfaceCoatingData.heatTreatment?.hasHeatTreatment) ? "disabledField" : ""}
                             style={{display: "flex", alignItems: "center"}}
                        >
                          <Form.Item
                            label={"Price of Annealing"}
                            name={"annealingPrice"}
                            style={{marginBottom: 5, marginTop: 5}}
                            initialValue={surfaceCoatingData.heatTreatment?.annealing?.hasAnnealing}
                          >
                            <InputNumber
                              style={{minWidth: 30, marginLeft: 5}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changeAnnealing}
                              disabled={!(surfaceCoatingData.heatTreatment?.annealing?.hasAnnealing && surfaceCoatingData.heatTreatment?.hasHeatTreatment)}
                            />
                          </Form.Item>
                          <Typography.Text style={{ marginLeft: 12}}>€/kg</Typography.Text>
                        </div>
                      </div>
                    </Card>
                  </Form>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <Form>
                    <div style={{ display: "flex", alignItems: "center"}}>
                      {isGelso ?
                        (<span className={"mainFromLabel"} style={{color: "white"}}>Blasting:</span>)
                        :
                        (
                          <Form.Item
                            label={<span className={"mainFromLabel"}>Blasting</span>}
                            valuePropName={"checked"}
                          >
                            <Checkbox
                              checked={surfaceCoatingData.blasting?.hasBlasting}
                              onChange={(event) => {
                                setBlasting(event.target.checked)
                              }}
                              disabled={disabledBlasting}
                            />
                          </Form.Item>
                        )
                      }
                      <Divider
                        className={"lightDivider"}
                        style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}
                      />
                    </div>
                  </Form>
                  <span className={"processDescription"}>(Standard Blasting processes)</span>
                  <Form
                    ref={blastingForm}
                  >
                    <Card className={"inputInfoText fieldGroupCard"}>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {!isGelso &&
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                          <div className={surfaceCoatingData.blasting?.hasBlasting ? "" : "disabledField"}
                               style={{display: "flex", alignItems: "center"}}
                          >
                            <Form.Item
                              name={"hasGlassBeadBlasting"}
                              style={{marginBottom: 5, marginTop: 5}}
                              valuePropName={"checked"}
                              initialValue={surfaceCoatingData?.blasting?.glassBeadBlasting?.hasGlassBeadBlasting}
                            >
                              <Checkbox
                                className={"fieldGroupCheckbox"}
                                onChange={(e) => {
                                  changeHasGlassBeadBlasting(e.target.checked)
                                }}
                                disabled={!surfaceCoatingData.blasting?.hasBlasting}
                              >
                                Has Glass Bead Blasting
                              </Checkbox>
                            </Form.Item>
                          </div>
                          <Divider type={"vertical"} style={{ height: "24px", margin: "0 8px 0 0", borderColor: "#838f94" }} />
                        </div>
                        }
                        <div className={!(surfaceCoatingData.blasting?.glassBeadBlasting?.hasGlassBeadBlasting && surfaceCoatingData.blasting?.hasBlasting) ? "disabledField" : ""}
                             style={{display: "flex", alignItems: "center"}}
                        >
                          <Form.Item
                            label={"Price of Glass Bead Blasting"}
                            name={"glassBeadBlastingPrice"}
                            style={{marginBottom: 5, marginTop: 5}}
                            initialValue={surfaceCoatingData.blasting?.glassBeadBlasting?.glassBeadBlastingPrice}
                          >
                            <InputNumber
                              style={{minWidth: 30, marginLeft: 5}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changeGlassBeadBlasting}
                              disabled={!(surfaceCoatingData.blasting?.glassBeadBlasting?.hasGlassBeadBlasting && surfaceCoatingData.blasting?.hasBlasting)}
                            />
                          </Form.Item>
                          <Typography.Text style={{ marginLeft: 12}}>€/m<sup>2</sup></Typography.Text>
                        </div>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {!isGelso &&
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                          <div className={surfaceCoatingData.blasting?.hasBlasting ? "" : "disabledField"}
                               style={{display: "flex", alignItems: "center"}}
                          >
                            <Form.Item
                              name={"hasSandBlasting"}
                              style={{marginBottom: 10, marginTop: 5}}
                              valuePropName={"checked"}
                              initialValue={surfaceCoatingData?.blasting?.sandBlasting?.hasSandBlasting}
                            >
                              <Checkbox
                                className={"fieldGroupCheckbox"}
                                onChange={(e) => {
                                  changeHasSandBlasting(e.target.checked)
                                }}
                                disabled={!surfaceCoatingData.blasting?.hasBlasting}
                              >
                                Has Ceramic Bead Blasting
                              </Checkbox>
                            </Form.Item>
                          </div>
                          <Divider type={"vertical"} style={{ height: "24px", margin: "0 8px 0 0", borderColor: "#838f94" }} />
                        </div>
                        }
                        <div className={!(surfaceCoatingData.blasting?.sandBlasting?.hasSandBlasting && surfaceCoatingData.blasting?.hasBlasting) ? "disabledField" : ""}
                             style={{display: "flex", alignItems: "center"}}
                        >
                          <Form.Item
                            label={"Price of Ceramic Bead Blasting"}
                            name={"sandBlastingPrice"}
                            style={{marginBottom: 10, marginTop: 5}}
                            initialValue={surfaceCoatingData?.blasting?.sandBlasting?.sandBlastingPrice}
                          >
                            <InputNumber
                              style={{minWidth: 30, marginLeft: 5}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changeSandBlasting}
                              disabled={!(surfaceCoatingData.blasting?.sandBlasting?.hasSandBlasting && surfaceCoatingData.blasting?.hasBlasting)}
                            />
                          </Form.Item>
                          <Typography.Text style={{ marginLeft: 12}}>€/m<sup>2</sup></Typography.Text>
                        </div>
                      </div>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
          }
        </div>
      </Card>
    </React.Fragment>
  )
}