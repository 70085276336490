import * as React from 'react';
import {AutoSizer, MultiGrid} from 'react-virtualized';
import "./style.less";
import "../tableStyle.less";
import {InputNumber, Spin} from "antd";
import {EmptyTable} from "../EmptyTable";
import {LoadingOutlined} from "@ant-design/icons";
import {Trans} from 'react-i18next';
import {useEffect, useState} from "react";

const STYLE = {
  borderRadius: '0 0 5px 0'
};


export const ValueTable = (props) => {

  const [editColumn, setEditColumn] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [value, setValue] = useState(null);

  const {
    columns, rows, allAvailable,
    tabIndex, onChange, updateTable,
    setUpdateTable, isEditable,
    nestedTable, leftColumnWidth,
    gradeTabIndex, referenceHeight
  } = props;

  const editField = React.createRef()

  let multiGridRef = null;
  let nameInput = null;

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  })

  const refreshGrid = () => {
    if (multiGridRef) {
      multiGridRef.forceUpdateGrids()
    }
  }

  const changeValue = (value) => {
    setValue(value)
    refreshGrid()
  }

  const updateValue = (data) => {
    if (data.rowIndex && data.columnIndex) {
      if (rows[data.rowIndex - 1][data.columnIndex] !== data.value) {
        onChange({
          value: data.value,
          row: data.rowIndex - 1,
          col: data.columnIndex,
          tab: tabIndex,
          grade: gradeTabIndex,
          materialId: allAvailable[rows[data.rowIndex - 1][0]][columns[data.columnIndex]]
        }).then(()=>{
          refreshGrid()
        })
      }
    }
  }

  useEffect(() => {
    if (nameInput) {
      nameInput.focus();
    }
  }, [editColumn, editRow])

  useEffect(() => {
    if (updateTable) {
      setUpdateTable(false)
      refreshGrid()
    }
  }, [updateTable])

  const handleClickOutside = (event) => {
    if (!event.defaultPrevented) {
      if (editColumn && editField && editField.current && !editField.current.contains(event.target)) {
        updateValue({rowIndex: editRow, columnIndex: editColumn, value: value})
        setEditColumn(null)
        setEditRow(null)
        setValue(null)
      }
    }
  }

  const getClassName = (rowIndex, columnIndex) => {
    if (rowIndex > rows.length) {
      return "laserSpeedDisabledRow"
    }
    if (rowIndex === 0) {
      return "laserSpeedZeroRow"
    } else {
      if (columnIndex === 0) {
        if(nestedTable) {
          return "laserSpeedZeroRow"
        }
        return "laserSpeedLeftColumn"
      } else {
        let row = rows[rowIndex - 1]
        let name = row[0]
        let value = row[columnIndex]
        if (!value) {
          if (allAvailable[name][columns[columnIndex]]) {
            return "laserSpeedOddRow"
          } else {
            return "laserSpeedDisabledRow"
          }
        } else {
          return "laserSpeedOddRow"
        }
      }
    }
  }

  const _cellRenderer = ({columnIndex, key, rowIndex, style}) => {
    return (
      <div ref={ref => editField.current = ref} onClick={(e) => {
        if(rowIndex !== 0) {
          e.preventDefault()
          if(isEditable) {
            updateValue({rowIndex: editRow, columnIndex: editColumn, value: value})
            handleEditShow(columnIndex, rowIndex, rows[rowIndex - 1][columnIndex])
          }
        }
      }} className={getClassName(rowIndex, columnIndex)}
           key={key} style={{
        ...style,
        padding: 5,
        borderTop: rowIndex === 0 ? "1px solid #888888" : "none",
        borderBottom: columnIndex === 0 && nestedTable ? "none" : "1px solid #888888",
        borderRight: '2px solid #001529',
        display: 'inline-block',
        alignItems: 'center',
        right: -2
      }}>
        <div style={{fontSize: 12}} className={"cellText"}>
          {rowIndex === 0 ?
            <div className={"speedTableHeaderCellText"}>{columns[columnIndex]}</div>
            :
            (rows.length < rowIndex ?
              null
              :
              <div>
                {
                  editColumn && editRow && editColumn === columnIndex && editRow === rowIndex ?
                    <React.Fragment>
                      <div>
                        <InputNumber
                          ref={(input) => {
                            nameInput = input;
                          }}
                          className={"laserSpeedTableInput"}
                          step={0.1}
                          min={0}
                          defaultValue={rows[rowIndex - 1][columnIndex]}
                          style={{width: "100%", marginLeft: -5, fontSize: 13, lineHeight: 1.5}}
                          formatter={value => value.toString()}
                          onChange={(value) => {
                            changeValue(value)
                          }}
                          bordered={false}
                        />
                      </div>
                    </React.Fragment>
                    :
                    (columnIndex === 0 ?
                      <div className={nestedTable ? "nestedSpeedTableCellLeft yesSelect": "speedTableCellLeft yesSelect"}><Trans>{rows[rowIndex - 1][columnIndex]}</Trans></div>
                      :
                      <div className={"speedTableCellText"}>{rows[rowIndex - 1][columnIndex]}</div>
                    )
                }
              </div>
            )
          }
        </div>
      </div>
    );
  }

  const handleEditShow = (columnIndex, rowIndex, value) => {
    setEditColumn(columnIndex)
    setEditRow(rowIndex)
    setValue(value)
  }

  const _getColumnWidth = ({index}) => {
    return index === 0 ? leftColumnWidth : 55
  };

  const _getColumnCount = (columns, width) =>  {
    const count = Object.keys(columns).length
    if(leftColumnWidth + (count-1) * 55 < width) {
      return Math.floor((width-leftColumnWidth)/55) + 1
    }
    else {
      return count
    }
  }

  if (rows.length === 0) {
    return (
      <React.Fragment>
        <EmptyTable/>
      </React.Fragment>
      )
  } else {
    return (
      <div style={{height: referenceHeight || "100%", minHeight: referenceHeight}}>
        <AutoSizer>
          {({height, width}) => (
            <MultiGrid
              ref={(grid) => {
                multiGridRef = grid;
              }}
              fixedColumnCount={1}
              fixedRowCount={1}
              scrollToColumn={0}
              scrollToRow={0}
              cellRenderer={_cellRenderer}
              columnWidth={_getColumnWidth}
              columnCount={_getColumnCount(columns, width)}
              enableFixedColumnScroll
              enableFixedRowScroll
              height={referenceHeight || height}
              rowHeight={33}
              rowCount={rows.length < 6 ? 7 : rows.length+1}
              style={STYLE}
              classNameTopLeftGrid={"topLeftGrid"}
              classNameTopRightGrid={"topRightGrid"}
              classNameBottomLeftGrid={"bottomLeftGrid"}
              classNameBottomRightGrid={"bottomRightGrid"}
              width={width}
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}