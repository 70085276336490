import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {Layout, Row, Col, Divider, Spin, Card, Tag, Button, Tooltip} from "antd";

import { CompanySidebar } from '../../../components/company'
import { logoutUser } from '../../../actions/authActions'
import history from "../../../utils/history";
import cuttingMachineIcon from "../../../assets/icons/webps/cuttingMachines.webp";
import bendingIcon from "../../../assets/icons/webps/bending.webp";
import machiningIcon from "../../../assets/icons/webps/machining.webp";
import simpleMachiningIcon from "../../../assets/icons/webps/simpleMachining.webp";
import materialsIcon from "../../../assets/icons/webps/materials.webp";
import tubeCuttingIcon from "../../../assets/icons/webps/tubeCutting.webp";

import "./style.less"
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import {DropIcon} from "../../../assets/icons/drop";

import logo from "../../../assets/Gelso.png";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { LoadingOutlined } from "@ant-design/icons";
import { EmptyTable } from "../../../components/EmptyTable";
import {
  formatTableDate,
  getOrderStateName,
  tagColor,
  tagTextColor
} from "../../../utils/utility";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import {
  fetchArchivedCalculationCustomers, fetchProducersForOrder,
  producerFetchRecentAwaitingChanges,
  producerRefetchRecentAwaitingChanges
} from "../../../actions/ordersActions";
import {CustomerDraftStatisticsModal} from "../../../components/company/CustomerDraftStatisticsModal";
import PublicIcon from '@mui/icons-material/Public';


const checkPermission = (user, permission) => {
  return (user?.role === "gelsoAdmin" || (user?.role === "gelsoSubAdmin" && user?.permissions?.includes(permission)))
}

export const CompanyDashboard = (props) => {
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user)
  const role = useSelector(state => state.auth.user?.role)
  const isFetchingRecentOrderChanges = useSelector(state => state.orders.isFetchingRecentOrderChanges)
  const recentOrderChanges = useSelector(state => state.orders.recentOrderChanges)

  const [firstFetch, setFirstFetch] = useState(true);
  const [statsModalVisible, setStatsModalVisible] = useState(false);
  const [customers, setCustomers] = useState([])

  const dispatch = useDispatch()

  const refetchRecentChanges = () => {
    dispatch(producerRefetchRecentAwaitingChanges()).catch(() => {});
  }

  useEffect(() => {
    if (checkPermission(user, "orders")) {
      dispatch(producerFetchRecentAwaitingChanges()).catch(() => {});
      const intervalCall = setInterval(() => {
        refetchRecentChanges();
        setFirstFetch(false)
      }, 60000);
      return () => {
        clearInterval(intervalCall);
      };
    }
  }, [])

  const openStatsModal = () => {
    dispatch(fetchArchivedCalculationCustomers()).then((customers) => {
      setCustomers(customers)
    })
    dispatch(fetchProducersForOrder())
    setStatsModalVisible(true)
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{overflowX: "hidden"}}>
          <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
            <Row type="flex">
              <Col span={24}>
                <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                  <div>
                    <div className={"dashboardWelcome"}>
                      Welcome back, <span className={"dashboardWelcomePrimary"}>Gelso</span>.
                    </div>
                    {/*<div style={{fontWeight: 500, fontSize: 16, color: "white"}}>Use the quick navigation below to get to the desired page:</div>*/}
                  </div>
                  <div className={"gelsoLogo"}
                       onClick={() => {
                         window.open("https://www.gelso.ch/", '_blank').focus();
                       }}
                  >
                    <img src={logo} alt={"Gelso"} height={48}/>
                  </div>
                </div>
              </Col>
            </Row>
            <Divider style={{marginTop: 15, marginBottom: 6, borderColor: "#e9ecef"}}/>
            <Row gutter={[12, 0]}>
              {checkPermission(user, "orders") &&
                <Col span={24}>
                  <div
                    className={"dashboardContainerBox"}
                    style={{padding: "6px 6px 2px 6px"}}
                  >
                    <div className={"dashboardTitle"} style={{ display: "flex", justifyContent: "space-between"}}>
                      <span>Quotes</span>
                      {user.role === "gelsoAdmin" &&
                        <Tooltip
                          title={"Review statistics from customer draft parts."}
                          placement={"left"}
                        >
                          <Button
                            type={"primary"}
                            onClick={() => {
                              openStatsModal()
                            }}
                          >
                            <span style={{ fontWeight: 600 }}>Get Draft Statistics</span>
                          </Button>
                        </Tooltip>
                      }
                    </div>
                    <Row gutter={[6, 8]}>
                      {/*<Col xxl={12} xl={12} md={12} sm={24} xs={24}>*/}
                      <div className={"dashboardCard"} onMouseDown={(e) => {
                        if (e.button === 1) {
                          window.open("/pending", "_blank");
                        } else if (e.button === 0) {
                          history.push("/pending")
                        }
                      }}>
                        <div className={"dashboardCardTitleWrapper"}>
                          <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                            <PendingActionsIcon style={{fontSize: 22, color: "#f0bd27"}}/>
                          </div>
                          <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                            Pending Quotes
                          </div>
                        </div>
                        <div>
                          <div className={"dashboardText"}>Review and quote pending quotes.</div>
                          {/*<div className={"dashboardText"}>Quote the orders that need manual quotation.</div>*/}
                        </div>
                      </div>
                      {/*</Col>*/}
                      {/*<Col xxl={12} xl={12} md={12} sm={24} xs={24}>*/}
                      <div className={"dashboardCard"} onMouseDown={(e)=>{
                        if (e.button === 1) {
                          window.open("/awaiting", "_blank");
                        } else if (e.button === 0) {
                          history.push("/awaiting")
                        }
                      }}>
                        <div className={"dashboardCardTitleWrapper"}>
                          <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                            <HourglassEmptyOutlinedIcon style={{fontSize: 22, color: "#9966cc"}}/>
                          </div>
                          <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                            Awaiting Quotes
                          </div>
                        </div>
                        <div>
                          <div className={"dashboardText"}>View your awaiting quotes.</div>
                          <div className={"dashboardText"}>See the recent awaiting confirmations.</div>
                        </div>
                      </div>
                      {/*</Col>*/}
                      {/*<Col xxl={12} xl={12} md={12} sm={24} xs={24}>*/}
                      <div className={"dashboardCard"} onMouseDown={(e)=>{
                        if (e.button === 1) {
                          window.open("/processed", "_blank");
                        } else if (e.button === 0) {
                          history.push("/processed")
                        }
                      }}>
                        <div className={"dashboardCardTitleWrapper"}>
                          <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                            <InventoryOutlinedIcon style={{fontSize: 21, color: "#6bce34"}}/>
                          </div>
                          <div className={"dashboardCardTitle"}  style={{marginLeft: 5}}>
                            Processed Quotes
                          </div>
                        </div>
                        <div>
                          <div className={"dashboardText"}>Review your processed quotes.</div>
                          <div className={"dashboardText"}>These include all accepted and declined quotes.</div>
                        </div>
                      </div>
                    </Row>
                    <div style={{ width: "80%" }}>
                      <Divider style={{ borderColor: "#828486", margin: "12px 0 6px 0" }}/>
                    </div>
                  </div>
                </Col>
              }
              {(checkPermission(user, "bulk") || checkPermission(user, "producers") || checkPermission(user, "customers")) &&
                <Col span={24}>
                  <div
                    className={"dashboardContainerBox"}
                    style={{padding: "6px 6px 2px 6px"}}
                  >
                    <div className={"dashboardTitle"}>
                      Automation & Configuration
                    </div>
                    <Row gutter={[6, 8]}>
                      {checkPermission(user, "bulk") &&
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/calculation", "_blank");
                          } else if (e.button === 0) {
                            history.push("/calculation")
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                              <WysiwygOutlinedIcon style={{fontSize: 22, color: "#820ee3"}}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Calculation
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>Quickly calculate the price of production.</div>
                            <div className={"dashboardText"}>Turn calculations into quotes.</div>
                          </div>
                        </div>
                      }
                      {role === "gelsoAdmin" &&
                        // <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/employeeManagement", "_blank");
                          } else if (e.button === 0) {
                            history.push("/employeeManagement")
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                              <BadgeOutlinedIcon style={{fontSize: 22, color: "#7c9bb2"}}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Employees
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>Manage your employees.</div>
                            <div className={"dashboardText"}>Add new employees or change existing ones.</div>
                          </div>
                        </div>
                        // </Col>
                      }
                      {checkPermission(user, "producers") &&
                        // <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/producersManagement", "_blank");
                          } else if (e.button === 0) {
                            history.push("/producersManagement")
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                              <PrecisionManufacturingIcon style={{fontSize: 22, color: "#fc732c"}}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Producers
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>Manage your producers.</div>
                            <div className={"dashboardText"}>Add new producers or change existing ones.</div>
                          </div>
                        </div>
                        // </Col>
                      }
                      {checkPermission(user, "materials") &&
                      // <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                      <div className={"dashboardCard"} onMouseDown={(e) => {
                        if (e.button === 1) {
                          window.open("/defaultParameters", "_blank");
                        } else if (e.button === 0) {
                          history.push("/defaultParameters")
                        }
                      }}>
                        <div className={"dashboardCardTitleWrapper"}>
                          <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                            <PublicIcon style={{fontSize: 22, color: "#aae73f"}}/>
                          </div>
                          <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                            Default Parameters
                          </div>
                        </div>
                        <div>
                          <div className={"dashboardText"}>Define default parameters for each region.</div>
                          <div className={"dashboardText"}>Set up default parameter templates that producers can load up.</div>
                        </div>
                      </div>
                        // </Col>
                      }
                      {checkPermission(user, "customers") &&
                        // <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/internal-customer-relations", "_blank");
                          } else if (e.button === 0) {
                            history.push("/internal-customer-relations")
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                              <PeopleAltOutlinedIcon style={{fontSize: 22, color: "#0ac9c0"}}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5, maxWidth: 210}}>
                              Customers
                            </div>
                          </div>
                          <div>
                            <div>
                              <div className={"dashboardText"}>View all your customers.</div>
                              <div className={"dashboardText"}>Configure price adjustments for customers.</div>
                            </div>
                          </div>
                        </div>
                        // </Col>
                      }
                    </Row>
                    <div style={{ width: "80%" }}>
                      <Divider style={{ borderColor: "#828486", margin: "12px 0 6px 0" }}/>
                    </div>
                  </div>
                </Col>
              }
              {checkPermission(user, "materials") &&
                <Col span={24}>
                  <div className={"dashboardContainerBox"}
                       style={{padding: "6px 6px 2px 6px"}}
                  >
                    <div className={"dashboardTitle"}>
                      Production
                    </div>
                    <Row gutter={[6, 8]}>
                      {checkPermission(user, "materials") &&
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/materials?tab=material", "_blank");
                          } else if (e.button === 0) {
                            history.push("/materials", {tab: "7"})
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div className={"dashboardCardTitle"}>
                              {/*<MaterialIcon style={{marginLeft: 1, color: "#ec050d"}}/>*/}
                              <img src={materialsIcon} width={22} style={{ position: "relative", bottom: 2 }}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Materials
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View company materials.</div>
                            <div className={"dashboardText"}>Add available materials and their parameters.</div>
                          </div>
                        </div>
                      }
                      {checkPermission(user, "materials") &&
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/materials?tab=cutting", "_blank");
                          } else if (e.button === 0) {
                            history.push("/materials", {tab: "1"})
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                              {/*<TimelineIcon style={{fontSize: 21, color: "#d3b700"}}/>*/}
                              <img src={cuttingMachineIcon} width={21} style={{ position: "relative", bottom: 4 }}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Cutting Machines
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View your cutting machines.</div>
                            <div className={"dashboardText"}>Configure machine parameters and cutting speeds.</div>
                          </div>
                        </div>
                      }
                      {checkPermission(user, "materials") &&
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/materials?tab=bending", "_blank");
                          } else if (e.button === 0) {
                            history.push("/materials", {tab: "2"})
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 1}} className={"dashboardCardTitle"}>
                              {/*<SheetIcon/>*/}
                              <img src={bendingIcon} width={21} style={{ position: "relative", bottom: 4 }}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Bending
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View your bending parameters.</div>
                            <div className={"dashboardText"}>Configure your bending machines.</div>
                          </div>
                        </div>
                      }
                      {checkPermission(user, "materials") &&
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/materials?tab=simple-machining", "_blank");
                          } else if (e.button === 0) {
                            history.push("/materials", {tab: "3"})
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 1}} className={"dashboardCardTitle"}>
                              {/*<CounterIcon/>*/}
                              <img src={simpleMachiningIcon} width={19} style={{ position: "relative", bottom: 4, marginRight: 1 }}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Simple Machining
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View simple machining settings.</div>
                            <div className={"dashboardText"}>Adjust simple machining services and parameters.</div>
                          </div>
                        </div>
                      }
                      {checkPermission(user, "materials") &&
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/materials?tab=machining", "_blank");
                          } else if (e.button === 0) {
                            history.push("/materials", {tab: "4"})
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 1}} className={"dashboardCardTitle"}>
                              {/*<CounterIcon/>*/}
                              <img src={machiningIcon} width={21} style={{ position: "relative", bottom: 4, marginRight: 1 }}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Machining
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View machining settings.</div>
                            <div className={"dashboardText"}>Adjust machining services and parameters.</div>
                          </div>
                        </div>
                      }
                      {checkPermission(user, "materials") &&
                      <div className={"dashboardCard"} onMouseDown={(e) => {
                        if (e.button === 1) {
                          window.open("/materials?tab=tubes", "_blank");
                        } else if (e.button === 0) {
                          history.push("/materials", {tab: "5"})
                        }
                      }}>
                        <div className={"dashboardCardTitleWrapper"}>
                          <div className={"dashboardCardTitle"}>
                            <img src={tubeCuttingIcon} width={21} style={{ position: "relative", bottom: 3 }}/>
                          </div>
                          <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                            Tube Cutting
                          </div>
                        </div>
                        <div>
                          <div className={"dashboardText"}>View your tube cutting machines.</div>
                          <div className={"dashboardText"}>Configure machine parameters and cutting speeds.</div>
                        </div>
                      </div>
                      }
                      {checkPermission(user, "materials") &&
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/materials?tab=coating", "_blank");
                          } else if (e.button === 0) {
                            history.push("/materials", {tab: "6"})
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            {/*"#9de80b"*/}
                            <div className={"dashboardCardTitle"}>
                              <DropIcon style={{fontSize: 15, color: "#e3bf5b"}}/> {/*#5c06f1*/}
                              {/*<img src={coatingIcon} width={21} style={{ position: "relative", bottom: 4 }}/>*/}
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Surface Treatments
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View surface treatment settings.</div>
                            <div className={"dashboardText"}>Adjust coating services and parameters.</div>
                          </div>
                        </div>
                      }
                    </Row>
                    <div style={{ width: "80%" }}>
                      <Divider style={{ borderColor: "#828486", margin: "12px 0 6px 0" }}/>
                    </div>
                  </div>
                </Col>
              }
              {checkPermission(user, "orders") &&
                <Col span={24}>
                  <div
                    className={"dashboardContainerBox dashboardAwaitingTable"}
                    style={{padding: "6px 6px 2px 6px"}}
                  >
                    <div className={"dashboardTitle"}>
                      Recent Quote Changes
                    </div>

                    <div style={{marginTop: 5, marginBottom: 10}}>
                      <Spin
                        spinning={isFetchingRecentOrderChanges && firstFetch}
                        wrapperClassName={"recentChangesSpin"}
                        indicator={<LoadingOutlined style={{ fontSize: 48, marginTop: -24, marginLeft: -24 }} spin />}
                      >
                        <div
                          className={"recentConfirmationsBox"}
                          style={{ minHeight: 224, maxHeight: 400 }}
                        >
                          {recentOrderChanges.length === 0 ?
                            <div className={"emptyRecentAwaiting"} style={{ minHeight: "210px"}}>
                              {
                                !isFetchingRecentOrderChanges &&
                                <EmptyTable text={"No Recent Quote Changes."}/>
                              }
                            </div>
                            :
                            (recentOrderChanges.map(item => (
                              <Card
                                className={"bendCard awaitingListItem"}
                                style={{height: 45}}
                                bodyStyle={{padding: "8px 12px 8px 12px", height: "100%", cursor: "pointer"}}
                                key={item.id}
                                onMouseDown={(e) => {
                                  if (e.button === 1) {
                                    let win;
                                    if(item.state === "accepted" || item.state === "inProgress") {
                                      win = window.open(`/completed-order?order=${item.id}`, "_blank");
                                    } else if (item.state === "completed" || item.state === "declined") {
                                      win = window.open(`/completed-order?order=${item.id}`, "_blank");
                                    } else if (item.state === "quoted" || item.state === "awaitingQuote") {
                                      win = window.open(`/pending-order?order=${item.id}`, "_blank");
                                    } else if (item.state === "awaitingConfirmation") {
                                      win = window.open(`/awaiting-order?order=${item.id}`, "_blank");
                                    }
                                    win.focus();
                                  } else if (e.button === 0) {
                                    if(item.state === "accepted" || item.state === "inProgress") {
                                      history.push(`/completed-order?order=${item.id}`)
                                    } else if (item.state === "completed" || item.state === "declined") {
                                      history.push(`/completed-order?order=${item.id}`)
                                    } else if (item.state === "quoted" || item.state === "awaitingQuote") {
                                      history.push(`/pending-order?order=${item.id}`)
                                    } else if (item.state === "awaitingConfirmation") {
                                      history.push(`/awaiting-order?order=${item.id}`)
                                    }
                                  }
                                }}
                              >
                                <div style={{ display: "flex", justifyContent: "space-between", height: "100%"}}>
                                  <div style={{ display: "flex", height: "100%"}}>
                                    <div className={"recentStatusLeft"} style={{width: 140}}>
                                      <div>Quote {item.name}</div>
                                    </div>
                                    <div style={{width: 180, fontSize: 13, fontWeight: 500, marginLeft: 10, lineHeight: 2}}>
                                      <div>{item.customerName}</div>
                                    </div>
                                  </div>
                                  <div align={"left"} className={"recentStatusLeft"} style={{marginRight: 8}}>
                                    <div style={{fontSize: 12, fontWeight: 500}}>Changed: {formatTableDate(item.lastStateChangeDate)}</div>
                                  </div>
                                  <div style={{ display: "flex", height: "100%", alignItems: "center", alignSelf: "center"}}>
                                    <div>
                                      <Tag
                                        color={tagColor(item.previousState)}
                                        style={{fontWeight: 600, width: 96, textAlign: "center", color: tagTextColor(item.previousState)}}
                                      >
                                        {getOrderStateName(item.previousState)}
                                      </Tag>
                                    </div>
                                    <div style={{marginRight: 8}}>
                                      <ArrowRightAltIcon style={{position: "relative", width: 60, top: 3, fontSize: 28, color: "#65757c"}}/>
                                    </div>
                                    <div>
                                      <Tag
                                        color={tagColor(item.state)}
                                        style={{fontWeight: 600, width: 96, textAlign: "center", color: tagTextColor(item.state)}}
                                      >
                                        {getOrderStateName(item.state)}
                                      </Tag>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            )))
                          }
                        </div>
                      </Spin>
                    </div>
                  </div>
                </Col>
              }
            </Row>
          </div>
        </div>
      </Layout.Content>

      <CustomerDraftStatisticsModal
        visible={statsModalVisible}
        setVisible={setStatsModalVisible}
        customers={customers}
      />

    </Layout>
  )
}
