import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import history from '../utils/history';

import authReducer from './authReducer'
import producersReducer from './producersReducer'
import producerParamsReducer from "./producerParamsReducer";
import materialsReducer from './materialsReducer'
import companySettingsReducer from './companySettingsReducer'
import customersReducer from "./customersReducer";
import customerSettingsReducer from "./customerSettingsReducer";
import adminReducer from "./adminReducer";
import customerRelationsReducer from "./customerRelationsReducer";
import bendingReducer from "./bendingReducer";
import simpleMachiningReducer from "./simpleMachiningReducer";
import surfaceCoatingReducer from "./surfaceCoatingReducer";
import tubeCuttingReducer from "./tubeCuttingReducer";
import cuttingMachinesReducer from "./cuttingMachinesReducer";
import ordersReducer from "./ordersReducer";
import corporateCustomersReducer from "./corporateCustomersReducer";
import subAdminReducer from "./subAdminReducer";
import utilReducer from "./utilReducer";
import machiningReducer from "./machiningReducer";
import orderReducer from "./orderReducer";
import defaultMaterialsReducer from "./defaultMaterialsReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  util: utilReducer,
  producers: producersReducer,
  producerParams: producerParamsReducer,
  subAdmins: subAdminReducer,
  customers: customersReducer,
  corporateCustomers: corporateCustomersReducer,
  materials: materialsReducer,
  bending: bendingReducer,
  simpleMachining: simpleMachiningReducer,
  machining: machiningReducer,
  surfaceCoating: surfaceCoatingReducer,
  tubeCutting: tubeCuttingReducer,
  cuttingMachines: cuttingMachinesReducer,
  router: connectRouter(history),
  companySettings: companySettingsReducer,
  customerSettings: customerSettingsReducer,
  admin: adminReducer,
  customerRelations: customerRelationsReducer,
  order: orderReducer,
  orders: ordersReducer,
  defaultMaterialsData: defaultMaterialsReducer
})

export default rootReducer
