import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, Tabs, Form, Spin, Row, Col, Divider, Modal, InputNumber, Typography} from "antd";

import MaterialTableContainer from "../../MaterialTableContainer";
import {ValueTable} from "../../MaterialTables/ValueTable";

import {InfoCircleOutlined, LoadingOutlined} from "@ant-design/icons";

import {MachiningMaterialList} from "../../MachiningMaterialList";
import MaterialTableContainerNested from "../../MaterialTableContainerNested";
import { ModalTable } from "../../MaterialTables/ModalTable";
import {Trans} from "react-i18next";
import {debounce} from "lodash";
import {EmptyTable} from "../../MaterialTables/EmptyTable";
import "./style.less";
import {TableHeader} from "../../MaterialTables/TableHeader";

const { TabPane } = Tabs;

export const MaterialsTab = (props) => {
  const {
    selectedTab, socketConnected, changeMaterialPrice,
    changeBaseMaterialCertificateParams, changeAssemblyMaterialCertificateParams,
    changeMachiningMaterialPrice, changeTubeMaterialPrice,
    updateMaterialPrice, fetchMaterialPrices, materialPrices,
    isFetchingMaterialPrices, fetchStandardTubeMaterialPrices,
    fetchTubeMaterialPrices, tubeCuttingMaterials,
    standardTubeMaterialPrices, isFetchingTubeMaterialPrices,
    isFetchingStandardTubeMaterialPrices, hasTubeCutting,
    updateTubeMaterialPrice, updateStandardTubeMaterialPrice,
    fetchMachiningMaterials, isFetchingMachiningMaterials,
    machiningMaterials, hasMachining, updateMachiningMaterialPrice,
    fetchMaterialCertificatePrices, changeStandardTubeMaterialPrice,
    updateBaseMaterialCertificatePrice, updateAssemblyMaterialCertificatePrice,
    baseMaterialCertFee, assemblyMaterialCertFee,
    isGelso = false, headerHeight = 0, editDefaultMode = false
  } = props

  const [shouldUpdatePrices, setShouldUpdatePrices] = useState(false)
  const [shouldUpdateMachiningTable, setShouldUpdateMachiningTable] = useState(false);
  const [shouldUpdateTubePrices, setShouldUpdateTubePrices] = useState(false)
  const [selectedTubeData, setSelectedTubeData] = useState({})
  const [tubeModalVisible, setTubeModalVisible] = useState(false);

  let showEditInfoText = editDefaultMode;
  let tableHeightOffset = headerHeight > 0 ? headerHeight + 60 : 0;

  const isFetchingMaterialCertPrices = useSelector(state => state.materials.isFetchingMaterialCertPrices)
  const isFetchingDefaultMaterialCertPrices = useSelector(state => state.defaultMaterialsData.isFetchingMaterialCertPrices)

  const selectedRegion = useSelector(state => state.defaultMaterialsData.selectedRegion)

  const certificateForm = React.createRef();
  const initialRegionMount = useRef(true);
  const selectedRegionRef = useRef(selectedRegion)

  const dispatch = useDispatch()

  const formatData = (data) => {
    return editDefaultMode ? {...data, region: selectedRegionRef.current} : data
  }

  const initialize = () => {
    if (selectedTab === "7") {
      dispatch(fetchMaterialPrices(formatData({}))).then(()=>{
        setShouldUpdatePrices(true)
      })
      dispatch(fetchTubeMaterialPrices(formatData({}))).then(()=>{
        setShouldUpdateTubePrices(true)
      })
      dispatch(fetchStandardTubeMaterialPrices(formatData({})))
      dispatch(fetchMachiningMaterials(formatData({}))).then(() => {
        setShouldUpdateMachiningTable(true)
      })
      dispatch(fetchMaterialCertificatePrices(formatData({})))
    }
  }

  const reloadTabKey = useSelector(state => state.defaultMaterialsData.reloadTabKey)
  useEffect(() => {
    if (reloadTabKey === selectedTab) {
      initialize()
    }
  }, [reloadTabKey])

  useEffect(()=>{
    initialize()
  }, [selectedTab])

  useEffect(() => {
    selectedRegionRef.current = selectedRegion
    if (initialRegionMount.current) {
      initialRegionMount.current = false
    } else {
      initialize()
    }
  }, [selectedRegion])

  useEffect(() => {
    certificateForm.current.setFieldsValue({
      baseMaterialCertFee: baseMaterialCertFee
    })
  }, [baseMaterialCertFee])

  useEffect(() => {
    certificateForm.current.setFieldsValue({
      assemblyMaterialCertFee: assemblyMaterialCertFee
    })
  }, [assemblyMaterialCertFee])

  const handleMaterialParams = (params) => {
    if (editDefaultMode) {
      return {...params, region: selectedRegion}
    } return params
  }

  const changePrice = (params) => {
    changeMaterialPrice(formatData({materialId: params.materialId, price: params.value}))
    dispatch(updateMaterialPrice(params))
    return Promise.resolve(10)
  }

  const changeTubeMaterial = (params) => {
    changeTubeMaterialPrice(formatData({grade: params.materialId, price: params.value}))
    dispatch(updateTubeMaterialPrice(params))

    dispatch(fetchStandardTubeMaterialPrices(formatData({groupName: params.groupName}))).then(()=>{})

    return Promise.resolve(10)
  }

    const changeStandardTubeMaterial = (params) => {
    changeStandardTubeMaterialPrice(formatData({materialId: params.materialId, price: params.value}))
    dispatch(updateStandardTubeMaterialPrice({type: 'circle', ...params}))
    return Promise.resolve(10)
  }

  const changeMachiningMaterial = (params) => {
    changeMachiningMaterialPrice(formatData({materialId: params.materialId, price: params.value}))
    dispatch(updateMachiningMaterialPrice(params))
    return Promise.resolve(10)
  }

  const handleOpenTubeMaterialModal = (params) => {
    setSelectedTubeData({
      gradeName: params.gradeName,
      materialId: params.materialId,
      groupName: params.groupName,
      x: params.materialThickness,
      y: params.materialGrade,
      tab: params.tab,
      grade: params.gradeTabIndex,
      data: params.tubeData,
      col: params.col,
      row: params.row
    })
    setTubeModalVisible(true);
  }

  const handleCloseTubeMaterialModal = () => {
    setTubeModalVisible(false);
    setSelectedTubeData({});
  }

  const handleStandardTubePriceChange = (data) => {
    const pomData = {
      type: 'square',
      tab: data.data.tab,
      grade: data.data.grade,
      row: data.data.row,
      col: data.data.col,
      elem: data.index,
      value: data.value
    }
    changeStandardTubeMaterialPrice(formatData({materialId: data.materialId, price: data.value}))
    dispatch(updateStandardTubeMaterialPrice(pomData))
  }

  const debouncedStandardTubePriceChange = useCallback(
    debounce(data => handleStandardTubePriceChange(data), 800),
    []
  );

  const changeStandardTubePrice = (data) => {
    debouncedStandardTubePriceChange(data)
  }

  /*----------- Base Part Certificate -----------*/

  const changeBaseCertificatePriceData = (data) => {
    changeBaseMaterialCertificateParams(formatData(data))
    dispatch(updateBaseMaterialCertificatePrice(data["baseMaterialCertFee"]))
  }

  const debouncedBaseCertificatePrice = useCallback(
    debounce(data => changeBaseCertificatePriceData(data), 800),
    []
  );

  const changeBaseCertificatePrice = () => {
    debouncedBaseCertificatePrice({
      "baseMaterialCertFee": certificateForm.current.getFieldValue("baseMaterialCertFee")
    })
  }

  /*----------- Assembly Part Certificate -----------*/

  const changeAssemblyCertificatePriceData = (data) => {
    changeAssemblyMaterialCertificateParams(formatData(data))
    dispatch(updateAssemblyMaterialCertificatePrice(data["assemblyMaterialCertFee"]))
  }

  const debouncedAssemblyCertificatePrice = useCallback(
    debounce(data => changeAssemblyCertificatePriceData(data), 800),
    []
  );

  const changeAssemblyCertificatePrice = () => {
    debouncedAssemblyCertificatePrice({
      "assemblyMaterialCertFee": certificateForm.current.getFieldValue("assemblyMaterialCertFee")
    })
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard tabMaterialCardMargin"} bodyStyle={{paddingBottom: 10, paddingTop: 8}}>
        <div style={{height: `calc(100vh - 94px - ${headerHeight}px)`, overflowY: materialPrices.length === 0 ? "hidden" : "auto", overflowX: "hidden", paddingRight: 15}}>
          <Row>
            <Col span={24}>
              {showEditInfoText &&
                <div style={{ marginTop: 4 }}>
                  <Card className={"defaultMachineCard"}>
                    <InfoCircleOutlined className={"editMaterialPageInfoIcon"}/>
                    <div style={{ marginLeft: 6 }}>
                      This page represents the default regional parameters for machining, sheet metal and tube cutting materials.
                    </div>
                  </Card>
                  <Divider style={{width: "50%", minWidth: "0%", margin: "8px 0 8px 0"}}/>
                </div>
              }
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2 }}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <span className={"mainFromLabel"} style={{color: "white", fontSize: 18, fontWeight: 600}}>Material Certificates:</span>
                </div>
                <Form ref={certificateForm}
                      style={{ width: "100%", marginLeft: 12}}
                >
                  <Spin
                    spinning={isFetchingMaterialCertPrices || isFetchingDefaultMaterialCertPrices || !socketConnected}
                    style={{zIndex: 1}}
                    indicator={<LoadingOutlined style={{ fontSize: 32, position: "absolute", left: 237, top: 17 }} spin />}
                  >
                    <Card
                      style={{ width: "100%"}}
                      bodyStyle={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 2 }}
                    >
                      <div style={{display: "flex", alignItems: "center", marginLeft: 12}}>
                        <Form.Item
                          label={"Base Part Cost"}
                          name={"baseMaterialCertFee"}
                          style={{marginBottom: 5, marginTop: 5}}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeBaseCertificatePrice}
                            disabled={isFetchingMaterialCertPrices || isFetchingDefaultMaterialCertPrices}
                          />
                        </Form.Item>
                        <Typography.Text className={"inputInfoText"} style={{marginLeft: 12}}>€</Typography.Text>
                      </div>
                      <Divider type={"vertical"} style={{ height: "24px", margin: "0 12px", borderColor: "#838f94" }} />
                      <div style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Assembly Part Cost"}
                          name={"assemblyMaterialCertFee"}
                          style={{marginBottom: 5, marginTop: 5}}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeAssemblyCertificatePrice}
                            disabled={isFetchingMaterialCertPrices || isFetchingDefaultMaterialCertPrices}
                          />
                        </Form.Item>
                        <Typography.Text className={"inputInfoText"} style={{marginLeft: 12}}>€</Typography.Text>
                      </div>
                    </Card>
                  </Spin>
                </Form>
              </div>
            </Col>
            <Col flex={"285px"}>
              <div style={{display: "flex", alignItems: "center", marginBottom: 2}}>
                <span className={"mainFromLabel"} style={{color: "white", fontSize: 18, fontWeight: 600}}>Machining:</span>
                <Divider className={"lightDivider"} style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
              </div>
              <Spin
                spinning={isFetchingMachiningMaterials || !socketConnected}
                wrapperClassName={"machiningMaterialsSpinWrapper"}
                style={{zIndex: 1}}
                indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-12px, -8px)" }} spin />}
              >
                {(machiningMaterials.availableMaterials.length === 0 || !hasMachining) ?
                  <Card
                    bordered={false}
                    style={{borderRadius: 5, width: 285}}
                    bodyStyle={{
                      border: "1px solid #f0f0f0",
                      borderRadius: 5,
                      height: `100%`,
                      minHeight: `calc(100vh - 192px - ${tableHeightOffset}px)`
                    }}
                  >
                    {(!hasMachining && !isFetchingMachiningMaterials)  &&
                      <div style={{width: "100%", height: "60%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{fontSize: 16, fontWeight: 600, color: "#aaaaaa", textAlign: "center"}}>
                          You do not have Machining enabled
                        </div>
                      </div>
                    }
                  </Card>
                  :
                  <div>
                    <TableHeader columns={[
                      {title: "Name", width: 130},
                      {title: "Price (€/kg)", width: 140}
                    ]}/>
                    <Card
                      className={"tableCard"}
                      style={{width: 285}}
                      bodyStyle={{
                        height: `calc(100vh - 232px - ${tableHeightOffset}px)`,
                        maxWidth: 300,
                        minHeight: editDefaultMode ? 592 : 670,
                      }}
                    >
                      <MachiningMaterialList
                        rows={machiningMaterials.availableMaterials}
                        allAvailable={machiningMaterials.allAvailable}
                        onChange={changeMachiningMaterial}
                        updateTable={shouldUpdateMachiningTable}
                        setUpdateTable={setShouldUpdateMachiningTable}
                        isEditable={true}
                      />
                    </Card>
                  </div>
                }
              </Spin>
            </Col>
            <Divider type="vertical" style={{height: `calc(100vh - 250px - ${tableHeightOffset}px)`, margin: "13px 15px 0 15px", borderColor: "#1e1e1e"}}/>
            <Col flex={"auto"}>
              <div style={{display: "flex", alignItems: "center", marginBottom: 2}}>
                <span className={"mainFromLabel"} style={{color: "white", fontSize: 18, fontWeight: 600}}>Sheet Metal:</span>
                <Divider className={"lightDivider"} style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
              </div>
                <div className={"materialsSheetMetalContainer"} style={{width: "100%", display: "flex"}}>
                  <Spin spinning={isFetchingMaterialPrices || !socketConnected}
                        style={{zIndex: 1, width: "100%"}}
                        indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
                  >
                    {materialPrices.length === 0 ?
                      <Card
                        bordered={false}
                        bodyStyle={{border: "1px solid #f0f0f0", borderRadius: 5, height: editDefaultMode ? 276 : 336, width: "100%"}}
                        style={{marginBottom: 0, borderRadius: 5, width: "100%"}}
                      >
                      </Card>
                      :
                      <Tabs
                        style={{marginLeft: -10, marginBottom: 0, width: "100%"}}
                        type="card"
                        className={"laserSpeedTabs"}
                      >
                        {materialPrices.map((item, index) => {
                          return (
                            <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                              <MaterialTableContainer
                                tableText={"Material Price (€/kg)"}
                                xAxisText={"Available Thickness (mm)"}
                                yAxisText={"Material Grade"}
                                xAxisStyle={{marginTop: 12, marginBottom: -12}}
                              >
                                <ValueTable
                                  columns={item.columns}
                                  rows={item.rows}
                                  allAvailable={item.allAvailable}
                                  tabIndex={index}
                                  onChange={changePrice}
                                  updateTable={shouldUpdatePrices}
                                  setUpdateTable={setShouldUpdatePrices}
                                  leftColumnWidth={140}
                                  referenceHeight={editDefaultMode ? 188 : 248}
                                  isEditable={true}
                                />
                              </MaterialTableContainer>
                            </TabPane>
                          )
                        })}
                      </Tabs>
                     }
                  </Spin>
                </div>
              <div style={{display: "flex", alignItems: "center", marginBottom: 2, marginTop: 4}}>
                <span className={"mainFromLabel"} style={{color: "white", fontSize: 18, fontWeight: 600}}>Tube Cutting:</span>
                <Divider className={"lightDivider"} style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
              </div>
                <div style={{width: "100%", display: "flex" }}>
                  <Row gutter={15} style={{width: "100%", marginRight: 0, marginLeft: 0}}> {/* AAAAAAAA */}
                    <Col flex="285px" style={{paddingLeft: 0}}>
                      <Spin
                        spinning={isFetchingTubeMaterialPrices || !socketConnected}
                        wrapperClassName={"tubeCuttingMaterialsSpinWrapper"}
                        style={{zIndex: 1}}
                        indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-25%, -50%)" }} spin />}
                      >
                        {(tubeCuttingMaterials.availableMaterials.length === 0 || !hasTubeCutting) ?
                          <Card
                            bordered={false}
                            style={{marginBottom: 0, borderRadius: 5}}
                            bodyStyle={{
                              border: "1px solid #f0f0f0",
                              borderRadius: 5,
                              height: "100%",
                              minHeight: `calc(100vh - 562px - ${headerHeight}px)`,
                              width: 285
                            }}
                          >
                            {(!hasTubeCutting && !isFetchingTubeMaterialPrices)  &&
                              <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <div style={{fontSize: 16, fontWeight: 600, color: "#aaaaaa", textAlign: "center"}}>
                                  You do not have Tube Cutting enabled
                                </div>
                              </div>
                            }
                          </Card>
                          :
                          <div>
                            <TableHeader columns={[
                              {title: "Name", width: 180},
                              {title: "Price (€/kg)", width: 90}
                            ]}/>
                            <Card
                              className={"tableCard"}
                              style={{width: 285}}
                              bodyStyle={{
                                maxWidth: 300,
                                height: `calc(100vh - 603px - ${headerHeight}px)`,
                                minHeight: editDefaultMode ? 280 : 299
                              }}
                            >
                              <MachiningMaterialList
                                rows={tubeCuttingMaterials.availableMaterials}
                                allAvailable={tubeCuttingMaterials.allAvailable}
                                onChange={changeTubeMaterial}
                                updateTable={shouldUpdateTubePrices}
                                setUpdateTable={setShouldUpdateTubePrices}
                                isEditable={true}
                                leftWidth={190}
                                rightWidth={70}
                                gradeAlignLeft={true}
                              />
                            </Card>
                          </div>
                        }
                      </Spin>
                    </Col>
                    <Col flex="auto" style={{paddingRight: 0}}>
                      <Spin
                      spinning={isFetchingStandardTubeMaterialPrices || !socketConnected}
                      style={{zIndex: 1}}
                      wrapperClassName={"largeTubeTable"}
                      indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
                    >
                      {(standardTubeMaterialPrices.length === 0 || !hasTubeCutting) ?
                        <Card
                          bordered={false}
                          style={{marginBottom: 0, borderRadius: 5, width: "100%"}}
                          bodyStyle={{
                            border: "1px solid #f0f0f0",
                            borderRadius: 5,
                            height: "100%",
                            minHeight: `calc(100vh - 562px - ${headerHeight}px)`
                          }}
                        >
                          {(!hasTubeCutting && !isFetchingStandardTubeMaterialPrices)  &&
                            <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <div style={{fontSize: 18, fontWeight: 600, color: "#aaaaaa", textAlign: "center"}}>
                                You do not have Tube Cutting enabled
                              </div>
                            </div>
                          }
                        </Card>
                        :
                        <Tabs
                          style={{marginLeft: -10, marginBottom: 0}}
                          type="card"
                          className={"laserSpeedTabs"}
                        >
                          {standardTubeMaterialPrices.map((group, index) => {
                            return (
                              <TabPane className={"materialsTabPane tubesTabPane"} tab={group.groupName} key={index}>
                                <Card
                                  className={'laserSpeedCard'}
                                  style={{ height: "100%" }}
                                  bodyStyle={{
                                    padding: "8px 0 0 0",
                                    minHeight: editDefaultMode ? 290 : 376,
                                    height:  editDefaultMode ? `calc(100vh - 678px - ${headerHeight}px)` : ``
                                  }}
                                >
                                  {group.grades.length === 0 ?
                                    <div className={"emptyTableContainer"} style={{margin: "0 12px 10px 12px"}}>
                                      <EmptyTable height={editDefaultMode ? 270 : 356} text={"No General Grade Price Set"}/>
                                    </div>
                                    :
                                    <Tabs
                                      style={{marginBottom: 0}}
                                      type="card"
                                      tabPosition="left"
                                      className={"laserSpeedTabs gradeSideTabs"} // #Todo height?
                                    >
                                      {group.grades.map((item, subIndex) => {
                                        return (
                                          <TabPane style={{paddingLeft: 0}} tab={item.gradeName} key={subIndex}>
                                            {item.tubeType === 'tube' ?
                                              <MaterialTableContainerNested
                                                tableText={`Tube Price (€/kg)`}
                                                xAxisText={"X dimension (mm)"}
                                                yAxisText={"Y dimension (mm)"}
                                                headerHeight={headerHeight}
                                              >
                                                <ModalTable
                                                  columns={item.columns}
                                                  rows={item.rows}
                                                  allAvailable={item.allAvailable}
                                                  tabIndex={index}
                                                  gradeTabIndex={subIndex}
                                                  onClick={handleOpenTubeMaterialModal}
                                                  groupName={group.groupName}
                                                  gradeName={item.gradeName}
                                                  updateTable={false}
                                                  setUpdateTable={()=>{}}
                                                  activeLoadingTabs={{}}
                                                  nestedTable={true}
                                                  isEditable={true}
                                                />
                                              </MaterialTableContainerNested>
                                              :
                                              <MaterialTableContainerNested
                                                tableText={`Tube Price (€/kg)`}
                                                xAxisText={"Diameter (mm)"}
                                                yAxisText={"Thickness (mm)"}
                                                headerHeight={headerHeight}
                                              >
                                                <ValueTable
                                                  columns={item.columns}
                                                  rows={item.rows}
                                                  allAvailable={item.allAvailable}
                                                  tabIndex={index}
                                                  gradeTabIndex={subIndex}
                                                  onChange={changeStandardTubeMaterial}
                                                  updateTable={false}
                                                  setUpdateTable={()=>{}}
                                                  nestedTable={true}
                                                  leftColumnWidth={45}
                                                  referenceHeight={editDefaultMode ? 222 : 0}
                                                  isEditable={true}
                                                />
                                              </MaterialTableContainerNested>
                                            }
                                          </TabPane>
                                        )
                                      })}
                                    </Tabs>
                                  }
                                </Card>
                              </TabPane>
                            )
                          })}
                        </Tabs>
                      }
                    </Spin>
                    </Col>
                  </Row>
                </div>
            </Col>
          </Row>
        </div>
      </Card>
      <Modal
        visible={tubeModalVisible}
        onCancel={handleCloseTubeMaterialModal}
        title={"Tube Prices"}
        footer={<div style={{ height: 20 }}/>}
        centered={true}
        bodyStyle={{ height: 660 }}
        destroyOnClose={true}
        zIndex={1}
        width={550}
      >
        <React.Fragment>
          <div style={{ fontWeight: 500 }}>
            <div style={{color: "white"}}>
              <div>
                <div>Tube Grade: <Trans>{selectedTubeData.gradeName}</Trans></div>
                <div>Tube Dimensions: {`${selectedTubeData.x}x${selectedTubeData.y}`}mm</div>
              </div>
              <div className={"modalDataContainer"}>
                <Form>
                {selectedTubeData?.data?.map((item, index) => (
                  <div className={"checkboxStyle"} key={item.id}>
                    <Card
                      className={"bendCard"}
                      style={{ marginTop: 3, marginBottom: 3}}
                      bodyStyle={{paddingTop: 8, paddingBottom: 8}}
                    >
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>Thickness: {item.thickness} mm</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                          <Form.Item
                            label="Price:"
                            name={`thickness-${index}`}
                            style={{marginBottom: 0, marginTop: 0}}
                            initialValue={item.price}
                          >
                            <InputNumber
                              style={{minWidth: 30, marginLeft: 5}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={(value)=>{changeStandardTubePrice({materialId: item.id, index, value, data: selectedTubeData})}}
                            />
                          </Form.Item>
                          <Typography.Text className={"inputInfoText"} style={{marginLeft: 8}}>€/kg</Typography.Text>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
                </Form>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Modal>
    </React.Fragment>
  )
}