import {
  BENDING_IS_FETCHING_BENDING_DATA, BENDING_SET_BENDING_DATA,
  BENDING_SET_BENDING_MAX_LENGTH, BENDING_SET_BENDING_SERVICE,
  BENDING_SET_BENDING_PREPARATION_PRICE, BENDING_SET_BENDING_AVERAGE_PREPARATION_TIME,
  BENDING_FETCHING_SINGLE_MATERIAL_BENDS, BENDING_SET_MATERIAL_BENDING_DATA,
  BENDING_RESET_BENDS_DATA, BENDING_IS_ADDING_NEW_BEND,
  BENDING_SET_SINGLE_MATERIAL_BENDS, BENDING_IS_EDITING_BEND,
  BENDING_IS_DELETING_BENDS,
} from '../actions/actionTypes'
import initialState from './initialState'

const bendingReducer = (state = initialState.bending, action) => {
  switch (action.type) {
    case BENDING_IS_FETCHING_BENDING_DATA:
      return {
        ...state,
        isFetchingBendingData: action.payload,
      }

    case BENDING_SET_BENDING_DATA:
      return {
        ...state,
        bendingData: action.payload,
      }

    case BENDING_SET_BENDING_SERVICE:
      return {
        ...state,
        bendingData: {...state.bendingData, hasBending: action.payload},
      }

    case BENDING_SET_BENDING_MAX_LENGTH:
      return {
        ...state,
        bendingData: {...state.bendingData, maxLength: action.payload},
      }

    case BENDING_SET_BENDING_PREPARATION_PRICE:
      return {
        ...state,
        bendingData: {...state.bendingData, preparationPrice: action.payload},
      }

    case BENDING_SET_BENDING_AVERAGE_PREPARATION_TIME :
      return {
        ...state,
        bendingData: {...state.bendingData, averagePreparationTime: action.payload},
      }

    case BENDING_FETCHING_SINGLE_MATERIAL_BENDS:
      return {
        ...state,
        isFetchingSingleMaterialBends: action.payload
      }

    case BENDING_SET_MATERIAL_BENDING_DATA:
      return {
        ...state,
        bendingBendsData: action.payload
      }

    case BENDING_RESET_BENDS_DATA:
      return {
        ...state,
        bendingBendsData: []
      }

    case BENDING_SET_SINGLE_MATERIAL_BENDS:
      return {
        ...state,
        bendingData: {...state.bendingData, bends: state.bendingData.bends.map(item => {
            if(item.groupName === action.payload.groupName) {
              return action.payload
            }
            return item
          })}
      }

    case BENDING_IS_ADDING_NEW_BEND:
      return {
        ...state,
        isAddingNewBend: action.payload
      }
    case BENDING_IS_EDITING_BEND:
      return {
        ...state,
        isEditingBend: action.payload
      }
    case BENDING_IS_DELETING_BENDS:
      return {
        ...state,
        isRemovingBend: action.payload
      }
    default:
      return state
  }
}

export default bendingReducer
