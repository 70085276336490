import React, {useCallback, useEffect, useRef, useState} from "react";
import "./style.less";
import {Card, Col, Divider, Form, Row, Switch, Checkbox, InputNumber, Typography} from "antd";
import {
  fetchSimpleMachiningData,
  setChamferingData,
  setDeburringData,
  setDrillingData,
  setPreparationData,
  setSimpleMachiningChamfering,
  setSimpleMachiningDeburring,
  setSimpleMachiningDrilling,
  setSimpleMachiningService
} from "../../../../../actions/simpleMachiningActions";
import {useDispatch, useSelector} from "react-redux";
import {debounce} from "lodash";
import {InfoCircleOutlined} from "@ant-design/icons";
import {
  fetchDefaultSimpleMachiningData,
  setDefaultChamferingData,
  setDefaultDeburringData, setDefaultDrillingData, setDefaultPreparationData
} from "../../../../../actions/defaultMaterialsActions";

export const SimpleMachiningTab = (props) => {
  const {
    selectedTab, simpleMachiningData,
    changeDeburringParams, changeChamferingParams,
    changeDrillingParams, changeSimpleMachiningParams,
    isFetchingSimpleMachiningData,
    isGelso, headerHeight = 0,
    editDefaultMode = false,
  } = props;

  const [disableSwitching, setDisableSwitching] = useState(false);
  const [disableDeburring, setDisableDeburring] = useState(false);
  const [disableChamfering, setDisableChamfering] = useState(false);
  const [disableDrilling, setDisableDrilling] = useState(false);

  let showFallbackParams = isGelso && !editDefaultMode;
  let showOfferService = !isGelso && !editDefaultMode;
  let showEditInfoText = editDefaultMode;

  const dispatch = useDispatch()

  const selectedRegion = useSelector(state => state.defaultMaterialsData.selectedRegion)
  const initialRegionMount = useRef(true);
  const selectedRegionRef = useRef(selectedRegion)

  const formatData = (data) => {
    return editDefaultMode ? {...data, region: selectedRegionRef.current} : data
  }

  const deburringForm = React.createRef()
  const chamferingForm = React.createRef()
  const drillingForm = React.createRef()
  const preparationForm = React.createRef()

  const reloadTabKey = useSelector(state => state.defaultMaterialsData.reloadTabKey)
  useEffect(() => {
    if (reloadTabKey === selectedTab) {
      initialize()
    }
  }, [reloadTabKey])

  useEffect(()=>{
    initialize()
  }, [selectedTab])

  const initialize = () => {
    if (selectedTab === "3") {
      if (editDefaultMode) {
        dispatch(fetchDefaultSimpleMachiningData({region: selectedRegion}))
      } else {
        dispatch(fetchSimpleMachiningData())
      }
    }
  }

  useEffect(() => {
    selectedRegionRef.current = selectedRegion

    if (initialRegionMount.current) {
      initialRegionMount.current = false;
    } else {
      initialize()
    }
  }, [selectedRegion])

  const triggerSlideToggle = (value) => {
    setDisableSwitching(true);
    dispatch(setSimpleMachiningService({value})).then(()=>{
      setDisableSwitching(false);
      if(value) {
        initialize()
      }
    })
  }

  const setDeburring = (value) => {
    setDisableDeburring(true)
    dispatch(setSimpleMachiningDeburring({value})).then(()=>{
      setDisableDeburring(false)
    })
  }

  const setChamfering = (value) => {
    setDisableChamfering(true)
    dispatch(setSimpleMachiningChamfering({value})).then(()=>{
      setDisableChamfering(false)
    })
  }

  const setDrilling = (value) => {
    setDisableDrilling(true)
    dispatch(setSimpleMachiningDrilling({value})).then(()=>{
      setDisableDrilling(false)
    })
    if(value) {
      fetchData()
    }
  }

  /*----------- Deburring -----------*/

  const changeDeburringData = (data) => {
    dispatch(setDeburringData(formatData(data)))
    changeDeburringParams(formatData(data))
  }

  const debouncedDeburring = useCallback(
    debounce(data => changeDeburringData(data), 800),
    []
  );

  const changeDeburring = () => {
    debouncedDeburring({
      ...deburringForm.current.getFieldsValue()
    })
  }

  /*----------- Chamfering -----------*/
  const changeChamferingData = (data) => {
    dispatch(setChamferingData(formatData(data)))
    changeChamferingParams(formatData(data))
  }

  const debouncedChamfering = useCallback(
    debounce(data => changeChamferingData(data), 800),
    []
  );

  const changeChamfering = () => {
    debouncedChamfering({
      ...chamferingForm.current.getFieldsValue()
    })
  }

  /*----------- Drilling -----------*/

  const changeDrillingData = (data) => {
    dispatch(setDrillingData(formatData(data)))
    changeDrillingParams(formatData(data))
  }

  const debouncedDrilling = useCallback(
    debounce(data => changeDrillingData(data), 800),
    []
  );

  const changeDrilling = () => {
    debouncedDrilling({
      ...drillingForm.current.getFieldsValue()
    })
  }

  /*----------- Preparation -----------*/

  const changePreparationData = (data) => {
    dispatch(setPreparationData(formatData(data)))
    changeSimpleMachiningParams(formatData(data))
  }

  const debouncedPreparation = useCallback(
    debounce(data => changePreparationData(data), 800),
    []
  );

  const changePreparation = () => {
    debouncedPreparation({
      ...preparationForm.current.getFieldsValue()
    })
  }

  useEffect(()=>{
    if(!isFetchingSimpleMachiningData) {
      deburringForm.current?.setFieldsValue({
        maxWidth: simpleMachiningData.deburring.maxWidth,
        maxThickness: simpleMachiningData.deburring.maxThickness,
        deburringPrice: simpleMachiningData.deburring.deburringPrice,
        averagePreparationTime: simpleMachiningData.deburring.averagePreparationTime
      })
      chamferingForm.current?.setFieldsValue({
        maxThickness: simpleMachiningData.chamfering.maxThickness,
        chamferingPrice: simpleMachiningData.chamfering.chamferingPrice,
        averagePreparationTime: simpleMachiningData.chamfering.averagePreparationTime
      })
      drillingForm.current?.setFieldsValue({
        maxDiameter: simpleMachiningData.drilling.maxDiameter,
        maxThickness: simpleMachiningData.drilling.maxThickness,
        drillingPrice: simpleMachiningData.drilling.drillingPrice,
        averagePreparationTime: simpleMachiningData.drilling.averagePreparationTime
      })
      preparationForm.current?.setFieldsValue({
        preparationPrice: simpleMachiningData.preparationPrice
      })
    }
  }, [isFetchingSimpleMachiningData])

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"}>
        <div style={{height: `calc(100vh - 100px - ${headerHeight}px)`, overflowY: "auto", overflowX: "hidden", paddingRight: 12}}>
          <Row>
            <Col span={24}>
              <div className={"switchContainer"}>
                {showFallbackParams && <span style={{marginRight: 30}}>Fallback Simple Machining parameters:</span>}
                {showOfferService &&
                  <div>
                    <span style={{marginRight: 30, marginBottom: 6}}>Do you offer Simple Machining service?</span>
                    <span>No</span>
                    <Switch checked={simpleMachiningData.hasSimpleMachining}
                            onChange={triggerSlideToggle}
                            style={{marginLeft: 6, marginRight: 6}}
                            disabled={disableSwitching}
                    />
                    <span>Yes</span>
                  </div>
                }
                {showEditInfoText &&
                  <Card className={"defaultMachineCard"}>
                    <InfoCircleOutlined className={"editMaterialPageInfoIcon"}/>
                    <div style={{ marginLeft: 6, fontWeight: 500 }}>
                      This page represents the default regional parameters for simple machining.
                    </div>
                  </Card>
                }
              </div>
            </Col>
          </Row>

          <Divider style={{width: "75%", minWidth: "0%", margin: "8px 0 10px 0"}}/>
          {simpleMachiningData.hasSimpleMachining &&
            <div>
              <div className={"mainForm"}>
                <Row gutter={16}>
                  {/*------------------------------------------------------------------------------------------------*/}
                  <Col xl={12} span={24}>
                    <Form>
                    <div style={{display: "flex", alignItems: "center"}}>
                      {isGelso ?
                        (<span className={"mainFromLabel"} style={{color: "white"}}>Deburring:</span>)
                        :
                        (
                          <Form.Item label={<span className={"mainFromLabel"}>Deburring</span>}>
                            <Checkbox
                              checked={simpleMachiningData.deburring.hasDeburring}
                              onChange={(event) => {
                                setDeburring(event.target.checked)
                              }}
                              disabled={disableDeburring}
                            />
                          </Form.Item>
                        )
                      }
                      <Divider
                        className={"lightDivider"}
                        style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
                    </div>
                    </Form>
                    <span className={"processDescription"}>(Post Sheet metal processing)</span>
                    <Form
                      ref={deburringForm}
                    >
                    <Card className={"inputInfoText"} style={{margin: "12px 0px 12px 0px"}} bodyStyle={{padding: "8px 16px"}}>
                      <div className={simpleMachiningData.deburring.hasDeburring ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Maximum width of Sheet metal part"}
                          name="maxWidth"
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={simpleMachiningData.deburring.maxWidth}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeDeburring}
                            disabled={!simpleMachiningData.deburring.hasDeburring}
                          />
                        </Form.Item>
                        <Typography.Text style={{marginLeft: 12}}>mm</Typography.Text>
                      </div>
                      <div className={simpleMachiningData.deburring.hasDeburring ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Maximum thickness of Sheet metal part"}
                          name="maxThickness"
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={simpleMachiningData.deburring.maxThickness}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeDeburring}
                            disabled={!simpleMachiningData.deburring.hasDeburring}
                          />
                        </Form.Item>
                        <Typography.Text style={{marginLeft: 12}}>mm</Typography.Text>
                      </div>
                      <div className={simpleMachiningData.deburring.hasDeburring ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Deburring price"}
                          name="deburringPrice"
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={simpleMachiningData.deburring.deburringPrice}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeDeburring}
                            disabled={!simpleMachiningData.deburring.hasDeburring}
                          />
                        </Form.Item>
                        <Typography.Text style={{marginLeft: 12}}>€/m<sup>2</sup></Typography.Text>
                      </div>
                      <div className={simpleMachiningData.deburring.hasDeburring ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Average preparation time"}
                          name="averagePreparationTime"
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={simpleMachiningData.deburring.averagePreparationTime}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeDeburring}
                            disabled={!simpleMachiningData.deburring.hasDeburring}
                          />
                        </Form.Item>
                        <Typography.Text style={{marginLeft: 12}}>min</Typography.Text>
                      </div>
                    </Card>
                  </Form>
                  </Col>

                  {/*</Row>*/}
                  {/*------------------------------------------------------------------------------------------------*/}
                  {/*<Row>*/}
                  <Col xl={12} span={24}>
                    <Form>
                    <div style={{display: "flex", alignItems: "center"}}>
                      {isGelso ?
                        (<span className={"mainFromLabel"} style={{color: "white"}}>Chamfering:</span>)
                        :
                        (
                          <Form.Item label={<span className={"mainFromLabel"}>Chamfering</span>}>
                            <Checkbox checked={simpleMachiningData.chamfering.hasChamfering}
                                      onChange={(event) => {
                                        setChamfering(event.target.checked)
                                      }}
                                      disabled={disableChamfering}
                            />
                          </Form.Item>
                        )
                      }
                      <Divider
                        className={"lightDivider"}
                        style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
                    </div>
                    </Form>
                    <span className={"processDescription"}>(Sheet metal edges processing - fillet, beveling, chamfer...)</span>
                    <Form
                      ref={chamferingForm}
                    >
                    <Card className={"inputInfoText"} style={{margin: "12px 0px 12px 0px"}}  bodyStyle={{padding: "8px 16px"}}>
                      <div className={simpleMachiningData.chamfering.hasChamfering ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Maximum thickness of Sheet metal part"}
                          name="maxThickness"
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={simpleMachiningData.chamfering.maxThickness}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeChamfering}
                            disabled={!simpleMachiningData.chamfering.hasChamfering}
                          />
                        </Form.Item>
                        <Typography.Text style={{marginLeft: 12}}>mm</Typography.Text>
                      </div>
                      <div className={simpleMachiningData.chamfering.hasChamfering ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Chamfering price"}
                          name="chamferingPrice"
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={simpleMachiningData.chamfering.chamferingPrice}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeChamfering}
                            disabled={!simpleMachiningData.chamfering.hasChamfering}
                          />
                        </Form.Item>
                        <Typography.Text style={{marginLeft: 12}}>€/m</Typography.Text>
                      </div>
                      <div className={simpleMachiningData.chamfering.hasChamfering ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Average preparation time"}
                          name="averagePreparationTime"
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={simpleMachiningData.chamfering.averagePreparationTime}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeChamfering}
                            disabled={!simpleMachiningData.chamfering.hasChamfering}
                          />
                        </Form.Item>
                        <Typography.Text style={{marginLeft: 12}}>min</Typography.Text>
                      </div>
                      <div style={{height: 42, width: "100%"}}/>
                    </Card>
                    </Form>
                  </Col>
                  {/*</Row>*/}
                  {/*------------------------------------------------------------------------------------------------*/}
                  {/*<Row>*/}
                  <Col xl={12} span={24}>
                    <Form>
                      <div style={{display: "flex", alignItems: "center"}}>
                        {isGelso ?
                          (<span className={"mainFromLabel"} style={{ color: "white" }}>Drilling:</span>)
                          :
                          (
                            <Form.Item label={<span className={"mainFromLabel"}>Drilling</span>}>
                              <Checkbox checked={simpleMachiningData.drilling.hasDrilling}
                                        onChange={(event) => {
                                          setDrilling(event.target.checked)
                                        }}
                                        disabled={disableDrilling}
                              />
                            </Form.Item>
                          )
                        }
                        <Divider
                          className={"lightDivider"}
                          style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>

                      </div>
                    </Form>
                    <span className={"processDescription"}>(Sheet metal hole processing - simple threading, countersink hole, ...)</span>
                    <Form
                      ref={drillingForm}
                    >
                    <Card className={"inputInfoText"} style={{margin: "12px 0px 12px 0px"}} bodyStyle={{padding: "8px 16px"}}>
                      <div className={simpleMachiningData.drilling.hasDrilling ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Maximum thickness of Sheet metal part"}
                          name="maxThickness"
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={simpleMachiningData.drilling.maxThickness}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeDrilling}
                            disabled={!simpleMachiningData.drilling.hasDrilling}
                          />
                        </Form.Item>
                        <Typography.Text style={{marginLeft: 12}}>mm</Typography.Text>
                      </div>
                      <div className={simpleMachiningData.drilling.hasDrilling ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Maximum hole diameter"}
                          name="maxDiameter"
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={simpleMachiningData.drilling.maxDiameter}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeDrilling}
                            disabled={!simpleMachiningData.drilling.hasDrilling}
                          />
                        </Form.Item>
                        <Typography.Text style={{marginLeft: 12}}>mm</Typography.Text>
                      </div>
                      <div className={simpleMachiningData.drilling.hasDrilling ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label="Drilling price"
                          name="drillingPrice"
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={simpleMachiningData.drilling.drillingPrice}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeDrilling}
                            disabled={!simpleMachiningData.drilling.hasDrilling}
                          />
                        </Form.Item>
                        <Typography.Text style={{marginLeft: 12}}>€/h</Typography.Text>
                      </div>
                      <div className={simpleMachiningData.drilling.hasDrilling ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Average preparation time"}
                          name="averagePreparationTime"
                          style={{marginBottom: 5, marginTop: 5}}
                          initialValue={simpleMachiningData.drilling.averagePreparationTime}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeDrilling}
                            disabled={!simpleMachiningData.drilling.hasDrilling}
                          />
                        </Form.Item>
                        <Typography.Text style={{marginLeft: 12}}>min</Typography.Text>
                      </div>
                    </Card>
                    </Form>
                  </Col>
                  {/*------------------------------------------------------------------------------------------------*/}
                </Row>
              </div>

              <Row gutter={16}>
                <Col xl={14} span={24}>
                  <Form
                    ref={preparationForm}
                  >
                    <div style={{display: "flex", alignItems: "center"}}>
                      <Form.Item
                        label={<span>Preparation price for all processes</span>}
                        name="preparationPrice"
                        style={{marginBottom: 10, marginTop: 5}}
                        initialValue={simpleMachiningData.preparationPrice}
                      >
                        <InputNumber
                          style={{minWidth: 30, marginLeft: 5}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={changePreparation}
                        />
                      </Form.Item>
                      <Typography.Text className={"inputInfoText"} style={{marginLeft: 12}}>€/h</Typography.Text>
                    </div>
                  </Form>
                  <Divider className={"lightDivider"} style={{width: "50%", minWidth: "0%", margin: "8px 0 8px 0"}}/>
                </Col>
              </Row>
            </div>
          }
        </div>
      </Card>
    </React.Fragment>
  )
}