import * as React from 'react';
import {AutoSizer, MultiGrid} from 'react-virtualized';
import "./style.less";
import "../MaterialTables/tableStyle.less";
import {InputNumber} from "antd";
import {Trans} from 'react-i18next';
import {EmptyTable} from "../../../EmptyTable";
import {createRef, useEffect, useState} from "react";

const STYLE = {
  borderRadius: '0 0 5px 0'
};

export const MachiningMaterialList = (props) => {

  const [scrollToColumn, setScrollToColumn] = useState(0);
  const [scrollToRow, setScrollToRow] = useState(0);
  const [editColumn, setEditColumn] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [value, setValue] = useState(null);

  const {
    rows, allAvailable,
    onChange, updateTable,
    setUpdateTable, isEditable,
    leftWidth, rightWidth, gradeAlignLeft,
  } = props

  const editField = createRef()
  let multiGridRef = null;
  let nameInput = null;

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  })

  const refreshGrid = () => {
    if(multiGridRef) {
      multiGridRef.forceUpdateGrids()
    }
  }

  const changeValue = (value) => {
    setValue(value)
    refreshGrid()
  }

  const updateValue = (data) => {
    if (data.rowIndex && data.columnIndex) {
      if (rows[data.rowIndex][data.columnIndex] !== data.value) {
        onChange({
          value: data.value,
          row: data.rowIndex,
          col: data.columnIndex,
          materialId: allAvailable[rows[data.rowIndex][0]]
        }).then(()=>{
          refreshGrid()
        })
      }
    }
  }

  useEffect(() => {
    if (updateTable) {
      setUpdateTable(false)
      refreshGrid()
    }
  }, [updateTable])

  useEffect(() => {
    if (nameInput) {
      nameInput.focus();
    }
  }, [editColumn, editRow])

  const handleClickOutside = (e) => {
    if (!e.defaultPrevented) {
      if (editColumn && editField && editField.current && !editField.current.contains(e.target)) {
        updateValue({rowIndex: editRow, columnIndex: editColumn, value: value})
        setEditColumn(null)
        setEditRow(null)
        setValue(null)
      }
    }
  }

  const getClassName = (rowIndex, columnIndex) => {
    if (rows[rowIndex].type === "group") {
      return "machiningMaterialGradeRow"
    }
    if (rowIndex > rows.length) {
      return "laserSpeedDisabledRow"
    }
    if (columnIndex === 0) {
      return "laserSpeedLeftColumn"
    } else {
      return "laserSpeedOddRow"
    }
  }

  const _cellRenderer = ({columnIndex, key, rowIndex, style}) => {
    return (
      <div ref={ref => editField.current = ref} onClick={(e) => {
        e.preventDefault()
        if(isEditable) {
          updateValue({rowIndex: editRow, columnIndex: editColumn, value: value})
          handleEditShow(columnIndex, rowIndex, rows[rowIndex][columnIndex])
        }
      }} className={getClassName(rowIndex, columnIndex)}
           key={key} style={{
        ...style,
        padding: 5,
        borderTop: "none",
        borderBottom: '1px solid #888888',
        borderRight: '1px solid #001529',
        display: 'inline-block',
        alignItems: 'center',
        right: -2
      }}>
        <div style={{fontSize: 13}} className={"cellText"}>
          {rows.length < rowIndex ?
            null
            :
            <div>
              {rows[rowIndex].type === "group" ? (
                  <div className={"speedTableCellLeft yesSelect"}
                       style={{ fontWeight: 600 }}
                  >
                    <Trans>{rows[rowIndex][columnIndex]}</Trans>
                  </div>
                )
                :
                (columnIndex !== 0 && !isNaN(editColumn) && !isNaN(editRow) && editColumn === columnIndex && editRow === rowIndex ?
                  <InputNumber
                    ref={(input) => {
                      nameInput = input;
                    }}
                    className={"laserSpeedTableInput"}
                    step={0.1}
                    min={0}
                    defaultValue={rows[rowIndex][columnIndex]}
                    style={{width: "100%", marginLeft: -5, fontSize: 13, lineHeight: 1.5}}
                    formatter={value => value.toString()}
                    onChange={(value) => {
                      changeValue(value)
                    }}
                    bordered={false}
                  />
                  :
                  (columnIndex === 0 ?
                    <div
                      className={gradeAlignLeft ? "speedTableCellLeft yesSelect" : "machiningMaterialGradeRowCell yesSelect"}
                      style={{ fontWeight: 600, fontSize: 12 }}
                    >
                      {rows[rowIndex][columnIndex]}
                    </div>
                    :
                    <div className={"speedTableCellText"}>{rows[rowIndex][columnIndex]}</div>
                  ))
              }
            </div>
          }
        </div>
      </div>
    );
  }

  const handleEditShow = (columnIndex, rowIndex, value) => {
    setEditColumn(columnIndex)
    setEditRow(rowIndex)
    setValue(value)
  }

  const _getColumnWidth = ({index}) => {
    if(leftWidth && rightWidth) {
      return index === 0 ? leftWidth : rightWidth
    }
    return 130
  };

  if (rows.length === 0) {
    return (
      <div className={"emptyTableContainer"}>
        <EmptyTable text={"No Data Available"}/>
      </div>
    )
  } else {
    return (
      <AutoSizer>
        {({width, height}) => (
          <MultiGrid
            ref={(grid) => {
              multiGridRef = grid;
            }}
            scrollToColumn={scrollToColumn}
            scrollToRow={scrollToRow}
            editColumn={editColumn}
            editRow={editRow}
            cellRenderer={_cellRenderer}
            columnWidth={_getColumnWidth}
            columnCount={2}
            height={height}
            rowHeight={35}
            rowCount={rows.length}
            style={STYLE}
            classNameTopLeftGrid={"topLeftGrid"}
            classNameTopRightGrid={"topRightGrid"}
            classNameBottomLeftGrid={"bottomLeftGrid"}
            classNameBottomRightGrid={"bottomRightGrid"}
            width={width}
            hideTopRightGridScrollbar
            hideBottomLeftGridScrollbar
          />
        )}
      </AutoSizer>
    );
  }
}