import React, {useEffect, useState} from "react";
import {Col, Divider, Row, Switch, Card, Checkbox, Spin, Form, InputNumber, Typography, Button} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProducerLathingMachineProperties,
  fetchProducerMachiningData,
  fetchProducerMillingMachineProperties,
  setProducerEmptyMillingMachines,
  setProducerEmptyLathingMachines
} from "../../../../../actions/producerParamsActions";
import {LoadingOutlined} from "@ant-design/icons";
import {MachineSelect, MachineTolerancesModal} from "../../../MachineSelect";
import {MachiningMaterialList} from "../../../CompanyMaterials/MachiningMaterialList";
import {EmptyTable} from "../../../../EmptyTable";
import {getRoughnessValue, getToleranceValue, useWindowSize} from "../../../../../utils/utility";
import "./style.less";
import {ProducerMaterialInput} from "../../NonEditableFields";
import {TableHeader} from "../../../CompanyMaterials/MaterialTables/TableHeader";

const MachiningTableContainer = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"}}>
        <TableHeader columns={[
          {title: "Name", width: 130},
          {title: <span>{props.type === "milling" ? "Milling" : "Lathing"} Speed (cm<sup>3</sup>/min)</span>, width: 173},
        ]}/>
        <Card
          className={"tableCard"}
          style={{width: 303}}
          bodyStyle={{height: "calc(-379px + 100vh)", maxWidth: 508}}
        >
          {props.children}
        </Card>
      </div>
      <Divider type="vertical" style={{height: 'calc(100vh - 357px)', marginInline: 15, borderColor: "#1e1e1e"}}/>
    </div>
  )
}

export const MachiningTab = (props) => {
  const {
    selectedTab,
    producerId
  } = props;

  const machiningData = useSelector(state => state.producerParams.machiningData)
  const isFetchingMachiningData = useSelector(state => state.producerParams.isFetchingMachiningData)
  const alreadyFetchedMachiningData = useSelector(state => state.producerParams.alreadyFetchedMachiningData)

  {/*-------------------Milling-------------------*/}
  const millingMachine = useSelector(state => state.producerParams.millingMachine)
  const isFetchingMillingMachine = useSelector(state => state.producerParams.isFetchingMillingMachine)
  const [shouldUpdateMillingTable, setShouldUpdateMillingTable] = useState(false);
  const [noMillingMachines, setNoMillingMachines] = useState(millingMachine ? Object.keys(millingMachine).length === 0 : true)
  const [millingMachines, setMillingMachines] = useState([]);
  const [millingMachineTolerancesModalVisible, setMillingMachineTolerancesModalVisible] = useState(false)

  {/*-------------------Lathing-------------------*/}
  const lathingMachine = useSelector(state => state.producerParams.lathingMachine)
  const isFetchingLathingMachine = useSelector(state => state.producerParams.isFetchingLathingMachine)
  const [shouldUpdateLathingTable, setShouldUpdateLathingTable] = useState(false);
  const [noLathingMachines, setNoLathingMachines] = useState(lathingMachine ? Object.keys(lathingMachine).length === 0 : true)
  const [lathingMachines, setLathingMachines] = useState([]);
  const [lathingMachineTolerancesModalVisible, setLathingMachineTolerancesModalVisible] = useState(false)

  const dispatch = useDispatch()
  const windowSize = useWindowSize();
  const [shouldChangeOrder, setShouldChangeOrder] = useState((windowSize.width < 1500 && windowSize.width >= 1235) || windowSize.width < 810);
  const [shouldChangeOrder2, setShouldChangeOrder2] = useState(windowSize.width < 1235 && windowSize.width > 991);

  const millingForm = React.createRef()
  const lathingForm = React.createRef()

  useEffect(() => {
    setShouldChangeOrder((windowSize.width < 1500 && windowSize.width >= 1235) || windowSize.width < 810)
    setShouldChangeOrder2(windowSize.width < 1235 && windowSize.width > 991)
  }, [windowSize.width])
  
  useEffect(()=> {
    if(selectedTab === "4") {
      fetchData()
    }
  }, [selectedTab])

  const fetchData = () => {
    dispatch(fetchProducerMachiningData({producerId: producerId, alreadyFetchedMachiningData}))
      .then((machiningData) => {
        if (machiningData) {
          const millingMachines = machiningData.milling.machines
          setMillingMachines([{
            id: "1",
            name: "Milling Machines",
            machines: millingMachines
          }]);

          if (millingMachines?.length > 0) {
            const millingMachineId = millingMachines[0].id
            dispatch(fetchProducerMillingMachineProperties({producerId, machineId: millingMachineId})).then(() => {
              setShouldUpdateMillingTable(true)
            })
          } else {
            dispatch(setProducerEmptyMillingMachines())
          }

          const lathingMachines = machiningData.lathing.machines
          setLathingMachines([{
            id: "1",
            name: "Lathing Machines",
            machines: lathingMachines
          }]);
          if (lathingMachines?.length > 0) {
            const lathingMachineId = lathingMachines[0].id
            dispatch(fetchProducerLathingMachineProperties({producerId, machineId: lathingMachineId})).then(() => {
              setShouldUpdateLathingTable(true)
            })
          } else {
            dispatch(setProducerEmptyLathingMachines())
          }
        }
      })
  }

  const changeSelectedMillingMachine = (millingMachineId) => {
    dispatch(fetchProducerMillingMachineProperties({producerId, machineId: millingMachineId})).then(() => {
      setShouldUpdateMillingTable(true)
    })
  }

  useEffect(() => {
    setNoMillingMachines(millingMachine ? Object.keys(millingMachine).length === 0 : true)
    millingForm.current?.setFieldsValue({
      pricePerHour: millingMachine.pricePerHour,
      engineerPricePerHour: millingMachine.engineerPricePerHour,
      averagePreparationTime: millingMachine.averagePreparationTime,
      supervisedMachine: millingMachine.supervisedMachine,
      supervisionPricePerHour: millingMachine.supervisionPricePerHour
    })
  }, [millingMachine])

    const changeSelectedLathingMachine = (lathingMachineId) => {
    dispatch(fetchProducerLathingMachineProperties({producerId, machineId: lathingMachineId})).then(() => {
      setShouldUpdateLathingTable(true)
    })
  }

  useEffect(() => {
    setNoLathingMachines(lathingMachine ? Object.keys(lathingMachine).length === 0 : true)
    lathingForm.current?.setFieldsValue({
      pricePerHour: lathingMachine.pricePerHour,
      engineerPricePerHour: lathingMachine.engineerPricePerHour,
      averagePreparationTime: lathingMachine.averagePreparationTime,
      supervisedMachine: lathingMachine.supervisedMachine,
      supervisionPricePerHour: lathingMachine.supervisionPricePerHour
    })
  }, [lathingMachine])

  const handleMillingMachineTolerancesOpen = () => {
    setMillingMachineTolerancesModalVisible(true)
  }
  
  const handleMillingMachineTolerancesClose = () => {
    setMillingMachineTolerancesModalVisible(false)
  }

  const handleLathingMachineTolerancesOpen = () => {
    setLathingMachineTolerancesModalVisible(true)
  }

  const handleLathingMachineTolerancesClose = () => {
    setLathingMachineTolerancesModalVisible(false)
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"} bodyStyle={{paddingRight: 0, paddingBottom: 15}}>
        <div style={{height: 'calc(100vh - 172px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
          <Row>
            <Col xl={14} span={24}>
              <div className={"switchContainer"}>
                <div>
                  <span style={{marginRight: 30, marginBottom: 6}}>Producer offers Machining service?</span>
                  <span>No</span>
                  <Switch
                    className={"producersUneditableSwitch"}
                    checked={machiningData.hasMachining}
                    style={{marginLeft: 6, marginRight: 6}}
                    disabled={true}
                  />
                  <span>Yes</span>
                </div>
              </div>
            </Col>
          </Row>
          <Divider style={{width: "75%", minWidth: "0%", margin: "14px 0 8px 0"}}/>
          {machiningData.hasMachining &&
            <div>
              <div className={"mainForm"}>
                <Row gutter={32}>
                  {/*------------------------------------------------------------------------------------------------*/}
                  <Col lg={12} span={24}>
                    <Spin
                      spinning={isFetchingMachiningData || isFetchingMillingMachine}
                      indicator={<LoadingOutlined style={{fontSize: 72, transform: "translate(-15%, 50%)"}} spin/>}
                    >
                      <div>
                        <div
                          className={machiningData.milling.hasMilling ? "trueDisabledCheckbox" : "falseDisabledCheckbox"}
                          style={{display: "inline-block"}}
                        >
                          <span style={{ fontSize: 16, marginRight: 8}}>
                            <span style={{fontWeight: 600}}>Milling</span>:
                          </span>
                          <Checkbox
                            checked={machiningData.milling.hasMilling}
                            disabled={true}
                          />
                        </div>
                        <Divider
                          className={"lightDivider"}
                          style={{width: "75%", minWidth: "0%", margin: "8px 0 5px 10px", display: "inline-block"}}
                        />

                        {machiningData.milling.hasMilling &&
                        <div>
                          <MachineSelect
                            type={"milling"}
                            style={{marginTop: 12}}
                            selectedMachine={millingMachine?.id}
                            changeSelectedMachine={changeSelectedMillingMachine}
                            machines={millingMachines}
                            isFetchingMachineTypes={false}
                            handleEditMachine={() => {}}
                            handleRemoveMachine={() => {}}
                            handleAddMachineOpen={() => {}}
                            allowPriorityChange={false}
                            allowAddMachine={false}
                            hasTolerances={millingMachine?.id || false}
                            isFetchingMachineTolerances={isFetchingMillingMachine}
                            handleMachineTolerancesOpen={handleMillingMachineTolerancesOpen}
                            showButtons={false}
                          />
                          <Divider style={{width: "90%", minWidth: "0%", margin: "0 0 16px 0"}}/>
                          <Card
                            className={"inputInfoText"}
                            style={{margin: 0, minHeight: 300}}
                            bodyStyle={{padding: "8px 16px", height: "100%"}}
                          >
                            {!noMillingMachines ?
                              <div style={{ display: "flex", flexDirection: shouldChangeOrder2 ? "column-reverse" : "row" }}>
                                <div style={{width: "334px"}}>
                                  <MachiningTableContainer type={"milling"}>
                                    <MachiningMaterialList
                                      rows={millingMachine?.rows || []}
                                      allAvailable={machiningData?.allAvailable}
                                      onChange={() => {}}
                                      updateTable={shouldUpdateMillingTable}
                                      setUpdateTable={setShouldUpdateMillingTable}
                                      leftWidth={130}
                                      rightWidth={150}
                                      isEditable={false}
                                    />
                                  </MachiningTableContainer>
                                </div>

                                <div style={{ width: "100%", minWidth: 0}}>
                                  <Form ref={millingForm}>
                                    <div
                                      className={machiningData.milling.hasMilling ? "" : "disabledField"}
                                      style={{display: "flex", alignItems: "center"}}
                                    >
                                      <Form.Item
                                        label={"Milling price per hour"}
                                        name={"pricePerHour"}
                                        className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                        initialValue={millingMachine.pricePerHour}
                                      >
                                        <InputNumber
                                          className={"nonEditableInputNumber"}
                                          style={{minWidth: 30}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={() => {}}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        style={shouldChangeOrder ? {
                                          position: "relative",
                                          top: 16,
                                          right: 36
                                        } : {marginLeft: 12}}
                                      >
                                        €/h
                                      </Typography.Text>
                                    </div>
                                    <div
                                      className={machiningData.milling.hasMilling ? "" : "disabledField"}
                                      style={{display: "flex", alignItems: "center"}}
                                    >
                                      <Form.Item
                                        label={"Preparation price"}
                                        name={"engineerPricePerHour"}
                                        className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                        initialValue={millingMachine.engineerPricePerHour}
                                      >
                                        <InputNumber
                                          className={"nonEditableInputNumber"}
                                          style={{minWidth: 30}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={() => {}}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        style={shouldChangeOrder ? {
                                          position: "relative",
                                          top: 16,
                                          right: 16
                                        } : {marginLeft: 12}}
                                      >
                                        €/h
                                      </Typography.Text>
                                    </div>
                                    <div
                                      className={machiningData.milling.hasMilling ? "" : "disabledField"}
                                      style={{display: "flex", alignItems: "center"}}
                                    >
                                      <Form.Item
                                        label={"Average preparation time"}
                                        name={"averagePreparationTime"}
                                        className={`${shouldChangeOrder ? "columnItem" : "rowItem"}`}
                                        initialValue={millingMachine.averagePreparationTime}
                                      >
                                        <InputNumber
                                          className={"nonEditableInputNumber"}
                                          style={{minWidth: 30}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={() => {}}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        style={shouldChangeOrder ? {
                                          position: "relative",
                                          top: 16,
                                          right: 62
                                        } : {marginLeft: 12}}
                                      >
                                        min
                                      </Typography.Text>
                                    </div>
                                    <div
                                      className={(machiningData.milling.hasMilling && millingMachine.supervisedMachine) ? "" : "disabledField"}
                                      style={{display: "flex", alignItems: "center"}}
                                    >
                                      <Form.Item
                                        name={"supervisedMachine"}
                                        valuePropName={"checked"}
                                        initialValue={millingMachine.supervisedMachine}
                                      >
                                        <Checkbox
                                          className={millingMachine.supervisedMachine ? "trueDisabledDirectCheckbox" : "falseDisabledDirectCheckbox"}
                                          style={{marginBottom: shouldChangeOrder ? 32 : 2, marginRight: 10}}
                                          onChange={() => {}}
                                          disabled={true}
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        label={"Additional operator cost"}
                                        name={"supervisionPricePerHour"}
                                        className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                        initialValue={millingMachine.supervisionPricePerHour}
                                      >
                                        <InputNumber
                                          className={millingMachine.supervisedMachine ? "nonEditableInputNumber" : ""}
                                          style={{minWidth: 30}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={() => {}}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        style={shouldChangeOrder ? {
                                          position: "relative",
                                          top: 16,
                                          right: 54
                                        } : {marginLeft: 12}}
                                      >
                                        €/h
                                      </Typography.Text>
                                    </div>
                                    <Divider style={{
                                      width: "75%",
                                      minWidth: "0%",
                                      margin: "9px 0 12px 0",
                                      borderColor: "rgb(30, 30, 30)"
                                    }}/>
                                    <div className={"disabledField"}
                                         style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                                      <ProducerMaterialInput
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          flexDirection: shouldChangeOrder ? "column" : "row",
                                          alignItems: shouldChangeOrder ? "flex-start" : "center",
                                          paddingRight: 0
                                        }}
                                        inputWidth={200}
                                        label={"Maximum Tolerance"}
                                        value={getToleranceValue(millingMachine?.maxTolerance, "machined")}
                                        suffix={""}
                                      />
                                    </div>
                                    <div className={"disabledField"}
                                         style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                                      <ProducerMaterialInput
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          flexDirection: shouldChangeOrder ? "column" : "row",
                                          alignItems: shouldChangeOrder ? "flex-start" : "center",
                                          paddingRight: 0
                                        }}
                                        inputWidth={200}
                                        label={"Max Surface Roughness"}
                                        value={getRoughnessValue(millingMachine?.maxRoughness)}
                                        suffix={""}
                                      />
                                    </div>
                                    <div style={{ marginTop: 8, display: "flex", width: "100%", justifyContent: "center"}}>
                                      <Button
                                        className={"toleranceButton d6ButtonDark"}
                                        style={{fontSize: 13}}
                                        onClick={() => handleMillingMachineTolerancesOpen()}
                                        disabled={false}
                                      >
                                        <span style={{fontWeight: 600}}>View Machine Adjustment</span>
                                      </Button>
                                    </div>
                                    {shouldChangeOrder2 &&
                                      <Divider style={{
                                        width: "75%",
                                        minWidth: "0%",
                                        margin: `9px 0 ${shouldChangeOrder ? 0 : "10px"} 0`,
                                        borderColor: "rgb(30, 30, 30)",
                                      }}/>
                                    }
                                  </Form>
                                </div>
                              </div>
                              :
                              <EmptyTable
                                text={"No Milling Machines Available"}
                                style={{
                                  position: "relative", top: "25%", height: "calc(100vh - 337px)",
                                  display: "flex", justifyContent: "center", alignItems: "center"
                                }}
                              />
                            }
                          </Card>
                        </div>
                        }
                      </div>
                    </Spin>
                  </Col>

                  <Col lg={12} span={24}>
                    <Spin
                      spinning={isFetchingMachiningData || isFetchingLathingMachine}
                      indicator={<LoadingOutlined style={{fontSize: 72, transform: "translate(-15%, 50%)"}} spin/>}
                    >
                      <div>
                        <div
                          className={machiningData.lathing.hasLathing ? "trueDisabledCheckbox" : "falseDisabledCheckbox"}
                          style={{display: "inline-block"}}
                        >
                          <span style={{ fontSize: 16, marginRight: 8}}>
                            <span style={{fontWeight: 600}}>Lathing</span>:
                          </span>
                          <Checkbox
                            checked={machiningData.lathing.hasLathing}
                            disabled={true}
                          />
                        </div>
                        <Divider
                          className={"lightDivider"}
                          style={{width: "75%", minWidth: "0%", margin: "8px 0 5px 10px", display: "inline-block"}}
                        />

                        {machiningData.lathing.hasLathing &&
                        <div>
                          <MachineSelect
                            type={"lathing"}
                            style={{marginTop: 12}}
                            selectedMachine={lathingMachine?.id}
                            changeSelectedMachine={changeSelectedLathingMachine}
                            machines={lathingMachines}
                            isFetchingMachineTypes={false}
                            handleEditMachine={() => {}}
                            handleRemoveMachine={() => {}}
                            handleAddMachineOpen={() => {}}
                            allowPriorityChange={false}
                            allowAddMachine={false}
                            hasTolerances={lathingMachine?.id || false}
                            isFetchingMachineTolerances={isFetchingLathingMachine}
                            handleMachineTolerancesOpen={handleLathingMachineTolerancesOpen}
                            showButtons={false}
                          />
                          <Divider style={{width: "90%", minWidth: "0%", margin: "0 0 16px 0"}}/>
                          <Card
                            className={"inputInfoText"}
                            style={{margin: 0, minHeight: 300}}
                            bodyStyle={{padding: "8px 16px", height: "100%"}}
                          >
                            {!noLathingMachines ?
                              <div style={{ display: "flex", flexDirection: shouldChangeOrder2 ? "column-reverse" : "row" }}>
                                <div style={{width: "334px"}}>
                                  <MachiningTableContainer type={"lathing"}>
                                    <MachiningMaterialList
                                      rows={lathingMachine?.rows || []}
                                      allAvailable={machiningData?.allAvailable}
                                      onChange={() => {}}
                                      updateTable={shouldUpdateLathingTable}
                                      setUpdateTable={setShouldUpdateLathingTable}
                                      leftWidth={130}
                                      rightWidth={150}
                                      isEditable={false}
                                    />
                                  </MachiningTableContainer>
                                </div>

                                <div style={{ width: "100%", minWidth: 0}}>
                                  <Form ref={lathingForm}>
                                    <div
                                      className={machiningData.lathing.hasLathing ? "" : "disabledField"}
                                      style={{display: "flex", alignItems: "center"}}
                                    >
                                      <Form.Item
                                        label={"Milling price per hour"}
                                        name={"pricePerHour"}
                                        className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                        initialValue={lathingMachine.pricePerHour}
                                      >
                                        <InputNumber
                                          className={"nonEditableInputNumber"}
                                          style={{minWidth: 30}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={() => {}}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        style={shouldChangeOrder ? {
                                          position: "relative",
                                          top: 16,
                                          right: 36
                                        } : {marginLeft: 12}}
                                      >
                                        €/h
                                      </Typography.Text>
                                    </div>
                                    <div
                                      className={machiningData.lathing.hasLathing ? "" : "disabledField"}
                                      style={{display: "flex", alignItems: "center"}}
                                    >
                                      <Form.Item
                                        label={"Preparation price"}
                                        name={"engineerPricePerHour"}
                                        className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                        initialValue={lathingMachine.engineerPricePerHour}
                                      >
                                        <InputNumber
                                          className={"nonEditableInputNumber"}
                                          style={{minWidth: 30}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={() => {}}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        style={shouldChangeOrder ? {
                                          position: "relative",
                                          top: 16,
                                          right: 16
                                        } : {marginLeft: 12}}
                                      >
                                        €/h
                                      </Typography.Text>
                                    </div>
                                    <div
                                      className={machiningData.lathing.hasLathing ? "" : "disabledField"}
                                      style={{display: "flex", alignItems: "center"}}
                                    >
                                      <Form.Item
                                        label={"Average preparation time"}
                                        name={"averagePreparationTime"}
                                        className={`${shouldChangeOrder ? "columnItem" : "rowItem"}`}
                                        initialValue={lathingMachine.averagePreparationTime}
                                      >
                                        <InputNumber
                                          className={"nonEditableInputNumber"}
                                          style={{minWidth: 30}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={() => {}}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        style={shouldChangeOrder ? {
                                          position: "relative",
                                          top: 16,
                                          right: 62
                                        } : {marginLeft: 12}}
                                      >
                                        min
                                      </Typography.Text>
                                    </div>
                                    <div
                                      className={(machiningData.lathing.hasLathing && lathingMachine.supervisedMachine) ? "" : "disabledField"}
                                      style={{display: "flex", alignItems: "center"}}
                                    >
                                      <Form.Item
                                        name={"supervisedMachine"}
                                        valuePropName={"checked"}
                                        initialValue={lathingMachine.supervisedMachine}
                                      >
                                        <Checkbox
                                          className={lathingMachine.supervisedMachine ? "trueDisabledDirectCheckbox" : "falseDisabledDirectCheckbox"}
                                          style={{marginBottom: shouldChangeOrder ? 32 : 2, marginRight: 10}}
                                          onChange={() => {}}
                                          disabled={true}
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        label={"Additional operator cost"}
                                        name={"supervisionPricePerHour"}
                                        className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                        initialValue={lathingMachine.supervisionPricePerHour}
                                      >
                                        <InputNumber
                                          className={lathingMachine.supervisedMachine ? "nonEditableInputNumber" : ""}
                                          style={{minWidth: 30}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={() => {}}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        style={shouldChangeOrder ? {
                                          position: "relative",
                                          top: 16,
                                          right: 54
                                        } : {marginLeft: 12}}
                                      >
                                        €/h
                                      </Typography.Text>
                                    </div>
                                    <Divider style={{
                                      width: "75%",
                                      minWidth: "0%",
                                      margin: "8px 0 20px 0",
                                      borderColor: "rgb(30, 30, 30)"
                                    }}/>
                                    <div className={"disabledField"}
                                         style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                                      <ProducerMaterialInput
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          flexDirection: shouldChangeOrder ? "column" : "row",
                                          alignItems: shouldChangeOrder ? "flex-start" : "center",
                                          paddingRight: 0
                                        }}
                                        inputWidth={200}
                                        label={"Maximum Tolerance"}
                                        value={getToleranceValue(lathingMachine?.maxTolerance, "machined")}
                                        suffix={""}
                                      />
                                    </div>
                                    <div className={"disabledField"}
                                         style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                                      <ProducerMaterialInput
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          flexDirection: shouldChangeOrder ? "column" : "row",
                                          alignItems: shouldChangeOrder ? "flex-start" : "center",
                                          paddingRight: 0
                                        }}
                                        inputWidth={200}
                                        label={"Max Surface Roughness"}
                                        value={getRoughnessValue(lathingMachine?.maxRoughness)}
                                        suffix={""}
                                      />
                                    </div>
                                    <div style={{ marginTop: 8, display: "flex", width: "100%", justifyContent: "center"}}>
                                      <Button
                                        className={"toleranceButton d6ButtonDark"}
                                        style={{fontSize: 13}}
                                        onClick={() => handleLathingMachineTolerancesOpen()}
                                        disabled={false}
                                      >
                                        <span style={{fontWeight: 600}}>View Machine Adjustment</span>
                                      </Button>
                                    </div>
                                    {shouldChangeOrder2 &&
                                      <Divider style={{
                                        width: "75%",
                                        minWidth: "0%",
                                        margin: `9px 0 ${shouldChangeOrder ? 0 : "10px"} 0`,
                                        borderColor: "rgb(30, 30, 30)",
                                      }}/>
                                    }
                                  </Form>
                                </div>
                              </div>
                              :
                              <EmptyTable
                                text={"No Lathing Machines Available"}
                                style={{
                                  position: "relative", top: "25%", height: "calc(100vh - 337px)",
                                  display: "flex", justifyContent: "center", alignItems: "center"
                                }}
                              />
                            }
                          </Card>
                        </div>
                        }
                      </div>
                    </Spin>
                  </Col>

                </Row>
              </div>
            </div>
          }
        </div>
      </Card>

      <MachineTolerancesModal
        machineType={"milling"}
        title={"Milling Machine Tolerance Markup"}
        modalVisible={millingMachineTolerancesModalVisible}
        handleCloseModal={handleMillingMachineTolerancesClose}
        isLoading={isFetchingMillingMachine}
        allowTolerancesChange={false}
        markupData={millingMachine?.markup}
      />

      <MachineTolerancesModal
        machineType={"lathing"}
        title={"Lathing Machine Tolerance Markup"}
        modalVisible={lathingMachineTolerancesModalVisible}
        handleCloseModal={handleLathingMachineTolerancesClose}
        isLoading={isFetchingLathingMachine}
        allowTolerancesChange={false}
        markupData={lathingMachine?.markup}
      />
      
    </React.Fragment>
  )
}