import React, {useEffect, useState} from "react";
import {Col, Row, Upload} from "antd";
import {
  CloudUploadOutlined,
} from "@ant-design/icons";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

import dragAndDrop from "../../../assets/icons/dragndroplum.png";
import uploadFile from "../../../assets/icons/uploadfile.png";
import checkIcon from "../../../assets/icons/checkmark.svg";
import "./style.less";
import {openNotification} from "../../../utils/utility";

const GuidelineSection = (props) => {
  return (
    <Col span={24}>
      <div style={{padding: 12, height: "100%"}}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
          <div style={{marginRight: 8}}>
            <img alt={"guide"} src={checkIcon} width={24} height={24}/>
          </div>
          <p align={"left"} style={{fontSize: 13, fontWeight: 500, lineHeight: 1.5, margin: 0}}>
            {props.text}
          </p>
        </div>
      </div>
    </Col>
  )
}

export const OrderUploadButton = (props) => {

  const {
    allItemsCount, triggerUpload, screens,
    openFileSizeNotification,
    onOpen
  } = props

  const [compactLayout, setCompactLayout] = useState(screens ? screens['xs'] || (screens["sm"] && !screens["md"]) : false)

  useEffect(() => {
    setCompactLayout(screens['xs'] || (screens["sm"] && !screens["md"]))
  }, [screens])

  const beforeUpload = (file, fileList) => {
    if (fileList.length > 100) {
      let num = fileList.findIndex(item => item.uid === file.uid)
      if (num > 99) {
        return false
      }
    }
    const sizeInMbs = file.size / 1024 / 1024;
    const is2Large = sizeInMbs < 300;
    if (!is2Large) {
      openFileSizeNotification()
    } else {
      if (sizeInMbs > 20) {
        openNotification("bottomRight")
      }
      triggerUpload(file)
    }
    return false;
  }

  return (
    <div>
      <Row gutter={16}>
        {allItemsCount === 0 &&
        <Col span={compactLayout ? 24 : 12}>
          <div style={{marginBottom: 8}}>
            <div className={"guidelinesCard"}>
              <Row style={{height: "100%", minHeight: 280}}>
                <Col span={24}
                     style={{borderBottom: "1px dotted #999999"}}>
                  <div style={{display: "flex", padding: "8px 24px 12px 24px", alignItems: "center", height: "100%"}}>
                    <div align={"center"} style={{width: "100%"}}>
                      <div style={{fontSize: 18, fontWeight: 600}}>
                        What to look out for:
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <Row>
                    <GuidelineSection text={"Automatic files must be clean. No text, dimensions or quotations."}/>
                    <GuidelineSection text={"Provide additional PDF drawings to improve calculation."}/>
                    <GuidelineSection text={"Load up also the Bill of Materials."}/>
                    <GuidelineSection text={"Part scale must be 1:1."}/>
                  </Row>
                </Col>
              </Row>
              <div style={{borderTop: "1px dotted #999999"}}>
                <div className={"fileFormatRules"}>
                  <div style={{marginRight: 15}}>
                    <span style={{fontWeight: 500}}>Automatic file types:</span> STEP, STP, DXF
                  </div>
                  <div style={{marginRight: 15}}>
                    <span style={{fontWeight: 500}}>File size: </span>{"< 100 MB"}
                  </div>
                  <div>
                    <span style={{fontWeight: 500}}>Files per drag & drop: </span>{" < 100"}
                  </div>
                </div>
              </div>
            </div>
            <div style={{marginTop: 8}}>
              <a
                className={"tipsLink"}
                href={`${window.location.origin}/step-tips`}
                onClick={(e) => {
                  e.preventDefault()
                  window.open(`${window.location.origin}/step-tips`, "_blank")
                }}
                style={{fontWeight: 500, fontSize: 14}}
              >
                <OpenInNewOutlinedIcon className={"tipsLink"} style={{fontSize: 16, marginBottom: -3}}/> Read more
                about Tips for Proper Part Design
              </a>
            </div>
          </div>
        </Col>
        }

        <Col span={compactLayout || allItemsCount !== 0 ? 24 : 12}>
          <Upload
            showUploadList={false}
            multiple={true}
            onClick={onOpen}
            beforeUpload={beforeUpload}
            className={allItemsCount === 0 ? "upload-button-big" : "upload-button"}
          >
            <div
              className={"drop-button"}
              style={allItemsCount === 0 ? {flexDirection: "column"} : {
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              {(allItemsCount === 0 || screens["md"]) &&
              <div className={"upload-button-side upload-button-side-left"}
                   style={allItemsCount !== 0 ? {width: "50%"} : {}}
              >
                <img src={dragAndDrop} className={"file-icon"} alt={""} style={{marginBottom: 4}}/>
                <div style={{display: 'flex'}}>
                  <div align={allItemsCount === 0 ? "center" : "left"} style={{display: 'block'}}>
                    <div className={"text14-600"}>
                      Drop one or more files here
                    </div>
                    <div className={"text12-500"} style={{paddingTop: 4}}>
                      Supported automatic file formats: .dxf, .stp, .step
                    </div>
                  </div>
                </div>
              </div>
              }
              {allItemsCount === 0 ?
                <div className={"or-style-horizontal"}>or</div>
                :
                screens["md"] ? <div className={"or-style-vertical"}>or</div>
                  : null
              }
              <div className={"upload-button-side jss163"}
                   style={allItemsCount !== 0 && screens["md"] ? {width: "50%"} : {width: "100%"}}
              >
                <div className={"buttonRightSide"} style={{flexDirection: allItemsCount === 0 ? "column" : "row"}}>
                  <img src={uploadFile} className={"file-icon-2"} alt={""} width={34} height={40}
                       style={allItemsCount === 0 ? {marginTop: 6, right: 4, marginLeft: 8} : {
                         marginRight: 16,
                         marginTop: 4
                       }}
                  />
                  <div
                    style={allItemsCount !== 0 ? {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%"
                    } : {}}>
                    <div align={allItemsCount === 0 ? "center" : "left"} style={{display: 'block'}}>
                      <div className={"text14-600"} style={{marginTop: 4}}>
                        Add files from your computer
                      </div>
                      <div className={"text12-500"} style={{paddingTop: 4}}>
                        Choose one or more files and add them
                      </div>
                    </div>
                    {allItemsCount !== 0 &&
                    <div
                      className={"upButton"}
                      style={allItemsCount === 0 ? {marginTop: 6, right: 4, marginLeft: 8} : {marginRight: screens["md"] ? 16 : 0}}
                    >
                      <CloudUploadOutlined className={"upButtonIcon"}/>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Upload>
        </Col>
      </Row>
    </div>
  )
}