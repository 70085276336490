import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import {forceLogoutUser} from "./authActions";
import {
  DEFAULT_MATERIALS_IS_ADDING_NEW_BEND,
  DEFAULT_MATERIALS_IS_DELETING_BENDS,
  DEFAULT_MATERIALS_IS_EDITING_BEND,
  DEFAULT_MATERIALS_IS_FETCHING_ALREADY_SEEDED,
  DEFAULT_MATERIALS_IS_FETCHING_BENDING_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_CUTTING_MACHINE_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_CUTTING_MACHINE_PARAMETERS,
  DEFAULT_MATERIALS_IS_FETCHING_LATHING_MACHINE_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_MACHINING_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_MACHINING_MATERIALS,
  DEFAULT_MATERIALS_IS_FETCHING_MATERIAL_CERTIFICATE_PRICES,
  DEFAULT_MATERIALS_IS_FETCHING_MATERIAL_PRICES,
  DEFAULT_MATERIALS_IS_FETCHING_MILLING_MACHINE_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_SIMPLE_MACHINING_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_SINGLE_MATERIAL_BENDS,
  DEFAULT_MATERIALS_IS_FETCHING_SURFACE_COATING_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_MACHINE_PROPERTIES,
  DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_MATERIAL_PRICES,
  DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_STANDARD_MATERIAL_PRICES,
  DEFAULT_MATERIALS_IS_SEEDING_DATA,
  DEFAULT_MATERIALS_RELOAD_TAB,
  DEFAULT_MATERIALS_RESET_BENDS_DATA,
  DEFAULT_MATERIALS_SET_ALREADY_SEEDED_DATA,
  DEFAULT_MATERIALS_SET_ANODIZING_DATA,
  DEFAULT_MATERIALS_SET_BENDING_AVERAGE_PREPARATION_TIME,
  DEFAULT_MATERIALS_SET_BENDING_DATA,
  DEFAULT_MATERIALS_SET_BENDING_MAX_LENGTH,
  DEFAULT_MATERIALS_SET_BENDING_PREPARATION_PRICE,
  DEFAULT_MATERIALS_SET_BLASTING_DATA,
  DEFAULT_MATERIALS_SET_CHAMFERING_DATA,
  DEFAULT_MATERIALS_SET_CUTTING_MACHINE_DATA,
  DEFAULT_MATERIALS_SET_CUTTING_MACHINE_PARAMETER,
  DEFAULT_MATERIALS_SET_CUTTING_MACHINE_PARAMETERS,
  DEFAULT_MATERIALS_SET_CUTTING_MACHINE_SPEED,
  DEFAULT_MATERIALS_SET_CUTTING_MACHINE_TOLERANCE,
  DEFAULT_MATERIALS_SET_DEBURRING_DATA,
  DEFAULT_MATERIALS_SET_DEFAULT_TUBE_CUTTING_MACHINES,
  DEFAULT_MATERIALS_SET_DRILLING_DATA,
  DEFAULT_MATERIALS_SET_GALVANIC_ZINC_PLATING_DATA,
  DEFAULT_MATERIALS_SET_GALVANIZING_DATA,
  DEFAULT_MATERIALS_SET_HEAT_TREATMENT_DATA,
  DEFAULT_MATERIALS_SET_LATHING_DATA,
  DEFAULT_MATERIALS_SET_LATHING_GRADE_SPEED,
  DEFAULT_MATERIALS_SET_LATHING_MACHINE_DATA,
  DEFAULT_MATERIALS_SET_LATHING_MACHINE_ROUGHNESS,
  DEFAULT_MATERIALS_SET_LATHING_MACHINE_TOLERANCE,
  DEFAULT_MATERIALS_SET_MACHINING_DATA,
  DEFAULT_MATERIALS_SET_MACHINING_MATERIALS,
  DEFAULT_MATERIALS_SET_MATERIAL_CERTIFICATE_PRICES,
  DEFAULT_MATERIALS_SET_MATERIAL_PRICES,
  DEFAULT_MATERIALS_SET_MILLING_DATA,
  DEFAULT_MATERIALS_SET_MILLING_GRADE_SPEED,
  DEFAULT_MATERIALS_SET_MILLING_MACHINE_DATA,
  DEFAULT_MATERIALS_SET_MILLING_MACHINE_ROUGHNESS,
  DEFAULT_MATERIALS_SET_MILLING_MACHINE_TOLERANCE,
  DEFAULT_MATERIALS_SET_NICKEL_PLATING_DATA,
  DEFAULT_MATERIALS_SET_PAINTING_DATA,
  DEFAULT_MATERIALS_SET_POWDER_PAINTING_DATA,
  DEFAULT_MATERIALS_SET_PREPARATION_DATA,
  DEFAULT_MATERIALS_SET_SELECTED_REGION,
  DEFAULT_MATERIALS_SET_SIMPLE_MACHINING_DATA,
  DEFAULT_MATERIALS_SET_SINGLE_MATERIAL_BENDING_DATA,
  DEFAULT_MATERIALS_SET_SINGLE_MATERIAL_BENDS,
  DEFAULT_MATERIALS_SET_SURFACE_COATING_DATA,
  DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_PARAMETER,
  DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_PROPERTIES,
  DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_SPEED,
  DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_TOLERANCE,
  DEFAULT_MATERIALS_SET_TUBE_CUTTING_MATERIAL_PRICES,
  DEFAULT_MATERIALS_SET_TUBE_CUTTING_STANDARD_MATERIAL_PRICES,
  DEFAULT_MATERIALS_UPDATE_ASSEMBLY_MATERIAL_CERTIFICATE_PRICE,
  DEFAULT_MATERIALS_UPDATE_BASE_MATERIAL_CERTIFICATE_PRICE,
  DEFAULT_MATERIALS_UPDATE_MACHINING_MATERIAL_PRICE,
  DEFAULT_MATERIALS_UPDATE_MATERIAL_PRICE,
  DEFAULT_MATERIALS_UPDATE_STANDARD_TUBE_CUTTING_MATERIAL_PRICE,
  DEFAULT_MATERIALS_UPDATE_TUBE_CUTTING_MATERIAL_PRICE,
  MACHINING_SET_MILLING_MACHINE_ROUGHNESS,
  MATERIALS_UPDATE_ASSEMBLY_MATERIAL_CERTIFICATE_PRICE,
  MATERIALS_UPDATE_BASE_MATERIAL_CERTIFICATE_PRICE,
  PARAMETER_SEEDING_IS_FETCHING_ALREADY_SEEDED,
  PARAMETER_SEEDING_SET_ALREADY_SEEDED_DATA,

} from "./actionTypes";
import {formatTubeCuttingMachines} from "../utils/utility";

const handleRegionChange = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_SELECTED_REGION,
    payload: payload.region
  })
}

const fetchDefaultCuttingMachine = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_CUTTING_MACHINE_DATA,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/default-params/sheet-metal/cutting-machine`, {region: payload.region})
    .then(response => {
      const machine = response.data.machines[0]

      dispatch({
        type: DEFAULT_MATERIALS_SET_CUTTING_MACHINE_DATA,
        payload: machine
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_CUTTING_MACHINE_DATA,
        payload: false
      })

      return machine
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_CUTTING_MACHINE_DATA,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}
const fetchDefaultCuttingMachineParameters = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_CUTTING_MACHINE_PARAMETERS,
    payload: true
  })
  return http
    .post(`${API_BASE_URL}/default-params/sheet-metal/cutting-machine/${payload.cuttingMachineId}/properties`, {region: payload.region})
    .then(response => {
      let data = response.data.machine.groupNames.map( name => {
        let item = response.data.machine.tables.find(item => item.groupName === name)
        if(item) {
          return item
        }
        else {
          return {
            groupName: name,
            rows: [],
            columns: [],
            allAvailable: {}
          }
        }
      })

      let machine = {...response.data.machine, tables: data}

      dispatch({
        type: DEFAULT_MATERIALS_SET_CUTTING_MACHINE_PARAMETERS,
        payload: machine
      })
      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_CUTTING_MACHINE_PARAMETERS,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_CUTTING_MACHINE_PARAMETERS,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const setDefaultMachineParameter = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_CUTTING_MACHINE_PARAMETER,
    payload: payload
  })
}
const setDefaultMachineTolerance = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_CUTTING_MACHINE_TOLERANCE,
    payload: payload
  })
}
const updateDefaultMachineSpeed = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_CUTTING_MACHINE_SPEED,
    payload: payload
  })
}

//#------------------------------------ Bending ------------------------------------#//

const fetchDefaultBendingParameters = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_BENDING_DATA,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/default-params/sheet-metal/bending`, {region: payload.region})
    .then(response => {
      let bends = []
      if(response.data?.bends) {
        bends = response.data.groupNames.map( name => {
          let item = response.data.bends.find(item => item.groupName === name)
          if(item) {
            return item
          }
          else {
            return {
              groupName: name,
              rows: [],
              columns: [],
              allAvailable: {}
            }
          }
        })
      }
      dispatch({
        type: DEFAULT_MATERIALS_SET_BENDING_DATA,
        payload: {...response.data, bends: bends},
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_BENDING_DATA,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_BENDING_DATA,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const setDefaultBendingMaxLength = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_BENDING_MAX_LENGTH,
    payload: payload.value,
  })
}
const setDefaultBendingPreparationPrice = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_BENDING_PREPARATION_PRICE,
    payload: payload.value,
  })
}
const setDefaultAverageBendingPreparationTime = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_BENDING_AVERAGE_PREPARATION_TIME,
    payload: payload.value,
  })
}
const fetchSingleMaterialDefaultBendsData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_SINGLE_MATERIAL_BENDS,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/default-params/sheet-metal/bending/${payload.materialId}`, {region: payload.region})
    .then(response => {
      dispatch({
        type: DEFAULT_MATERIALS_SET_SINGLE_MATERIAL_BENDING_DATA,
        payload: response.data,
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_SINGLE_MATERIAL_BENDS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_SINGLE_MATERIAL_BENDS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const fetchSingleMaterialDefaultBends = payload => dispatch => {
  return http
    .post(`${API_BASE_URL}/default-params/sheet-metal/bending/bends`, {groupName: payload.groupName, region: payload.region})
    .then(response => {
      if(response.data) {
        dispatch({
          type: DEFAULT_MATERIALS_SET_SINGLE_MATERIAL_BENDS,
          payload: response.data,
        })
      }
      else {
        dispatch({
          type: DEFAULT_MATERIALS_SET_SINGLE_MATERIAL_BENDS,
          payload: {
            groupName: payload.groupName,
            rows: [],
            columns: [],
            allAvailable: {}
          },
        })
      }
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const resetDefaultMaterialBendsData = () => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_RESET_BENDS_DATA,
    payload: [],
  })
}
const addNewDefaultBend = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_ADDING_NEW_BEND,
    payload: true,
  })
  return http
    .post(`${API_BASE_URL}/default-params/sheet-metal/bending/${payload.materialId}/bends`, {length: payload.length, price: payload.price, region: payload.region})
    .then(response => {
      dispatch({
        type: DEFAULT_MATERIALS_SET_SINGLE_MATERIAL_BENDING_DATA,
        payload: response.data,
      })
      dispatch({
        type: DEFAULT_MATERIALS_IS_ADDING_NEW_BEND,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_ADDING_NEW_BEND,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
        throw error;
      }
    })
}
const editDefaultBend = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_EDITING_BEND,
    payload: true,
  })
  return http
    .put(`${API_BASE_URL}/default-params/sheet-metal/bending/${payload.materialId}/bends/${payload.bendId}`, {length: payload.length, price: payload.price, region: payload.region})
    .then(response => {
      dispatch({
        type: DEFAULT_MATERIALS_SET_SINGLE_MATERIAL_BENDING_DATA,
        payload: response.data,
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_EDITING_BEND,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_EDITING_BEND,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
        throw error;
      }
    })
}
const deleteDefaultBends = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_DELETING_BENDS,
    payload: true,
  })

  return http
    .deleteData(`${API_BASE_URL}/default-params/sheet-metal/bending/${payload.materialId}/bends`, {bends: [...payload.bends], region: payload.region})
    .then(response => {
      dispatch({
        type: DEFAULT_MATERIALS_SET_SINGLE_MATERIAL_BENDING_DATA,
        payload: response.data,
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_DELETING_BENDS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_DELETING_BENDS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

//#-------------------------------- Simple Machining --------------------------------#//

const fetchDefaultSimpleMachiningData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_SIMPLE_MACHINING_DATA,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/default-params/simple-machining`, {region: payload.region})
    .then(response => {
      dispatch({
        type: DEFAULT_MATERIALS_SET_SIMPLE_MACHINING_DATA,
        payload: response.data,
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_SIMPLE_MACHINING_DATA,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_SIMPLE_MACHINING_DATA,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const setDefaultDeburringData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_DEBURRING_DATA,
    payload: payload,
  })
}
const setDefaultChamferingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_CHAMFERING_DATA,
    payload: payload,
  })
}
const setDefaultDrillingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_DRILLING_DATA,
    payload: payload,
  })
}
const setDefaultPreparationData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_PREPARATION_DATA,
    payload: payload,
  })
}

//#----------------------------------- Machining -----------------------------------#//

const fetchDefaultMachiningData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_MACHINING_DATA,
    payload: !payload.alreadyFetchedDefaultMachiningData,
  })

  return http
    .post(`${API_BASE_URL}/default-params/machining`, {region: payload.region})
    .then(response => {
      dispatch({
        type: DEFAULT_MATERIALS_SET_MACHINING_DATA,
        payload: response.data,
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_MACHINING_DATA,
        payload: false,
      })
      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_MACHINING_DATA,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const fetchDefaultMillingMachineData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_MILLING_MACHINE_DATA,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/default-params/machining/milling/${payload.machineId}/properties`, {region: payload.region})
    .then((response) => {

      dispatch({
        type: DEFAULT_MATERIALS_SET_MILLING_MACHINE_DATA,
        payload: response.data,
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_MILLING_MACHINE_DATA,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_MILLING_MACHINE_DATA,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const setEmptyDefaultMillingMachines = () => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_MILLING_MACHINE_DATA,
    payload: {},
  })
}
const fetchDefaultLathingMachineData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_LATHING_MACHINE_DATA,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/default-params/machining/lathing/${payload.machineId}/properties`, {region: payload.region})
    .then(response => {

      dispatch({
        type: DEFAULT_MATERIALS_SET_LATHING_MACHINE_DATA,
        payload: response.data,
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_LATHING_MACHINE_DATA,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_LATHING_MACHINE_DATA,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const setEmptyDefaultLathingMachines = () => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_LATHING_MACHINE_DATA,
    payload: {},
  })
}
const setDefaultMillingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_MILLING_DATA,
    payload: payload,
  })
}
const setDefaultLathingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_LATHING_DATA,
    payload: payload,
  })
}
const updateDefaultGradeMillingSpeed = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_MILLING_GRADE_SPEED,
    payload: payload,
  })
}
const updateDefaultGradeLathingSpeed = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_LATHING_GRADE_SPEED,
    payload: payload,
  })
}
const setDefaultMillingMachineRoughness = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_MILLING_MACHINE_ROUGHNESS,
    payload: payload
  })
}
const setDefaultLathingMachineRoughness = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_LATHING_MACHINE_ROUGHNESS,
    payload: payload
  })
}
const setDefaultMillingMachineTolerance = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_MILLING_MACHINE_TOLERANCE,
    payload: payload
  })
}
const setDefaultLathingMachineTolerance = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_LATHING_MACHINE_TOLERANCE,
    payload: payload
  })
}

//#---------------------------------- Tube Cutting ---------------------------------#//

const fetchDefaultTubeCuttingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_DATA,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/default-params/tube-cutting`, {region: payload.region})
    .then(response => {
      const machine = formatTubeCuttingMachines(response.data.machines)[0].machines[0]
      dispatch({
        type: DEFAULT_MATERIALS_SET_DEFAULT_TUBE_CUTTING_MACHINES,
        payload: [machine]
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_DATA,
        payload: false
      })

      return machine
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_DATA,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}
const fetchDefaultTubeCuttingMachineParameters = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_MACHINE_PROPERTIES,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/default-params/tube-cutting/${payload.id}/properties`, {region: payload.region})
    .then(response => {
      let data = response.data.groupNames.map( name => {
        let item = response.data.tables.find(item => item.groupName === name)
        if(item) {
          return item
        }
        else {
          return {
            groupName: name,
            rows: [],
            columns: [],
            allAvailable: {}
          }
        }
      })

      let machine = {...response.data, tables: data}

      dispatch({
        type: DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_PROPERTIES,
        payload: machine
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_MACHINE_PROPERTIES,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_MACHINE_PROPERTIES,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const updateDefaultTubeCuttingMachineSpeed = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_SPEED,
    payload: payload
  })
}
const setDefaultTubeCuttingMachineParameter = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_PARAMETER,
    payload: payload
  })
}
const setDefaultTubeCuttingMachineTolerance = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_TOLERANCE,
    payload: payload
  })
}

//#-------------------------------- Surface Coating --------------------------------#//
const fetchDefaultSurfaceCoatingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_SURFACE_COATING_DATA,
    payload: true
  })
  return http
    .post(`${API_BASE_URL}/default-params/surface-coating`, {region: payload.region})
    .then(response => {
      dispatch({
        type: DEFAULT_MATERIALS_SET_SURFACE_COATING_DATA,
        payload: response.data,
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_SURFACE_COATING_DATA,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_SURFACE_COATING_DATA,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const setDefaultPaintingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_PAINTING_DATA,
    payload: payload,
  })
}
const setDefaultPowderPaintingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_POWDER_PAINTING_DATA,
    payload: payload,
  })
}
const setDefaultGalvanizingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_GALVANIZING_DATA,
    payload: payload,
  })
}
const setDefaultNickelPlatingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_NICKEL_PLATING_DATA,
    payload: payload,
  })
}
const setDefaultAnodizingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_ANODIZING_DATA,
    payload: payload,
  })
}
const setDefaultGalvanicZincPlatingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_GALVANIC_ZINC_PLATING_DATA,
    payload: payload,
  })
}
const setDefaultBlastingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_BLASTING_DATA,
    payload: payload
  })
}
const setDefaultHeatTreatmentData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_SET_HEAT_TREATMENT_DATA,
    payload: payload
  })
}

//#-------------------------------- Materials --------------------------------#//
const fetchDefaultMaterialPrices = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_MATERIAL_PRICES,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/default-params/sheet-metal/prices`, {region: payload.region})
    .then(response => {
      let data = response.data.groupNames.map( name => {
        let item = response.data.prices.find(item => item.groupName === name)
        if(item) {
          return item
        }
        else {
          return {
            groupName: name,
            rows: [],
            columns: [],
            allAvailable: {}
          }
        }
      })

      dispatch({
        type: DEFAULT_MATERIALS_SET_MATERIAL_PRICES,
        payload: [...data],
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_MATERIAL_PRICES,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_MATERIAL_PRICES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const updateDefaultMaterialPrice = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_UPDATE_MATERIAL_PRICE,
    payload: payload
  })
}
const fetchDefaultTubeMaterialPrices = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_MATERIAL_PRICES,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/default-params/tube-cutting/materials`, {region: payload.region})
    .then(response => {
      dispatch({
        type: DEFAULT_MATERIALS_SET_TUBE_CUTTING_MATERIAL_PRICES,
        payload: {
          availableMaterials: response.data.rows,
          allAvailable: response.data.allAvailable
        }
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_MATERIAL_PRICES,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_MATERIAL_PRICES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const fetchDefaultStandardTubeMaterialPrices = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_STANDARD_MATERIAL_PRICES,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/default-params/tube-cutting/standard-tube-materials`, {region: payload.region})
    .then(response => {
      dispatch({
        type: DEFAULT_MATERIALS_SET_TUBE_CUTTING_STANDARD_MATERIAL_PRICES,
        payload: {
          hasTubeCutting: response.data.hasTubeCutting,
          standardTubeMaterialPrices: response.data.table
        }
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_STANDARD_MATERIAL_PRICES,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_STANDARD_MATERIAL_PRICES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const updateDefaultTubeMaterialPrice = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_UPDATE_TUBE_CUTTING_MATERIAL_PRICE,
    payload: payload
  })
}
const updateDefaultStandardTubeMaterialPrice = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_UPDATE_STANDARD_TUBE_CUTTING_MATERIAL_PRICE,
    payload: payload
  })
}
const fetchDefaultMachiningMaterials = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_MACHINING_MATERIALS,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/default-params/machining/materials`, {region: payload.region})
    .then(response => {
      dispatch({
        type: DEFAULT_MATERIALS_SET_MACHINING_MATERIALS,
        payload: response.data,
      })
      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_MACHINING_MATERIALS,
        payload: false,
      })
      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_MACHINING_MATERIALS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const updateDefaultMachiningMaterialPrice = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_UPDATE_MACHINING_MATERIAL_PRICE,
    payload: payload
  })
}
const fetchDefaultMaterialCertificatePrices = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_MATERIAL_CERTIFICATE_PRICES,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/default-params/general/material-cert-fees`, {region: payload.region})
    .then(response => {
      dispatch({
        type: DEFAULT_MATERIALS_SET_MATERIAL_CERTIFICATE_PRICES,
        payload: response.data,
      })
      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_MATERIAL_CERTIFICATE_PRICES,
        payload: false,
      })
      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_MATERIAL_CERTIFICATE_PRICES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const updateDefaultBaseMaterialCertificatePrice = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_UPDATE_BASE_MATERIAL_CERTIFICATE_PRICE,
    payload: payload,
  })
}
const updateDefaultAssemblyMaterialCertificatePrice = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_UPDATE_ASSEMBLY_MATERIAL_CERTIFICATE_PRICE,
    payload: payload,
  })
}

//#------------------------------ SEEDING DATA ------------------------------#//
const fetchSeedingData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_FETCHING_ALREADY_SEEDED,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/default-params/general/machines-already-seeded`, payload)
    .then(response => {

      dispatch({
        type: DEFAULT_MATERIALS_SET_ALREADY_SEEDED_DATA,
        payload: response.data
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_FETCHING_ALREADY_SEEDED,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_FETCHING_ALREADY_SEEDED,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const seedData = payload => dispatch => {
  dispatch({
    type: DEFAULT_MATERIALS_IS_SEEDING_DATA,
    payload: true
  })
  return http
    .post(`${API_BASE_URL}/default-params/general/seed`, payload.params)
    .then(response => {

      dispatch({
        type: DEFAULT_MATERIALS_RELOAD_TAB,
        payload: payload.reloadTabKey
      })

      dispatch({
        type: DEFAULT_MATERIALS_IS_SEEDING_DATA,
        payload: false
      })

      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: DEFAULT_MATERIALS_IS_SEEDING_DATA,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}


export {
  handleRegionChange,
  fetchDefaultCuttingMachine,
  fetchDefaultCuttingMachineParameters,
  setDefaultMachineParameter,
  setDefaultMachineTolerance,
  updateDefaultMachineSpeed,

  fetchDefaultBendingParameters,
  fetchSingleMaterialDefaultBendsData,
  fetchSingleMaterialDefaultBends,
  setDefaultBendingMaxLength,
  addNewDefaultBend,
  editDefaultBend,
  deleteDefaultBends,
  resetDefaultMaterialBendsData,
  setDefaultBendingPreparationPrice,
  setDefaultAverageBendingPreparationTime,

  fetchDefaultSimpleMachiningData,
  setDefaultDeburringData,
  setDefaultChamferingData,
  setDefaultDrillingData,
  setDefaultPreparationData,

  fetchDefaultMachiningData,
  fetchDefaultMillingMachineData,
  setEmptyDefaultMillingMachines,
  fetchDefaultLathingMachineData,
  setEmptyDefaultLathingMachines,
  setDefaultMillingData,
  setDefaultLathingData,
  updateDefaultGradeMillingSpeed,
  updateDefaultGradeLathingSpeed,
  setDefaultMillingMachineRoughness,
  setDefaultLathingMachineRoughness,
  setDefaultMillingMachineTolerance,
  setDefaultLathingMachineTolerance,

  fetchDefaultTubeCuttingData,
  fetchDefaultTubeCuttingMachineParameters,
  updateDefaultTubeCuttingMachineSpeed,
  setDefaultTubeCuttingMachineParameter,
  setDefaultTubeCuttingMachineTolerance,

  fetchDefaultSurfaceCoatingData,
  setDefaultPaintingData,
  setDefaultPowderPaintingData,
  setDefaultGalvanizingData,
  setDefaultNickelPlatingData,
  setDefaultAnodizingData,
  setDefaultGalvanicZincPlatingData,
  setDefaultBlastingData,
  setDefaultHeatTreatmentData,

  fetchDefaultMaterialPrices,
  updateDefaultMaterialPrice,
  fetchDefaultTubeMaterialPrices,
  fetchDefaultStandardTubeMaterialPrices,
  updateDefaultTubeMaterialPrice,
  updateDefaultStandardTubeMaterialPrice,
  fetchDefaultMachiningMaterials,
  updateDefaultMachiningMaterialPrice,
  fetchDefaultMaterialCertificatePrices,
  updateDefaultBaseMaterialCertificatePrice,
  updateDefaultAssemblyMaterialCertificatePrice,

  fetchSeedingData,
  seedData
}

