import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Card, Layout, Select, Spin, Tabs} from "antd";
import {forceLogoutUser, logoutUser} from "../../../actions/authActions";
import {LaserCuttingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/LaserCuttingTab";
import {MaterialBendingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/MaterialBendingTab";
import {SimpleMachiningTab} from "../../../components/company/CompanyMaterials/MaterialTabs/SimpleMachiningTab";
import {SurfaceCoatingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/SurfaceCoatingTab";
import {MaterialsTab} from "../../../components/company/CompanyMaterials/MaterialTabs/MaterialsTab";
import "./style.less"
import {CompanySidebar} from "../../../components/company";
import {LoadingOutlined} from "@ant-design/icons";
import {ProducerSidebar} from "../../../components/producer/ProducerSidebar";
import { MachiningTab } from "../../../components/company/CompanyMaterials/MaterialTabs/MachiningTab";
import {TubeCuttingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/TubeCuttingTab";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  initializeSocket,
  changeMaterialPrice, changeBaseMaterialCertificateParams,
  changeAssemblyMaterialCertificateParams, changeBendingParams,
  changeDeburringParams, changeChamferingParams,
  changeDrillingParams, changeSimpleMachiningParams,
  changePaintingParams, changePowderPaintingParams,
  changeGalvanizingParams, changeNickelPlatingParams,
  changeAnodizingParams, changeGalvanicZincPlatingParams,
  changeHardeningParams, changeCaseHardeningParams,
  changeAnnealingParams, changeGlassBeadBlastingParams,
  changeSandBlastingParams, changeCuttingSpeed,
  changeCuttingMachineParams, changeMillingParams,
  changeLathingParams, changeGradeMillingSpeed,
  changeGradeLathingSpeed, changeMachiningMaterialPrice,
  changeTubeCuttingMachineParams, changeTubeCuttingMachineSpeed,
  changeTubeMaterialPrice, changeStandardTubeMaterialPrice,
  changeMachineMaxTolerance, changeMachineToleranceMarkup,
  changeMachineMaxRoughness, changeMachineRoughnessMarkup,
} from "../../../components/company/CompanyMaterials/SocketFunctions/DefaultMaterialsSocketFunctions";
import {
  fetchDefaultSurfaceCoatingData, handleRegionChange,
  setDefaultPaintingData, setDefaultPowderPaintingData,
  setDefaultGalvanizingData, setDefaultNickelPlatingData,
  setDefaultAnodizingData, setDefaultGalvanicZincPlatingData,
  setDefaultBlastingData, setDefaultHeatTreatmentData,
  editDefaultBend, deleteDefaultBends,
  fetchDefaultBendingParameters, setDefaultBendingMaxLength,
  setDefaultBendingPreparationPrice, setDefaultAverageBendingPreparationTime,
  resetDefaultMaterialBendsData, fetchSingleMaterialDefaultBendsData,
  fetchSingleMaterialDefaultBends, addNewDefaultBend,
  fetchDefaultMachiningData, updateDefaultGradeLathingSpeed,
  setDefaultLathingData, updateDefaultGradeMillingSpeed,
  setDefaultMillingData, fetchDefaultMillingMachineData,
  fetchDefaultLathingMachineData, setEmptyDefaultMillingMachines,
  setEmptyDefaultLathingMachines, updateDefaultMachineSpeed,
  setDefaultMachineParameter, setDefaultMachineTolerance,
  setDefaultDeburringData, setDefaultChamferingData,
  setDefaultDrillingData, setDefaultPreparationData,
  updateDefaultTubeCuttingMachineSpeed, setDefaultTubeCuttingMachineParameter,
  updateDefaultMaterialPrice, fetchDefaultMaterialPrices,
  fetchDefaultTubeMaterialPrices, fetchDefaultStandardTubeMaterialPrices,
  updateDefaultTubeMaterialPrice, updateDefaultStandardTubeMaterialPrice,
  fetchDefaultMachiningMaterials, updateDefaultMachiningMaterialPrice,
  setDefaultLathingMachineTolerance, setDefaultMillingMachineTolerance,
  setDefaultLathingMachineRoughness, setDefaultMillingMachineRoughness,
  setDefaultTubeCuttingMachineTolerance, fetchDefaultMaterialCertificatePrices,
  updateDefaultBaseMaterialCertificatePrice, updateDefaultAssemblyMaterialCertificatePrice,
} from "../../../actions/defaultMaterialsActions";
import {RegionSelect} from "../../../utils/utility";

const { TabPane } = Tabs;

export const EditDefaultMaterialsPage = () => {
  const dispatch = useDispatch()

  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    const headerCard = document.getElementById("editMaterialsHeader")
    setHeaderHeight(headerCard ? headerCard.clientHeight + 6 : 0)
    window.addEventListener("resize", () => {
      setHeaderHeight(headerCard ? headerCard.clientHeight + 6 : 0)
    })
    return () => {
      window.removeEventListener("resize", () => {
        setHeaderHeight(headerCard ? headerCard.clientHeight + 6 : 0)
      })
    }
  }, [])

  const getInitialTab = () => {
    if(history.state?.state?.tab) {
      return history.state.state.tab
    }
    const query = new URLSearchParams(window.location.search);
    const tab = query.get("tab")

    if(tab) {
      switch(tab) {
        case "material":
          return "7"
        case "cutting":
          return "1"
        case "bending":
          return "2"
        case "simple-machining":
          return "3"
        case "machining":
          return "4"
        case "tubes":
          return "5"
        case "coating":
          return "6"
        default:
          return "1"
      }
    }
    return "1"
  }

  const [selectedTab, setSelectedTab] = useState(getInitialTab())
  const [socketConnected, setSocketConnected] = useState(false)

  const isFetchingCuttingMachine = useSelector(state => state.defaultMaterialsData.isFetchingCuttingMachine)
  const defaultCuttingMachine = useSelector(state => state.defaultMaterialsData.defaultCuttingMachine)
  const isFetchingCuttingMachineData = useSelector(state => state.defaultMaterialsData.isFetchingCuttingMachineData)
  const defaultCuttingMachineData = useSelector(state => state.defaultMaterialsData.defaultCuttingMachineData)

  const isFetchingBendingData = useSelector(state => state.bending.isFetchingBendingData)
  const isFetchingDefaultBendingData = useSelector(state => state.defaultMaterialsData.isFetchingDefaultBendingData)
  const bendingData = useSelector(state => state.defaultMaterialsData.defaultBendingData)
  const bendingBendsData = useSelector(state => state.defaultMaterialsData.bendingDefaultBendsData)
  const isAddingDefaultBend = useSelector(state => state.defaultMaterialsData.isAddingDefaultBend)
  const isEditingDefaultBend = useSelector(state => state.defaultMaterialsData.isEditingDefaultBend)
  const isRemovingDefaultBend = useSelector(state => state.defaultMaterialsData.isRemovingDefaultBend)

  const isFetchingSimpleMachiningData = useSelector(state => state.simpleMachining.isFetchingSimpleMachiningData)
  const isFetchingDefaultSimpleMachiningData = useSelector(state => state.defaultMaterialsData.isFetchingDefaultSimpleMachiningData)
  const defaultSimpleMachiningData = useSelector(state => state.defaultMaterialsData.defaultSimpleMachiningData)

  const isFetchingDefaultMachiningData = useSelector(state => state.defaultMaterialsData.isFetchingDefaultMachiningData)
  const defaultMachiningData = useSelector(state => state.defaultMaterialsData.defaultMachiningData)
  const defaultMillingMachine = useSelector(state => state.defaultMaterialsData.defaultMillingMachine);
  const isFetchingDefaultMillingMachine = useSelector(state => state.defaultMaterialsData.isFetchingDefaultMillingMachineData);
  const defaultLathingMachine = useSelector(state => state.defaultMaterialsData.defaultLathingMachine);
  const isFetchingDefaultLathingMachine = useSelector(state => state.defaultMaterialsData.isFetchingDefaultLathingMachineData);
  const alreadyFetchedDefaultMachiningData = useSelector(state => state.defaultMaterialsData.alreadyFetchedDefaultMachiningData)

  const isFetchingSurfaceCoatingData = useSelector(state => state.surfaceCoating.isFetchingSurfaceCoatingData)
  const isFetchingDefaultSurfaceCoatingData = useSelector(state => state.defaultMaterialsData.isFetchingDefaultSurfaceCoatingData)
  const defaultSurfaceCoatingData = useSelector(state => state.defaultMaterialsData.defaultSurfaceCoatingData)

  const isFetchingDefaultTubeCuttingData = useSelector(state => state.defaultMaterialsData.isFetchingDefaultTubeCuttingData)
  const isFetchingDefaultTubeCuttingMachineData = useSelector(state => state.defaultMaterialsData.isFetchingDefaultTubeCuttingMachineData)
  const defaultTubeCuttingMachines = useSelector(state => state.defaultMaterialsData.defaultTubeCuttingMachines)
  const defaultTubeCuttingMachineData = useSelector(state => state.defaultMaterialsData.defaultTubeCuttingMachineData)

  const isFetchingDefaultMaterialPrices = useSelector(state => state.defaultMaterialsData.isFetchingDefaultMaterialPrices)
  const defaultMaterialPrices = useSelector(state => state.defaultMaterialsData.defaultMaterialPrices)
  const defaultTubeCuttingMaterials = useSelector(state => state.defaultMaterialsData.defaultTubeCuttingMaterials)
  const defaultStandardTubeMaterialPrices = useSelector(state => state.defaultMaterialsData.defaultStandardTubeMaterialPrices)

  const isFetchingDefaultTubeCuttingMaterials = useSelector(state => state.defaultMaterialsData.isFetchingDefaultTubeCuttingMaterials)
  const isFetchingDefaultStandardTubeMaterialPrices = useSelector(state => state.defaultMaterialsData.isFetchingDefaultStandardTubeMaterialPrices)

  const isFetchingMachiningMaterials = useSelector(state => state.defaultMaterialsData.isFetchingDefaultMachiningMaterials)
  const machiningMaterials = useSelector(state => state.defaultMaterialsData.defaultMachiningMaterials)
  const defaultBaseMaterialCertFee = useSelector(state => state.defaultMaterialsData.baseMaterialCertFee)
  const defaultAssemblyMaterialCertFee = useSelector(state => state.defaultMaterialsData.assemblyMaterialCertFee)

  const selectedRegion = useSelector(state => state.defaultMaterialsData.selectedRegion)
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user)

  const isGelso = user?.role === "gelsoAdmin" || user?.role === "gelsoSubAdmin"

  const onRegionChange = (value) => {
    if (selectedRegion !== value) {
      dispatch(handleRegionChange({region: value}))
    }
  }

  useEffect(() => {
    initializeSocket({
      setSocketConnected,
      logOutUser: () => dispatch(forceLogoutUser()),
    })
  }, []);

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      {user?.role === "producerAdmin" ?
        <ProducerSidebar
          onLogout={()=>{dispatch(logoutUser())}}
          user={user}
        />
        :
        <CompanySidebar
          onLogout={()=>{dispatch(logoutUser())}}
          user={user}
        />
      }
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}} type="flex">
        <div style={{paddingLeft: 15, paddingTop: 15, paddingRight: 15}}>
          <div>
            <div id={"editMaterialsHeader"}>
              <Card className={"tabMaterialCardMargin editMaterialPageHeaderCard"}>
                <InfoCircleOutlined className={"editMaterialPageInfoIcon"}/>
                <div style={{ marginLeft: 6 }}>
                  This page allows you to define default parameter values for individual regions. Producers can load up these parameters as their own instead of manually setting each value.
                </div>
              </Card>
              <RegionSelect
                onChange={onRegionChange}
                initialValue={selectedRegion}
                label={"Edit parameters for the following region:"}
                style={{ margin: "0 0 6px 10px" }}
              />
            </div>
            <Tabs size={"small"} activeKey={selectedTab} onChange={(e)=>{setSelectedTab(e)}} type="card" className={"materialsTabs noSelect"}>
            <TabPane className={"materialsTabPane noSelect"} tab="Cutting Machines" key="1">
              <LaserCuttingTab
                selectedTab={selectedTab}
                cuttingMachines={defaultCuttingMachine}
                isFetchingMachines={isFetchingCuttingMachine}
                machineData={defaultCuttingMachineData}
                isFetchingMachineData={isFetchingCuttingMachineData}
                changeCuttingSpeed={changeCuttingSpeed}
                changeCuttingMachineParams={changeCuttingMachineParams}
                changeMachineMaxTolerance={changeMachineMaxTolerance}
                changeMachineToleranceMarkup={changeMachineToleranceMarkup}
                updateCuttingMachineSpeed={updateDefaultMachineSpeed}
                setMachineParameter={setDefaultMachineParameter}
                setCuttingMachineTolerance={setDefaultMachineTolerance}
                socketConnected={socketConnected}
                headerHeight={headerHeight}
                editDefaultMode={true}
              />
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Bending" key="2">
              <Spin spinning={isFetchingBendingData || isFetchingDefaultBendingData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px",
                    }}
              >
                <MaterialBendingTab
                  changeBendingParams={changeBendingParams}
                  addNewBend={addNewDefaultBend}
                  editBend={editDefaultBend}
                  deleteBends={deleteDefaultBends}
                  fetchBendingData={fetchDefaultBendingParameters}
                  setBendingMaxLength={setDefaultBendingMaxLength}
                  setPreparationPrice={setDefaultBendingPreparationPrice}
                  setAveragePreparationTime={setDefaultAverageBendingPreparationTime}
                  resetMaterialBendsData={resetDefaultMaterialBendsData}
                  fetchSingleMaterialBendsData={fetchSingleMaterialDefaultBendsData}
                  fetchSingleMaterialBends={fetchSingleMaterialDefaultBends}
                  bendingData={bendingData}
                  bendingBendsData={bendingBendsData}
                  isAddingNewBend={isAddingDefaultBend}
                  isEditingBend={isEditingDefaultBend}
                  isRemovingBend={isRemovingDefaultBend}
                  isGelso={isGelso}
                  selectedTab={selectedTab}
                  headerHeight={headerHeight}
                  editDefaultMode={true}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Simple Machining" key="3">
              <Spin spinning={isFetchingSimpleMachiningData || isFetchingDefaultSimpleMachiningData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <SimpleMachiningTab
                  isFetchingSimpleMachiningData={isFetchingSimpleMachiningData || isFetchingDefaultSimpleMachiningData || !socketConnected}
                  selectedTab={selectedTab}
                  isGelso={isGelso}
                  simpleMachiningData={defaultSimpleMachiningData}
                  setDeburringData={setDefaultDeburringData}
                  setChamferingData={setDefaultChamferingData}
                  setDrillingData={setDefaultDrillingData}
                  setPreparationData={setDefaultPreparationData}
                  changeDeburringParams={changeDeburringParams}
                  changeChamferingParams={changeChamferingParams}
                  changeDrillingParams={changeDrillingParams}
                  changeSimpleMachiningParams={changeSimpleMachiningParams}
                  headerHeight={headerHeight}
                  editDefaultMode={true}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Machining" key="4">
              <Spin spinning={isFetchingDefaultMachiningData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <MachiningTab
                  isFetchingMachiningData={!socketConnected}
                  selectedTab={selectedTab}
                  isGelso={isGelso}
                  machiningData={defaultMachiningData}
                  selectedMillingMachine={defaultMillingMachine}
                  isFetchingSelectedMillingMachine={isFetchingDefaultMillingMachine}
                  selectedLathingMachine={defaultLathingMachine}
                  isFetchingSelectedLathingMachine={isFetchingDefaultLathingMachine}
                  alreadyFetchedMachiningData={alreadyFetchedDefaultMachiningData}
                  fetchData={fetchDefaultMachiningData}
                  fetchMillingMachineData={fetchDefaultMillingMachineData}
                  setEmptyMillingMachines={setEmptyDefaultMillingMachines}
                  fetchLathingMachineData={fetchDefaultLathingMachineData}
                  setEmptyLathingMachines={setEmptyDefaultLathingMachines}
                  setMillingData={setDefaultMillingData}
                  updateGradeMillingSpeed={updateDefaultGradeMillingSpeed}
                  setLathingData={setDefaultLathingData}
                  updateGradeLathingSpeed={updateDefaultGradeLathingSpeed}
                  changeMillingParams={changeMillingParams}
                  changeLathingParams={changeLathingParams}
                  changeGradeMillingSpeed={changeGradeMillingSpeed}
                  changeGradeLathingSpeed={changeGradeLathingSpeed}
                  changeMachineMaxTolerance={changeMachineMaxTolerance}
                  changeMachineToleranceMarkup={changeMachineToleranceMarkup}
                  changeMachineMaxRoughness={changeMachineMaxRoughness}
                  changeMachineRoughnessMarkup={changeMachineRoughnessMarkup}
                  setMillingMachineRoughness={setDefaultMillingMachineRoughness}
                  setMillingMachineTolerance={setDefaultMillingMachineTolerance}
                  setLathingMachineRoughness={setDefaultLathingMachineRoughness}
                  setLathingMachineTolerance={setDefaultLathingMachineTolerance}
                  socketConnected={socketConnected}
                  headerHeight={headerHeight}
                  editDefaultMode={true}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Tube Cutting" key="5">
              <Spin spinning={isFetchingDefaultTubeCuttingData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <TubeCuttingTab
                  isFetchingTubeCuttingData={isFetchingDefaultTubeCuttingData}
                  isFetchingSelectedMachine={isFetchingDefaultTubeCuttingMachineData}
                  availableMachines={defaultTubeCuttingMachines}
                  machineData={defaultTubeCuttingMachineData}
                  changeTubeCuttingMachineParams={changeTubeCuttingMachineParams}
                  changeTubeCuttingMachineSpeed={changeTubeCuttingMachineSpeed}
                  changeMachineMaxTolerance={changeMachineMaxTolerance}
                  changeMachineToleranceMarkup={changeMachineToleranceMarkup}
                  updateTubeCuttingMachineSpeed={updateDefaultTubeCuttingMachineSpeed}
                  setTubeCuttingMachineParameter={setDefaultTubeCuttingMachineParameter}
                  setTubeCuttingMachineTolerance={setDefaultTubeCuttingMachineTolerance}
                  hasTubeCutting={true}
                  selectedTab={selectedTab}
                  socketConnected={socketConnected}
                  headerHeight={headerHeight}
                  editDefaultMode={true}
                  isGelso={isGelso}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Surface Treatments" key="6">
              <Spin spinning={isFetchingSurfaceCoatingData || isFetchingDefaultSurfaceCoatingData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <SurfaceCoatingTab
                  surfaceCoatingData={defaultSurfaceCoatingData}
                  isFetchingSurfaceCoatingData={isFetchingSurfaceCoatingData || isFetchingDefaultSurfaceCoatingData}
                  fetchData={fetchDefaultSurfaceCoatingData}
                  isGelso={isGelso}
                  changePaintingParams={changePaintingParams}
                  changePowderPaintingParams={changePowderPaintingParams}
                  changeGalvanizingParams={changeGalvanizingParams}
                  changeNickelPlatingParams={changeNickelPlatingParams}
                  changeAnodizingParams={changeAnodizingParams}
                  changeGalvanicZincPlatingParams={changeGalvanicZincPlatingParams}
                  changeHardeningParams={changeHardeningParams}
                  changeCaseHardeningParams={changeCaseHardeningParams}
                  changeAnnealingParams={changeAnnealingParams}
                  changeGlassBeadBlastingParams={changeGlassBeadBlastingParams}
                  changeSandBlastingParams={changeSandBlastingParams}
                  setPaintingData={setDefaultPaintingData}
                  setPowderPaintingData={setDefaultPowderPaintingData}
                  setGalvanizingData={setDefaultGalvanizingData}
                  setNickelPlatingData={setDefaultNickelPlatingData}
                  setAnodizingData={setDefaultAnodizingData}
                  setGalvanicZincPlatingData={setDefaultGalvanicZincPlatingData}
                  setBlastingData={setDefaultBlastingData}
                  setHeatTreatmentData={setDefaultHeatTreatmentData}
                  selectedTab={selectedTab}
                  headerHeight={headerHeight}
                  editDefaultMode={true}
                />
              </Spin>
            </TabPane>

            <TabPane className={"materialsTabPane noSelect"} tab="Materials" key="7">
              <MaterialsTab
                isFetchingMaterialPrices={isFetchingDefaultMaterialPrices}
                materialPrices={defaultMaterialPrices}
                tubeCuttingMaterials={defaultTubeCuttingMaterials}
                standardTubeMaterialPrices={defaultStandardTubeMaterialPrices}
                isFetchingTubeMaterialPrices={isFetchingDefaultTubeCuttingMaterials}
                isFetchingStandardTubeMaterialPrices={isFetchingDefaultStandardTubeMaterialPrices}
                hasTubeCutting={true}
                isFetchingMachiningMaterials={isFetchingMachiningMaterials}
                machiningMaterials={machiningMaterials}
                hasMachining={true}
                baseMaterialCertFee={defaultBaseMaterialCertFee}
                assemblyMaterialCertFee={defaultAssemblyMaterialCertFee}
                updateTubeMaterialPrice={updateDefaultTubeMaterialPrice}
                fetchMaterialPrices={fetchDefaultMaterialPrices}
                fetchTubeMaterialPrices={fetchDefaultTubeMaterialPrices}
                fetchStandardTubeMaterialPrices={fetchDefaultStandardTubeMaterialPrices}
                fetchMachiningMaterials={fetchDefaultMachiningMaterials}
                fetchMaterialCertificatePrices={fetchDefaultMaterialCertificatePrices}
                updateStandardTubeMaterialPrice={updateDefaultStandardTubeMaterialPrice}
                updateMaterialPrice={updateDefaultMaterialPrice}
                updateMachiningMaterialPrice={updateDefaultMachiningMaterialPrice}
                changeMaterialPrice={changeMaterialPrice}
                changeBaseMaterialCertificateParams={changeBaseMaterialCertificateParams}
                updateBaseMaterialCertificatePrice={updateDefaultBaseMaterialCertificatePrice}
                updateAssemblyMaterialCertificatePrice={updateDefaultAssemblyMaterialCertificatePrice}
                changeAssemblyMaterialCertificateParams={changeAssemblyMaterialCertificateParams}
                changeMachiningMaterialPrice={changeMachiningMaterialPrice}
                changeTubeMaterialPrice={changeTubeMaterialPrice}
                changeStandardTubeMaterialPrice={changeStandardTubeMaterialPrice}
                selectedTab={selectedTab}
                socketConnected={socketConnected}
                headerHeight={headerHeight}
                editDefaultMode={true}
                isGelso={isGelso}
              />
            </TabPane>
          </Tabs>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}