import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  Card, Col, Divider, Form, InputNumber, Row, Space, Spin, Switch, Tabs, Typography
} from "antd";
import {lineBreakLimit} from "../../../../../constants";
import {
  AddMachineModal,
  EditMachineModal,
  MachineSelect,
  MachineTolerancesModal,
  RemoveMachineModal
} from "../../../MachineSelect";
import {useWindowSize} from "../../../../../utils/utility";
import {InfoCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import MaterialTableContainer from "../../MaterialTableContainer";
import {ValueTable} from "../../MaterialTables/ValueTable";
import {
  fetchTubeCuttingMachineParameters,
  fetchTubeCuttingData,
  setTubeCuttingService,
  createTubeCuttingMachine,
  editTubeCuttingMachine,
  deleteTubeCuttingMachine,
  setEmptyTubeCuttingMachineData,
} from "../../../../../actions/tubeCuttingActions";
import {debounce} from "@material-ui/core";
import {ToleranceSelect} from "../../../ToleranceSelect";
import {
  fetchDefaultTubeCuttingData,
  fetchDefaultTubeCuttingMachineParameters
} from "../../../../../actions/defaultMaterialsActions";

const {TabPane} = Tabs;

export const TubeCuttingTab = (props) => {

  const {
    selectedTab, socketConnected, isGelso,
    changeTubeCuttingMachineParams,
    changeTubeCuttingMachineSpeed,
    changeMachineMaxTolerance,
    changeMachineToleranceMarkup, setTubeCuttingMachineTolerance,
    machineData, availableMachines, hasTubeCutting,
    isFetchingSelectedMachine, isFetchingTubeCuttingData,
    updateTubeCuttingMachineSpeed, setTubeCuttingMachineParameter,
    headerHeight = 0, editDefaultMode = false,
  } = props;

  const [fetchingAll, setFetchingAll] = useState(false);
  const [rowFlex, setRowFlex] = useState(false);
  const [shouldUpdateSpeeds, setShouldUpdateSpeeds] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState("");
  const [disableSwitching, setDisableSwitching] = useState(false);
  const [editMachine, setEditmachine] = useState(null);
  const [removeMachine, setRemoveMachine] = useState(null);

  const isCreatingTubeCuttingMachine = useSelector(state => state.tubeCutting.isCreatingTubeCuttingMachine)
  const isEditingTubeCuttingMachine = useSelector(state => state.tubeCutting.isEditingTubeCuttingMachine)
  const isRemovingTubeCuttingMachine = useSelector(state => state.tubeCutting.isRemovingTubeCuttingMachine)

  const selectedRegion = useSelector(state => state.defaultMaterialsData.selectedRegion)

  const [addMachineModalVisible, setAddMachineModalVisible] = useState(false)
  const [editMachineModalVisible, setEditMachineModalVisible] = useState(false)
  const [removeMachineModalVisible, setRemoveMachineModalVisible] = useState(false)
  const [machineTolerancesModalVisible, setMachineTolerancesModalVisible] = useState(false)

  let showFallbackParams = isGelso && !editDefaultMode;
  let showOfferService = !isGelso && !editDefaultMode;
  let showEditInfoText = editDefaultMode;

  const dispatch = useDispatch();
  const tubeCuttingRef = React.createRef();
  const initialRegionMount = useRef(true)
  const selectedRegionRef = useRef(selectedRegion)

  const formatData = (data) => {
    return editDefaultMode ? {...data, region: selectedRegionRef.current} : data
  }

  const windowSize = useWindowSize();

  const reloadTabKey = useSelector(state => state.defaultMaterialsData.reloadTabKey)
  useEffect(() => {
    if (reloadTabKey === selectedTab) {
      initialize()
    }
  }, [reloadTabKey])

  useEffect(() => {
    if (windowSize.width >= 1153 && windowSize.width <= 1426) {
      setRowFlex(true)
    } else {
      setRowFlex(false);
    }
  }, [windowSize.width])

  useEffect(() => {
    selectedRegionRef.current = selectedRegion
    if (initialRegionMount.current) {
      initialRegionMount.current = false;
    } else {
      initialize()
    }
  }, [selectedRegion])

  const updateMachineSelectionAndParameters = (machines) => {
    let machineId = selectedMachine;
    const doesExist = machines.some(m => m?.machines.some(item => item.id === selectedMachine));
    if (!doesExist && machines.length > 0) {
      machineId = machines[0].machines[0].id;
      setSelectedMachine(machineId);
    }
    if (machineId) {
      dispatch(fetchTubeCuttingMachineParameters({ id: machineId })).then(() => {
        setShouldUpdateSpeeds(true);
        setFetchingAll(false);
      });
    } else {
      setFetchingAll(false);
    }
  }

  const initialize = () => {
    if (selectedTab === "5") {
      if (editDefaultMode) {
        setFetchingAll(true);
        dispatch(fetchDefaultTubeCuttingData(formatData({})))
          .then((machine) => {
            dispatch(fetchDefaultTubeCuttingMachineParameters(formatData({ id: machine.id })))
              .then(() => {
                setSelectedMachine(machine.id)
                setShouldUpdateSpeeds(true);
                setFetchingAll(false);
              }).catch(() => {
              setFetchingAll(false);
            })
          }).catch(() => {
          setFetchingAll(false);
        })
      } else {
        setFetchingAll(true)
        dispatch(fetchTubeCuttingData()).then((machines) => {
          updateMachineSelectionAndParameters(machines);
        }).catch(() => {
          setFetchingAll(false);
        })
      }
    }
  }

  useEffect(() => {
    initialize()
  }, [selectedTab])

  const triggerSlideToggle = (value) => {
    setDisableSwitching(true);
    dispatch(setTubeCuttingService({value})).then(() => {
      setDisableSwitching(false)
      if (value) {
        initialize()
      }
    })
  }

  const changeSelectedMachine = (id) => {
    setSelectedMachine(id)
    dispatch(fetchTubeCuttingMachineParameters({id: id})).then(()=>{
      setShouldUpdateSpeeds(true)
    })
  }

  const handleAddMachineOpen = () => {
    setAddMachineModalVisible(true)
  }

  const handleAddMachineClose = () => {
    setAddMachineModalVisible(false)
  }

  const createNewMachine = (values) => {
    dispatch(createTubeCuttingMachine(values)).then(machines => {
      const machineId = machines[machines.length - 1].id // Najnovija masina je uvek poslednja u nizu
      setSelectedMachine(machineId)
      dispatch(fetchTubeCuttingMachineParameters({id: machineId})).then(() => {
        setShouldUpdateSpeeds(true)
      })
      handleAddMachineClose()
    })
  }

  const handleEditMachine = (e, item) => {
    e.stopPropagation()
    setEditmachine(item)
    setEditMachineModalVisible(true);
  }

  const handleEditMachineClose = () => {
    setEditMachineModalVisible(false);
  }

  const applyEditMachine = (values) => {
    dispatch(editTubeCuttingMachine({id: editMachine.id, data: values})).then(() => {
      handleEditMachineClose()
    })
  }

  const handleRemoveMachine = (e, item) => {
    e.stopPropagation()
    setRemoveMachine(item)
    setRemoveMachineModalVisible(true)
  }

  const handleRemoveMachineClose = () => {
    setRemoveMachine(null)
    setRemoveMachineModalVisible(false)
  }

  const handleRemoveMachineConfirm = () => {
    dispatch(deleteTubeCuttingMachine({id: removeMachine.id})).then(machines => {
      if (machines.length === 0) {
        setSelectedMachine(null)
        dispatch(setEmptyTubeCuttingMachineData())
      } else {
        if (removeMachine.id === selectedMachine) {
          setSelectedMachine(machines[0].machines[0].id)
          dispatch(fetchTubeCuttingMachineParameters({id: machines[0].machines[0].id})).then(() => {
            setShouldUpdateSpeeds(true)
            setFetchingAll(false)
          })
        }
      }
      setRemoveMachine(null)
      setRemoveMachineModalVisible(false)
    })
  }

  const handleMachineTolerancesOpen = () => {
    setMachineTolerancesModalVisible(true);
  }

  const handleMachineTolerancesClose = () => {
    setMachineTolerancesModalVisible(false);
  }

  /*--------- Change Table Values --------*/

  const changeSpeed = (params) => {
    changeTubeCuttingMachineSpeed(formatData({machineId: machineData.id, materialId: params.materialId, speed: params.value}))
    dispatch(updateTubeCuttingMachineSpeed(params))
    return Promise.resolve(10)
  }

  /*--------- Parameters ---------*/
  /*--------- Max Weight ---------*/

  const applyMachineMaxTubeWeight = (data) => {
    dispatch(setTubeCuttingMachineParameter({maxTubeWeight: data.fieldValue}))
    changeTubeCuttingMachineParams(formatData(data))
  }

  const debouncedMachineMaxTubeWeight = useCallback(
    debounce(data => applyMachineMaxTubeWeight(data), 800),
    []
  )

  const changeMachineMaxTubeWeight = (value) => {
    debouncedMachineMaxTubeWeight({
      machineId: machineData.id,
      fieldName: "maxTubeWeight",
      fieldValue: value
    })
  }

  /*--------- Max Length ---------*/

  const applyMachineMaxTubeLength = (data) => {
    dispatch(setTubeCuttingMachineParameter({maxTubeLength: data.fieldValue}))
    changeTubeCuttingMachineParams(formatData(data))
  }

  const debouncedMachineMaxTubeLength = useCallback(
    debounce(data => applyMachineMaxTubeLength(data), 800),
      []
  )

  const changeMachineMaxTubeLength = (value) => {
    debouncedMachineMaxTubeLength({
      machineId: machineData.id,
      fieldName: "maxTubeLength",
      fieldValue: value
    })
  }

  /*--------- Cutting Price ---------*/

  const applyMachineCuttingPrice = (data) => {
    dispatch(setTubeCuttingMachineParameter({cuttingPrice: data.fieldValue}))
    changeTubeCuttingMachineParams(formatData(data))
  }

  const debouncedMachineCuttingPrice = useCallback(
    debounce(data => applyMachineCuttingPrice(data), 800),
    []
  )

  const changeMachineCuttingPrice = (value) => {
    debouncedMachineCuttingPrice({
      machineId: machineData.id,
      fieldName: "cuttingPrice",
      fieldValue: value
    })
  }

  /*--------- Prep Price ---------*/

  const applyMachinePreparationPrice = (data) => {
    dispatch(setTubeCuttingMachineParameter({engineerPricePerHour: data.fieldValue}))
    changeTubeCuttingMachineParams(formatData(data))
  }

  const debouncedMachinePreparationPrice = useCallback(
    debounce(data => applyMachinePreparationPrice(data), 800),
    []
  )

  const changeMachinePreparationPrice = (value) => {
    debouncedMachinePreparationPrice({
      machineId: machineData.id,
      fieldName: "engineerPricePerHour",
      fieldValue: value
    })
  }

  /*--------- Avg Prep Time ---------*/

  const applyMachinePreparationTime = (data) => {
    dispatch(setTubeCuttingMachineParameter({averagePreparationTime: data.fieldValue}))
    changeTubeCuttingMachineParams(formatData(data))
  }

  const debouncedMachinePreparationTime = useCallback(
    debounce(data => applyMachinePreparationTime(data), 800),
    []
  )

  const changeMachinePreparationTime = (value) => {
    debouncedMachinePreparationTime({
      machineId: machineData.id,
      fieldName: "averagePreparationTime",
      fieldValue: value
    })
  }

  /*--------- Pierce Time Low ---------*/

  const applyMachinePiercingTimeLow = (data) => {
    dispatch(setTubeCuttingMachineParameter({pierceTimeLow: data.fieldValue}))
    changeTubeCuttingMachineParams(formatData(data))
  }

  const debouncedMachinePiercingTimeLow = useCallback(
    debounce(data => applyMachinePiercingTimeLow(data), 800),
    []
  )

  const changeMachinePiercingTimeLow = (value) => {
    debouncedMachinePiercingTimeLow({
      machineId: machineData.id,
      fieldName: "pierceTimeLow",
      fieldValue: value
    })
  }

  /*--------- Pierce Time High ---------*/

  const applyMachinePiercingTimeHigh = (data) => {
    dispatch(setTubeCuttingMachineParameter({pierceTimeHigh: data.fieldValue}))
    changeTubeCuttingMachineParams(formatData(data))
  }

  const debouncedMachinePiercingTimeHigh = useCallback(
    debounce(data => applyMachinePiercingTimeHigh(data), 800),
    []
  )

  const changeMachinePiercingTimeHigh = (value) => {
    debouncedMachinePiercingTimeHigh({
      machineId: machineData.id,
      fieldName: "pierceTimeHigh",
      fieldValue: value
    })
  }

  /*--------- Pierce Time Threshold ---------*/

  const applyMachinePiercingTimeThreshold = (data) => {
    dispatch(setTubeCuttingMachineParameter({pierceTimeThreshold: data.fieldValue}))
    changeTubeCuttingMachineParams(formatData(data))
  }

  const debouncedMachinePiercingTimeThreshold = useCallback(
    debounce(data => applyMachinePiercingTimeThreshold(data), 800),
    []
  )

  const changeMachinePiercingTimeThreshold = (value) => {
    debouncedMachinePiercingTimeThreshold({
      machineId: machineData.id,
      fieldName: "pierceTimeThreshold",
      fieldValue: value
    })
  }

  /* ------------ Tolerances ---------- */

  const handleChangeTolerance = (data) => {
    dispatch(setTubeCuttingMachineTolerance(data))
    changeMachineToleranceMarkup(formatData(data))
  }

  const handleChangeTubeCuttingTolerance = (data) => {
    changeMachineMaxTolerance(formatData(data))
  }

  useEffect(() => {
    tubeCuttingRef.current?.setFieldsValue({
      maxTubeLength: machineData.maxTubeLength,
      maxTubeWeight: machineData.maxTubeWeight,
      cuttingPrice: machineData.cuttingPrice,
      engineerPricePerHour: machineData.engineerPricePerHour,
      averagePreparationTime: machineData.averagePreparationTime,
      pierceTimeLow: machineData.pierceTimeLow,
      pierceTimeHigh: machineData.pierceTimeHigh,
      pierceTimeThreshold: machineData.pierceTimeThreshold,
      maxTolerance: machineData.maxTolerance,
    })
  }, [machineData])

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"}>
        <div style={{height: `calc(100vh - 100px - ${headerHeight}px)`, overflowY: "auto", overflowX: "hidden", paddingRight: 12}}>
          {!editDefaultMode &&
            <Row gutter={15}>
              <Col xl={14} span={24}>
                <div className={"switchContainer"}>
                  {showFallbackParams && <span style={{marginRight: 30}}>Fallback Tube Cutting parameters:</span>}
                  {showOfferService &&
                  <div>
                    <span style={{marginRight: 30, marginBottom: 6}}>Do you offer Tube Cutting services?</span>
                    <span>No</span>
                    <Switch
                      checked={hasTubeCutting}
                      onChange={triggerSlideToggle}
                      style={{marginLeft: 6, marginRight: 6}}
                      disabled={disableSwitching}
                    />
                    <span>Yes</span>
                  </div>
                  }
                </div>
              </Col>
            </Row>
          }
          {!editDefaultMode && <Divider style={{width: "75%", minWidth: "0%", margin: "8px 0 12px 0"}}/>}
          {hasTubeCutting &&
            <div>
              <Form
                layout={'horizontal'}
                style={{height: "100%"}}
                ref={tubeCuttingRef}
              >
                {editDefaultMode ?
                  <div>
                    <Card className={"defaultMachineCard"} style={{ marginBottom: 12 }}>
                      <InfoCircleOutlined className={"editMaterialPageInfoIcon"}/>
                      <div style={{ marginLeft: 6, fontWeight: 500 }}>
                        This page represents the default regional parameters for tube cutting.
                      </div>
                    </Card>
                    <Divider style={{width: "75%", minWidth: "0%", margin: "8px 0 12px 0"}}/>
                  </div>
                  :
                  <div>
                    <div className={windowSize.width > lineBreakLimit ? "inLine" : "newLIne"}>
                      <div className={"customColumn2"} style={{width: "100%", maxWidth: 1605}}>
                        <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'flex-end'}}>
                          <MachineSelect
                            type={"tubeCutting"}
                            isFetchingMachines={isFetchingTubeCuttingData}
                            selectedMachine={selectedMachine}
                            changeSelectedMachine={changeSelectedMachine}
                            machines={availableMachines}
                            isFetchingMachineTypes={false}
                            handleEditMachine={handleEditMachine}
                            handleRemoveMachine={handleRemoveMachine}
                            handleAddMachineOpen={handleAddMachineOpen}
                            allowPriorityChange={false}
                            allowAddMachine={true}
                            showButtons={true}
                          />
                        </div>
                      </div>
                    </div>
                    <Divider style={{width: "75%", minWidth: "0%", margin: "0 0 16px 0"}}/>
                  </div>
                }
                <Spin spinning={fetchingAll || isFetchingSelectedMachine || !socketConnected}
                      indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                      style={{
                        position: "absolute",
                        width: "100%",
                        top: machineData.id ? "41px" : "36%",
                        left: machineData.id ? "-40px" : "-10px",
                      }}
                >
                  {machineData.id &&
                    <div className={windowSize.width > lineBreakLimit ? "inLine" : "newLIne"}>
                      <div className={"customColumn3"}>
                        <div className={"column3CardContainer"}>
                          <Card style={{marginBottom: 16}} bodyStyle={{padding: "8px 16px"}}>
                            <Form.Item className={"materialFormItem"}>
                              <Space>
                                <Form.Item
                                  label="Maximum tube length"
                                  name="maxTubeLength"
                                  style={{marginBottom: 12}}
                                  initialValue={machineData.maxTubeLength}
                                >
                                  <InputNumber
                                    style={{minWidth: 100, marginLeft: 2}}
                                    min={0}
                                    step={1}
                                    formatter={value => value ? value : 0}
                                    parser={value => value ? value : 0}
                                    onChange={changeMachineMaxTubeLength}
                                  />
                                </Form.Item>
                                <Typography.Text className={"cuttingSpeedText"} style={{position: 'relative', top: -7}}>mm</Typography.Text>
                              </Space>
                            </Form.Item>
                            <Form.Item className={"materialFormItem"}>
                              <Space>
                                <Form.Item
                                  className={"materialFormItem"}
                                  label="Maximum tube weight"
                                  name="maxTubeWeight"
                                  initialValue={machineData.maxTubeWeight}
                                >
                                  <InputNumber
                                    style={{minWidth: 100}}
                                    min={0}
                                    step={1}
                                    formatter={value => value ? value : 0}
                                    parser={value => value ? value : 0}
                                    onChange={changeMachineMaxTubeWeight}
                                  />
                                </Form.Item>
                                <Typography.Text className={"cuttingSpeedText"} style={{position: 'relative', top: -1}}>kg/m</Typography.Text>
                              </Space>
                            </Form.Item>
                          </Card>
                        </div>
                        <Tabs style={{marginLeft: -10, marginBottom: 20}}
                              type="card"
                              className={"laserSpeedTabs"}
                        >
                          {machineData.tables.map((item, index) => (
                            <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                              <MaterialTableContainer tableText={"Cutting speed by Tube Grade and Tube Thickness (m/min)"}
                                                      xAxisText={"Available Thickness (mm)"}
                                                      yAxisText={"Tube Grade"}
                                                      xAxisStyle={{marginTop: 12, marginBottom: -12}}
                              >
                                <ValueTable
                                  columns={item.columns}
                                  rows={item.rows}
                                  allAvailable={item.allAvailable}
                                  tabIndex={index}
                                  onChange={changeSpeed}
                                  updateTable={shouldUpdateSpeeds}
                                  setUpdateTable={setShouldUpdateSpeeds}
                                  activeLoadingTabs={{}}
                                  leftColumnWidth={200}
                                  referenceHeight={248}
                                  isEditable={true}
                                />
                              </MaterialTableContainer>
                            </TabPane>
                          ))}
                        </Tabs>
                      </div>

                      <div className={`${rowFlex ? "customColumn5" : "customColumn4"}`}>
                        <Card bodyStyle={{ padding: 16 }}>
                          <div style={{ display: "flex", flexDirection: rowFlex ? "row" : "column", height: "100%", width : "100%", alignItems: "flex-start" }}>
                            <div style={{ width : "100%", maxWidth: 430 }}>
                              <Form.Item className={"materialFormItem"}>
                                <Space>
                                  <Form.Item
                                    className={"materialFormItem"}
                                    label="Cutting price"
                                    name="cuttingPrice"
                                    initialValue={machineData.cuttingPrice}
                                  >
                                    <InputNumber
                                      style={{minWidth: 100}}
                                      min={0}
                                      step={1}
                                      formatter={value => value ? value : 0}
                                      parser={value => value ? value : 0}
                                      onChange={changeMachineCuttingPrice}
                                    />
                                  </Form.Item>
                                  <Typography.Text className={"cuttingSpeedText"}>€/h</Typography.Text>
                                </Space>
                              </Form.Item>
                              <Divider className={"laserDivider"}/>
                              <div style={{marginTop: 16}}>
                                <Form.Item className={"materialFormItem"}>
                                  <Space>
                                    <Form.Item
                                      className={"materialFormItem"}
                                      label="Preparation price"
                                      name="engineerPricePerHour"
                                      initialValue={machineData.engineerPricePerHour}
                                    >
                                      <InputNumber
                                        style={{minWidth: 100}}
                                        min={0}
                                        step={1}
                                        formatter={value => value ? value : 0}
                                        parser={value => value ? value : 0}
                                        onChange={changeMachinePreparationPrice}
                                      />
                                    </Form.Item>
                                    <Typography.Text className={"cuttingSpeedText"}>€/h</Typography.Text>
                                  </Space>
                                </Form.Item>
                                <div align={'right'} style={{marginRight: 21}}>
                                  <Form.Item className={"materialFormItem"}>
                                    <Space>
                                      <Form.Item
                                        className={"materialFormItem"}
                                        label={"Average Preparation time"}
                                        name="averagePreparationTime"
                                        initialValue={machineData.averagePreparationTime}
                                      >
                                        <InputNumber
                                          style={{minWidth: 100}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={changeMachinePreparationTime}
                                        />
                                      </Form.Item>
                                      <Typography.Text className={"cuttingSpeedText"}>min</Typography.Text>
                                    </Space>
                                  </Form.Item>
                                </div>
                              </div>
                              {!rowFlex && <Divider className={"laserDivider"}/>}
                            </div>
                            {rowFlex && <Divider type={"vertical"} style={{ height: "135px", margin: "0 10px" }}/>}
                            <div style={{display: 'flex', flexDirection: "column"}}>
                              <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                <div className={"cuttingSpeedText"}
                                     style={{display: 'flex', alignItems: 'center', marginRight: 23, marginTop: -20}}>
                                  Average Piercing time:
                                </div>
                                <div>
                                  <Form.Item className={"materialFormItem"}>
                                    <Space>
                                      <Form.Item
                                        style={{marginBottom: 5}}
                                        name="pierceTimeLow"
                                        initialValue={machineData.pierceTimeLow}
                                      >
                                        <InputNumber
                                          style={{maxWidth: 70}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={changeMachinePiercingTimeLow}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        className={"cuttingSpeedText"}
                                        style={{position: 'relative', top: -5}}
                                      >
                                        sec
                                      </Typography.Text>
                                      <Typography.Text
                                        className={"cuttingSpeedText"}
                                        style={{position: 'relative', top: -4, marginLeft: 12}}
                                      >
                                        for thickness
                                      </Typography.Text>
                                      <Typography.Text
                                        className={"cuttingSpeedText"}
                                        style={{position: 'relative', top: -3}}
                                      >
                                        ≤
                                      </Typography.Text>
                                    </Space>
                                  </Form.Item>
                                  <Form.Item className={"materialFormItem"}>
                                    <Space>
                                      <Form.Item
                                        style={{marginBottom: 5}}
                                        name="pierceTimeHigh"
                                        initialValue={machineData.pierceTimeHigh}
                                      >
                                        <InputNumber
                                          style={{maxWidth: 70}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={changeMachinePiercingTimeHigh}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        className={"cuttingSpeedText"}
                                        style={{position: 'relative', top: -5}}
                                      >
                                        sec
                                      </Typography.Text>
                                      <Typography.Text
                                        className={"cuttingSpeedText"}
                                        style={{position: 'relative', top: -4, marginLeft: 12}}
                                      >
                                        for thickness
                                      </Typography.Text>
                                      <Typography.Text
                                        className={"cuttingSpeedText"}
                                        style={{position: 'relative', top: -3}}
                                      >
                                        >
                                      </Typography.Text>
                                    </Space>
                                  </Form.Item>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginLeft: 15, marginTop: -5}}>
                                  <Form.Item className={"materialFormItem"}>
                                    <Space>
                                      <Form.Item
                                        className={"materialFormItem"}
                                        name="pierceTimeThreshold"
                                        initialValue={machineData.pierceTimeThreshold}
                                      >
                                        <InputNumber
                                          style={{maxWidth: 70}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={changeMachinePiercingTimeThreshold}
                                        />
                                      </Form.Item>
                                      <Typography.Text className={"cuttingSpeedText"}>mm</Typography.Text>
                                    </Space>
                                  </Form.Item>
                                </div>
                              </div>
                              <div style={{ marginLeft: 5 }}>
                                <Divider className={"laserDivider"}/>
                                <ToleranceSelect
                                  type={"maxTolerance"}
                                  machineType={"tubeCutting"}
                                  isDisabled={false}
                                  initialValue={machineData.maxTolerance}
                                  machineId={machineData.id}
                                  handleChangeTolerance={handleChangeTubeCuttingTolerance}
                                  shouldChangeOrder={false}
                                  showButton={true}
                                  isLoading={isFetchingSelectedMachine}
                                  handleMachineTolerancesOpen={handleMachineTolerancesOpen}
                                />
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  }
                </Spin>
              </Form>
            </div>
          }
        </div>
      </Card>

      <AddMachineModal
        type={"tubeCutting"}
        title={"Add New Tube Cutting Machine"}
        modalVisible={addMachineModalVisible}
        handleCloseModal={handleAddMachineClose}
        isLoading={isCreatingTubeCuttingMachine}
        handleAddMachine={createNewMachine}
        machineTypes={[]}
      />

      <EditMachineModal
        type={"tubeCutting"}
        title={"Edit Tube Cutting Machine"}
        modalVisible={editMachineModalVisible}
        handleCloseModal={handleEditMachineClose}
        isLoading={isEditingTubeCuttingMachine}
        handleEditMachine={applyEditMachine}
        editMachine={editMachine}
        machineTypes={[]}
      />

      <MachineTolerancesModal
        machineType={"tubeCutting"}
        modalVisible={machineTolerancesModalVisible}
        handleCloseModal={handleMachineTolerancesClose}
        isLoading={isFetchingSelectedMachine}
        handleChangeTolerance={handleChangeTolerance}
        handleChangeRoughness={()=>{}}
        selectedMachineId={selectedMachine}
        markupData={machineData?.markup}
        allowTolerancesChange={true}
      />

      <RemoveMachineModal
        type={"tubeCutting"}
        modalVisible={removeMachineModalVisible}
        handleCloseModal={handleRemoveMachineClose}
        machine={removeMachine}
        isLoading={isRemovingTubeCuttingMachine}
        handleRemoveMachine={handleRemoveMachineConfirm}
      />

    </React.Fragment>
  )
}