import storage from "../../../../../utils/storage";
import {io} from "socket.io-client";
import {SOCKET_PATH, SOCKET_URL} from "../../../../../constants";

let socket;

const initializeSocket = (props) => {
  const { setSocketConnected, logOutUser } = props;

  const accessToken = storage.retrieveAccessToken();

  socket = io(SOCKET_URL, {
    path: SOCKET_PATH,
    auth: {
      token: accessToken.substring(7)
    },
  });

  socket.on("connect", () => {
    setSocketConnected(true)
  });

  socket.on("connect_error", (err) => {
    if (err.message === "invalid credentials") {
      storage.refreshAccessToken().then(() => {
        const accessToken = storage.retrieveAccessToken();
        if(accessToken) {
          socket.auth.token = accessToken.substring(7);
          socket.connect();
        }
      })
    }
  });

  socket.on("server-error", (err) => {
    console.log(err.msg)
    if (err.msg === "invalid credentials") {
      logOutUser()
    }
  });

  // Destroys the socket reference
  // when the connection is closed
  return () => {
    socket.disconnect();
  };
}

const changeMaterialPrice = ({materialId, price}) => {
  if(socket) {
    socket.emit("put-producer-material-price", { materialId, price })
  }
}
const changeBaseMaterialCertificateParams = (data) => {
  if (socket) {
    socket.emit("put-base-material-cert-fee", data)
  }
}
const changeAssemblyMaterialCertificateParams = (data) => {
  if (socket) {
    socket.emit("put-assembly-material-cert-fee", data)
  }
}
const changeBendingParams = (data) => {
  if(socket) {
    socket.emit("put-producer-bending-params", { ...data })
  }
}
const changeDeburringParams = (data) => {
  if(socket) {
    socket.emit("put-deburring-params", { ...data })
  }
}
const changeChamferingParams = (data) => {
  if(socket) {
    socket.emit("put-chamfering-params", { ...data })
  }
}
const changeDrillingParams = (data) => {
  if(socket) {
    socket.emit("put-drilling-params", { ...data })
  }
}
const changeSimpleMachiningParams = (data) => {
  if(socket) {
    socket.emit("put-simple-machining-params", { ...data })
  }
}
const changePaintingParams = (data) => {
  if(socket) {
    socket.emit("put-painting-params", { ...data })
  }
}
const changePowderPaintingParams = (data) => {
  if(socket) {
    socket.emit("put-powder-painting-params", { ...data })
  }
}
const changeGalvanizingParams = (data) => {
  if(socket) {
    socket.emit("put-galvanizing-params", { ...data })
  }
}
const changeNickelPlatingParams = (data) => {
  if(socket) {
    socket.emit("put-nickel-plating-params", { ...data })
  }
}
const changeAnodizingParams = (data) => {
  if(socket) {
    socket.emit("put-anodizing-params", { ...data })
  }
}
const changeGalvanicZincPlatingParams = (data) => {
  if(socket) {
    socket.emit("put-galvanic-zinc-plating-params", { ...data })
  }
}
const changeHardeningParams = (data) => {
  if(socket) {
    socket.emit("put-hardening-params", {...data})
  }
}
const changeCaseHardeningParams = (data) => {
  if(socket) {
    socket.emit("put-case-hardening-params", {...data})
  }
}
const changeAnnealingParams = (data) => {
  if(socket) {
    socket.emit("put-annealing-params", {...data})
  }
}
const changeGlassBeadBlastingParams = (data) => {
  if(socket) {
    socket.emit("put-glass-bead-blasting-params", {...data})
  }
}
const changeSandBlastingParams = (data) => {
  if(socket) {
    socket.emit("put-sand-blasting-params", {...data})
  }
}
const changeCuttingSpeed = (data) => {
  if(socket) {
    socket.emit("put-cutting-machine-cutting-speed", { ...data })
  }
}
const changeCuttingMachineParams = (data) => {
  if(socket) {
    socket.emit("put-cutting-machine-one-field", { ...data })
  }
}
const changeMillingParams = (data) => {
  if(socket) {
    socket.emit("put-milling-params", { ...data })
  }
}
const changeLathingParams = (data) => {
  if(socket) {
    socket.emit("put-lathing-params", { ...data })
  }
}
const changeGradeMillingSpeed = (data) => {
  if(socket) {
    socket.emit("put-milling-speed", {...data})
  }
}
const changeGradeLathingSpeed = (data) => {
  if(socket) {
    socket.emit("put-lathing-speed", {...data})
  }
}
const changeMachiningMaterialPrice = (data) => {
  if(socket) {
    socket.emit("put-material-price", { ...data })
  }
}
const changeTubeCuttingMachineParams = (data) => {
  if (socket) {
    socket.emit("put-tube-cutting-machine-params", {...data})
  }
}
const changeTubeCuttingMachineSpeed = (data) => {
  if (socket) {
    socket.emit("put-tube-cutting-speed", {...data})
  }
}
const changeTubeMaterialPrice = (data) => {
  if(socket) {
    socket.emit("put-tube-cutting-material-price", { ...data })
  }
}
const changeStandardTubeMaterialPrice = (data) => {
  if(socket) {
    socket.emit("put-tube-cutting-standard-material-price", { ...data })
  }
}
const changeMachineMaxTolerance = (data) => {
  if (socket) {
    socket.emit("put-machine-max-tolerance", { ...data })
  }
}
const changeMachineToleranceMarkup = (data) => {
  if (socket) {
    socket.emit("put-machine-tolerance-markup", { ...data })
  }
}
const changeMachineMaxRoughness = (data) => {
  if (socket) {
    socket.emit("put-machine-max-roughness", { ...data })
  }
}
const changeMachineRoughnessMarkup = (data) => {
  if (socket) {
    socket.emit("put-machine-roughness-markup", { ...data })
  }
}

export {
  initializeSocket,
  changeCuttingMachineParams, changeCuttingSpeed,
  changeMachineMaxTolerance,changeMachineToleranceMarkup,

  changeBendingParams,

  changeDeburringParams, changeChamferingParams,
  changeDrillingParams, changeSimpleMachiningParams,

  changeMillingParams, changeLathingParams,
  changeGradeMillingSpeed, changeGradeLathingSpeed,
  changeMachineMaxRoughness, changeMachineRoughnessMarkup,

  changeTubeCuttingMachineParams, changeTubeCuttingMachineSpeed,

  changePaintingParams, changePowderPaintingParams,
  changeGalvanizingParams, changeNickelPlatingParams,
  changeAnodizingParams, changeGalvanicZincPlatingParams,
  changeHardeningParams, changeCaseHardeningParams,
  changeAnnealingParams, changeGlassBeadBlastingParams,
  changeSandBlastingParams,

  changeMaterialPrice, changeBaseMaterialCertificateParams,
  changeAssemblyMaterialCertificateParams, changeMachiningMaterialPrice,
  changeTubeMaterialPrice, changeStandardTubeMaterialPrice
}