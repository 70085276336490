import React from "react";

import "./style.less";
import {Button, Form, Select} from "antd";
import {CaretDownOutlined} from "@ant-design/icons";
import {
  getRoughnesses, getRoughnessValue,
  getTolerances, getToleranceValue
} from "../../../utils/utility";

const {Option} = Select;

export const ToleranceSelect = (props) => {

  const {
    isDisabled, shouldChangeOrder,
    handleChangeTolerance, type,
    initialValue, isLoading,
    handleMachineTolerancesOpen,
    showButton, machineType, machineId
  } = props;

  const renderSelect = () => {
    if (type === "maxTolerance") {
      return (
        <Form.Item
          label={"Maximum Tolerance"}
          name={"maxTolerance"}
          className={shouldChangeOrder ? "columnItem" : "rowItem"}
          style={{ width: "100%" }}
          initialValue={initialValue}
        >
          <Select
            placeholder={"Select a Maximum Tolerance"}
            className={"toleranceSelect machineSelect"}
            suffixIcon={<CaretDownOutlined/>}
            onChange={(value) => {
              handleChangeTolerance({machineId: machineId, machineType, maxTolerance: value})
            }}
            disabled={isDisabled}
          >
            <Option
                key={-1}
                value={null}
                className={"toleranceOption"}
            >
                {(machineType === "milling" || machineType === "lathing") ? ">= 0.1mm" : ">= 0.5mm"}
            </Option>
            {getTolerances().map((item, index) => (
              <Option 
                key={index} 
                value={item}
                className={"toleranceOption"}
              >
                {getToleranceValue(item, (machineType === "milling" || machineType === "lathing") ? "machined" : "other")}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )
    } else if (type === "maxRoughness") {
      return (
        <Form.Item
          label={"Max Surface Roughness"}
          name={"maxRoughness"}
          className={shouldChangeOrder ? "columnItem" : "rowItem"}
          style={{ width: "100%" }}
          initialValue={initialValue}
        >
          <Select
            placeholder={"Select Surface Roughness"}
            className={"toleranceSelect machineSelect"}
            suffixIcon={<CaretDownOutlined/>}
            onChange={(value) => {
              handleChangeTolerance({machineId, machineType, maxRoughness: value})
            }}
            disabled={isDisabled}
          >
            <Option
                key={-1}
                value={null}
                className={"toleranceOption"}
            >
                >= 3.2μm Ra
            </Option>
            {getRoughnesses().map((item, index) => (
              <Option 
                key={index} 
                value={item}
                className={"toleranceOption"}
              >
                {getRoughnessValue(item)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        )
    } else return null
  }

  return (
    <div
      className={isDisabled ? "disabledField" : ""}
      style={{display: "flex", alignItems: "center", width: "100%", flexDirection: "column", maxWidth: 500}}
    >
      {renderSelect()}
      {showButton &&
        <div style={{ marginTop: 8, display: "flex", width: "100%", justifyContent: "center"}}>
          <Button
            className={"toleranceButton d6ButtonDark"}
            style={{fontSize: 13}}
            onClick={() => {
              handleMachineTolerancesOpen()
            }}
            disabled={isLoading}
          >
            <span style={{fontWeight: 600}}>
              {(machineType === "milling" || machineType === "lathing") ?
                "Set Machine Adjustments"
                :
                "Set Tolerance Markup"
              }
            </span>
          </Button>
        </div>
      }
    </div>
  )
}