import React, {useEffect, useState, useCallback, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./style.less";

import {
  Form, Row, Col,
  Card, InputNumber,
  Divider, Switch,
  Tabs, Modal,
  Typography, Button,
  Input, Checkbox,
  Spin,
} from "antd";

import MaterialTableContainer from "../../MaterialTableContainer";
import {ModalTable} from "../../MaterialTables/ModalTable";

import {DeleteOutlined, EditOutlined, InfoCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {DeletionModal} from "../../../../DeletionModal";
import {
  setBendingService,
} from "../../../../../actions/bendingActions";
import {debounce} from "lodash";
import {Trans} from "react-i18next";
import {EmptyTable} from "../../../../EmptyTable";

const { TabPane } = Tabs;

export const MaterialBendingTab = (props) => {
  const {
    selectedTab, changeBendingParams,
    bendingData, bendingBendsData,
    addNewBend, editBend,
    deleteBends, fetchBendingData,
    setBendingMaxLength, setPreparationPrice,
    setAveragePreparationTime, resetMaterialBendsData,
    fetchSingleMaterialBendsData, fetchSingleMaterialBends,
    isAddingNewBend, isEditingBend, isRemovingBend,
    isGelso, headerHeight = 0, editDefaultMode = false,
  } = props

  const [disableSwitching, setDisableSwitching] = useState(false);

  const [mainModalVisible, setMainModalVisible] = useState(false);
  const [selectedFieldData, setSelectedFieldData] = useState({});

  const [editBendModalVisible, setEditBendModalVisible] = useState(false);
  const [addBendModalVisible, setAddBendModalVisible] = useState(false);
  const [currentBend, setCurrentBend] = useState({});
  const [deleteBendModalVisible, setDeleteBendModalVisible] = useState(false);
  const [multiDeleteMode, setMultiDeleteMode] = useState(false);
  const [multiDeleteBendSelection, setMultiDeleteBendSelection] = useState([]);

  const [shouldUpdateBends, setShouldUpdateBends] = useState(false)
  const [activeLoadingBendsTabs, setActiveLoadingBendsTabs] = useState({})

  let showFallbackParams = isGelso && !editDefaultMode;
  let showOfferService = !isGelso && !editDefaultMode;
  let showEditInfoText = editDefaultMode;

  const [editBendForm] = Form.useForm();
  const [addBendForm] = Form.useForm();

  const bendingForm = React.createRef()

  const dispatch = useDispatch()

  const isFetchingSingleMaterialBends = useSelector(state => state.bending.isFetchingSingleMaterialBends)
  const isFetchingSingleMaterialDefaultBends = useSelector(state => state.defaultMaterialsData.isFetchingSingleMaterialDefaultBends)

  const selectedRegion = useSelector(state => state.defaultMaterialsData.selectedRegion)
  const initialRegionMount = useRef(true);
  const selectedRegionRef = useRef(selectedRegion)

  const reloadTabKey = useSelector(state => state.defaultMaterialsData.reloadTabKey)
  useEffect(() => {
    if (reloadTabKey === selectedTab) {
      initialize()
    }
  }, [reloadTabKey])
  
  const formatData = (data) => {
    return editDefaultMode ? {...data, region: selectedRegionRef.current} : data
  }

  const initialize = () => {
    if (selectedTab === "2") {
      dispatch(fetchBendingData(formatData({}))).then(()=>{
        setShouldUpdateBends(true)
      })
    }
  }

  useEffect(()=>{
    initialize()
  }, [selectedTab])

  useEffect(()=>{
    bendingForm.current?.setFieldsValue({
      maxLength: bendingData.maxLength,
      preparationPrice: bendingData.preparationPrice,
      averagePreparationTime: bendingData.averagePreparationTime
    })
  }, [bendingData])

  useEffect(() => {
    selectedRegionRef.current = selectedRegion;

    if (initialRegionMount.current) {
      initialRegionMount.current = false;
    } else {
      initialize()
    }
  }, [selectedRegion])

  const triggerSlideToggle = (value) => {
    setDisableSwitching(true);
    dispatch(setBendingService({value})).then(()=>{
      setDisableSwitching(false);
      if(value) {
        dispatch(fetchBendingData())
      }
    })
  }

  const fetchMaterialBendsData = (materialId) => {
    dispatch(fetchSingleMaterialBendsData(formatData({materialId})))
  }

  const setupMainModal = (params) => {
    setSelectedFieldData({
      materialGrade: params.materialGrade,
      materialThickness: params.materialThickness,
      bendsMaterialId: params.materialId,
      bendsGroupName: params.groupName,
      tab: params.tab
    })

    fetchMaterialBendsData(params.materialId)
    setMainModalVisible(true);
  }

  const handleBendModalOpen = (params) => {
    setupMainModal(params)
  }

  const handleMainModalClose = () => {
    setMainModalVisible(false);
    setMultiDeleteMode(false)
    setMultiDeleteBendSelection([]);
    dispatch(resetMaterialBendsData(formatData({})))
  }

  const handleEditBend = (item) => {
    setCurrentBend({
      ...item
    })

    editBendForm.setFieldsValue({
      length: item.length,
      price: item.price
    })
    setEditBendModalVisible(true);
  }

  const handleEditBendModalClose = () => {
    editBendForm.resetFields()
    setCurrentBend({})
    setEditBendModalVisible(false);
  }

  const handleAddBendModalClose = () => {
    addBendForm.resetFields()
    setAddBendModalVisible(false);
  }

  const handleMultiDeleteCheckBend = (item) => {
    if (multiDeleteBendSelection.includes(item.id)) {
      setMultiDeleteBendSelection(multiDeleteBendSelection.filter(elem => elem !== item.id));
    } else {
      setMultiDeleteBendSelection(prevState => [...prevState, item.id])
    }
  }

  const handleMultiBendDelete = () => {
    dispatch(deleteBends(formatData({materialId: selectedFieldData.bendsMaterialId, bends: multiDeleteBendSelection}))).then(()=>{
      setMultiDeleteMode(false);
      setMultiDeleteBendSelection([]);
      setActiveLoadingBendsTabs(prevState => ({...prevState, [selectedFieldData.tab]: true}))
      dispatch(fetchSingleMaterialBends(formatData({groupName: selectedFieldData.bendsGroupName}))).then(()=>{
        setActiveLoadingBendsTabs(prevState => ({...prevState, [selectedFieldData.tab]: false}))
      })
    })
  }

  const handleSingleBendDelete = (item) => {
    dispatch(deleteBends(formatData({materialId: selectedFieldData.bendsMaterialId, bends: [item.id]}))).then(()=>{
      setDeleteBendModalVisible(false)
      setActiveLoadingBendsTabs(prevState => ({...prevState, [selectedFieldData.tab]: true}))
      dispatch(fetchSingleMaterialBends(formatData({groupName: selectedFieldData.bendsGroupName}))).then(()=>{
        setActiveLoadingBendsTabs(prevState => ({...prevState, [selectedFieldData.tab]: false}))
      })
    })
  }

  const submitEditBendForm = (values) => {
    if(Number(values.length) === Number(currentBend.length) && Number(values.price) === Number(currentBend.price)) {
      handleEditBendModalClose()
    } else {
      dispatch(editBend(formatData({
        materialId: selectedFieldData.bendsMaterialId,
        length: Number(values.length),
        price: Number(values.price),
        bendId: currentBend.id,
      }))).then(()=>{
        handleEditBendModalClose()
      }).catch(()=>{})
    }
  }

  const submitAddBendForm = (values) => {
    dispatch(addNewBend(formatData({
      materialId: selectedFieldData.bendsMaterialId,
      length: Number(values.length),
      price: Number(values.price),
    }))).then(()=>{
      handleAddBendModalClose()
      setActiveLoadingBendsTabs(prevState => ({...prevState, [selectedFieldData.tab]: true}))
      dispatch(fetchSingleMaterialBends(formatData({groupName: selectedFieldData.bendsGroupName}))).then(()=>{
        setActiveLoadingBendsTabs(prevState => ({...prevState, [selectedFieldData.tab]: false}))
      })
    }).catch(()=>{})
  }

 /*------- Max Length -------*/
  const changeMaxLength = (data) => {
    dispatch(setBendingMaxLength(formatData({value: data.maxLength})))
    changeBendingParams(formatData(data))
  }

  const debouncedMaxLengthChange = useCallback(
    debounce(data => changeMaxLength(data), 800),
    []
  );

  const changeBendingMaxLength = () => {
    debouncedMaxLengthChange({
      ...bendingForm.current.getFieldsValue()
    })
  }

  /*------- Preparation Price -------*/
  const changePreparationPrice = (data) => {
    dispatch(setPreparationPrice(formatData({value: data.preparationPrice})))
    changeBendingParams(formatData(data))
  }

  const debouncedPreparationPriceChange = useCallback(
    debounce(data => changePreparationPrice(data), 800),
    []
  );

  const changeBendingPreparationPrice = () => {
    debouncedPreparationPriceChange({
      ...bendingForm.current.getFieldsValue()
    })
  }

  /*------- Average Preparation Time -------*/
  const changeAveragePreparationTime = (data) => {
    dispatch(setAveragePreparationTime(formatData({value: data.averagePreparationTime})))
    changeBendingParams(formatData(data))
  }

  const debouncedAveragePreparationTimeChange = useCallback(
    debounce(data => changeAveragePreparationTime(data), 800),
    []
  );

  const changeBendingAveragePreparationTime = () => {
    debouncedAveragePreparationTimeChange({
      ...bendingForm.current.getFieldsValue()
    })
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"}>
        <div style={{height: `calc(100vh - 100px - ${headerHeight}px)`, overflowY: "auto", overflowX: "hidden", paddingRight: 12}}>
          {bendingData && Object.keys(bendingData).length === 0 ? <div/>
            :
          <Form
            ref={bendingForm}
          >
            <Row>
              <Col span={24}>
                <div className={"switchContainer"}>
                  {showFallbackParams && <span style={{marginRight: 30}}>Fallback Bending parameters:</span>}
                  {showOfferService &&
                    <div>
                      <span style={{marginRight: 30, marginBottom: 6}}>Do you offer Bending service?</span>
                      <span>No</span>
                      <Switch checked={bendingData.hasBending}
                              onChange={triggerSlideToggle}
                              style={{marginLeft: 6, marginRight: 6}}
                              disabled={disableSwitching}
                      />
                      <span>Yes</span>
                    </div>
                  }
                  {showEditInfoText &&
                    <Card className={"defaultMachineCard"}>
                      <InfoCircleOutlined className={"editMaterialPageInfoIcon"}/>
                      <div style={{ marginLeft: 6, fontWeight: 500 }}>
                        This page represents the default regional parameters for bending.
                      </div>
                    </Card>
                  }
                </div>
              </Col>
            </Row>

            <Divider style={{width: "75%", minWidth: "0%", margin: "8px 0 2px 0"}}/>
            {bendingData.hasBending &&
            <div>
              <Row>
                <Col xxl={12} span={24}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <Form.Item
                      label="Maximum bending length:"
                      name="maxLength"
                      style={{marginBottom: 10, marginTop: 10}}
                      initialValue={bendingData.maxLength}
                    >
                      <InputNumber
                        style={{minWidth: 100, marginLeft: 5}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 1}
                        parser={value => value ? value : 1}
                        onChange={changeBendingMaxLength}
                      />
                    </Form.Item>
                    <Typography.Text className={"inputInfoText"} style={{marginLeft: 12}}>mm</Typography.Text>
                  </div>
                </Col>
              </Row>

              <Divider style={{width: "100%", minWidth: "0%", margin: "2px 0 12px 0"}}/>

              <div className={"newLine"}>
                <div className={"customColumn"}>
                  <div>
                    <Tabs style={{marginLeft: -10, marginBottom: 10}}
                          type="card"
                          className={"laserSpeedTabs"}
                    >
                      {bendingData.bends.map((item, index) => (
                        <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                          <MaterialTableContainer tableText={"Cost of bending per punch and material group (€/punch)"}
                                                  xAxisText={"Available Thickness (mm)"}
                                                  yAxisText={"Material Grade"}
                                                  xAxisStyle={{marginTop: 12, marginBottom: -12}}
                          >
                            <ModalTable
                              columns={item.columns}
                              rows={item.rows}
                              allAvailable={item.allAvailable}
                              tabIndex={index}
                              onClick={handleBendModalOpen}
                              groupName={item.groupName}
                              updateTable={shouldUpdateBends}
                              setUpdateTable={setShouldUpdateBends}
                              activeLoadingTabs={activeLoadingBendsTabs}
                              referenceHeight={248}
                              isEditable={true}
                            />
                          </MaterialTableContainer>
                        </TabPane>
                      ))}
                    </Tabs>
                  </div>
                </div>
              </div>

              <Divider className={"lightDivider"} style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 0"}}/>

              <Row gutter={16}>
                <Col xxl={12} span={24}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <Form.Item
                      label="Preparation price:"
                      name="preparationPrice"
                      style={{marginBottom: 5, marginTop: 5}}
                      initialValue={bendingData.preparationPrice}
                    >
                      <InputNumber
                        style={{minWidth: 30, marginLeft: 5}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={changeBendingPreparationPrice}
                      />
                    </Form.Item>
                    <Typography.Text className={"inputInfoText"} style={{marginLeft: 12}}>€/h</Typography.Text>
                  </div>

                  <div style={{display: "flex", alignItems: "center"}}>
                    <Form.Item
                      label="Average preparation time:"
                      name="averagePreparationTime"
                      style={{marginBottom: 5, marginTop: 5}}
                      initialValue={bendingData.averagePreparationTime}
                    >
                      <InputNumber
                        style={{minWidth: 30, marginLeft: 5}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={changeBendingAveragePreparationTime}
                      />
                    </Form.Item>
                    <Typography.Text className={"inputInfoText"} style={{marginLeft: 12}}>min</Typography.Text>
                  </div>

                  <Divider className={"lightDivider"} style={{width: "50%", minWidth: "0%", margin: "8px 0 8px 0"}}/>
                </Col>
              </Row>
            </div>
            }
          </Form>
          }
        </div>
      </Card>
      <Modal visible={mainModalVisible}
             onCancel={handleMainModalClose}
             title={"Available bends"}
             footer={<div style={{ height: 20 }}/>}
             centered={true}
             bodyStyle={{ height: 660 }}
             destroyOnClose={true}
             zIndex={2}
             width={550}
      >
        <React.Fragment>
          <div style={{ fontWeight: 500 }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "white"}}>
              <div>
                <div>Material Grade: <Trans>{selectedFieldData.materialGrade}</Trans></div>
                <div>Material Thickness: {selectedFieldData.materialThickness} mm</div>
              </div>
              <div>
                {multiDeleteMode ?
                  (
                    <div style={{ display: "flex", flexDirection: "row"}}>
                      <Button type={"danger"}
                              onClick={() => handleMultiBendDelete()}
                              style={{ marginRight: 6 }}
                              disabled={multiDeleteBendSelection.length === 0 || isRemovingBend}>
                        <span>Delete</span>
                      </Button>
                      <Button onClick={() => {
                                setMultiDeleteMode(false)
                                setMultiDeleteBendSelection([]);
                              }}
                              disabled={isRemovingBend}
                      >
                        <span className={"inputInfoText"}>Cancel</span>
                      </Button>
                    </div>
                  )
                  :
                  (
                    <div>
                      <Button type={"primary"} style={{ marginRight: 6 }}
                              onClick={() => {setAddBendModalVisible(true)}}
                      >
                        <span className={"inputInfoText"}>Add bend</span>
                      </Button>
                      <Button type={"danger"} onClick={() => {
                        setMultiDeleteMode(prevState => !prevState)
                      }}>
                        <span >Delete multiple</span>
                      </Button>
                    </div>
                  )}
              </div>
            </div>
            <Spin spinning={isFetchingSingleMaterialBends || isFetchingSingleMaterialDefaultBends || isRemovingBend}
                  indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-25%, -50%)" }} spin />}
            >
              <div className={"modalDataContainer"}>
                {(bendingBendsData.length === 0 ?
                  (
                    <div style={{position: "relative", top: "25%"}}>
                      <EmptyTable text={"No Bends"}/>
                    </div>
                  )
                  :
                  bendingBendsData.map(item => (
                    <div className={"checkboxStyle"} key={item.id}>
                      {multiDeleteMode && <div className={"multiDeleteCheckbox"}>
                        <Checkbox onChange={() => handleMultiDeleteCheckBend(item)}/>
                      </div>}
                      <Card
                        className={"bendCard"}
                        style={{ marginTop: 3, marginBottom: 3}}
                      >
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <div>
                            <div>Length: {item.length} mm</div>
                            <div>Price per bend: {item.price} €</div>
                          </div>
                          <div style={{ display: "flex"}}>
                            <Button onClick={() => {handleEditBend(item)}} disabled={multiDeleteMode}>
                              <EditOutlined/>
                            </Button>
                            <Button disabled={multiDeleteMode} onClick={() => {
                              DeletionModal(deleteBendModalVisible,
                                "Remove Bend",
                                "Are you sure you want to remove this bend",
                                ()=>{setDeleteBendModalVisible(false)},
                                ()=>{handleSingleBendDelete(item)},
                                "Confirm",
                                "Cancel",
                                {loading: isRemovingBend}
                              )
                            }}>
                              <DeleteOutlined style={{ color: multiDeleteMode ? "grey" : "#ff5b2d"}}/>
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )))
                }
              </div>
            </Spin>
          </div>
        </React.Fragment>
      </Modal>
      <Modal visible={editBendModalVisible}
             onCancel={handleEditBendModalClose}
             title="Edit bend parameters"
             width={400}
             footer={
               <div align={'right'}>
                <Button
                  type={"primary"}
                  style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
                  form={"edit-bend"}
                  key={"submit"}
                  htmlType={"submit"}
                  loading={isEditingBend}
                >
                  Save Changes
                </Button>
              </div>}
             centered={true}
             bodyStyle={{ height: 132 }}
             zIndex={3}
             destroyOnClose={true}
             getContainer={false} //# When using Forms in Modals, you need this
      >
        <React.Fragment>
          <div className={"inputInfoText"}>
            <Form form={editBendForm}
                  name={'edit-bend'}
                  onFinish={submitEditBendForm}
            >
              <div style={{ display: "flex", flexDirection: "row", color: "white"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 134}}>
                  Length:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'length'}
                    initialValue={currentBend.length}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {
                        required: true,
                        message: 'Input required!',
                      },
                    ]}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      type={"number"}
                      suffix={<span className={"inputInfoText"}>mm</span>}
                      min={1}
                    />
                  </Form.Item>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", color: "white"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 134}}>
                  Price per bend:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'price'}
                    initialValue={currentBend.price}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {
                        required: true,
                        message: 'Input required!',
                      },
                    ]}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      type={"number"}
                      suffix={<span className={"inputInfoText"}>€</span>}
                      min={0}
                      step={0.01}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </React.Fragment>
      </Modal>

      <Modal visible={addBendModalVisible}
             onCancel={handleAddBendModalClose}
             title="Add new bend"
             width={400}
             footer={
               <div align={'right'}>
                 <Button
                   type={"primary"}
                   style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
                   form={"add-bend"}
                   key={"submit"}
                   htmlType={"submit"}
                   loading={isAddingNewBend}
                 >
                   Add bend
                 </Button>
               </div>}
             centered={true}
             bodyStyle={{ height: 132 }}
             zIndex={3}
             destroyOnClose={true}
             getContainer={false} //# When using Forms in Modals, you need this
      >
        <React.Fragment>
          <div className={"inputInfoText"}>
            <Form form={addBendForm}
                  name={'add-bend'}
                  onFinish={submitAddBendForm}
            >
              <div style={{ display: "flex", flexDirection: "row", color: "white"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 134}}>
                  Length:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'length'}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {
                        required: true,
                        message: 'Input required!',
                      },
                    ]}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      type={"number"}
                      placeholder={"Bending length"}
                      suffix={<span className={"inputInfoText"}>mm</span>}
                      min={1}
                      max={bendingData.maxLength}
                    />
                  </Form.Item>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", color: "white"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 134}}>
                  Price per bend:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'price'}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {
                        required: true,
                        message: 'Input required!',
                      },
                    ]}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      placeholder={"Bending price"}
                      type={"number"}
                      suffix={<span className={"inputInfoText"}>€</span>}
                      min={0}
                      step={0.01}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </React.Fragment>
      </Modal>
    </React.Fragment>
  )
}