import { storage } from '../utils'
import {initialMachiningData} from "../utils/utility";
import {useSelector} from "react-redux";

const authData = storage.retrieveAuthData()

const initialState = {
  auth: {
    user: authData ? authData.user : null,
    loggingIn: false,
    registering: false,
    registeringCompany: false,
    redirect: null,
  },
  util: {
    isCollapsed: true,
    activeSubmenuKeys: [],
    activeCustomerSubmenuKeys: [],
  },
  producers: {
    producers: [],
    fetchingProducers: false,
    isCreatingProducer: false,
    fetchingProducerDetails: false,
    producersPagination: {pageSize: 10, showSizeChanger: false},
    isUpdatingProducerInfo: false,
    isUpdatingProducerLogo: false,
  },
  producerParams: {
    isFetchingMachines: false,
    cuttingMachines: [],
    selectedMachine: {},
    isFetchingSelectedMachine: false,
    machinePriorities: [],
    isFetchingMachinePriorities: false,

    materialPrices: [],
    isFetchingMaterialPrices: false,

    isFetchingMaterialCertPrices: false,
    assemblyMaterialCertFee: 0,
    baseMaterialCertFee: 0,

    bendingData: {},
    isFetchingBendingData: false,
    bendingBendsData: [],
    isFetchingSingleMaterialBends: false,

    isFetchingTubeCuttingData: false,
    tubeCuttingData: {},
    alreadyFetchedTubeCuttingService: false,
    isFetchingSelectedTubeCuttingMachine: false,
    selectedTubeCuttingMachine: {},

    surfaceCoatingData: {},
    isFetchingSurfaceCoatingData: false,

    machiningData: {},
    isFetchingMachiningData: false,
    millingMachine: {},
    isFetchingMillingMachine: false,
    lathingMachine: {},
    isFetchingLathingMachine: false,
    alreadyFetchedMachiningData: false,

    isFetchingTubeMaterialPrices: false,
    tubeCuttingMaterials: {
      availableMaterials: [],
      allAvailable: {}
    },
    hasTubeCutting: false,
    isFetchingStandardTubeMaterialPrices: false,
    standardTubeMaterialPrices: [],

    isFetchingMachiningMaterials: false,
    hasMachining: false,
    machiningMaterials: {
      availableMaterials: [],
      allAvailable: {}
    },

    simpleMachiningData: {},
    isFetchingSimpleMachiningData: false
  },
  subAdmins: {
    isAddingSubAdmin: false,
    subAdmins: [],
    isFetchingSubAdmins: false,
    subAdminsPagination: {pageSize: 10, showSizeChanger: false},
    isFetchingSubAdminsLimit: true,
    isEditingSubAdmin: false
  },
  customers: {
    customers: [],
    customersPagination: {pageSize: 6, showSizeChanger: false},
    fetchingCustomers: true,
    fetchingCustomerDetails: true,
    removingCustomer: false,
    togglingCustomerBlock: false,
  },
  corporateCustomers: {
    corporateCustomers: [],
    fetchingCorporateCustomers: false,
    fetchingCorporateCustomerDetails: false,
    corporateCustomersPagination: {pageSize: 10, showSizeChanger: false},
    removingCorporateCustomer: false,
    togglingCorporateCustomerBlock: false,
  },
  materials: {
    materialPrices: [],
    isFetchingMaterialPrices: false,

    baseMaterialCertFee: 0,
    assemblyMaterialCertFee: 0,
    isFetchingMaterialCertPrices: false,
  },
  bending: {
    bendingData: {},
    isFetchingBendingData: false,
    isFetchingSingleMaterialBends: false,
    bendingBendsData: [],
    isAddingNewBend: false,
    isEditingBend: false,
    isRemovingBend: false
  },
  simpleMachining: {
    simpleMachiningData: {},
    isFetchingSimpleMachiningData: false,
  },
  machining: {
    hasMachining: false,
    machiningData: initialMachiningData,
    materials: {
      availableMaterials: [],
      allAvailable: {}
    },
    selectedMillingMachine: {},
    selectedLathingMachine: {},
    isFetchingMachiningMaterials: false,
    isFetchingMachiningData: false,
    isFetchingSelectedMillingMachine: false,
    isFetchingSelectedLathingMachine: false,
    isCreatingMillingMachine: false,
    isCreatingLathingMachine: false,
    isRemovingMillingMachine: false,
    isRemovingLathingMachine: false,
    isUpdatingMillingMachine: false,
    isUpdatingLathingMachine: false,
    alreadyFetchedMachiningData: false,
  },
  surfaceCoating: {
    surfaceCoatingData: {},
    isFetchingSurfaceCoatingData: false,
  },
  tubeCutting: {
    hasTubeCutting: false,
    standardTubeMaterialPrices: [],
    isFetchingStandardTubeMaterialPrices: false,
    materials: {
      availableMaterials: [],
      allAvailable: {}
    },
    isFetchingTubeMaterialPrices: false,
    isFetchingTubeCuttingData: false,
    isFetchingMachines: false,
    isFetchingSelectedMachine: false,
    isCreatingTubeCuttingMachine: false,
    isEditingTubeCuttingMachine: false,
    isRemovingTubeCuttingMachine: false,
    tubeCuttingMachines: [],
    selectedMachine: {}
  },
  cuttingMachines: {
    isFetchingMachines: false,
    cuttingMachines: [],
    selectedMachine: {},
    isFetchingSelectedMachine: false,
    machineTypes: [],
    isFetchingMachineTypes: false,
    machinePriorities: [],
    isFetchingMachinePriorities: false,
    isUpdatingMachinePriorities: false,
    isCreatingCuttingMachine: false,
    isEditingCuttingMachine: false,
    isRemovingMachine: false,
  },
  companySettings: {
    companyDetails: null,
    fetchingCompanyDetails: true,
    editingCompanySettings: false,
    resettingPassword: false
  },
  customerSettings: {
    editingCustomerSettings: false,
    resettingPassword: false,
  },
  admin: {
    logs: [],
    logsPagination: {pageSize: 5, showSizeChanger: false},
    isFetchingLogs: false,
  },
  customerRelations: {
    internalCustomerRelations: [],
    internalCustomerRelationsPagination: {pageSize: 10, showSizeChanger: false},
    fetchingInternalCustomerRelations: false,
    updatingInternalCustomer: false,
    unauthorizedCustomers: [],
    fetchingUnauthorizedCustomers: false,
    isAuthorizingCustomer: false
  },
  order: {
    toProcess: 0,
    currentlyUploaded: 0,
    currentlyProcessed: 0
  },
  orders: {
    isFetchingProducerPendingOrders: false,
    producerPendingOrders: [],
    producerPendingOrdersPagination: {pageSize: 10, showSizeChanger: false},

    isFetchingProducerAwaitingConfirmationOrders: false,
    producerAwaitingConfirmation: [],
    producerAwaitingConfirmationPagination: {pageSize: 6, showSizeChanger: false},

    isFetchingProducerProcessedOrders: false,
    producerProcessedOrders: [],
    producerProcessedOrdersPagination: {pageSize: 10, showSizeChanger: false},

    isFetchingCustomerPendingOrders: false,
    customerPendingOrders: [],
    customerPendingOrdersPagination: {pageSize: 10, showSizeChanger: false},

    isFetchingCustomerAcceptedOrders: false,
    customerAcceptedOrders: [],
    customerAcceptedOrdersPagination: {pageSize: 10, showSizeChanger: false},

    isFetchingCustomerArchivedOrders: false,
    customerArchivedOrders: [],
    customerArchivedOrdersPagination: {pageSize: 10, showSizeChanger: false},

    isFetchingCustomerProcessedOrders: false,
    customerProcessedOrders: [],
    customerProcessedOrdersPagination: {pageSize: 10, showSizeChanger: false},

    isFetchingCustomerAwaitingConfirmationOrdersDashboard: false,
    customerAwaitingConfirmationDashboard: [],

    isFetchingCustomerAwaitingConfirmationOrders: false,
    customerAwaitingConfirmation: [],
    customerAwaitingConfirmationPagination: {pageSize: 6, showSizeChanger: false},

    isFetchingRecentOrderChanges: false,
    recentOrderChanges: [],

    isFetchingDrafts: false,
    draftOrders: [],

    isCreatingDraftOrder: false,

    isFetchingOrder: false,

    isProceedingToShipping: false,
    isChangingDraftStage: false,
    toAnalyze: [],
    isChangingDeliveryDate: false,

    isFetchingPendingOrder: false,
    pendingOrder: {},
    isAddingNewPart: false,
    isQuotingAssembly: false,
    isFetchingPendingOrderParts: false,
    isEditingPendingOrder: false,
    isResolvingPendingOrder: false,
    isRevertingOrder: false,
    isChangingDraftName: false,
    isUpdatingOdooLink: false,

    isFetchingAwaitingChanges: false,
    awaitingChanges: [],

    isFetchingRecentChanges: false,
    recentChanges: [],
    isGeneratingZip: false,

    fetchingArchivedBulkOrders: false,
    archivedInternalOrders: [],
    archivedCalculation: {},
    isFetchingArchivedCalculation: false,
    isArchivingCalculation: false,

    isFetchingArchivedCalculationCustomers: false,
    archivedCalculationCustomers: [],

    filesToUpload: [],

    isFetchingProducersForOrder: false,
    producersForOrder: {}
  },
  defaultMaterialsData: {
    selectedRegion: "asia",

    //# Cutting Machines
    isFetchingCuttingMachine: false,
    defaultCuttingMachine: [],
    isFetchingCuttingMachineData: false,
    defaultCuttingMachineData: {},

    //# Bending
    isFetchingDefaultBendingData: false,
    defaultBendingData: {},
    isFetchingSingleMaterialDefaultBends: false,
    bendingDefaultBendsData: [],
    isAddingDefaultBend: false,
    isEditingDefaultBend: false,
    isRemovingDefaultBend: false,

    //# Simple Machining
    defaultSimpleMachiningData: {},
    isFetchingDefaultSimpleMachiningData: false,

    //# Machining
    isFetchingDefaultMachiningData: false,
    defaultMachiningData: initialMachiningData,
    isFetchingDefaultMillingMachineData: false,
    isFetchingDefaultLathingMachineData: false,
    alreadyFetchedDefaultMachiningData: false,
    defaultMillingMachine: {},
    defaultLathingMachine: {},

    //# Tube Cutting
    isFetchingDefaultTubeCuttingData: false,
    isFetchingDefaultTubeCuttingMachineData: false,
    defaultTubeCuttingMachines: [],
    defaultTubeCuttingMachineData: {},

    //# Surface Coating
    isFetchingDefaultSurfaceCoatingData: false,
    defaultSurfaceCoatingData: {},

    //# Materials
    isFetchingDefaultMaterialPrices: false,
    defaultMaterialPrices: [],

    isFetchingDefaultTubeCuttingMaterials: false,
    defaultTubeCuttingMaterials: {
      availableMaterials: [],
      allAvailable: {}
    },
    isFetchingDefaultStandardTubeMaterialPrices: false,
    defaultStandardTubeMaterialPrices: [],
    isFetchingDefaultMachiningMaterials: false,
    defaultMachiningMaterials: {
      availableMaterials: [],
      allAvailable: {}
    },

    isFetchingMaterialCertPrices: false,
    baseMaterialCertFee: 0,
    assemblyMaterialCertFee: 0,

    isFetchingSeedingData: false,
    seedingData: {},
    isSeedingData: false,

    reloadTabKey: null
  },
}


export default initialState
