import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Button, Form, Layout, Spin, Tabs} from "antd";
import {forceLogoutUser, logoutUser} from "../../../actions/authActions";
import {LaserCuttingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/LaserCuttingTab";
import {MaterialBendingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/MaterialBendingTab";
import {SimpleMachiningTab} from "../../../components/company/CompanyMaterials/MaterialTabs/SimpleMachiningTab";
import {SurfaceCoatingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/SurfaceCoatingTab";
import {MaterialsTab} from "../../../components/company/CompanyMaterials/MaterialTabs/MaterialsTab";
import "./style.less"
import {CompanySidebar} from "../../../components/company";
import {LoadingOutlined} from "@ant-design/icons";
import {ProducerSidebar} from "../../../components/producer/ProducerSidebar";
import { MachiningTab } from "../../../components/company/CompanyMaterials/MaterialTabs/MachiningTab";
import {TubeCuttingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/TubeCuttingTab";
import {
  initializeSocket,
  changeMaterialPrice, changeBaseMaterialCertificateParams,
  changeAssemblyMaterialCertificateParams, changeBendingParams,
  changeDeburringParams, changeChamferingParams,
  changeDrillingParams, changeSimpleMachiningParams,
  changePaintingParams, changePowderPaintingParams,
  changeGalvanizingParams, changeNickelPlatingParams,
  changeAnodizingParams, changeGalvanicZincPlatingParams,
  changeHardeningParams, changeCaseHardeningParams,
  changeAnnealingParams, changeGlassBeadBlastingParams,
  changeSandBlastingParams, changeCuttingSpeed,
  changeCuttingMachineParams, changeMillingParams,
  changeLathingParams, changeGradeMillingSpeed,
  changeGradeLathingSpeed, changeMachiningMaterialPrice,
  changeTubeCuttingMachineParams, changeTubeCuttingMachineSpeed,
  changeTubeMaterialPrice, changeStandardTubeMaterialPrice,
  changeMachineMaxTolerance, changeMachineToleranceMarkup,
  changeMachineMaxRoughness, changeMachineRoughnessMarkup,
} from "../../../components/company/CompanyMaterials/SocketFunctions/RegularSocketFunctions";
import {
  fetchSurfaceCoatingData, setAnodizingData,
  setBlastingData, setGalvanicZincPlatingData,
  setGalvanizingData, setHeatTreatmentData,
  setNickelPlatingData, setPaintingData,
  setPowderPaintingData
} from "../../../actions/surfaceCoatingActions";
import {
  addNewBend, deleteBends,
  editBend, fetchBendingData,
  fetchSingleMaterialBends, fetchSingleMaterialBendsData,
  resetMaterialBendsData, setAveragePreparationTime,
  setBendingMaxLength, setPreparationPrice
} from "../../../actions/bendingActions";
import {
  fetchLathingMachineData, fetchMachiningData, fetchMachiningMaterials,
  fetchMillingMachineData, setEmptyLathingMachines,
  setEmptyMillingMachines, setLathingData, setLathingMachineRoughness, setLathingMachineTolerance,
  setMillingData, setMillingMachineRoughness, setMillingMachineTolerance, updateGradeLathingSpeed,
  updateGradeMillingSpeed, updateMachiningMaterialPrice
} from "../../../actions/machiningActions";
import {
  setCuttingMachineTolerance,
  setMachineParameter, updateCuttingMachineSpeed
} from "../../../actions/cuttinMachinesActions";
import {
  setChamferingData, setDeburringData,
  setDrillingData, setPreparationData
} from "../../../actions/simpleMachiningActions";
import {
  fetchStandardTubeMaterialPrices,
  fetchTubeMaterialPrices,
  setTubeCuttingMachineParameter, setTubeCuttingMachineTolerance, updateStandardTubeMaterialPrice,
  updateTubeCuttingMachineSpeed, updateTubeMaterialPrice
} from "../../../actions/tubeCuttingActions";
import {
  fetchMaterialCertificatePrices,
  fetchMaterialPrices, updateAssemblyMaterialCertificatePrice, updateBaseMaterialCertificatePrice,
  updateMaterialPrice
} from "../../../actions/materialsActions";
import {SeedModal} from "../../../components/producer/SeedModal";
import {fetchSeedingData} from "../../../actions/defaultMaterialsActions";

const { TabPane } = Tabs;

export const MaterialsAndMachinesPage = () => {
  const dispatch = useDispatch()

  const getInitialTab = () => {
    if(history.state?.state?.tab) {
      return history.state.state.tab
    }
    const query = new URLSearchParams(window.location.search);
    const tab = query.get("tab")

    if(tab) {
      switch(tab) {
        case "material":
          return "7"
        case "cutting":
          return "1"
        case "bending":
          return "2"
        case "simple-machining":
          return "3"
        case "machining":
          return "4"
        case "tubes":
          return "5"
        case "coating":
          return "6"
        default:
          return "1"
      }
    }
    return "1"
  }

  const [selectedTab, setSelectedTab] = useState(getInitialTab())
  const [socketConnected, setSocketConnected] = useState(false)
  const [seedModalVisible, setSeedModalVisible] = useState(false)

  const cuttingMachines = useSelector(state => state.cuttingMachines.cuttingMachines)
  const isFetchingMachines = useSelector(state => state.cuttingMachines.isFetchingMachines)
  const selectedMachineData = useSelector(state => state.cuttingMachines.selectedMachine)
  const isFetchingSelectedMachine = useSelector(state => state.cuttingMachines.isFetchingSelectedMachine)

  const isFetchingBendingData = useSelector(state => state.bending.isFetchingBendingData)
  const bendingData = useSelector(state => state.bending.bendingData)
  const bendingBendsData = useSelector(state => state.bending.bendingBendsData)
  const isAddingNewBend = useSelector(state => state.bending.isAddingNewBend)
  const isEditingBend = useSelector(state => state.bending.isEditingBend)
  const isRemovingBend = useSelector(state => state.bending.isRemovingBend)

  const isFetchingSimpleMachiningData = useSelector(state => state.simpleMachining.isFetchingSimpleMachiningData)
  const simpleMachiningData = useSelector(state => state.simpleMachining.simpleMachiningData)

  const isFetchingMachiningData = useSelector(state => state.machining.isFetchingMachiningData)
  const machiningData = useSelector(state => state.machining.machiningData)
  const selectedMillingMachine = useSelector(state => state.machining.selectedMillingMachine);
  const isFetchingSelectedMillingMachine = useSelector(state => state.machining.isFetchingSelectedMillingMachine);
  const selectedLathingMachine = useSelector(state => state.machining.selectedLathingMachine);
  const isFetchingSelectedLathingMachine = useSelector(state => state.machining.isFetchingSelectedLathingMachine);
  const alreadyFetchedMachiningData = useSelector(state => state.machining.alreadyFetchedMachiningData)

  const isFetchingSurfaceCoatingData = useSelector(state => state.surfaceCoating.isFetchingSurfaceCoatingData)
  const surfaceCoatingData = useSelector(state => state.surfaceCoating.surfaceCoatingData)

  const isFetchingTubeCuttingData = useSelector(state => state.tubeCutting.isFetchingTubeCuttingData)
  const isFetchingSelectedTubeCuttingMachine = useSelector(state => state.tubeCutting.isFetchingSelectedMachine)
  const availableTubeCuttingMachines = useSelector(state => state.tubeCutting.tubeCuttingMachines)
  const tubeCuttingMachineData = useSelector(state => state.tubeCutting.selectedMachine)
  const hasTubeCutting = useSelector(state => state.tubeCutting.hasTubeCutting)

  const isFetchingMaterialPrices = useSelector(state => state.materials.isFetchingMaterialPrices)
  const materialPrices = useSelector(state => state.materials.materialPrices)
  const tubeCuttingMaterials = useSelector(state => state.tubeCutting.materials)
  const standardTubeMaterialPrices = useSelector(state => state.tubeCutting.standardTubeMaterialPrices)

  const isFetchingStandardTubeMaterialPrices = useSelector(state => state.tubeCutting.isFetchingStandardTubeMaterialPrices)
  const isFetchingTubeMaterialPrices = useSelector(state => state.tubeCutting.isFetchingTubeMaterialPrices)

  const isFetchingMachiningMaterials = useSelector(state => state.machining.isFetchingMachiningMaterials)
  const machiningMaterials = useSelector(state => state.machining.materials)
  const hasMachining = useSelector(state => state.machining.hasMachining)

  const baseMaterialCertFee = useSelector(state => state.materials.baseMaterialCertFee)
  const assemblyMaterialCertFee = useSelector(state => state.materials.assemblyMaterialCertFee)

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user)

  const [seedFormRef] = Form.useForm();

  const isGelso = user?.role === "gelsoAdmin" || user?.role === "gelsoSubAdmin"

  useEffect(() => {
    initializeSocket({
      setSocketConnected,
      logOutUser: () => dispatch(forceLogoutUser()),
    })
  }, []);

  const handleOpenSeedModal = () => {
    dispatch(fetchSeedingData())
    setSeedModalVisible(true)
  }

  const handleCloseSeedModal = () => {
    setSeedModalVisible(false)
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      {user?.role === "producerAdmin" ?
        <ProducerSidebar
          onLogout={()=>{dispatch(logoutUser())}}
          user={user}
        />
        :
        <CompanySidebar
          onLogout={()=>{dispatch(logoutUser())}}
          user={user}
        />
      }
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}} type="flex">
        <div style={{paddingLeft: 15, paddingTop: 15, paddingRight: 15}}>
          {user?.role === "producerAdmin" &&
          <div>
            <div style={{position: "absolute", color: "white", fontSize: 16, fontWeight: 600, right: 170, top: 8}}>
              {user.producer?.name}
            </div>
            <div style={{position: "absolute", color: "white", fontSize: 11, fontWeight: 500, right: 170, top: 30}}>
              {user.producer?.address}, {user.producer?.postalCode} {user.producer?.city}, {user.producer?.country}
            </div>
            <div style={{position: "absolute", right: 25, top: 11}}>
              <Button type={"primary"}
                      className={"seedParametersButton"}
                      onClick={handleOpenSeedModal}
              >
                Load Parameters
              </Button>
            </div>
          </div>
          }
          <Tabs size={"small"} activeKey={selectedTab} onChange={(e)=>{setSelectedTab(e)}} type="card" className={"materialsTabs noSelect"}>
            <TabPane className={"materialsTabPane noSelect"} tab="Cutting Machines" key="1">
              <LaserCuttingTab
                selectedTab={selectedTab}
                cuttingMachines={cuttingMachines}
                isFetchingMachines={isFetchingMachines}
                machineData={selectedMachineData}
                isFetchingMachineData={isFetchingSelectedMachine}
                changeCuttingSpeed={changeCuttingSpeed}
                changeCuttingMachineParams={changeCuttingMachineParams}
                changeMachineMaxTolerance={changeMachineMaxTolerance}
                changeMachineToleranceMarkup={changeMachineToleranceMarkup}
                updateCuttingMachineSpeed={updateCuttingMachineSpeed}
                setMachineParameter={setMachineParameter}
                setCuttingMachineTolerance={setCuttingMachineTolerance}
                socketConnected={socketConnected}
              />
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Bending" key="2">
              <Spin spinning={isFetchingBendingData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px",
                    }}
              >
                <MaterialBendingTab
                  changeBendingParams={changeBendingParams}
                  addNewBend={addNewBend}
                  editBend={editBend}
                  deleteBends={deleteBends}
                  fetchBendingData={fetchBendingData}
                  setBendingMaxLength={setBendingMaxLength}
                  setPreparationPrice={setPreparationPrice}
                  setAveragePreparationTime={setAveragePreparationTime}
                  resetMaterialBendsData={resetMaterialBendsData}
                  fetchSingleMaterialBendsData={fetchSingleMaterialBendsData}
                  fetchSingleMaterialBends={fetchSingleMaterialBends}
                  bendingData={bendingData}
                  bendingBendsData={bendingBendsData}
                  isAddingNewBend={isAddingNewBend}
                  isEditingBend={isEditingBend}
                  isRemovingBend={isRemovingBend}
                  isGelso={isGelso}
                  selectedTab={selectedTab}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Simple Machining" key="3">
              <Spin spinning={isFetchingSimpleMachiningData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <SimpleMachiningTab
                  isFetchingSimpleMachiningData={isFetchingSimpleMachiningData || !socketConnected}
                  selectedTab={selectedTab}
                  isGelso={isGelso}
                  simpleMachiningData={simpleMachiningData}
                  setDeburringData={setDeburringData}
                  setChamferingData={setChamferingData}
                  setDrillingData={setDrillingData}
                  setPreparationData={setPreparationData}
                  changeDeburringParams={changeDeburringParams}
                  changeChamferingParams={changeChamferingParams}
                  changeDrillingParams={changeDrillingParams}
                  changeSimpleMachiningParams={changeSimpleMachiningParams}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Machining" key="4">
              <Spin spinning={isFetchingMachiningData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <MachiningTab
                  isFetchingMachiningData={isFetchingMachiningData || !socketConnected}
                  selectedTab={selectedTab}
                  isGelso={isGelso}
                  machiningData={machiningData}
                  selectedMillingMachine={selectedMillingMachine}
                  isFetchingSelectedMillingMachine={isFetchingSelectedMillingMachine}
                  selectedLathingMachine={selectedLathingMachine}
                  isFetchingSelectedLathingMachine={isFetchingSelectedLathingMachine}
                  alreadyFetchedMachiningData={alreadyFetchedMachiningData}
                  fetchData={fetchMachiningData}
                  fetchMillingMachineData={fetchMillingMachineData}
                  setEmptyMillingMachines={setEmptyMillingMachines}
                  fetchLathingMachineData={fetchLathingMachineData}
                  setEmptyLathingMachines={setEmptyLathingMachines}
                  setMillingData={setMillingData}
                  updateGradeMillingSpeed={updateGradeMillingSpeed}
                  setLathingData={setLathingData}
                  updateGradeLathingSpeed={updateGradeLathingSpeed}
                  changeMillingParams={changeMillingParams}
                  changeLathingParams={changeLathingParams}
                  changeGradeMillingSpeed={changeGradeMillingSpeed}
                  changeGradeLathingSpeed={changeGradeLathingSpeed}
                  changeMachineMaxTolerance={changeMachineMaxTolerance}
                  changeMachineToleranceMarkup={changeMachineToleranceMarkup}
                  changeMachineMaxRoughness={changeMachineMaxRoughness}
                  changeMachineRoughnessMarkup={changeMachineRoughnessMarkup}
                  setMillingMachineRoughness={setMillingMachineRoughness}
                  setLathingMachineRoughness={setLathingMachineRoughness}
                  setMillingMachineTolerance={setMillingMachineTolerance}
                  setLathingMachineTolerance={setLathingMachineTolerance}
                  socketConnected={socketConnected}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Tube Cutting" key="5">
              <Spin spinning={isFetchingTubeCuttingData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <TubeCuttingTab
                  isFetchingTubeCuttingData={isFetchingTubeCuttingData}
                  isFetchingSelectedMachine={isFetchingSelectedTubeCuttingMachine}
                  availableMachines={availableTubeCuttingMachines}
                  machineData={tubeCuttingMachineData}
                  changeTubeCuttingMachineParams={changeTubeCuttingMachineParams}
                  changeTubeCuttingMachineSpeed={changeTubeCuttingMachineSpeed}
                  changeMachineMaxTolerance={changeMachineMaxTolerance}
                  changeMachineToleranceMarkup={changeMachineToleranceMarkup}
                  updateTubeCuttingMachineSpeed={updateTubeCuttingMachineSpeed}
                  setTubeCuttingMachineParameter={setTubeCuttingMachineParameter}
                  setTubeCuttingMachineTolerance={setTubeCuttingMachineTolerance}
                  hasTubeCutting={hasTubeCutting}
                  selectedTab={selectedTab}
                  socketConnected={socketConnected}
                  isGelso={isGelso}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Surface Treatments" key="6">
              <Spin spinning={isFetchingSurfaceCoatingData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <SurfaceCoatingTab
                  surfaceCoatingData={surfaceCoatingData}
                  isFetchingSurfaceCoatingData={isFetchingSurfaceCoatingData}
                  fetchData={fetchSurfaceCoatingData}
                  isGelso={isGelso}
                  updateTubeMaterialPrice={updateTubeMaterialPrice}
                  changePaintingParams={changePaintingParams}
                  changePowderPaintingParams={changePowderPaintingParams}
                  changeGalvanizingParams={changeGalvanizingParams}
                  changeNickelPlatingParams={changeNickelPlatingParams}
                  changeAnodizingParams={changeAnodizingParams}
                  changeGalvanicZincPlatingParams={changeGalvanicZincPlatingParams}
                  changeHardeningParams={changeHardeningParams}
                  changeCaseHardeningParams={changeCaseHardeningParams}
                  changeAnnealingParams={changeAnnealingParams}
                  changeGlassBeadBlastingParams={changeGlassBeadBlastingParams}
                  changeSandBlastingParams={changeSandBlastingParams}
                  setPaintingData={setPaintingData}
                  setPowderPaintingData={setPowderPaintingData}
                  setGalvanizingData={setGalvanizingData}
                  setNickelPlatingData={setNickelPlatingData}
                  setAnodizingData={setAnodizingData}
                  setGalvanicZincPlatingData={setGalvanicZincPlatingData}
                  setBlastingData={setBlastingData}
                  setHeatTreatmentData={setHeatTreatmentData}
                  selectedTab={selectedTab}
                />
              </Spin>
            </TabPane>

            <TabPane className={"materialsTabPane noSelect"} tab="Materials" key="7">
              <MaterialsTab
                isFetchingMaterialPrices={isFetchingMaterialPrices}
                materialPrices={materialPrices}
                tubeCuttingMaterials={tubeCuttingMaterials}
                standardTubeMaterialPrices={standardTubeMaterialPrices}
                isFetchingTubeMaterialPrices={isFetchingTubeMaterialPrices}
                isFetchingStandardTubeMaterialPrices={isFetchingStandardTubeMaterialPrices}
                hasTubeCutting={hasTubeCutting}
                isFetchingMachiningMaterials={isFetchingMachiningMaterials}
                machiningMaterials={machiningMaterials}
                hasMachining={hasMachining}
                baseMaterialCertFee={baseMaterialCertFee}
                assemblyMaterialCertFee={assemblyMaterialCertFee}
                fetchMaterialPrices={fetchMaterialPrices}
                fetchTubeMaterialPrices={fetchTubeMaterialPrices}
                fetchStandardTubeMaterialPrices={fetchStandardTubeMaterialPrices}
                fetchMachiningMaterials={fetchMachiningMaterials}
                fetchMaterialCertificatePrices={fetchMaterialCertificatePrices}
                updateStandardTubeMaterialPrice={updateStandardTubeMaterialPrice}
                updateMaterialPrice={updateMaterialPrice}
                updateMachiningMaterialPrice={updateMachiningMaterialPrice}
                changeMaterialPrice={changeMaterialPrice}
                changeBaseMaterialCertificateParams={changeBaseMaterialCertificateParams}
                updateBaseMaterialCertificatePrice={updateBaseMaterialCertificatePrice}
                updateAssemblyMaterialCertificatePrice={updateAssemblyMaterialCertificatePrice}
                changeAssemblyMaterialCertificateParams={changeAssemblyMaterialCertificateParams}
                changeMachiningMaterialPrice={changeMachiningMaterialPrice}
                changeTubeMaterialPrice={changeTubeMaterialPrice}
                changeStandardTubeMaterialPrice={changeStandardTubeMaterialPrice}
                selectedTab={selectedTab}
                socketConnected={socketConnected}
              />
            </TabPane>
          </Tabs>
        </div>
      </Layout.Content>

      <SeedModal
        visible={seedModalVisible}
        handleClose={handleCloseSeedModal}
        formRef={seedFormRef}
        selectedTab={selectedTab}
      />

    </Layout>
  )
}