import {
  MACHINING_SET_DATA,
  MACHINING_IS_FETCHING_DATA,
  MACHINING_IS_FETCHING_MATERIALS,
  MACHINING_SET_MACHINING_SERVICE,
  MACHINING_SET_MILLING_SERVICE,
  MACHINING_SET_LATHING_SERVICE,
  MACHINING_SET_MILLING_DATA,
  MACHINING_SET_LATHING_DATA,
  MACHINING_SET_MATERIALS,
  MACHINING_SET_MACHINING_MATERIAL_PRICE,
  MACHINING_SET_SELECTED_MILLING_MACHINE,
  MACHINING_SET_SELECTED_LATHING_MACHINE,
  MACHINING_IS_FETCHING_SELECTED_MILLING_MACHINE_DATA,
  MACHINING_IS_FETCHING_SELECTED_LATHING_MACHINE_DATA,
  MACHINING_SET_IS_CREATING_MILLING_MACHINE,
  MACHINING_SET_MILLING_MACHINES,
  MACHINING_IS_REMOVING_MILLING_MACHINE,
  MACHINING_SET_IS_CREATING_LATHING_MACHINE,
  MACHINING_SET_LATHING_MACHINES,
  MACHINING_IS_REMOVING_LATHING_MACHINE,
  MACHINING_IS_UPDATING_MILLING_MACHINE,
  MACHINING_IS_UPDATING_LATHING_MACHINE,
  MACHINING_SET_MILLING_GRADE_SPEED,
  MACHINING_SET_LATHING_GRADE_SPEED,
  MACHINING_SET_MILLING_MACHINE_TOLERANCE,
  MACHINING_SET_LATHING_MACHINE_TOLERANCE,
  MACHINING_SET_MILLING_MACHINE_ROUGHNESS,
  MACHINING_SET_LATHING_MACHINE_ROUGHNESS
} from "./actionTypes";
import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import {forceLogoutUser} from "./authActions";

const fetchMachiningData = payload => dispatch => {
  dispatch({
    type: MACHINING_IS_FETCHING_DATA,
    payload: !payload.alreadyFetchedMachiningData,
  })

  return http
    .get(`${API_BASE_URL}/machining`)
    .then(response => {

      dispatch({
        type: MACHINING_SET_DATA,
        payload: response.data,
      })

      dispatch({
        type: MACHINING_IS_FETCHING_DATA,
        payload: false,
      })

      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MACHINING_IS_FETCHING_DATA,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchMillingMachineData = payload => dispatch => {
  dispatch({
    type: MACHINING_IS_FETCHING_SELECTED_MILLING_MACHINE_DATA,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/machining/milling/${payload.machineId}/properties`)
    .then((response) => {

      dispatch({
        type: MACHINING_SET_SELECTED_MILLING_MACHINE,
        payload: response.data,
      })

      dispatch({
        type: MACHINING_IS_FETCHING_SELECTED_MILLING_MACHINE_DATA,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MACHINING_IS_FETCHING_SELECTED_MILLING_MACHINE_DATA,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setEmptyMillingMachines = () => dispatch => {
  dispatch({
    type: MACHINING_SET_SELECTED_MILLING_MACHINE,
    payload: {},
  })
}

const fetchLathingMachineData = payload => dispatch => {
  dispatch({
    type: MACHINING_IS_FETCHING_SELECTED_LATHING_MACHINE_DATA,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/machining/lathing/${payload.machineId}/properties`)
    .then(response => {

      dispatch({
        type: MACHINING_SET_SELECTED_LATHING_MACHINE,
        payload: response.data,
      })

      dispatch({
        type: MACHINING_IS_FETCHING_SELECTED_LATHING_MACHINE_DATA,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MACHINING_IS_FETCHING_SELECTED_LATHING_MACHINE_DATA,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setEmptyLathingMachines = () => dispatch => {
  dispatch({
    type: MACHINING_SET_SELECTED_LATHING_MACHINE,
    payload: {},
  })
}

const createMillingMachine = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_IS_CREATING_MILLING_MACHINE,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/machining/milling/create`, payload)
    .then(response => {

      dispatch({
        type: MACHINING_SET_MILLING_MACHINES,
        payload: response.data
      })

      dispatch({
        type: MACHINING_SET_IS_CREATING_MILLING_MACHINE,
        payload: false,
      })

      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MACHINING_SET_IS_CREATING_MILLING_MACHINE,
          payload: false,
        })
        // message.show(message.type.error, http.getErrorMessage(error))
        message.show(message.type.error, "A machine with the same X dimension already exists!")
      }
    })
}

const createLathingMachine = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_IS_CREATING_LATHING_MACHINE,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/machining/lathing/create`, payload)
    .then(response => {

      dispatch({
        type: MACHINING_SET_LATHING_MACHINES,
        payload: response.data
      })

      dispatch({
        type: MACHINING_SET_IS_CREATING_LATHING_MACHINE,
        payload: false,
      })

      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MACHINING_SET_IS_CREATING_LATHING_MACHINE,
          payload: false,
        })
        // message.show(message.type.error, http.getErrorMessage(error))
        message.show(message.type.error, "A machine with the same Radius already exists!")
      }
    })
}

const deleteMillingMachine = payload => dispatch => {
  dispatch({
    type: MACHINING_IS_REMOVING_MILLING_MACHINE,
    payload: true
  })

  return http
    .delete(`${API_BASE_URL}/machining/milling/${payload.machineId}`)
    .then(response => {

      dispatch({
        type: MACHINING_SET_MILLING_MACHINES,
        payload: response.data
      })

      dispatch({
        type: MACHINING_IS_REMOVING_MILLING_MACHINE,
        payload: false
      })

      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MACHINING_IS_REMOVING_MILLING_MACHINE,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const deleteLathingMachine = payload => dispatch => {
  dispatch({
    type: MACHINING_IS_REMOVING_LATHING_MACHINE,
    payload: true
  })

  return http
    .delete(`${API_BASE_URL}/machining/lathing/${payload.machineId}`)
    .then(response => {

      dispatch({
        type: MACHINING_SET_LATHING_MACHINES,
        payload: response.data
      })

      dispatch({
        type: MACHINING_IS_REMOVING_LATHING_MACHINE,
        payload: false
      })

      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MACHINING_IS_REMOVING_LATHING_MACHINE,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const updateMillingMachine = payload => dispatch => {
  dispatch({
    type: MACHINING_IS_UPDATING_MILLING_MACHINE,
    payload: true
  })
  return http
    .put(`${API_BASE_URL}/machining/milling/${payload.machineId}`, payload.data)
    .then(response => {
      dispatch({
        type: MACHINING_SET_MILLING_MACHINES,
        payload: response.data
      })

      dispatch({
        type: MACHINING_IS_UPDATING_MILLING_MACHINE,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MACHINING_IS_UPDATING_MILLING_MACHINE,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const updateLathingMachine = payload => dispatch => {
dispatch({
    type: MACHINING_IS_UPDATING_LATHING_MACHINE,
    payload: true
  })
  return http
    .put(`${API_BASE_URL}/machining/lathing/${payload.machineId}`, payload.data)
    .then(response => {
      dispatch({
        type: MACHINING_SET_LATHING_MACHINES,
        payload: response.data
      })

      dispatch({
        type: MACHINING_IS_UPDATING_LATHING_MACHINE,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MACHINING_IS_UPDATING_LATHING_MACHINE,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchMachiningMaterials = () => dispatch => {
  dispatch({
    type: MACHINING_IS_FETCHING_MATERIALS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/machining/materials`)
    .then(response => {
      dispatch({
        type: MACHINING_SET_MATERIALS,
        payload: response.data,
      })
      dispatch({
        type: MACHINING_IS_FETCHING_MATERIALS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MACHINING_IS_FETCHING_MATERIALS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setMachiningService = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_MACHINING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/machining`, {hasMachining: payload.value})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setMachiningMilling = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_MILLING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/machining/has-milling`, {hasMilling: payload.value})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setMachiningLathing = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_LATHING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/machining/has-lathing`, {hasLathing: payload.value})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setMillingData = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_MILLING_DATA,
    payload: payload,
  })
}

const setLathingData = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_LATHING_DATA,
    payload: payload,
  })
}

const updateMachiningMaterialPrice = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_MACHINING_MATERIAL_PRICE,
    payload: payload,
  })
}

const setEmptySelectedMillingMachineData = () => dispatch => {
  dispatch({
    type: MACHINING_SET_SELECTED_MILLING_MACHINE,
    payload: {},
  })
  return Promise.resolve(10)
}

const setEmptySelectedLathingMachineData = () => dispatch => {
  dispatch({
    type: MACHINING_SET_SELECTED_LATHING_MACHINE,
    payload: {},
  })
  return Promise.resolve(10)
}

const updateGradeMillingSpeed = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_MILLING_GRADE_SPEED,
    payload: payload,
  })
}

const updateGradeLathingSpeed = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_LATHING_GRADE_SPEED,
    payload: payload,
  })
}

const setMillingMachineTolerance = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_MILLING_MACHINE_TOLERANCE,
    payload: payload
  })
}

const setLathingMachineTolerance = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_LATHING_MACHINE_TOLERANCE,
    payload: payload
  })
}

const setMillingMachineRoughness = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_MILLING_MACHINE_ROUGHNESS,
    payload: payload
  })
}

const setLathingMachineRoughness = payload => dispatch => {
  dispatch({
    type: MACHINING_SET_LATHING_MACHINE_ROUGHNESS,
    payload: payload
  })
}

export {
  fetchMachiningData,
  fetchMachiningMaterials,
  setMachiningService,
  setMachiningMilling,
  setMachiningLathing,
  setMillingData,
  setLathingData,
  updateMachiningMaterialPrice,
  fetchMillingMachineData,
  setEmptyMillingMachines,
  fetchLathingMachineData,
  setEmptyLathingMachines,
  createMillingMachine,
  createLathingMachine,
  deleteMillingMachine,
  deleteLathingMachine,
  setEmptySelectedMillingMachineData,
  setEmptySelectedLathingMachineData,
  updateMillingMachine,
  updateLathingMachine,
  updateGradeMillingSpeed,
  updateGradeLathingSpeed,
  setMillingMachineTolerance,
  setLathingMachineTolerance,
  setMillingMachineRoughness,
  setLathingMachineRoughness
}
