import React, {useEffect, useState, useCallback, createRef, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forceCheck } from "react-lazyload";
import {forceLogoutUser, logoutUser} from '../../actions/authActions';
import {
  uploadNewPart,
  changeItemColor,
  changeItemTolerance,
  changeStandard,
  removeItemColor,
  changeItemQuantity,
  changeItemNote,
  changeItemMaterial,
  changePartMachiningMaterial,
  changePartMaterialCertificate,
  changePartTubeCuttingMaterial,
  changeItemThickness,
  changeNumberOfUniqueParts,
  removeItem,
  removeMultipleItems,
  applyMultipleItems,
  changeOrderPrice,
  updatePartData,
  updatePartFilePath,
  uploadPartDrawing,
  removePartDrawing,
  setAssemblyPartData,
  updateAssemblyPartData,
  updateAssemblyPrice,
  updateAssemblyQuantity,
  updateManyAssembliesPrices,
  deleteEntireAssembly,
  updatePartAnalysedStatus,
  updatePartProcessingType,
  resetToProcess,
  changePartToleranceData,
  changeItemRoughness,
  changePartRoughnessData,

  // Draft
  changeStageToShipping,
  changeStageToConfirmation,
  changeToPreviousStage,
  changeDeliveryDate,
  placeOrder,
  changePartPrice,
  changeManyPartPrices,
  changeDraftOrderName,
  confirmAdjustmentChange,
  changeAdjustments,

  // Bulk
  clearBulkOrder,
  changePartData,
  changeManyPartData,
  changeWeldingPrice,
  changeAssemblingPrice,
  changePackagingPrice,
  changeTransportationPrice,
  clearProducerOrder, changeManualItemTolerance, changeManualItemRoughness, changePartMaterialCertificateData
} from "../../actions/orderActions";

import {
  resetToAnalyze,

  // Draft
  fetchDraftOrder,
  resetFilesToUpload,

  // Bulk
  fetchInternalDraftOrder,
  fetchInternalArchivedOrders,
  archiveCurrentCalculation,
  fetchProducersForOrder,

  // Producer
  fetchProducersInternalDraftOrder
} from "../../actions/ordersActions";

import { debounce } from 'lodash';
import {io} from "socket.io-client";
import cuid from "cuid";
import {
  Grid, Col, Layout,
  Row, Spin, Progress,
  notification, Modal,
  Form, Divider, Button, Checkbox
} from 'antd'
import {
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Divider as MiDivider
} from "@material-ui/core";
import ItemVisualisation from "../../components/Order/ItemVisualisation";
import { Sidebar } from '../../components';
import { CompanySidebar } from '../../components/company';

import history from '../../utils/history';
import storage from "../../utils/storage";
import { SOCKET_URL, SOCKET_PATH } from "../../constants";
import { useLocation } from 'react-router-dom'
import './style.less';

import {getAvailableThicknesses} from "../../utils/material";
import {OrderProcessingStage} from "../../components/Order/OrderStages/OrderProcessingStage";
import {openNotification, openPdfNotification} from "../../utils/utility";
import {OrderDocumentViewModal} from "../../components/Order/OrderCalculationComponents/SharedComponents/OrderDocumentViewModal";
import {OrderDragger} from "../../components/Order/OrderCalculationComponents/SharedComponents/OrderDragger";

// Draft
import {OrderShippingStage} from "../../components/Order/OrderStages/OrderShippingStage";
import {OrderConfirmationStage} from "../../components/Order/OrderStages/OrderConfirmationStage";
import {FetchingOrderIndicator} from "../../components/Order/FetchingOrderIndicator";
import {DocumentsModal} from "../../components/Order/DocumentsModal";
import {DraftOrderHeader} from "../../components/Order/OrderCalculationComponents/DraftOrderComponents/DraftOrderHeader";
import {DraftOrderSticky} from "../../components/Order/OrderCalculationComponents/DraftOrderComponents/DraftOrderSticky";
import {OrderViewOtherModal} from "../../components/Order/OrderCalculationComponents/SharedComponents/OrderViewOtherModal";
import {DraftOrderAdjustmentModal} from "../../components/Order/OrderCalculationComponents/DraftOrderComponents/DraftOrderAdjustmentModal";
import {DraftOrderStageCard} from "../../components/Order/OrderCalculationComponents/DraftOrderComponents/DraftOrderStageCard";

// Bulk
import {ProducersPricesModal} from "../../components/company/ProducersPricesModal";
import {BulkOrderHeader} from "../../components/Order/OrderCalculationComponents/BulkOrderComponents/BulkOrderHeader";
import {BulkOrderSticky} from "../../components/Order/OrderCalculationComponents/BulkOrderComponents/BulkOrderSticky";
import {BulkOrderArchiveModal} from "../../components/Order/OrderCalculationComponents/BulkOrderComponents/BulkOrderArchiveModal";
import {BulkOrderArchiveAccordion} from "../../components/Order/OrderCalculationComponents/BulkOrderComponents/BulkOrderArchiveAccordion";
import {ProducerSidebar} from "../../components/producer/ProducerSidebar";
import {ProducerOrderSticky} from "../../components/Order/OrderCalculationComponents/ProducerOrderComponents/ProducerOrderSticky";
import {
  ProducerStageCard
} from "../../components/Order/OrderCalculationComponents/ProducerOrderComponents/ProducerStageCard";
import {fetchSurfaceCoatingData} from "../../actions/surfaceCoatingActions";


let socket;

export const OrderCalculationPage = () => {
  const location = useLocation();

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user)
  const isFetchingOrder = useSelector(state => state.orders.isFetchingOrder)
  const orderId = useSelector(state => state.order.id)
  const items = useSelector(state => state.order.parts)
  const sheetMetalMaterials = useSelector(state => state.order.materials?.sheetMetal)
  const sheetMetalMaterialMap = useSelector(state => state.order.sheetMetalMaterialMap)
  const machiningMaterials = useSelector(state => state.order.materials?.machining)
  const tubeCuttingMaterials = useSelector(state => state.order.materials?.tubeCutting)
  const machiningMaterialMap = useSelector(state => state.order.machiningMaterialMap)
  const totalPrice = useSelector(state => state.order.totalPrice)
  const toProcess = useSelector(state => state.order.toProcess)
  const toAnalyze = useSelector(state => state.orders.toAnalyze)
  const currentlyUploaded = useSelector(state => state.order.currentlyUploaded)
  const currentlyProcessed = useSelector(state => state.order.currentlyProcessed)
  const companySelectableSheetMetalMaterials = useSelector(state => state.order.selectableGrades?.sheetMetal)
  const companySelectableMachiningMaterials = useSelector(state => state.order.selectableGrades?.machining)
  const companySelectableTubeCuttingMaterials = useSelector(state => state.order.selectableGrades?.tubeCutting)
  const [selectableThickness, setSelectableThickness] = useState(new Set([]))
  const [viewVisible, setViewVisible] = useState(false)
  const [itemView, setItemView] = useState(null)
  const [modalWidth, setModalWidth] = useState(window.innerWidth-100)
  const [modalHeight, setModalHeight] = useState(window.innerHeight-135)
  const [largeThreshold, _setLargeThreshold] = useState(window.innerWidth < 1300)
  const [smallThreshold, _setSmallThreshold] = useState(window.innerWidth < 750)
  const [analysedAssemblyMap, setAnalysedAssemblyMap] = useState({})
  const [assemblyProgressMap, setAssemblyProgressMap] = useState({})
  const [partiallySelectedAssemblies, setPartiallySelectedAssemblies] = useState({})
  const [selectedItems, setSelectedItems] = useState({})
  const [selectableItemsCount, setSelectableItemsCount] = useState(0)
  const [allItemsCount, setAllItemsCount] = useState(0)
  const [selectedSheetMetalPartsCount, setSelectedSheetMetalPartsCount] = useState(0)
  const [selectedMillingPartsCount, setSelectedMillingPartsCount] = useState(0)
  const [selectedLathingPartsCount, setSelectedLathingPartsCount] = useState(0)
  const [selectedAutomaticTubeCuttingPartsCount, setSelectedAutomaticTubeCuttingPartsCount] = useState(0)
  const [selectedManualTubeCuttingPartsCount, setSelectedManualTubeCuttingPartsCount] = useState(0)
  const [selectedProfilePartsCount, setSelectedProfilePartsCount] = useState(0)
  const [selectedOtherPartsCount, setSelectedOtherPartsCount] = useState(0)
  const [selectedItemsCount, setSelectedItemsCount] = useState(0)
  const [selectedAllItems, setSelectedAllItems] = useState(false)
  const [selectedParameters, setSelectedParameters] = useState({
    sheetMetalMaterial: null,
    machiningMaterial: null,
    standard: null,
    thickness: null,
    quantity: null,
    certNeeded: null,
    coating: {
      hasCoating: false,
      type: "",
      data: {
        colorRAL: "",
        colorGlossId: "",
        ralRow: 0,
        ralHex: "",
      }
    }
  })
  const [selectedPartsMapByType, setSelectedPartsMapByType] = useState({
    sheetMetal: {},
    machined: {},
    tubeProfile: {},
  })
  const [multiEditFields, setMultiEditFields] = useState([{id: cuid(), selected: null}])
  const [currentActiveMultiEditFields, setCurrentActiveMultiEditFields] = useState({
    sheetMetalMaterial: false,
    machiningMaterial: false,
    standard: false,
    thickness: false,
    quantity: false,
    certificate: false,
    coating: false
  })
  const [working, setWorking] = useState(false)
  const [actionMap, setActionMap] = useState(new Map());
  const [selectableSheetMetalMaterials, setSelectableSheetMetalMaterials] = useState(new Set([...(companySelectableSheetMetalMaterials || [])]))
  const [selectedItemsSheetMetalMaterials, setSelectedItemsSheetMetalMaterials] = useState({})
  const [selectableMachiningMaterials, setSelectableMachiningMaterials] = useState(new Set([...(companySelectableMachiningMaterials || [])]))
  const [selectedItemsMachiningMaterials, setSelectedItemsMachiningMaterials] = useState({})
  const [socketConnected, setSocketConnected] = useState(false)

  const [documentViewItem, setDocumentViewItem] = useState(null)
  const [uploadingDrawing, setUploadingDrawing] = useState(false)
  const [documentViewVisible, _setDocumentViewVisible] = useState(false)
  const documentViewVisibleRef = useRef(null)
  documentViewVisibleRef.current = documentViewVisible
  const setDocumentViewVisible = (value) => {
    documentViewVisibleRef.current = value;
    _setDocumentViewVisible(value);
  }
  const smallThresholdRef = useRef(smallThreshold)
  const largeThresholdRef = useRef(largeThreshold)
  const workingRef = useRef(working)
  const screens = Grid.useBreakpoint()
  const dispatch = useDispatch()
  const updateActions = (k,v) => {
    setActionMap(prev => new Map(prev.set(k,v)));
  }
  const removeAction = (actionId) => {
    setActionMap(prev => {
      const tmpMap = prev
      tmpMap.delete(actionId)
      return new Map(tmpMap)
    });
  }
  const isDraft = location.pathname === "/draft"

  //---------------------- Draft ---------------------
  const needsManualQuote = useSelector(state=>state.order.needsManualQuote)
  const processingPrice = useSelector(state => state.order.processingPrice)
  const stage = useSelector(state => state.order.stage)
  const order = useSelector(state => state.order)
  const adjustment = useSelector(state => state.order.adjustment || 0)
  const isProceedingToShipping = useSelector(state => state.orders.isProceedingToShipping)
  const isChangingDraftStage = useSelector(state => state.orders.isChangingDraftStage)
  const isChangingDeliveryDate = useSelector(state => state.orders.isChangingDeliveryDate)
  const isChangingDraftName = useSelector(state => state.orders.isChangingDraftName)
  const filesToUpload = useSelector(state => state.orders.filesToUpload)
  const [numberOfManualParts, setNumberOfManualParts] = useState(0);
  const [prevPartProcessingType, setPrevPartProcessingType] = useState({});
  const prevPartProcessingTypeRef = useRef({})
  const [selectedDate, setSelectedDate] = useState(null)
  const [viewOtherVisible, setViewOtherVisible] = useState(false)
  const [otherItemView, setOtherItemView] = useState(null)
  const [showAdjustmentChangeModal, setShowAdjustmentChangeModal] = useState(false);
  const [additionalDocs, setAdditionalDocs] = useState([])
  const [additionalDocsVisible, setAdditionalDocsVisible] = useState(false)
  const [processedParts, setProcessedParts] = useState(0)
  const [showOverlay, setShowOverlay] = useState(false)
  const [overlayShown, setOverlayShown] = useState(false)
  const [dontShowOverlayAgain, setDontShowOverlayAgain] = useState(false)
  const stageRef = useRef(stage)
  const firstDrawingButtonRef = useRef(null);

//----------------------- Bulk --------------------
  const [existsZeroPrice, setExistsZeroPrice] = useState(false);
  const [selectedCalc, setSelectedCalc] = useState("self");
  const [isFetchingInternalArchived, setIsFetchingInternalArchived] = useState(false);
  const [internalArchivedCalculations, setInternalArchivedCalculations] = useState([]);
  const [searchingArchived, setSearchingArchived] = useState(false);
  const [archiveOrderForm] = Form.useForm();
  const archivedInternalOrders = useSelector(state => state.orders.archivedInternalOrders)
  const isArchivingCalculation = useSelector(state => state.orders.isArchivingCalculation)
  const isFetchingProducersForOrder = useSelector(state => state.orders.isFetchingProducersForOrder)
  const [isArchiveModalVisible, _setIsArchiveModalVisible] = useState(false);
  const isArchiveModalVisibleRef = useRef(null);
  isArchiveModalVisibleRef.current = isArchiveModalVisible;
  const setIsArchiveModalVisible = (value) => {
    isArchiveModalVisibleRef.current = value;
    _setIsArchiveModalVisible(value);
  }
  const [allPricesModalVisible, _setAllPricesModalVisible] = useState(false);
  const allPricesModalVisibleRef = useRef(null);
  allPricesModalVisibleRef.current = allPricesModalVisible;
  const setAllPricesModalVisible = (value) => {
    allPricesModalVisibleRef.current = value;
    _setAllPricesModalVisible(value);
  }
  const [pricesModalItem, setPricesModalItem] = useState({});
  const form = createRef()

  //---------------------- Draft ---------------------
  const isProducer = useSelector(state => state.auth.user.role === "producerAdmin")
  
  const handleAnalysedPart = (data) => {
    dispatch(updatePartData(data))
    setProcessedParts(prevState => prevState + 1)
    if(data.data.state === "processed" && data.data.partType !== "assembly") {
      if(!data.data.assemblyId) {
        setSelectableItemsCount(prevState => prevState + 1)
      }
      if(isDraft && data.data.processingType === "manual") {
        setNumberOfManualParts(prevState => prevState + 1)
      }
    }
  }

  const handleUpdateOrderPartFilePath = (data) => {
    dispatch(updatePartFilePath(data))
  }
  const handleOrderPriceChange = (data) => {
    dispatch(changeOrderPrice(data))
  }
  const handleDeletePartsResponse = (data) => {
    removeAction(data.actionId);
  }
  const handleUpdatePartResponse = (data) => {
    removeAction(data.actionId);
    if (isDraft) {
      dispatch(changePartPrice(data))
    } else {
      dispatch(changePartData(data))
    }
  }
  const handleUpdateManyPartsResponse = (data) => {
    removeAction(data.actionId);
    if (isDraft) {
      dispatch(changeManyPartPrices(data))
    } else {
      dispatch(changeManyPartData(data))
    }
  }
  const handleUpdatePartToleranceResponse = (data) => {
    removeAction(data.actionId);
    dispatch(changePartToleranceData({...data, isDraft: isDraft}))
  }

  const handleUpdatePartRoughnessResponse = (data) => {
    removeAction(data.actionId);
    dispatch(changePartRoughnessData({...data, isDraft: isDraft}))
  }
  const handleUpdatePartMaterialCertificate = (data) => {
    removeAction(data.actionId)
    dispatch(changePartMaterialCertificateData(data))
  }

  const handleUpdatePartType = (data) => {
    dispatch(updatePartProcessingType(data))
    if(isDraft) {
      if(data.processingType === "manual") {
        if(prevPartProcessingTypeRef.current[data.orderPartId] !== "manual") {
          setNumberOfManualParts(prevState => prevState + 1)
        }
        setPrevPartProcessingType(prevState => {
          let tmp = {...prevState}
          delete tmp[data.orderPartId]
          return tmp
        })
      } else {
        if(prevPartProcessingTypeRef.current[data.orderPartId] === "manual") {
          setNumberOfManualParts(prevState => prevState - 1)
        }
        setPrevPartProcessingType(prevState => {
          let tmp = {...prevState}
          delete tmp[data.orderPartId]
          return tmp
        })
      }
    }
  }
  const handleSetAssemblyData = (data) => {
    setAnalysedAssemblyMap(prevState => ({...prevState, [data.assemblyData?.id]: data.assemblyData?.isAnalysed}))
    setAllItemsCount(prevState => prevState + data.parts.length)
    dispatch(setAssemblyPartData(data))
  }
  const handleUpdateAssemblyData = (data) => {
    dispatch(updateAssemblyPartData(data))
  }
  const handleUpdateAssemblyPrice = (data) => {
    if(data.isAnalysed === true) {
      setAnalysedAssemblyMap(prevState => ({...prevState, [data.assemblyId]: true}))
      setProcessedParts(prevState => prevState + 1)
    }
    dispatch(updateAssemblyPrice(data))
  }

  const prevAnalysedRef = useRef({});
  useEffect(()=>{
    for (const key in analysedAssemblyMap) {
      if(analysedAssemblyMap[key]) {
        if(prevAnalysedRef.current[key] === false) {
          let count = countAssemblyParts(key)
          setSelectableItemsCount(prevState => prevState + count)
        }
      }
    }
    prevAnalysedRef.current = analysedAssemblyMap
  }, [analysedAssemblyMap])

  const handleUpdateManyAssembliesPrice = (data) => {
    dispatch(updateManyAssembliesPrices(data))
  }
  const handleDeleteAssemblyResponse = (data) => {
    removeAction(data.actionId);
  }
  const handleChangeAssemblyQuantity = (data) => {
    removeAction(data.actionId);
    dispatch(updateAssemblyQuantity(data))
  }
  const handleUpdateAnalyzedPartStatus = (data) => {
    if (data.assemblyId) {
      setAssemblyProgressMap(prevState => {
        let tmp = {...prevState}
        if (tmp[data.assemblyId]) {
          tmp[data.assemblyId] += 1
        } else {
          tmp[data.assemblyId] = 1
        }
        return tmp
      })
    }
    dispatch(updatePartAnalysedStatus(data))
  }

  useEffect(()=>{
    if(selectedItemsCount > 0) {
      if(selectedItemsCount === selectableItemsCount) {
        setSelectedAllItems(true);
      } else {
        setSelectedAllItems(false);
      }
    } else {
      setSelectedAllItems(false);
    }
  }, [selectableItemsCount, selectedItemsCount])

  // useEffect(() => {
  //   if(orderId && socketConnected) {
  //     socket.emit("join-room", orderId);
  //   }
  // }, [socketConnected, orderId]);

  useEffect(() => {
    const accessToken = storage.retrieveAccessToken();
    socket = io(SOCKET_URL, {
      path: SOCKET_PATH,
      auth: {
        token: accessToken.substring(7)
      },
    });

    socket.on("connect", () => {
      setSocketConnected(true)
    });

    socket.on("put-one-order-part-price", (data) => {
      handleUpdatePartPriceResponse(data)
    })

    socket.on("analysed-order-part", (data) => {
      handleAnalysedPart(data)
    });

    socket.on("put-order-part-file-path", (data) => {
      handleUpdateOrderPartFilePath(data)
    });

    socket.on("changed-order-price", (data) => {
      handleOrderPriceChange(data)
    });

    socket.on("delete-order-part", (data) => {
      handleDeletePartsResponse(data)
    })

    socket.on("put-one-order-part", (data) => {
      handleUpdatePartResponse(data)
    })

    socket.on("put-many-order-parts", (data) => {
      handleUpdateManyPartsResponse(data)
    })

    socket.on("put-order-part-tolerance", (data) => {
      handleUpdatePartToleranceResponse(data)
    })

    socket.on("put-order-part-roughness", (data) => {
      handleUpdatePartRoughnessResponse(data)
    })

    socket.on("put-order-part-type", (data) => {
      handleUpdatePartType(data)
    })

    socket.on("put-order-part-assembly-data", (data) => {
      handleSetAssemblyData(data)
    })

    socket.on("put-order-part-base-assembly-part", (data) => {
      handleUpdateAssemblyData(data)
    })

    socket.on("put-order-assembly-price-change", (data) => {
      handleUpdateAssemblyPrice(data)
    })

    socket.on("put-order-many-assemblies-price-change", (data) => {
      handleUpdateManyAssembliesPrice(data)
    })

    socket.on("delete-order-assembly", (data) => {
      handleDeleteAssemblyResponse(data)
    })

    socket.on("put-is-part-analysed", (data) => {
      handleUpdateAnalyzedPartStatus(data)
    })

    socket.on('put-order-assembly-quantity-change', (data) => {
      handleChangeAssemblyQuantity(data)
    })

    socket.on('put-order-assembly-welding-price', (data) => {
      handleChangeAssemblyProductionPrice(data)
    })

    socket.on('put-order-assembly-assembling-price', (data) => {
      handleChangeAssemblyProductionPrice(data)
    })

    socket.on('put-order-assembly-packaging-price', (data) => {
      handleChangeAssemblyProductionPrice(data)
    })

    socket.on('put-order-assembly-transportation-price', (data) => {
      handleChangeAssemblyProductionPrice(data)
    })

    socket.on('put-order-part-cert-needed', (data) => {
      handleUpdatePartMaterialCertificate(data)
    })

    socket.on("connect_error", (err) => {
      if (err.message === "invalid credentials") {
        storage.refreshAccessToken().then(() => {
          const accessToken = storage.retrieveAccessToken();
          if(accessToken) {
            socket.auth.token = accessToken.substring(7);
            socket.connect();
          }
        })
      } else {
        setSocketConnected(false)
      }
    });

    socket.on("server-error", (err) => {
      if (err.msg === "invalid credentials") {
        dispatch(forceLogoutUser())
      }
      if (err.data?.actionId) {
        const actionId = err.data.actionId
        removeAction(actionId);
      }
      console.log(err.msg)
    });

    // Destroys the socket reference
    // when the connection is closed
    return () => {
      socket.disconnect();
    };
  }, []);

  const triggerPartProcessing = ({orderId, orderPartId}) => {
    if(socket) {
      socket.emit("trigger-order-part-processing", { orderId, orderPartId });
    }
  }
  const triggerRemoveOneOrMoreParts = (data) => {
    if(socket) {
      socket.emit("delete-order-part", { ...data });
    }
  }
  const triggerUpdatePart = (data) => {
    if(socket) {
      socket.emit("put-one-order-part", data);
    }
  }
  const triggerUpdateMultipleParts = (data) => {
    if(socket) {
      socket.emit("put-many-order-parts", data);
    }
  }
  const triggerUpdatePartTolerance = (data) => {
    if(socket) {
      socket.emit("put-order-part-tolerance", data);
    }
  }
  const triggerUpdatePartRoughness = (data) => {
    if(socket) {
      socket.emit("put-order-part-roughness", data);
    }
  }
  const triggerUpdateManualPartTolerance = (data) => {
    if(socket) {
      socket.emit("put-manual-order-part-tolerance", data);
    }
  }
  const triggerUpdateManualPartRoughness = (data) => {
    if(socket) {
      socket.emit("put-manual-order-part-roughness", data);
    }
  }
  const triggerPartNoteChange = (data) => {
    if(socket) {
      socket.emit("put-order-part-note", data);
    }
  }
  const triggerChangePartType = (data) => {
    if(socket) {
      socket.emit("put-order-part-type", data);
    }
  }
  const triggerPartStandardChange = (data) => {
    if (socket) {
      socket.emit("put-order-part-standard", data)
    }
  }
  const triggerDeleteAssembly = (data) => {
    if (socket) {
      socket.emit("delete-order-assembly", data)
    }
  }
  const triggerAssemblyQuantityChange = (data) => {
    if (socket) {
      socket.emit("put-order-assembly-quantity-change", data)
    }
  }
  const triggerNumberOfUniquePartsChange = (data) => {
    if (socket) {
      socket.emit("put-order-assembly-number-of-parts", data)
    }
  }
  const triggerUpdatePartMaterialCertificate = (data) => {
    if (socket) {
      socket.emit("put-order-part-cert-needed", data)
    }
  }

  useEffect(()=>{
    if(currentlyUploaded < toProcess || currentlyProcessed < toProcess) {
      setWorking(true)
      workingRef.current = true
    }
    else {
      if (workingRef.current) {
        // TODO fetch the order to confirm everything
      }
      setWorking(false)
      workingRef.current = false
    }
  }, [toProcess, currentlyUploaded])
  useEffect(()=>{
    if(socketConnected) {
      if(toAnalyze.length > 0) {
        analyzeParts([...toAnalyze])
        resetToAnalyze()
      }
    }
  }, [socketConnected, toAnalyze])
  const analyzeParts = (parts) => {
    for(let part of parts) {
      triggerPartProcessing({orderId: orderId, orderPartId: part.id})
    }
  }

  const [isOpenBrowse, _setIsOpenBrowse] = useState(false);
  const isOpenBrowseRef = useRef(null);
  isOpenBrowseRef.current = isOpenBrowse;
  const setIsOpenBrowse = (value) => {
    isOpenBrowseRef.current = value;
    _setIsOpenBrowse(value);
  }

  const handleFocus = useCallback(() => {
    if (isOpenBrowseRef.current) {
      setIsOpenBrowse(false);
    }
  }, []);
  useEffect(()=>{
    if (toProcess !== 0 && currentlyProcessed === toProcess) {
      dispatch(resetToProcess({}))
    }
  }, [currentlyProcessed, toProcess, dispatch])
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  const setLargeThreshold = (value) => {
    largeThresholdRef.current = value
    _setLargeThreshold(value)
  }
  const setSmallThreshold = (value) => {
    smallThresholdRef.current = value
    _setSmallThreshold(value)
  }

  const handleResize = () => {
    const width = window.innerWidth
    if(width < 1300) {
      if(!largeThresholdRef.current) {
        setLargeThreshold(true)
      }
    }
    else {
      if(largeThresholdRef.current) {
        setLargeThreshold(false)
      }
    }

    if(width < 750) {
      if(!smallThresholdRef.current) {
        setSmallThreshold(true)
      }
    }
    else {
      if(smallThresholdRef.current) {
        setSmallThreshold(false)
      }
    }

    setModalWidth(width-100);
    setModalHeight(window.innerHeight-135);
  };

  const changeSheetMetalMaterial = ({partId, materialName, partType, type, processingType, thickness, partMaterials}) => {
    let rez = sheetMetalMaterialMap[materialName];
    if (type === "step" && processingType === "automatic") {
      let thick = rez.thicknesses[parseFloat(thickness)]
      rez.selectedMaterial = {id: thick.id, thickness: thick.thickness}
    } else {
      if(type === "dxf" && processingType === "automatic") {
        rez.thickness = rez.thickness.filter(item => partMaterials?.[rez.groupName]?.[rez.grade]?.[item.id])
      }
      let thick = rez.thickness.find(t => parseFloat(t.thickness) === parseFloat(thickness))
      rez.selectedMaterial = {
        id: thick?.id || rez.thickness[0].id,
        thickness: thick?.id ? thick.thickness : rez.thickness[0].thickness
      }
    }

    if (rez?.selectedMaterial?.id) {
      const actionId = cuid()
      const data = {
        orderId: orderId,
        orderPartId: partId,
        partType: partType,
        actionId,
        fields: [
          {
            fieldName: "material",
            fieldValue: rez.selectedMaterial.id
          },
        ]
      }
      updateActions(actionId, true)
      triggerUpdatePart(data)
    }
    dispatch(changeItemMaterial({id: partId, material: rez}))
  }
  const changeMachiningMaterial = ({ id, partType, materialId }) => {
    const actionId = cuid()
    const data2 = {
      orderId: orderId,
      actionId,
      partType: partType,
      orderPartId: id,
      fields: [
        {
          fieldName: "material",
          fieldValue: materialId
        },
      ]
    }
    updateActions(actionId, true)
    triggerUpdatePart(data2)
    const grade = machiningMaterialMap[materialId]?.grade
    dispatch(changePartMachiningMaterial({id, materialId, grade}))
  }
  const changeTubeCuttingMaterial = ({ id, partType, material }) => {
    const actionId = cuid()
    const data = {
      orderId: orderId,
      actionId,
      partType: partType,
      orderPartId: id,
      fields: [
        {
          fieldName: "material",
          fieldValue: material
        },
      ]
    }
    updateActions(actionId, true)
    triggerUpdatePart(data)

    dispatch(changePartTubeCuttingMaterial({id, grade: material.grade}))
  }
  const changeMaterialCertificate = ({ id, certNeeded }) => {
    const actionId = cuid()
    const data = {
      orderId: orderId,
      actionId,
      orderPartId: id,
      certNeeded: certNeeded
    }
    updateActions(actionId, true)
    triggerUpdatePartMaterialCertificate(data)

    dispatch(changePartMaterialCertificate({ id, certNeeded }))
  }

  const changePartThickness = (data) => {
    const actionId = cuid()
    const data2 = {
      orderId: orderId,
      actionId,
      partType: data.partType,
      orderPartId: data.id,
      fields: [
        {
          fieldName: "material",
          fieldValue: data.thickness.id
        },
      ]
    }
    updateActions(actionId, true)
    triggerUpdatePart(data2)
    dispatch(changeItemThickness(data))
  }
  const changePartColor = (data) => {
    const actionId = cuid()

    let coating;
    if (data.coatingType === "painting") {
      coating = {
        hasCoating: true,
        type: data.coatingType,
        data: {
          colorRAL: data.colorRAL,
          colorGlossId: data.colorGlossId.toString(),
          ralRow: data.ralRow,
          ralHex: data.ralHex
        }
      }
    } else {
      coating = {
        hasCoating: true,
        type: data.coatingType
      }
    }

    const data2 = {
      orderId: orderId,
      actionId,
      partType: data.partType,
      orderPartId: data.id,
      fields: [{
        fieldName: "coating",
        fieldValue: {
          ...coating
        }
      }]
    }

    updateActions(actionId, true)
    triggerUpdatePart(data2)
    dispatch(changeItemColor(data))
  }

  const changePartTolerance = (data) => {
    const actionId = cuid()
    const toleranceData = {
      orderId: orderId,
      actionId,
      partType: data.partType,
      orderPartId: data.id,
      tolerance: data.tolerance,
      material: data.partType !== 'tubeCut' ? {materialId: data.materialId} : {
        grade: data.material.grade,
        thickness: data.material.thickness,
        x: data.material.x,
        y: data.material.y,
        tubeType: data.material.tubeType
      }
    }
    updateActions(actionId, true)
    triggerUpdatePartTolerance(toleranceData)
    dispatch(changeItemTolerance(data))
  }
  const changePartRoughness = (data) => {
    const actionId = cuid()
    const roughnessData = {
      orderId: orderId,
      actionId,
      partType: data.partType,
      orderPartId: data.id,
      roughness: data.roughness,
      materialId: data.materialId
    }
    updateActions(actionId, true)
    triggerUpdatePartRoughness(roughnessData)
    dispatch(changeItemRoughness(data))
  }
  const changeManualPartTolerance = (data) => {
    const toleranceData = {
      orderId: orderId,
      orderPartId: data.id,
      tolerance: data.tolerance
    }
    triggerUpdateManualPartTolerance(toleranceData)
    dispatch(changeManualItemTolerance(data))
  }
  const changeManualPartRoughness = (data) => {
    const roughnessData = {
      orderId: orderId,
      orderPartId: data.id,
      roughness: data.roughness
    }
    triggerUpdateManualPartRoughness(roughnessData)
    dispatch(changeManualItemRoughness(data))
  }

  const handleSheetMetalMaterialFilter = ({item, checked}) => {
    let a = null
    if(checked) {
      a = new Set(item.selectableGrades.sheetMetal)
      a = new Set([...a].filter(x => selectableSheetMetalMaterials.has(x)))
      setSelectedItemsSheetMetalMaterials((prev)=>({...prev, [item.id]: new Set(item.selectableGrades.sheetMetal)}))
    } else {
      const tmpSelectedItemsSheetMetalMaterials = {...selectedItemsSheetMetalMaterials}
      delete tmpSelectedItemsSheetMetalMaterials[item.id]
      for(const key in tmpSelectedItemsSheetMetalMaterials) {
        if (!a) {
          a = selectedItemsSheetMetalMaterials[key]
        } else {
          a = new Set([...a].filter(x => selectedItemsSheetMetalMaterials[key].has(x)))
        }
      }
      setSelectedItemsSheetMetalMaterials({...tmpSelectedItemsSheetMetalMaterials})
    }
    if(!a) {
      a = new Set([...companySelectableSheetMetalMaterials])
    }
    if(selectedParameters.sheetMetalMaterial) {
      if(!a.has(selectedParameters.sheetMetalMaterial.grade)) {
        setSelectedParameters((prev) => ({
          ...prev,
          sheetMetalMaterial: null,
          thickness: null
        }))
      } else {
        if(item.fileType === "dxf" && item.processingType === "automatic") {
          if (checked) {
            let newThicknesses = [...selectableThickness]
            let thicknesses = item.materials.sheetMetal[selectedParameters.sheetMetalMaterial.groupName][selectedParameters.sheetMetalMaterial.grade]
            newThicknesses = newThicknesses.filter(t => thicknesses[t])
            setSelectableThickness(new Set(newThicknesses))
            setSelectedParameters((prev) => ({
              ...prev,
              thickness: null
            }))
          } else {
            let thick = [...selectedParameters.sheetMetalMaterial.thickness]
            for (let part of items) {
              if(selectedItems[part.id] && part.fileType === "dxf" && part.processingType === "automatic" && part.id !== item.id) {
                let thicknesses = part.materials.sheetMetal[selectedParameters.sheetMetalMaterial.groupName][selectedParameters.sheetMetalMaterial.grade]
                thick = thick.filter(t => thicknesses[t.id])
              }
              setSelectableThickness(new Set(thick.map(item => item.id)))
            }
          }
        }
      }
    }
    setSelectableSheetMetalMaterials(a)
  }
  const handleMachiningMaterialFilter = ({item, partType, checked}) => {
    let a = null
    let selectableGrades = item.selectableGrades.milling
    if(partType === "lathed") {
      selectableGrades = item.selectableGrades.lathing
    }
    if(checked) {
      a = new Set(selectableGrades)
      a = new Set([...a].filter(x => selectableMachiningMaterials.has(x)))
      setSelectedItemsMachiningMaterials((prev)=>({...prev, [item.id]: new Set(selectableGrades)}))
    } else {
      const tmpSelectedItemsMachiningMaterials = {...selectedItemsMachiningMaterials}
      delete tmpSelectedItemsMachiningMaterials[item.id]
      for(const key in tmpSelectedItemsMachiningMaterials) {
        if (!a) {
          a = tmpSelectedItemsMachiningMaterials[key]
        } else {
          a = new Set([...a].filter(x => tmpSelectedItemsMachiningMaterials[key].has(x)))
        }
      }
      setSelectedItemsMachiningMaterials({...tmpSelectedItemsMachiningMaterials})
    }
    if(!a) {
      a = new Set([...companySelectableMachiningMaterials])
    }
    if(selectedParameters.machiningMaterial) {
      if(!a.has(selectedParameters.machiningMaterial.grade)) {
        setSelectedParameters((prev) => ({
          ...prev,
          machiningMaterial: null
        }))
      }
    }
    setSelectableMachiningMaterials(a)
  }
  const handleMachiningMaterialFilterMilledLathedChange = ({item, newType}) => {
    let a = null
    let gradesToAdd = item.selectableGrades.lathing
    if(newType === "milled") {
      gradesToAdd = item.selectableGrades.milling
    }
    const tmpSelectedItemsMachiningMaterials = {...selectedItemsMachiningMaterials}
    tmpSelectedItemsMachiningMaterials[item.id] = new Set(gradesToAdd)
    for(const key in tmpSelectedItemsMachiningMaterials) {
      if (!a) {
        a = tmpSelectedItemsMachiningMaterials[key]
      } else {
        a = new Set([...a].filter(x => tmpSelectedItemsMachiningMaterials[key].has(x)))
      }
    }
    setSelectedItemsMachiningMaterials({...tmpSelectedItemsMachiningMaterials})

    if(!a) {
      a = new Set([...companySelectableMachiningMaterials])
    }
    if(selectedParameters.machiningMaterial) {
      if(!a.has(selectedParameters.machiningMaterial.grade)) {
        setSelectedParameters((prev) => ({
          ...prev,
          machiningMaterial: null
        }))
      }
    }
    setSelectableMachiningMaterials(a)
  }

  const debouncedQuantityChange = useCallback(
    debounce(data => handleQuantityChange(data), 800),
    []
  );

  const debouncedNoteChange = useCallback(
    debounce(data => handleNoteChange(data), 800),
    []
  );

  const debouncedStandardChange = useCallback(
    debounce(data => handleStandardChange(data), 800),
    []
  );

  const debouncedAssemblyQuantityChange = useCallback(
    debounce(data => handleAssemblyQuantityChange(data), 800),
    []
  );

  const debouncedNumberOfUniquePartsChange = useCallback(
    debounce(data => handleNumberOfUniquePartsChange(data), 800),
    []
  );

  const changePartQuantity = (data) => {
    debouncedQuantityChange(data)
  }
  const changePartNote = (data) => {
    debouncedNoteChange(data)
  }
  const changePartStandard = (data) => {
    debouncedStandardChange(data)
  }
  const changeAssemblyQuantity = (data) => {
    debouncedAssemblyQuantityChange(data)
  }
  const changePartNumberOfUniqueParts = (data) => {
    debouncedNumberOfUniquePartsChange(data)
  }

  // Assembly utility

  const getAssemblyParts = (assemblyId) => {
    return items.filter(item => item.partType !== "assembly" && item.assemblyId === assemblyId);
  }
  const countAssemblyParts = (assemblyId) => {
    return items.filter(item => item.partType !== "assembly" && item.assemblyId === assemblyId).length
  }
  // const checkIfAssemblyWillBeDeleted = () => {
  //   let assemblyCount = 0;
  //   for (let assemblyId of Object.keys(partiallySelectedAssemblies)) {
  //     if (partiallySelectedAssemblies[assemblyId].selected === partiallySelectedAssemblies[assemblyId].total) {
  //       assemblyCount += 1;
  //     }
  //   }
  //   return assemblyCount > 0
  // }

  // Handlers

  const handleRemoveMultipleItems = () => {
    const actionId = cuid()
    updateActions(actionId, true)
    const data = {
      actionId,
      orderId: orderId,
      partIds: []
    }

    dispatch(removeMultipleItems({selected: {...selectedItems}}))
    let assemblyCount = 0;
    for (let assemblyId of Object.keys(partiallySelectedAssemblies)) {
      if (partiallySelectedAssemblies[assemblyId].selected === partiallySelectedAssemblies[assemblyId].total) {
        assemblyCount += 1;
        dispatch(deleteEntireAssembly({orderId: orderId, assemblyId: assemblyId}))
        setPartiallySelectedAssemblies( prevState => {
          const copy = {...prevState};
          delete copy[assemblyId];
          return copy;
        })
      } else {
        setPartiallySelectedAssemblies(prevState => ({...prevState, [assemblyId]: {selected: 0, total: prevState[assemblyId].total - prevState[assemblyId].selected}}))
      }
    }

    let count = 0;
    let map = {...selectedItems}
    for(let key of Object.keys(selectedItems)) {
      if(selectedItems[key]) {
        data.partIds.push(key)
        count += 1;
        delete map[key]
      }
    }

    setAllItemsCount(prevState => prevState - count - assemblyCount)
    setSelectableItemsCount(prevState => prevState - count)

    let manualCount = items.filter(item => item.processingType === "manual" && selectedItems[item.id]).length
    setNumberOfManualParts(prevState => prevState - manualCount)

    triggerRemoveOneOrMoreParts(data)
    setSelectedItems({...map})
    setSelectedItemsCount(0)


    setSelectedPartsMapByType({
      sheetMetal: {},
      machined: {},
      tubeProfile: {},
    })
    setSelectedSheetMetalPartsCount(0)
    setSelectedMillingPartsCount(0)
    setSelectedLathingPartsCount(0)
    setSelectedAutomaticTubeCuttingPartsCount(0)
    setSelectedManualTubeCuttingPartsCount(0)
    setSelectedProfilePartsCount(0)
    setSelectedOtherPartsCount(0)

    setSelectedAllItems(false)
    setSelectedItemsSheetMetalMaterials({})
    setSelectableSheetMetalMaterials(new Set([...companySelectableSheetMetalMaterials]))
    setSelectedItemsMachiningMaterials({})
    setSelectableMachiningMaterials(new Set([...companySelectableMachiningMaterials]))
    setSelectableThickness(new Set([]))
  }
  const handleMultiQuantityChange = (q) => {
    setSelectedParameters((prev) => ({
      ...prev,
      quantity: q
    }))
  }
  const handleMultiCertificateChange = (value) => {
    setSelectedParameters((prev) => ({
      ...prev,
      certNeeded: value
    }))
  }
  const handleMultiCoatingChange = (item) => {
    let coating;
    if (item.coatingType === "painting") {
      coating = {
        hasCoating: true,
        type: item.coatingType,
        data: {
          colorRAL: item.colorRAL,
          colorGlossId: item.colorGlossId.toString(),
          ralRow: item.ralRow,
          ralHex: item.ralHex
        }
      }
    } else {
      coating = {
        hasCoating: true,
        type: item.coatingType
      }
    }

    setSelectedParameters((prev) => ({
      ...prev,
      coating: {
        ...coating
      }
    }))
  }
  const handleMultiSheetMetalMaterialChange = (materialName) => {
    let rez = sheetMetalMaterialMap[materialName];
    if (rez) {
      let a = [...rez.thickness]
      for (let part of items) {
        if (selectedItems[part.id] && part.fileType === "dxf" && part.processingType === "automatic") {
          let thicknesses = part.materials.sheetMetal[rez.groupName][rez.grade]
          a = a.filter(t => thicknesses[t.id])
        }
      }
      let b = [...a]
      setSelectableThickness(new Set(b.map(item => item.id)))
      let oldThickness = selectedParameters.thickness
      setSelectedParameters((prev) => ({
        ...prev,
        sheetMetalMaterial: rez
      }))
      if (!oldThickness || !rez.thickness.some(e => e.value === oldThickness)) {
        setSelectedParameters((prev) => ({
          ...prev,
          thickness: null
        }))
      }
    }
  }
  const handleMultiThicknessChange = (thickness) => {
    setSelectedParameters((prev) => ({
      ...prev,
      thickness: thickness
    }))
  }
  const handleMultiMachiningMaterialChange = (materialId) => {
    if(materialId) {
      setSelectedParameters((prev) => ({
        ...prev,
        machiningMaterial: machiningMaterialMap[materialId]
      }))
    }
  }
  const handleMultiStandardChange = (standard) => {
    if(standard?.length > 0) {
      let standardString = standard.join(" / ");
      setSelectedParameters((prev) => ({
        ...prev,
        standard: standardString
      }))
    }
  }
  const handleMultiSheetMetalMaterialClear = () => {
    setSelectedParameters((prev) => ({
      ...prev,
      sheetMetalMaterial: null,
      thickness: null
    }))
  }
  const handleMultiCoatingClear = () => {
    setSelectedParameters((prev) => ({
      ...prev,
      coating: {
        hasCoating: false,
        type: "painting",
        data: {
          colorRAL: "",
          colorGlossId: "",
          ralRow: 0,
          ralHex: "",
        }
      }
    }))
  }
  const handleMultiThicknessClear = () => {
    setSelectedParameters((prev) => ({
      ...prev,
      thickness: null
    }))
  }
  const handleMultiMachiningMaterialClear = () => {
    setSelectedParameters((prev) => ({
      ...prev,
      machiningMaterial: null
    }))
  }
  const handleMultiStandardClear = () => {
    setSelectedParameters((prev) => ({
      ...prev,
      standard: null
    }))
  }

  const handleMultiApply = () => {
    const applyingChanges = {}
    const actionId = cuid()
    const dataToSend = {
      orderId,
      actionId,
      standard: null,
      quantity: null,
      certNeeded: null,
      surfaceCoating: null,
      machiningMaterial: null,
      parts: []
    }
    const { quantity, certNeeded, coating, sheetMetalMaterial, thickness, machiningMaterial, standard } = selectedParameters;
    if (quantity || (certNeeded !== null) || coating?.hasCoating  || sheetMetalMaterial || machiningMaterial || standard) {
      if(quantity) {
        dataToSend.quantity = quantity;
      }
      if(certNeeded !== null) {
        dataToSend.certNeeded = certNeeded;
      }
      if(coating?.hasCoating) {
        dataToSend.surfaceCoating = coating;
      }
      if(standard) {
        dataToSend.standard = standard;
      }
      if(machiningMaterial) {
        dataToSend.machiningMaterial = machiningMaterial.id;
      }
      for (let item of items) {
        if (selectedItems[item.id]) {
          if(item.partType === "sheetMetal" && (quantity || (certNeeded !== null) || coating?.hasCoating || sheetMetalMaterial)) {
            let newMaterial = {
              grade: null,
              materialId: null,
              thickness: null,
              availableThicknesses: []
            };
            if (sheetMetalMaterial) {
              if (item.fileType === 'step' && item.processingType === "automatic") {
                let rez = sheetMetalMaterial.thickness.find(thick => parseFloat(thick.thickness) === parseFloat(item.selectedMaterial.thickness))
                newMaterial = {
                  grade: sheetMetalMaterial.grade,
                  materialId: rez.id,
                  thickness: rez.thickness,
                  availableThicknesses: [{id: rez.id, thickness: rez.thickness}]
                }
              } else {
                let availableThicknesses = getAvailableThicknesses(item.fileType, item.processingType, sheetMetalMaterials, sheetMetalMaterial, item.materials.sheetMetal, sheetMetalMaterialMap)
                if (thickness) {
                  let rez = sheetMetalMaterial.thickness.find(thick => parseFloat(thick.thickness) === parseFloat(thickness))
                  newMaterial = {
                    grade: sheetMetalMaterial.grade,
                    materialId: rez.id,
                    thickness: rez.thickness,
                    availableThicknesses: availableThicknesses
                  }
                } else {
                  let rez = availableThicknesses.find(thick => parseFloat(thick.thickness) === parseFloat(item.selectedMaterial.thickness))
                  newMaterial = {
                    grade: sheetMetalMaterial.grade,
                    materialId: rez ? rez.id : availableThicknesses[0].id,
                    thickness: rez ? item.thickness : availableThicknesses[0].thickness,
                    availableThicknesses: availableThicknesses
                  }
                }
              }
            }

            dataToSend.parts.push({
              orderPartId: item.id,
              partType: item.partType,
              materialId: newMaterial.materialId
            })

            applyingChanges[item.id] = { material: (newMaterial.materialId ? newMaterial : null), quantity, certNeeded, coating: (coating?.hasCoating ? coating : null) }
          }
          else if((item.partType === "milled" || item.partType === "lathed") && (quantity || (certNeeded !== null) || coating?.hasCoating  || machiningMaterial)) {
            dataToSend.parts.push({
              orderPartId: item.id,
              partType: item.partType,
              materialId: null
            })
            applyingChanges[item.id] = { material: machiningMaterial, quantity, certNeeded, coating: (coating?.hasCoating ? coating : null) }
          }
          else if(item.processingType === "automatic" && item.partType === "tubeCut" && (quantity || (certNeeded !== null) || coating?.hasCoating)) {
            dataToSend.parts.push({
              orderPartId: item.id,
              partType: item.partType,
              materialId: null
            })
            applyingChanges[item.id] = { quantity, certNeeded, coating: (coating?.hasCoating ? coating : null) }
          }
          else if(item.processingType === "manual" && (item.partType === "tubeCut" || item.partType === "profile") && (quantity || (certNeeded !== null) || coating?.hasCoating || standard)) {
            dataToSend.parts.push({
              orderPartId: item.id,
              partType: item.partType,
              materialId: null
            })
            applyingChanges[item.id] = { quantity, certNeeded, standard, coating: (coating?.hasCoating ? coating : null) }
          } else {
            if(quantity) {
              dataToSend.parts.push({
                orderPartId: item.id,
                partType: item.partType,
                materialId: null
              })
              applyingChanges[item.id] = { quantity }
            }
          }
        }
      }
      updateActions(actionId, true)
      triggerUpdateMultipleParts(dataToSend)
      dispatch(applyMultipleItems({applyingChanges}))
    }
  }
  const handleMultiClear = () => {
    setSelectedParameters({
      sheetMetalMaterial: null,
      machiningMaterial: null,
      standard: null,
      thickness: null,
      quantity: null,
      certNeeded: null,
      coating: {
        hasCoating: false,
        type: "painting",
        data: {
          colorRAL: "",
          colorGlossId: "",
          ralRow: 0,
          ralHex: "",
        }
      }
    })
    setMultiEditFields([{id: cuid(), selected: null}])
    setCurrentActiveMultiEditFields({
      sheetMetalMaterial: false,
      machiningMaterial: false,
      standard: false,
      thickness: false,
      quantity: false,
      certificate: false,
      coating: false
    })
  }

  const handleSelectAll = (checked) => {
    if(checked) {
      let map = {}
      let partsMapByType = {
        sheetMetal: {},
        machined: {},
        tubeProfile: {}
      }
      let selectableSheetMetal = {}
      let sheetMetal = null
      let selectableMachined = {}
      let machined = null
      let sheetMetalCount = 0;
      let milledCont = 0;
      let lathedCount = 0;
      let automaticTubeCutCount = 0;
      let manualTubeCutCount = 0;
      let profileCount = 0;
      let otherCount = 0;
      for (let item of items) {
        if (item.partType === "assembly" && item.processingType !== "manual" && item.isAnalysed) {
          const totalAssemblyParts = getAssemblyParts(item.id).length
          partiallySelectedAssemblies[item.id] = {"selected": totalAssemblyParts, "total": totalAssemblyParts}
        } else {
          if (item.state === "processed") {
            if(item.assemblyId && !analysedAssemblyMap[item.assemblyId]) {
              continue;
            }
            if(item.partType === "sheetMetal") {
              sheetMetalCount++;
              partsMapByType.sheetMetal[item.id] = item.fileName
              selectableSheetMetal[item.id] = new Set([...item.selectableGrades.sheetMetal])
              if (!sheetMetal) {
                sheetMetal = selectableSheetMetal[item.id]
              } else {
                sheetMetal = new Set([...sheetMetal].filter(x => selectableSheetMetal[item.id].has(x)))
              }
            } else if (item.partType === "milled") {
              milledCont++;
              partsMapByType.machined[item.id] = item.fileName
              selectableMachined[item.id] = new Set([...item.selectableGrades.milling])
              if (!machined) {
                machined = selectableMachined[item.id]
              } else {
                machined = new Set([...machined].filter(x => selectableMachined[item.id].has(x)))
              }
            } else if (item.partType === "lathed") {
              lathedCount++;
              partsMapByType.machined[item.id] = item.fileName
              selectableMachined[item.id] = new Set([...item.selectableGrades.lathing])
              if (!machined) {
                machined = selectableMachined[item.id]
              } else {
                machined = new Set([...machined].filter(x => selectableMachined[item.id].has(x)))
              }
            } else if (item.partType === "tubeCut") {
              if (item.processingType === "automatic") {
                automaticTubeCutCount++;
              } else {
                manualTubeCutCount++;
                partsMapByType.tubeProfile[item.id] = item.fileName
              }
            } else if (item.partType === "profile") {
              profileCount++;
              partsMapByType.tubeProfile[item.id] = item.fileName
            } else {
              otherCount++;
            }

            map[item.id] = true
          }
        }
      }
      setSelectedItems({...map})
      setSelectedPartsMapByType({...partsMapByType})
      setSelectedItemsCount(selectableItemsCount)

      setSelectedSheetMetalPartsCount(sheetMetalCount)
      setSelectedMillingPartsCount(milledCont)
      setSelectedLathingPartsCount(lathedCount)
      setSelectedAutomaticTubeCuttingPartsCount(automaticTubeCutCount)
      setSelectedManualTubeCuttingPartsCount(manualTubeCutCount)
      setSelectedProfilePartsCount(profileCount)
      setSelectedOtherPartsCount(otherCount)

      setSelectedItemsSheetMetalMaterials({...selectableSheetMetal})
      setSelectedItemsMachiningMaterials({...selectableMachined})

      for(const key in selectableSheetMetal) {
        if (!sheetMetal) {
          sheetMetal = selectableSheetMetal[key]
        } else {
          sheetMetal = new Set([...sheetMetal].filter(x => selectableSheetMetal[key].has(x)))
        }
      }
      for(const key in selectableMachined) {
        if (!machined) {
          machined = selectableMachined[key]
        } else {
          machined = new Set([...machined].filter(x => selectableMachined[key].has(x)))
        }
      }
      if(!sheetMetal) {
        if(sheetMetalCount > 0) {
          sheetMetal = new Set([])
        } else {
          sheetMetal = new Set([...companySelectableSheetMetalMaterials])
        }
      }
      if(!machined) {
        if((milledCont + lathedCount) > 0) {
          machined = new Set([])
        } else {
          machined = new Set([...companySelectableMachiningMaterials])
        }
      }
      if(selectedParameters.sheetMetalMaterial) {
        if(!sheetMetal.has(selectedParameters.sheetMetalMaterial.grade)) {
          setSelectedParameters((prev) => ({
            ...prev,
            sheetMetalMaterial: null,
            thickness: null
          }))
        } else {
          let thick = [...selectedParameters.sheetMetalMaterial.thickness]
          for (let part of items) {
            if(map[part.id] && part.fileType === "dxf" && part.processingType === "automatic") {
              let thicknesses = part.materials.sheetMetal[selectedParameters.sheetMetalMaterial.groupName][selectedParameters.sheetMetalMaterial.grade]
              thick = thick.filter(t => thicknesses[t.id])
            }
          }
          setSelectableThickness(new Set(thick.map(item => item.id)))
          setSelectedParameters((prev) => ({
            ...prev,
            thickness: null
          }))
        }
      }
      if(selectedParameters.machiningMaterial) {
        if(!machined.has(selectedParameters.machiningMaterial.grade)) {
          setSelectedParameters((prev) => ({
            ...prev,
            machiningMaterial: null
          }))
        }
      }
      setSelectableSheetMetalMaterials(sheetMetal)
      setSelectableMachiningMaterials(machined)
    }
    else {
      for (let assemblyId of Object.keys(partiallySelectedAssemblies)) {
        partiallySelectedAssemblies[assemblyId] = {"selected": 0, "total": getAssemblyParts(assemblyId).length}
      }
      setSelectedItems({})
      setSelectedPartsMapByType({
        sheetMetal: {},
        machined: {},
        tubeProfile: {}
      })
      setSelectedItemsCount(0)

      setSelectedSheetMetalPartsCount(0)
      setSelectedMillingPartsCount(0)
      setSelectedLathingPartsCount(0)
      setSelectedAutomaticTubeCuttingPartsCount(0)
      setSelectedManualTubeCuttingPartsCount(0)
      setSelectedProfilePartsCount(0)
      setSelectedOtherPartsCount(0)

      setSelectedItemsSheetMetalMaterials({})
      setSelectableSheetMetalMaterials(new Set([...companySelectableSheetMetalMaterials]))
      setSelectedItemsMachiningMaterials({})
      setSelectableMachiningMaterials(new Set([...companySelectableMachiningMaterials]))
      if(selectedParameters.sheetMetalMaterial) {
        setSelectableThickness(new Set([...(selectedParameters.sheetMetalMaterial.thickness.map(item => item.id))]))
      } else {
        setSelectableThickness(new Set([]))
      }
    }
  }
  const handleSelectAssembly = ({ assembly, checked }) => {
    const assemblyId = assembly.id;
    const assemblyParts = getAssemblyParts(assemblyId);
    const assemblyPartsLen = assemblyParts.length
    const selectedAssemblyParts = {}
    let selectedAssemblyPartsMapByType = {
      sheetMetal: {},
      machined: {},
      tubeProfile: {}
    }
    let numOfSelectedAssemblyItems;
    let tmpSelectableSheetMetalMaterials = new Set([...selectableSheetMetalMaterials])
    let tmpSelectedItemsSheetMetalMaterials = {...selectedItemsSheetMetalMaterials}
    let tmpSelectableMachiningMaterials = new Set([...selectableMachiningMaterials])
    let tmpSelectedItemsMachiningMaterials = {...selectedItemsMachiningMaterials}

    let sheetMetalCount = 0;
    let milledCont = 0;
    let lathedCount = 0;
    let automaticTubeCutCount = 0;
    let manualTubeCutCount = 0;
    let profileCount = 0;
    let otherCount = 0;

    if (checked) {
      numOfSelectedAssemblyItems = assemblyPartsLen
      for (let i = 0; i < assemblyPartsLen; i++) {
        let item = assemblyParts[i]
        if(item.partType === "sheetMetal") {
          tmpSelectableSheetMetalMaterials = new Set([...item.selectableGrades.sheetMetal].filter(x => tmpSelectableSheetMetalMaterials.has(x)))
          tmpSelectedItemsSheetMetalMaterials = { ...tmpSelectedItemsSheetMetalMaterials, [item.id]: new Set(item.selectableGrades.sheetMetal) }
        }
        if(item.partType === "milled") {
          tmpSelectableMachiningMaterials = new Set([...item.selectableGrades.milling].filter(x => tmpSelectableMachiningMaterials.has(x)))
          tmpSelectedItemsMachiningMaterials = { ...tmpSelectedItemsMachiningMaterials, [item.id]: new Set(item.selectableGrades.milling) }
        }
        if(item.partType === "lathed") {
          tmpSelectableMachiningMaterials = new Set([...item.selectableGrades.lathing].filter(x => tmpSelectableMachiningMaterials.has(x)))
          tmpSelectedItemsMachiningMaterials = { ...tmpSelectedItemsMachiningMaterials, [item.id]: new Set(item.selectableGrades.lathing) }
        }
        selectedAssemblyParts[item.id] = true
        if(!selectedItems[item.id]) {
          if(item.partType === "sheetMetal") {
            sheetMetalCount++;
            selectedAssemblyPartsMapByType.sheetMetal[item.id] = item.fileName
          } else if (item.partType === "milled") {
            milledCont++;
            selectedAssemblyPartsMapByType.machined[item.id] = item.fileName
          } else if (item.partType === "lathed") {
            lathedCount++;
            selectedAssemblyPartsMapByType.machined[item.id] = item.fileName
          } else if (item.partType === "tubeCut") {
            if (item.processingType === "automatic") {
              automaticTubeCutCount++;
            } else {
              manualTubeCutCount++;
              selectedAssemblyPartsMapByType.tubeProfile[item.id] = item.fileName
            }
          } else if (item.partType === "profile") {
            profileCount++;
            selectedAssemblyPartsMapByType.tubeProfile[item.id] = item.fileName
          } else {
            otherCount++;
          }
        }
      }
      setSelectedItems(prevState => ({...prevState, ...selectedAssemblyParts}))
      setSelectedPartsMapByType(prevState => ({
        sheetMetal: {...prevState.sheetMetal, ...selectedAssemblyPartsMapByType.sheetMetal},
        machined: {...prevState.machined, ...selectedAssemblyPartsMapByType.machined},
        tubeProfile: {...prevState.tubeProfile, ...selectedAssemblyPartsMapByType.tubeProfile}
      }))
      if (partiallySelectedAssemblies[assemblyId]) {
        setSelectedItemsCount(prevState => prevState + (assemblyPartsLen - partiallySelectedAssemblies[assemblyId]["selected"]));
      } else {
        setSelectedItemsCount(prevState => prevState + assemblyPartsLen);
      }

      setSelectedSheetMetalPartsCount(prevState => prevState + sheetMetalCount)
      setSelectedMillingPartsCount(prevState => prevState + milledCont)
      setSelectedLathingPartsCount(prevState => prevState + lathedCount)
      setSelectedAutomaticTubeCuttingPartsCount(prevState => prevState + automaticTubeCutCount)
      setSelectedManualTubeCuttingPartsCount(prevState => prevState + manualTubeCutCount)
      setSelectedProfilePartsCount(prevState => prevState + profileCount)
      setSelectedOtherPartsCount(prevState => prevState + otherCount)
    } else {
      numOfSelectedAssemblyItems = 0
      selectedAssemblyPartsMapByType = {...selectedPartsMapByType}
      for (let i = 0; i < assemblyPartsLen; i++) {
        let item = assemblyParts[i]
        if(tmpSelectedItemsSheetMetalMaterials[item.id]) {
          delete tmpSelectedItemsSheetMetalMaterials[item.id]
        }
        if(tmpSelectedItemsMachiningMaterials[item.id]) {
          delete tmpSelectedItemsMachiningMaterials[item.id]
        }
        selectedAssemblyParts[item.id] = false

        if(item.partType === "sheetMetal") {
          sheetMetalCount++;
          if(selectedAssemblyPartsMapByType.sheetMetal[item.id]) {
            delete selectedAssemblyPartsMapByType.sheetMetal[item.id]
          }
        } else if (item.partType === "milled") {
          milledCont++;
          if(selectedAssemblyPartsMapByType.machined[item.id]) {
            delete selectedAssemblyPartsMapByType.machined[item.id]
          }
        } else if (item.partType === "lathed") {
          lathedCount++;
          if(selectedAssemblyPartsMapByType.machined[item.id]) {
            delete selectedAssemblyPartsMapByType.machined[item.id]
          }
        } else if (item.partType === "tubeCut") {
          if (item.processingType === "automatic") {
            automaticTubeCutCount++;
          } else {
            manualTubeCutCount++;
          }
          if(selectedAssemblyPartsMapByType.tubeProfile[item.id]) {
            delete selectedAssemblyPartsMapByType.tubeProfile[item.id]
          }
        } else if (item.partType === "profile") {
          profileCount++;
          if(selectedAssemblyPartsMapByType.tubeProfile[item.id]) {
            delete selectedAssemblyPartsMapByType.tubeProfile[item.id]
          }
        } else {
          otherCount++;
        }
      }

      let sheetMetal = null
      for(const key in tmpSelectedItemsSheetMetalMaterials) {
        if (!sheetMetal) {
          sheetMetal = tmpSelectedItemsSheetMetalMaterials[key]
        } else {
          sheetMetal = new Set([...sheetMetal].filter(x => tmpSelectedItemsSheetMetalMaterials[key].has(x)))
        }
      }
      tmpSelectableSheetMetalMaterials = sheetMetal

      let machining = null
      for(const key in tmpSelectedItemsMachiningMaterials) {
        if (!machining) {
          machining = tmpSelectedItemsMachiningMaterials[key]
        } else {
          machining = new Set([...machining].filter(x => tmpSelectedItemsMachiningMaterials[key].has(x)))
        }
      }
      tmpSelectableMachiningMaterials = machining

      setSelectedItems(prevState => ({...prevState, ...selectedAssemblyParts}))
      setSelectedPartsMapByType({
        sheetMetal: selectedAssemblyPartsMapByType.sheetMetal,
        machined: selectedAssemblyPartsMapByType.machined,
        tubeProfile: selectedAssemblyPartsMapByType.tubeProfile
      })
      setSelectedItemsCount(prevState => prevState - assemblyPartsLen);

      setSelectedSheetMetalPartsCount(prevState => prevState - sheetMetalCount)
      setSelectedMillingPartsCount(prevState => prevState - milledCont)
      setSelectedLathingPartsCount(prevState => prevState - lathedCount)
      setSelectedAutomaticTubeCuttingPartsCount(prevState => prevState - automaticTubeCutCount)
      setSelectedManualTubeCuttingPartsCount(prevState => prevState - manualTubeCutCount)
      setSelectedProfilePartsCount(prevState => prevState - profileCount)
      setSelectedOtherPartsCount(prevState => prevState - otherCount)
    }
    if(!tmpSelectableSheetMetalMaterials) {
      tmpSelectableSheetMetalMaterials = new Set([...companySelectableSheetMetalMaterials])
    }
    if(selectedParameters.sheetMetalMaterial) {
      if(!tmpSelectableSheetMetalMaterials.has(selectedParameters.sheetMetalMaterial.grade)) {
        setSelectedParameters((prev) => ({
          ...prev,
          sheetMetalMaterial: null,
          thickness: null
        }))
      }
    }

    if(!tmpSelectableMachiningMaterials) {
      tmpSelectableMachiningMaterials = new Set([...companySelectableMachiningMaterials])
    }
    if(selectedParameters.machiningMaterial) {
      if(!tmpSelectableMachiningMaterials.has(selectedParameters.machiningMaterial.grade)) {
        setSelectedParameters((prev) => ({
          ...prev,
          machiningMaterial: null
        }))
      }
    }

    setSelectedItemsSheetMetalMaterials({...tmpSelectedItemsSheetMetalMaterials})
    setSelectedItemsMachiningMaterials({...tmpSelectedItemsMachiningMaterials})
    if(!tmpSelectableSheetMetalMaterials) {
      tmpSelectableSheetMetalMaterials = new Set([])
    }
    setSelectableSheetMetalMaterials(tmpSelectableSheetMetalMaterials)
    if(!tmpSelectableMachiningMaterials) {
      tmpSelectableMachiningMaterials = new Set([])
    }
    setSelectableMachiningMaterials(tmpSelectableMachiningMaterials)
    setPartiallySelectedAssemblies(prevState => ({...prevState, [assemblyId]: {"selected": numOfSelectedAssemblyItems, "total": assemblyPartsLen}}))
  }
  const handlePartiallySelectAssembly = ({ assemblyId, checked }) => {
    if (!partiallySelectedAssemblies[assemblyId]) {
      if (checked) {
        setPartiallySelectedAssemblies(prevState => ({...prevState, [assemblyId]: {selected: 1, total: getAssemblyParts(assemblyId).length}}))
      }
    } else {
      setPartiallySelectedAssemblies(prevState => ({...prevState, [assemblyId]: {...prevState[assemblyId], selected: prevState[assemblyId].selected + (checked ? 1 : -1)}}))
    }
  }

  const addMultiEditField = (index) => {
    setMultiEditFields(prev => {
      let copy = [...prev]
      copy.splice(index + 1, 0, {id: cuid(), selected: null})
      return copy
    })
  }
  const adjustSelectedParameters =  (type) => {
    switch (type) {
      case "quantity":
        setSelectedParameters(prev => ({
          ...prev,
          quantity: null
        }))
        break
      case "certificate":
        setSelectedParameters(prev => ({
          ...prev,
          certNeeded: null
        }))
        break
      case "coating":
        setSelectedParameters(prev => ({
          ...prev,
          coating: {
            hasCoating: false,
            type: "painting",
            data: {
              colorRAL: "",
              colorGlossId: "",
              ralRow: 0,
              ralHex: "",
            }
          }
        }))
        break
      case "sheetMetalMaterial":
        setSelectedParameters(prev => ({
          ...prev,
          sheetMetalMaterial: null,
          thickness: null
        }))
        break
      case "thickness":
        setSelectedParameters(prev => ({
          ...prev,
          thickness: null
        }))
        break
      case "machiningMaterial":
        setSelectedParameters(prev => ({
          ...prev,
          machiningMaterial: null
        }))
        break
      case "standard":
        setSelectedParameters(prev => ({
          ...prev,
          standard: null
        }))
        break
    }
  }
  const removeMultiEditField = ({ id, selected }) => {
    setMultiEditFields(prev => {
      let tmp = prev.filter((item) => item.id !== id)
      if(tmp.length === 0) {
        tmp = [{id: cuid(), selected: null}]
      }
      return tmp
    })
    setCurrentActiveMultiEditFields(prev => {
      let copy = {...prev}
      copy[selected] = false
      return copy
    })
    adjustSelectedParameters(selected)
  }
  const setMultiEditField = ({ index, prevSelected, selected }) => {
    setMultiEditFields(prev => {
      let copy = [...prev]
      copy[index].selected = selected
      return copy
    })
    setCurrentActiveMultiEditFields(prev => {
      let copy = {...prev}
      if(prevSelected) {
        copy[prevSelected] = false
      }
      copy[selected] = true
      return copy
    })
    if(selected === 'certificate') {
      setSelectedParameters(prev => ({
        ...prev,
        certNeeded: true
      }))
    }
    adjustSelectedParameters(prevSelected)
  }

  const handleQuantityChange = (data) => {
    if (data.id && !isNaN(parseInt(data.quantity))) {
      const actionId = cuid()
      const data2 = {
        orderId: data.orderId,
        actionId,
        partType: data.partType,
        orderPartId: data.id,
        fields: [
          {
            fieldName: "quantity",
            fieldValue: data.quantity
          },
        ]
      }
      updateActions(actionId, true)
      triggerUpdatePart(data2)
    }
    dispatch(changeItemQuantity(data))
  }
  const handleStandardChange = (data) => {
    const data2 = {
      orderId: data.orderId,
      orderPartId: data.id,
      standard: data.standard
    }
    triggerPartStandardChange(data2)
    dispatch(changeStandard({orderPartId: data.id, standard: data.standard}))
  }
  const handleNoteChange = (data) => {
    const data2 = {
      orderId: data.orderId,
      orderPartId: data.id,
      partType: data.partType === "assembly" ? "assembly" : "part",
      note: data.note
    }
    triggerPartNoteChange(data2)
    dispatch(changeItemNote(data))
  }
  const handleAssemblyQuantityChange = (data) => {
    if (data.id && !isNaN(parseInt(data.quantity))) {
      const actionId = cuid()
      const data2 = {
        orderId: data.orderId,
        actionId,
        assemblyId: data.id,
        quantity: data.quantity
      }
      updateActions(actionId, true)
      triggerAssemblyQuantityChange(data2)
    }
  }
  const handleNumberOfUniquePartsChange = (data) => {
    if (data.id && !isNaN(parseInt(data.numberOfUniqueParts))) {
      const data2 = {
        orderId: data.orderId,
        assemblyId: data.id,
        numberOfUniqueParts: data.numberOfUniqueParts
      }
      triggerNumberOfUniquePartsChange(data2)
      dispatch(changeNumberOfUniqueParts(data))
    }
  }
  const handleChangeItemType = ({ id, item, newType }) => {
    if(selectedItems[id]) {
      let selectedAssemblyPartsMapByType = {...selectedPartsMapByType}
      if(item.partType === "sheetMetal") {
        setSelectedSheetMetalPartsCount(prev => prev - 1)
        handleSheetMetalMaterialFilter({item, checked: false})
        if(selectedAssemblyPartsMapByType.sheetMetal[item.id]) {
          delete selectedAssemblyPartsMapByType.sheetMetal[item.id]
        }
      } else if (item.partType === "milled") {
        setSelectedMillingPartsCount(prev => prev - 1)
        if(newType !== "lathed") {
          handleMachiningMaterialFilter({item, partType: item.partType, checked: false})
        }
        if(selectedAssemblyPartsMapByType.machined[item.id]) {
          delete selectedAssemblyPartsMapByType.machined[item.id]
        }
      } else if (item.partType === "lathed") {
        setSelectedLathingPartsCount(prev => prev - 1)
        if(newType !== "milled") {
          handleMachiningMaterialFilter({item, partType: item.partType, checked: false})
        }
        if(selectedAssemblyPartsMapByType.machined[item.id]) {
          delete selectedAssemblyPartsMapByType.machined[item.id]
        }
      } else if (item.partType === "tubeCut") {
        if (item.processingType === "automatic") {
          setSelectedAutomaticTubeCuttingPartsCount(prevState => prevState - 1)
        } else {
          setSelectedManualTubeCuttingPartsCount(prevState => prevState - 1)
        }
        if(selectedAssemblyPartsMapByType.tubeProfile[item.id]) {
          delete selectedAssemblyPartsMapByType.tubeProfile[item.id]
        }
      } else if (item.partType === "profile") {
        setSelectedProfilePartsCount(prev => prev - 1)
        if(selectedAssemblyPartsMapByType.tubeProfile[item.id]) {
          delete selectedAssemblyPartsMapByType.tubeProfile[item.id]
        }
      } else {
        setSelectedOtherPartsCount(prev => prev - 1)
      }

      if(newType === "sheetMetal") {
        setSelectedSheetMetalPartsCount(prev => prev + 1)
        handleSheetMetalMaterialFilter({item, checked: true})
        selectedAssemblyPartsMapByType.sheetMetal[item.id] = item.fileName
      } else if (newType === "milled") {
        setSelectedMillingPartsCount(prev => prev + 1)
        if(item.partType !== "lathed") {
          handleMachiningMaterialFilter({item, partType: newType, checked: true})
        }
        selectedAssemblyPartsMapByType.machined[item.id] = item.fileName
      } else if (newType === "lathed") {
        setSelectedLathingPartsCount(prev => prev + 1)
        if(item.partType !== "milled") {
          handleMachiningMaterialFilter({item, partType: newType, checked: true})
        }
        selectedAssemblyPartsMapByType.machined[item.id] = item.fileName
      } else if (newType === "tubeCut") {
        if (item.isTubeCut) {
          setSelectedAutomaticTubeCuttingPartsCount(prevState => prevState + 1)
        } else {
          setSelectedManualTubeCuttingPartsCount(prevState => prevState + 1)
        }
        selectedAssemblyPartsMapByType.tubeProfile[item.id] = item.fileName
      } else if (newType === "profile") {
        setSelectedProfilePartsCount(prev => prev + 1)
        selectedAssemblyPartsMapByType.tubeProfile[item.id] = item.fileName
      } else {
        setSelectedOtherPartsCount(prev => prev + 1)
      }

      if ((item.partType === "milled" && newType === "lathed") || (newType === "milled" && item.partType === "lathed")) {
        handleMachiningMaterialFilterMilledLathedChange({item, newType})
      }
    }
    if(isDraft) {
      setPrevPartProcessingType(prev => ({...prev, [item.id]: item.processingType}))
    }
    triggerChangePartType({orderId: orderId, orderPartId: id, partType: newType})
  }

  const handleColorRemove = ({ id, partType }) => {
    const actionId = cuid()
    const data2 = {
      orderId: orderId,
      actionId,
      partType: partType,
      orderPartId: id,
      fields: [
        {
          fieldName: "coating",
          fieldValue: {
            hasCoating: false,
            type: "painting",
            data: {
              colorRAL: "",
              colorGlossId: "",
              ralRow: 0,
              ralHex: ""
            }
          }
        }
      ]
    }

    updateActions(actionId, true)
    triggerUpdatePart(data2)
    dispatch(removeItemColor({id}))
  }
  const handleRemoveEntireAssembly = (data) => {
    const actionId = cuid()
    const data2 = {
      actionId,
      orderId: data.orderId,
      assemblyId: data.assemblyId
    }
    updateActions(actionId, true)
    triggerDeleteAssembly(data2)

    const assemblyParts = items.filter(item => item.partType !== "assembly" && item.assemblyId === data.assemblyId)
    let countParts = 0
    let countSelectedParts = 0
    let manualCount = 0

    let sheetMetalCount = 0
    let milledCont = 0
    let lathedCount = 0
    let automaticTubeCutCount = 0
    let manualTubeCutCount = 0
    let profileCount = 0
    let otherCount = 0

    let map = {...selectedItems}
    let partTypeMap = {...selectedPartsMapByType}
    const tmpSelectedItemsSheetMetalMaterials = {...selectedItemsSheetMetalMaterials}
    const tmpSelectedItemsMachiningMaterials = {...selectedItemsMachiningMaterials}
    for(const part of assemblyParts) {
      if (selectedItems[part.id]) {
        delete map[part.id]
        if(tmpSelectedItemsSheetMetalMaterials[part.id]) {
          delete tmpSelectedItemsSheetMetalMaterials[part.id]
        }
        if(tmpSelectedItemsMachiningMaterials[part.id]) {
          delete tmpSelectedItemsMachiningMaterials[part.id]
        }
        countSelectedParts += 1
        if(part.processingType === 'manual') {
          manualCount += 1
        }

        if(part.partType === "sheetMetal") {
          sheetMetalCount++;
          if(partTypeMap.sheetMetal[part.id]) {
            delete partTypeMap.sheetMetal[part.id]
          }
        } else if (part.partType === "milled") {
          milledCont++;
          if(partTypeMap.machined[part.id]) {
            delete partTypeMap.machined[part.id]
          }
        } else if (part.partType === "lathed") {
          lathedCount++;
          if(partTypeMap.machined[part.id]) {
            delete partTypeMap.machined[part.id]
          }
        } else if (part.partType === "tubeCut") {
          if (part.processingType === "automatic") {
            automaticTubeCutCount++;
          } else {
            manualTubeCutCount++;
          }
          if(partTypeMap.tubeProfile[part.id]) {
            delete partTypeMap.tubeProfile[part.id]
          }
        } else if (part.partType === "profile") {
          profileCount++;
          if(partTypeMap.tubeProfile[part.id]) {
            delete partTypeMap.tubeProfile[part.id]
          }
        } else {
          otherCount++;
        }

      }
      countParts += 1
    }
    setSelectedItems({...map})
    setSelectedPartsMapByType({...partTypeMap})
    setSelectedItemsCount(prev => prev - countSelectedParts)
    setNumberOfManualParts(prevState => prevState - manualCount)

    setSelectedSheetMetalPartsCount(prev => prev - sheetMetalCount)
    setSelectedMillingPartsCount(prev => prev - milledCont)
    setSelectedLathingPartsCount(prev => prev - lathedCount)
    setSelectedAutomaticTubeCuttingPartsCount(prev => prev - automaticTubeCutCount)
    setSelectedManualTubeCuttingPartsCount(prev => prev - manualTubeCutCount)
    setSelectedProfilePartsCount(prev => prev - profileCount)
    setSelectedOtherPartsCount(prev => prev - otherCount)

    let count = 0;
    for(let key of Object.keys(map)) {
      if(map[key]) {
        count += 1;
      }
    }
    if(selectedAllItems) {
      if(count === 0) {
        setSelectedAllItems(false)
      }
    }

    let sheetMetal = null
    for(const key in tmpSelectedItemsSheetMetalMaterials) {
      if (!sheetMetal) {
        sheetMetal = selectedItemsSheetMetalMaterials[key]
      } else {
        sheetMetal = new Set([...sheetMetal].filter(x => selectedItemsSheetMetalMaterials[key].has(x)))
      }
    }
    setSelectedItemsSheetMetalMaterials({...tmpSelectedItemsSheetMetalMaterials})
    if(!sheetMetal) {
      sheetMetal = new Set([...companySelectableSheetMetalMaterials])
    }
    setSelectableSheetMetalMaterials(sheetMetal)

    let machining = null
    for(const key in tmpSelectedItemsMachiningMaterials) {
      if (!machining) {
        machining = selectedItemsMachiningMaterials[key]
      } else {
        machining = new Set([...machining].filter(x => selectedItemsMachiningMaterials[key].has(x)))
      }
    }
    setSelectedItemsMachiningMaterials({...tmpSelectedItemsMachiningMaterials})
    if(!machining) {
      machining = new Set([...companySelectableMachiningMaterials])
    }
    setSelectableMachiningMaterials(machining)

    setAllItemsCount(prevState => prevState - (countParts) - 1)
    setSelectableItemsCount(prevState => prevState - countParts)
    setPartiallySelectedAssemblies( prevState => {
      const copy = {...prevState};
      delete copy[data.assemblyId];
      return copy;
    })
    dispatch(deleteEntireAssembly(data))
  }
  const handleRemoveItem = (item) => {
    const actionId = cuid()
    updateActions(actionId, true)
    const data = {
      actionId,
      orderId: orderId,
      partIds: [item.id]
    }
    setSelectableItemsCount(prevState => prevState - 1);
    if(item.assemblyId) {
      if(countAssemblyParts(item.assemblyId) === 1) {
        dispatch(deleteEntireAssembly({orderId: orderId, assemblyId: item.assemblyId}))
        setAllItemsCount(prevState => prevState - 2);
        setPartiallySelectedAssemblies( prevState => {
          const copy = {...prevState};
          delete copy[item.assemblyId];
          return copy;
        })
      } else {
        setAllItemsCount(prevState => prevState - 1);
        let selected = selectedItems[item.id]
        if(selected) {
          setPartiallySelectedAssemblies(prevState => ({...prevState, [item.assemblyId]: {selected: prevState[item.assemblyId].selected - 1, total: prevState[item.assemblyId].total - 1}}))
        }
      }
    } else {
      setAllItemsCount(prevState => prevState - 1);
    }
    triggerRemoveOneOrMoreParts(data)
    dispatch(removeItem({item}))
    if (selectedItems[item.id]) {
      let map = {...selectedItems}
      delete map[item.id]
      setSelectedItems({...map})
      setSelectedItemsCount(prev => prev - 1)

      if(item.partType === "sheetMetal") {
        setSelectedSheetMetalPartsCount(prev => prev - 1)
        setSelectedPartsMapByType(prev => {
          let copy = {...prev}
          if(copy.sheetMetal[item.id]) {
            delete copy.sheetMetal[item.id]
          }
          return copy
        })
        handleSheetMetalMaterialFilter({item, checked: false})
      } else if (item.partType === "milled") {
        setSelectedMillingPartsCount(prev => prev - 1)
        setSelectedPartsMapByType(prev => {
          let copy = {...prev}
          if(copy.machined[item.id]) {
            delete copy.machined[item.id]
          }
          return copy
        })
        handleMachiningMaterialFilter({item, partType: item.partType, checked: false})
      } else if (item.partType === "lathed") {
        setSelectedLathingPartsCount(prev => prev - 1)
        setSelectedPartsMapByType(prev => {
          let copy = {...prev}
          if(copy.machined[item.id]) {
            delete copy.machined[item.id]
          }
          return copy
        })
        handleMachiningMaterialFilter({item, partType: item.partType, checked: false})
      } else if (item.partType === "tubeCut") {
        if (item.processingType === "automatic") {
          setSelectedAutomaticTubeCuttingPartsCount(prev => prev - 1)
        } else {
          setSelectedManualTubeCuttingPartsCount(prev => prev - 1)
        }
        setSelectedPartsMapByType(prev => {
          let copy = {...prev}
          if(copy.tubeProfile[item.id]) {
            delete copy.tubeProfile[item.id]
          }
          return copy
        })
      } else if (item.partType === "profile") {
        setSelectedProfilePartsCount(prev => prev - 1)
        setSelectedPartsMapByType(prev => {
          let copy = {...prev}
          if(copy.tubeProfile[item.id]) {
            delete copy.tubeProfile[item.id]
          }
          return copy
        })
      } else {
        setSelectedOtherPartsCount(prev => prev - 1)
      }

      let count = 0;
      for(let key of Object.keys(map)) {
        if(map[key]) {
          count += 1;
        }
      }
      if(selectedAllItems) {
        if(count === 0) {
          setSelectedAllItems(false)
        }
      }
    }
    if (item.processingType === 'manual') {
      setNumberOfManualParts(prevState => prevState - 1)
    }
  }

  // Utility

  const triggerUpload = (file) => {
    const id = cuid()
    const uploadTime = Date.now()
    const uploadIndex = parseInt(file.uid.slice(file.uid.lastIndexOf("-")+1))
    dispatch(uploadNewPart({file: file, data: {orderId: orderId, fileId: id, fileName: file.name, uploadTime, uploadIndex}, callback: triggerPartProcessing}))
    setAllItemsCount( prevState => prevState + 1)
  }
  const showItem = (item) => {
    setItemView({...item, viewType: "model"})
    setViewVisible(true)
  }
  const cancelShowItem = ()  => {
    setItemView(null)
    setViewVisible(false)
  }
  const onCheck = ({ item, checked }) => {
    setSelectedItems(prevState => ({...prevState, [item.id]: checked}))
    setSelectedItemsCount(prevState => checked ? prevState + 1 : prevState - 1)

    if(item.partType === "sheetMetal") {
      setSelectedSheetMetalPartsCount(prevState => checked ? prevState + 1 : prevState - 1)
      setSelectedPartsMapByType(prev => {
        let copy = {...prev}
        if(checked) {
          copy.sheetMetal[item.id] = item.fileName
        }
        else {
          if (copy.sheetMetal[item.id]) {
            delete copy.sheetMetal[item.id]
          }
        }
        return copy
      })
      handleSheetMetalMaterialFilter({item, checked})
    } else if (item.partType === "milled") {
      setSelectedMillingPartsCount(prevState => checked ? prevState + 1 : prevState - 1)
      setSelectedPartsMapByType(prev => {
        let copy = {...prev}
        if(checked) {
          copy.machined[item.id] = item.fileName
        }
        else {
          if (copy.machined[item.id]) {
            delete copy.machined[item.id]
          }
        }
        return copy
      })
      handleMachiningMaterialFilter({item, partType: item.partType, checked})
    } else if (item.partType === "lathed") {
      setSelectedLathingPartsCount(prevState => checked ? prevState + 1 : prevState - 1)
      setSelectedPartsMapByType(prev => {
        let copy = {...prev}
        if(checked) {
          copy.machined[item.id] = item.fileName
        }
        else {
          if (copy.machined[item.id]) {
            delete copy.machined[item.id]
          }
        }
        return copy
      })
      handleMachiningMaterialFilter({item, partType: item.partType, checked})
    } else if (item.partType === "tubeCut") {
      if (item.processingType === "automatic") {
        setSelectedAutomaticTubeCuttingPartsCount(prevState => checked ? prevState + 1 : prevState - 1)
      } else {
        setSelectedManualTubeCuttingPartsCount(prevState => checked ? prevState + 1 : prevState - 1)
        setSelectedPartsMapByType(prev => {
          let copy = {...prev}
          if(checked) {
            copy.tubeProfile[item.id] = item.fileName
          }
          else {
            if (copy.tubeProfile[item.id]) {
              delete copy.tubeProfile[item.id]
            }
          }
          return copy
        })
      }

    } else if (item.partType === "profile") {
      setSelectedProfilePartsCount(prevState => checked ? prevState + 1 : prevState - 1)
      setSelectedPartsMapByType(prev => {
        let copy = {...prev}
        if(checked) {
          copy.tubeProfile[item.id] = item.fileName
        }
        else {
          if (copy.tubeProfile[item.id]) {
            delete copy.tubeProfile[item.id]
          }
        }
        return copy
      })
    } else {
      setSelectedOtherPartsCount(prevState => checked ? prevState + 1 : prevState - 1)
    }

    if (item.assemblyId) {
      handlePartiallySelectAssembly({assemblyId: item.assemblyId, checked})
    }
  }
  const openFileSizeNotification = () => {
    notification.error({
      key: "file2Large",
      placement: "bottomRight",
      className: "fileSizeWarningPopup",
      message: (<div style={{fontWeight: 600}}>File Too Large</div>),
      description: (
        <div style={{fontSize: 14, fontWeight: 600}}>
          Some of the files are above the allowed 100MB file size limit.
        </div>
      ),
      duration: 0
    });
  };
  const beforeUpload = (file, fileList) => {
    if(fileList.length > 100) {
      let num = fileList.findIndex(item => item.uid === file.uid)
      if(num > 99) {
        return false
      }
    }

    const sizeInMbs = file.size / 1024 / 1024;
    const is2Large = sizeInMbs < 300;
    if (!is2Large) {
      openFileSizeNotification()
    }
    else {
      if (sizeInMbs > 20) {
        openNotification("bottomRight")
      }
      triggerUpload(file)
    }
    return false;
  }

  // Documents & drawings

  const openDocumentsView = (item) => {
    setDocumentViewItem(item)
    setDocumentViewVisible(true)
  }
  const handleRemovePartDrawing = ({ orderPartId, partType, documentId }) => {
    let tmpDocuments = documentViewItem.documents;
    tmpDocuments = tmpDocuments.filter(doc => doc.documentId !== documentId);

    if(tmpDocuments.length > 1) {
      setDocumentViewItem({...documentViewItem, documents: tmpDocuments});
    } else {
      setDocumentViewItem(null)
      setDocumentViewVisible(false)
    }

    dispatch(removePartDrawing({
      orderId: orderId,
      orderPartId: orderPartId,
      partType: partType === "assembly" ? "assembly" : "part",
      documentId: documentId
    }))
  } //Diff
  const addPartDrawing = ({ orderPartId, partType, file }) => {
    setUploadingDrawing(true)
    dispatch(uploadPartDrawing({
      orderId: orderId,
      orderPartId: orderPartId,
      partType: partType === "assembly" ? "assembly" : "part",
      file: file
    })).then((data)=>{
      setDocumentViewItem( prev => {
        prev.documents.push({...data})
        return { ...prev }
      });
      setUploadingDrawing(false)
    }).catch(()=>{
      setUploadingDrawing(false)
    })
  } //Diff

  useEffect(()=> {
    if (allItemsCount === 0) {
      firstDrawingButtonRef.current = null
    }
    forceCheck()
  }, [allItemsCount])
  useEffect(() => {
    if(selectedSheetMetalPartsCount === 0 && selectedItemsCount > 0) {
      setMultiEditFields(prev => {
        let copy = []
        for(let elem of prev) {
          if(elem.selected !== "sheetMetalMaterial" && elem.selected !== "thickness") {
            copy.push(elem)
          }
        }
        if(copy.length === 0) {
          copy.push({id: cuid(), selected: null})
        }
        return copy
      })
      setCurrentActiveMultiEditFields(prev => {
        let copy = {...prev}
        copy["sheetMetalMaterial"] = false
        copy["thickness"] = false
        return copy
      })
      setSelectedParameters(prev => ({
        ...prev,
        sheetMetalMaterial: null,
        thickness: null
      }))
    }
  }, [selectedSheetMetalPartsCount, selectedItemsCount])
  useEffect(() => {
    if((selectedLathingPartsCount + selectedMillingPartsCount) === 0 && selectedItemsCount > 0) {
      setMultiEditFields(prev => {
        let copy = []
        for(let elem of prev) {
          if(elem.selected !== "machiningMaterial") {
            copy.push(elem)
          }
        }
        if(copy.length === 0) {
          copy.push({id: cuid(), selected: null})
        }
        return copy
      })
      setCurrentActiveMultiEditFields(prev => {
        let copy = {...prev}
        copy["machiningMaterial"] = false
        return copy
      })
      setSelectedParameters(prev => ({
        ...prev,
        machiningMaterial: null
      }))
    }
  }, [selectedLathingPartsCount, selectedMillingPartsCount, selectedItemsCount])
  useEffect(() => {
    if((selectedManualTubeCuttingPartsCount + selectedProfilePartsCount) === 0 && selectedItemsCount > 0) {
      setMultiEditFields(prev => {
        let copy = []
        for(let elem of prev) {
          if(elem.selected !== "standard") {
            copy.push(elem)
          }
        }
        if(copy.length === 0) {
          copy.push({id: cuid(), selected: null})
        }
        return copy
      })
      setCurrentActiveMultiEditFields(prev => {
        let copy = {...prev}
        copy["standard"] = false
        return copy
      })
      setSelectedParameters(prev => ({
        ...prev,
        standard: null
      }))
    }
  }, [selectedManualTubeCuttingPartsCount, selectedProfilePartsCount, selectedItemsCount])
  useEffect(() => {
    if(
      selectedSheetMetalPartsCount === 0 &&
      selectedLathingPartsCount === 0 &&
      selectedMillingPartsCount === 0 &&
      selectedAutomaticTubeCuttingPartsCount === 0 &&
      selectedManualTubeCuttingPartsCount === 0 &&
      selectedProfilePartsCount === 0 &&
      selectedItemsCount > 0
    ) {
      setMultiEditFields(prev => {
        let copy = []
        for(let elem of prev) {
          if(elem.selected !== "certificate" && elem.selected !== "coating") {
            copy.push(elem)
          }
        }
        if(copy.length === 0) {
          copy.push({id: cuid(), selected: null})
        }
        return copy
      })
      setCurrentActiveMultiEditFields(prev => {
        let copy = {...prev}
        copy["certificate"] = false
        copy["coating"] = false
        return copy
      })
      setSelectedParameters(prev => ({
        ...prev,
        certNeeded: null,
        coating: {
          hasCoating: false,
          type: "",
          data: {
            colorRAL: "",
            colorGlossId: "",
            ralRow: 0,
            ralHex: "",
          }
        }
      }))
    }
  }, [
    selectedSheetMetalPartsCount,
    selectedLathingPartsCount,
    selectedMillingPartsCount,
    selectedAutomaticTubeCuttingPartsCount,
    selectedManualTubeCuttingPartsCount,
    selectedProfilePartsCount,
    selectedItemsCount
  ])
  useEffect(() => {
    if(selectedItemsCount === 0) {
      setMultiEditFields([{id: cuid(), selected: null}])
      setCurrentActiveMultiEditFields({
        sheetMetalMaterial: false,
        machiningMaterial: false,
        standard: false,
        thickness: false,
        quantity: false,
        certificate: false,
        coating: false
      })
    }
  }, [selectedItemsCount])

  const setOrderData = (order) => {
    setAllItemsCount(order.parts.length)
    setSelectableItemsCount(order.parts.filter(item => item.state === "processed" && item.partType !== "assembly").length)

    setSelectableSheetMetalMaterials(new Set([...(order?.selectableGrades?.sheetMetal || [])]))
    setSelectableMachiningMaterials(new Set([...(order?.selectableGrades?.machining || [])]))

    const assemblies = {};
    for (const part of order.parts) {
      if (part.partType === "assembly") {
        assemblies[part.id] = part.isAnalysed
      }
    }
    setAnalysedAssemblyMap({...assemblies})
  }
  const initialize = () => {
    if (isDraft) {
      if (!orderId) {
        const id = new URLSearchParams(history.location.search).get('order')
        if (id) {
          dispatch(fetchDraftOrder({id: id, withRedirect: false}))
            .then((order)=>{
              setOrderData(order)
              if (order.parts.filter(item => item.state === "processed").length > 0) {
                setOverlayShown(true)
              }
              setNumberOfManualParts(order.parts.filter(item => item.processingType === "manual").length)
              if (order?.deliveryDate) {
                dateSet(order.deliveryDate)
              }
              if (order?.adjustmentChanged) {
                let hasParts = false
                if (order.parts.length > 0) {
                  for (let part of order.parts) {
                    if (part.processingType === "automatic" && part.state === "processed") {
                      setShowAdjustmentChangeModal(true)
                      hasParts = true
                      break
                    }
                  }
                }
                if (!hasParts) {
                  handleAdjustmentConfirm(orderId)
                }
              }
            })
        } else {
          history.push('/')
        }
      } else {
        setOrderData(order)
        if (order.parts.filter(item => item.state === "processed").length > 0) {
          setOverlayShown(true)
        }
        setNumberOfManualParts(order.parts.filter(item => item.processingType === "manual").length)
        if (order?.deliveryDate) {
          dateSet(order.deliveryDate)
        }
        if(order?.adjustmentChanged) {
          let hasParts = false
          if(order.parts.length > 0) {
            for(let part of order.parts) {
              if(part.processingType === "automatic" && part.state === "processed") {
                setShowAdjustmentChangeModal(true)
                hasParts = true
                break;
              }
            }
          }
          if(!hasParts) {
            handleAdjustmentConfirm(orderId)
          }
        }
        if(filesToUpload.length > 0) {
          let i = 0;
          for(let file of filesToUpload) {
            if (i > 99) {
              break;
            }
            const is2Large = file.size / 1024 / 1024 < 300;
            if (!is2Large) {
              openFileSizeNotification()
            }
            else {
              triggerUpload(file)
            }
            i++
          }
          dispatch(resetFilesToUpload())
        }
      }
    } else {
      if (isProducer) {
        dispatch(fetchProducersInternalDraftOrder()).then((order)=>{
          setOrderData(order)
        }).catch(()=>{})
        dispatch(fetchSurfaceCoatingData())
      } else {
        dispatch(fetchInternalDraftOrder()).then((order)=>{
          setOrderData(order)
        }).catch(()=>{})
        dispatch(fetchProducersForOrder())
      }
    }
  }
  useEffect(() => {
    initialize();
  }, [])

  //-------------------- Draft Order Specific --------------------//

  const positionTooltip = () => {
    if (firstDrawingButtonRef.current) {
      const firstDrawingButtonBBox = firstDrawingButtonRef.current.getBoundingClientRect()
      const firstDrawingButtonTooltip = document.getElementsByClassName("drawingButtonTooltip")[0]
      firstDrawingButtonTooltip.style.top = `${firstDrawingButtonBBox.y - 170}px`
      firstDrawingButtonTooltip.style.left = `${firstDrawingButtonBBox.x}px`
    }
  }
  useEffect(() => {
    if (!isDraft) {
      return
    }

    const dontShowAgain = localStorage.getItem("dontShowOverlayAgain") === "true"
    if (dontShowAgain) {
      return
    }

    if (allItemsCount === 0) {
      setProcessedParts(0)
    }

    if (allItemsCount > 0 && processedParts === allItemsCount && !overlayShown) {
      const firstDrawingButton = document.getElementsByClassName("drawingsButton")[0]
      if (firstDrawingButton) {
        setTimeout(() => {
          setShowOverlay(true)
          setOverlayShown(true)
          firstDrawingButtonRef.current = firstDrawingButton
          firstDrawingButton.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

          firstDrawingButton.classList.add("draftOrderOverlayButton")
          firstDrawingButton.disabled = true
          positionTooltip()

          const wrapper = document.getElementsByClassName("calcPageWrapper")[0]
          wrapper.classList.add("calcPageWrapperNoScroll")
          wrapper.classList.remove("calcPageWrapper")

          window.addEventListener("scroll", (e) => {
            positionTooltip()
          })
          window.addEventListener("resize", () => {
            positionTooltip()
          })
        }, "500")
      }
    }

    return () => {
      // Calling removeEventListener() with arguments that do not identify any currently registered event listener on the EventTarget has no effect
      window.removeEventListener("scroll", (e) => {
        positionTooltip()
      })
      window.removeEventListener("resize", () => {
        positionTooltip()
      })
    }
  }, [allItemsCount, processedParts])
  const closeTooltip = () => {
    localStorage.setItem("dontShowOverlayAgain", dontShowOverlayAgain ? "true" : "false")
    firstDrawingButtonRef.current.classList.remove("draftOrderOverlayButton")
    firstDrawingButtonRef.current.disabled = false

    const wrapper = document.getElementsByClassName("calcPageWrapperNoScroll")[0]
    wrapper.classList.add("calcPageWrapper")
    wrapper.classList.remove("calcPageWrapperNoScroll")

    window.removeEventListener("scroll", (e) => {
      positionTooltip()
    })
    window.removeEventListener("resize", () => {
      positionTooltip()
    })

    setShowOverlay(false)
  }

  const changeOrderName = (data) => {
    debouncedNameChange(data)
  }
  const debouncedNameChange = useCallback(
    debounce(data => handleOrderNameChange(data), 800),
    []
  );
  const handleOrderNameChange = (data) => {
    const data2 = {
      orderId: data.orderId,
      name: data.name
    }
    dispatch(changeDraftOrderName(data2))
  }
  const dateSet = (date) => {
    let datum = new Date(date)
    if(datum < new Date()) {
      datum = null
    }
    setSelectedDate(date)
  };
  const handleDateChange = date => {
    let datum = new Date(date)
    if(datum < new Date()) {
      datum = null
    }
    setSelectedDate(datum)
    if(datum) {
      dispatch(changeDeliveryDate({orderId: orderId, deliveryDate: datum.toISOString(), dateTimestamp: datum.getTime()}))
    }
  };

  // Other stages

  useEffect(()=>{
    stageRef.current = stage
  }, [stage])

  useEffect(()=>{
    prevPartProcessingTypeRef.current = prevPartProcessingType
  }, [prevPartProcessingType])
  const handleOpenDocsModal = (docs) => {
    setAdditionalDocs(docs)
    setAdditionalDocsVisible(true);
  }
  const handleCloseDocsModal = () => {
    setAdditionalDocsVisible(false);
    setAdditionalDocs([])
  }
  const proceedToShipping = () => {
    setSelectedDate(order.deliveryDate)
    dispatch(changeStageToShipping({orderId: orderId}))
  }
  const proceedToConfirmation = (data) => {
    const shippingData = {
      address: data.address,
      city: data.city,
      country: data.country,
      postalCode: data.postalCode,
      phone: data.phone
    }

    dispatch(changeStageToConfirmation({orderId: orderId, shippingData}))
  }
  const returnToStage = (stage) => {
    dispatch(changeToPreviousStage({orderId: orderId, stage}))
  }
  const showDetailsItem = (item) => {
    if (item.partType === "sheetMetal" && item.selectedMaterial && item.selectedMaterial.grade) {
      let groupName = sheetMetalMaterialMap[item.selectedMaterial.grade].groupName
      setItemView({...item, orderState: "draft", selectedMaterial: {...item.selectedMaterial, groupName}, viewType: "details"})
    } else {
      setItemView({...item, orderState: "draft", viewType: "details"})
    }
    setViewVisible(true)
  }
  const showOtherItem = (item) => {
    if(item.partType === "sheetMetal") {
      let groupName = sheetMetalMaterialMap[item.selectedMaterial.grade].groupName
      setOtherItemView({...item, selectedMaterial: {...item.selectedMaterial, groupName}})
    }
    else {
      setOtherItemView(item)
    }
    setViewOtherVisible(true)
  }
  const closeOtherView = () => {
    setOtherItemView(null)
    setViewOtherVisible(false)
  }
  const handlePlaceOrder = () => {
    dispatch(placeOrder({orderId: orderId})).catch((error)=>{
      let data = error.response?.data?.msg
      dispatch(changeAdjustments(data))
      let hasParts = false
      if(order.parts.length > 0) {
        for(let part of order.parts) {
          if(part.processingType === "automatic" && part.state === "processed") {
            setShowAdjustmentChangeModal(true)
            hasParts = true
            break;
          }
        }
      }
      if(!hasParts) {
        dispatch(confirmAdjustmentChange({orderId: orderId})).then(()=>{
          dispatch(placeOrder({orderId: orderId}))
        })
      }
    })
  }
  const handleAdjustmentConfirm = (id) =>{
    setShowAdjustmentChangeModal(false)
    dispatch(confirmAdjustmentChange({orderId: id}))
  }
  const checkSelectedDate = (date) => {
    if(date < Date.now()) {
      return null
    }
    return date
  }

  //-------------------- Bulk Order Specific --------------------//

  const changePrice = (data) => {
    debouncedPriceChange(data)
  }
  const handleUpdatePartPriceResponse = (data) => {
    removeAction(data.actionId);
  }
  const debouncedPriceChange = useCallback(
    debounce(data => handlePriceChange(data), 800),
    []
  );
  const handlePriceChange = (data) => {
    const actionId = cuid()
    const data2 = {
      actionId,
      orderId: data.orderId,
      assemblyId: data.assemblyId,
      orderPartId: data.id,
      pricePerPiece: data.price,
      quantity: data.quantity,
      assemblyQuantity: data.assemblyQuantity || 1
    }
    updateActions(actionId, true)
    triggerPartPriceChange(data2)
    dispatch(changePartData({orderPartId: data.id, pricePerPiece: data.price, totalPrice: data.quantity * data.price}))
  }

  const handleChangeAssemblyProductionPrice = (data) => {
    removeAction(data.actionId);
    dispatch(updateAssemblyPrice(data))
  }
  const changeAssemblyWeldingPrice = (data) => {
    debouncedAssemblyWeldingPriceChange(data)
  }
  const changeAssemblyAssemblingPrice = (data) => {
    debouncedAssemblyAssemblingPriceChange(data)
  }
  const changeAssemblyPackagingPrice = (data) => {
    debouncedAssemblyPackagingPriceChange(data)
  }
  const changeAssemblyTransportationPrice = (data) => {
    debouncedAssemblyTransportationPriceChange(data)
  }
  const debouncedAssemblyWeldingPriceChange = useCallback(
    debounce(data => handleAssemblyWeldingPriceChange(data), 800),
    []
  );
  const debouncedAssemblyAssemblingPriceChange = useCallback(
    debounce(data => handleAssemblyAssemblingPriceChange(data), 800),
    []
  );
  const debouncedAssemblyPackagingPriceChange = useCallback(
    debounce(data => handleAssemblyPackagingPriceChange(data), 800),
    []
  );
  const debouncedAssemblyTransportationPriceChange = useCallback(
    debounce(data => handleAssemblyTransportationPriceChange(data), 800),
    []
  );
  const handleAssemblyWeldingPriceChange = (data) => {
    if (data.id) {
      const actionId = cuid()
      const data2 = {
        orderId: data.orderId,
        actionId,
        assemblyId: data.id,
        weldingPrice: data.weldingPrice
      }
      updateActions(actionId, true)
      triggerAssemblyWeldingPriceChange(data2)
      dispatch(changeWeldingPrice(data))
    }
  }
  const handleAssemblyAssemblingPriceChange = (data) => {
    if (data.id) {
      const actionId = cuid()
      const data2 = {
        orderId: data.orderId,
        actionId,
        assemblyId: data.id,
        assemblingPrice: data.assemblingPrice
      }
      updateActions(actionId, true)
      triggerAssemblyAssemblingPriceChange(data2)
      dispatch(changeAssemblingPrice(data))
    }
  }
  const handleAssemblyPackagingPriceChange = (data) => {
    if (data.id) {
      const actionId = cuid()
      const data2 = {
        orderId: data.orderId,
        actionId,
        assemblyId: data.id,
        packagingPrice: data.packagingPrice
      }
      updateActions(actionId, true)
      triggerAssemblyPackagingPriceChange(data2)
      dispatch(changePackagingPrice(data))
    }
  }
  const handleAssemblyTransportationPriceChange = (data) => {
    if (data.id) {
      const actionId = cuid()
      const data2 = {
        orderId: data.orderId,
        actionId,
        assemblyId: data.id,
        transportationPrice: data.transportationPrice
      }
      updateActions(actionId, true)
      triggerAssemblyTransportationPriceChange(data2)
      dispatch(changeTransportationPrice(data))
    }
  }
  const triggerPartPriceChange = (data) => {
    if (socket) {
      socket.emit("put-one-order-part-price", data)
    }
  }
  const triggerAssemblyWeldingPriceChange = (data) => {
    if (socket) {
      socket.emit('put-order-assembly-welding-price', data)
    }
  }
  const triggerAssemblyAssemblingPriceChange = (data) => {
    if (socket) {
      socket.emit('put-order-assembly-assembling-price', data)
    }
  }
  const triggerAssemblyPackagingPriceChange = (data) => {
    if (socket) {
      socket.emit('put-order-assembly-packaging-price', data)
    }
  }
  const triggerAssemblyTransportationPriceChange = (data) => {
    if (socket) {
      socket.emit('put-order-assembly-transportation-price', data)
    }
  }
  const checkIfPriceIsZero = () => {
    return items.filter(item => item.totalPrice === 0).length > 0
  }
  const openClearOrder = () => {
    let modal = Modal.confirm({
      className: "inputInfoText",
      visible: true,
      style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
      centered: true,
      onOk: (e) => {
        modal.update(prevConfig => ({
          ...prevConfig,
          cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
          okButtonProps: {...prevConfig.okButtonProps, loading: true}
        }));
        handleClearOrder(e, modal)
      },
      confirmLoading: true,
      destroyOnClose: true,
      width: 400,
      title: <span style={{color: "white"}}>{"Clear Calculation"}</span>,
      content: <div>
        <div style={{color: "white"}}>Are you sure you want to remove all parts from the current calculation?</div>
        <Divider className={"lightDivider"}
                 style={{marginBottom: 0, position: 'relative', right: 36, width: 334}}
        />
      </div>,
      okText: <span style={{fontSize: 13, fontWeight: 500}}>Confirm</span>,
      okButtonProps: {type: "primary", loading: false},
      cancelText: <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
      icon: <DeleteOutlined style={{color: "#ff5b2d"}}/>,
      zIndex: 100,
    })
  }

  const handleClearOrderAfter = (e, order) => {
    setAnalysedAssemblyMap({})
    setAssemblyProgressMap({})
    setPartiallySelectedAssemblies({})
    setAllItemsCount(0)
    setSelectableItemsCount(0);
    setSelectedItems({})
    setSelectedPartsMapByType({
      sheetMetal: {},
      machined: {},
      tubeProfile: {}
    })
    setSelectedItemsCount(0)

    setSelectedSheetMetalPartsCount(0)
    setSelectedMillingPartsCount(0)
    setSelectedLathingPartsCount(0)
    setSelectedAutomaticTubeCuttingPartsCount(0)
    setSelectedManualTubeCuttingPartsCount(0)
    setSelectedProfilePartsCount(0)
    setSelectedOtherPartsCount(0)

    setSelectedItemsSheetMetalMaterials({})
    setSelectedItemsMachiningMaterials({})
    setSelectedAllItems(false)
    setSelectableSheetMetalMaterials(new Set([...(order.selectableGrades?.sheetMetal || [])]))
    setSelectableMachiningMaterials(new Set([...(order.selectableGrades?.machining || [])]))
    setSelectableThickness(new Set([]))
    setActionMap(new Map())
    handleMultiClear()
    e()
  }

  const handleClearOrder = (e, modal) => {
    if (isProducer) {
      dispatch(clearProducerOrder({}))
        .then((order) => {
          handleClearOrderAfter(e, order)
        })
        .catch((error)=>{
          console.log(error)
          modal.update(prevConfig => ({
            ...prevConfig,
            cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: false},
            okButtonProps: {...prevConfig.okButtonProps, loading: false},
          }))
        })
    } else {
      dispatch(clearBulkOrder({}))
        .then((order) => {
          handleClearOrderAfter(e, order)
        })
        .catch((error)=>{
          console.log(error)
          modal.update(prevConfig => ({
            ...prevConfig,
            cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: false},
            okButtonProps: {...prevConfig.okButtonProps, loading: false},
          }))
        })
    }
  }

  // Archived

  const fetchArchivedList = () => {
    form?.current?.setFieldsValue({
      search: ""
    })
    setIsFetchingInternalArchived(true)
    dispatch(fetchInternalArchivedOrders({filter: selectedCalc})).then(orders => {
      setInternalArchivedCalculations(orders)
      setIsFetchingInternalArchived(false)
    })
  }
  const handleArchiveOrder = (values) => {
    dispatch(archiveCurrentCalculation({customName: values.customName, orderNote: values.orderNote})).then(()=>{
      archiveOrderForm.setFieldsValue({
        customName: "",
        orderNote: ""
      })
      setIsArchiveModalVisible(false)
      fetchArchivedList()
    })
  }
  useEffect(()=>{
    if (!isDraft && !isProducer) {
      fetchArchivedList()
    }
  }, [selectedCalc])
  const escapeRegExp = (text) => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  const filterArchivedCalculations = (data) => {
    if(!data.text) {
      setInternalArchivedCalculations(data.archivedInternalOrders)
    } else {
      const orders = data.archivedInternalOrders.filter(item => (item.customName || item.name).toLowerCase().search(escapeRegExp(data.text.toLowerCase())) !== -1)
      setInternalArchivedCalculations(orders)
    }
    setSearchingArchived(false)
  }
  const debounceSearchArchivedCalculations = useCallback(
    debounce(data => filterArchivedCalculations(data), 800),
    []
  );
  const searchArchivedCalculations = (text) => {
    setSearchingArchived(true)
    debounceSearchArchivedCalculations({archivedInternalOrders,text})
  }

  // Prices modal

  const handleOpenPricesModal = (itemData) => {
    setPricesModalItem(itemData)
    setAllPricesModalVisible(true)
  }
  const handleClosePricesModal = () => {
    setAllPricesModalVisible(false)
    setPricesModalItem({})
  }

  // Content

  return (
    <Layout className={"calcPageWrapper"}>
      <div style={{position: 'fixed', top:0, width: '100%', zIndex: 10000 }}>
        {(working || actionMap.size > 0 || !!isChangingDraftStage || !!isChangingDeliveryDate || !!isChangingDraftName) &&
          <div style={{marginTop: -9}}>
            <Progress className={"progress"} percent={100} status={"active"} showInfo={false}/>
          </div>
        }
      </div>
      {isDraft ? (
        <Sidebar
          onLogout={()=>{dispatch(logoutUser())}}
          user={user}
        />
      ) : (
        isProducer ?
          <ProducerSidebar
            onLogout={() => dispatch(logoutUser({}))}
            user={user}
          />
          :
          <CompanySidebar
            onLogout={() => dispatch(logoutUser({}))}
            user={user}
          />
      )}
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}} type={"flex"}>
        <Spin
          spinning={isFetchingOrder || isFetchingProducersForOrder || !socketConnected}
          wrapperClassName={"fetchingOrderSpin"}
          indicator={<FetchingOrderIndicator text={isDraft ? "Fetching Your Quote" : "Fetching Your Calculation"}/>}
        >
          <div style={{overflowX: 'hidden'}}>
            <div style={{marginRight: 15, marginBottom: 15, marginLeft: 25, marginTop: 15, minHeight: "calc(100vh - 30px)"}}>
              {orderId &&
              <Row wrap={false} style={{height: '100%', minHeight: "calc(100vh - 30px)"}}>
                <Col span={largeThreshold ? 24 : ((isDraft || isProducer) ? 18 : 17)}>
                  {/*---------------------Header--------------------*/}
                  {isDraft ?
                    <DraftOrderHeader
                      order={order}
                      changeOrderName={changeOrderName}
                    />
                  :
                    <BulkOrderHeader
                      largeThreshold={largeThreshold}
                      allItemsCount={allItemsCount}
                      openClearOrder={openClearOrder}
                      checkIfPriceIsZero={checkIfPriceIsZero}
                      setExistsZeroPrice={setExistsZeroPrice}
                      setIsArchiveModalVisible={setIsArchiveModalVisible}
                      isProducer={isProducer}
                    />
                  }
                  <MiDivider style={{marginLeft: -25, marginBottom: 10, marginTop: 15, background: "#838f94"}}/>

                  {/*-----------------------Body-----------------------*/}
                  <div>
                    {isDraft ?
                      <div style={{paddingBottom: 10, marginRight: 10, display: largeThreshold ? 'block' : 'none'}}>
                        <DraftOrderStageCard
                          stage={stage}
                          returnToStage={returnToStage}
                          selectableItemsCount={selectableItemsCount}
                          needsManualQuote={needsManualQuote}
                          working={working}
                          adjustment={adjustment}
                          proceedToShipping={proceedToShipping}
                          isChangingDraftStage={isChangingDraftStage}
                          isChangingDeliveryDate={isChangingDeliveryDate}
                          isChangingDraftName={isChangingDraftName}
                          handlePlaceOrder={handlePlaceOrder}
                          actionMap={actionMap}
                          totalPrice={totalPrice}
                        />
                      </div>
                      :
                      /*--------Stored (Archived) Calculations--------*/
                      (isProducer ?
                        <div style={{paddingBottom: 10, marginRight: 10, display: largeThreshold ? 'block' : 'none'}}>
                          <ProducerStageCard
                            items={items}
                            numOfItems={selectableItemsCount}
                            totalPrice={totalPrice || 0}
                            orderName={"Internal_Calculation"}
                          />
                        </div>
                        :
                        <div style={{paddingBottom: 10, display: largeThreshold ? 'block' : 'none', marginRight: 10}}>
                          <BulkOrderArchiveAccordion
                            selectedCalc={selectedCalc}
                            setSelectedCalc={setSelectedCalc}
                            searchArchivedCalculations={searchArchivedCalculations}
                            internalArchivedCalculations={internalArchivedCalculations}
                            isFetchingInternalArchived={isFetchingInternalArchived}
                            searchingArchived={searchingArchived}
                          />
                        </div>
                      )
                    }
                    {((isDraft && stage === "processing") || !isDraft) &&
                      <OrderProcessingStage
                        isProducer={isProducer}
                        orderId={orderId}
                        largeThreshold={largeThreshold}
                        allItemsCount={allItemsCount}
                        screens={screens}
                        openFileSizeNotification={openFileSizeNotification}
                        triggerUpload={triggerUpload}
                        smallThreshold={smallThreshold}
                        toProcess={toProcess}
                        currentlyUploaded={currentlyUploaded}
                        currentlyProcessed={currentlyProcessed}

                        selectableItemsCount={selectableItemsCount}
                        selectedAllItems={selectedAllItems}
                        setSelectedAllItems={setSelectedAllItems}
                        handleSelectAll={handleSelectAll}

                        selectedItemsCount={selectedItemsCount}
                        selectedSheetMetalPartsCount={selectedSheetMetalPartsCount}
                        selectedMillingPartsCount={selectedMillingPartsCount}
                        selectedLathingPartsCount={selectedLathingPartsCount}
                        selectedAutomaticTubeCuttingPartsCount={selectedAutomaticTubeCuttingPartsCount}
                        selectedManualTubeCuttingPartsCount={selectedManualTubeCuttingPartsCount}
                        selectedProfilePartsCount={selectedProfilePartsCount}
                        selectedOtherPartsCount={selectedOtherPartsCount}
                        selectedPartsMapByType={selectedPartsMapByType}

                        handleRemoveMultipleItems={handleRemoveMultipleItems}

                        multiEditFields={multiEditFields}

                        addMultiEditField={addMultiEditField}
                        removeMultiEditField={removeMultiEditField}
                        setMultiEditField={setMultiEditField}
                        currentActiveMultiEditFields={currentActiveMultiEditFields}

                        selectedParameters={selectedParameters}
                        sheetMetalMaterials={sheetMetalMaterials}
                        selectableSheetMetalMaterials={selectableSheetMetalMaterials}
                        selectableThickness={selectableThickness}
                        machiningMaterials={machiningMaterials}
                        tubeCuttingMaterials={tubeCuttingMaterials}
                        selectableMachiningMaterials={selectableMachiningMaterials}

                        handleMultiQuantityChange={handleMultiQuantityChange}
                        handleMultiCertificateChange={handleMultiCertificateChange}
                        handleMultiCoatingChange={handleMultiCoatingChange}
                        handleMultiCoatingClear={handleMultiCoatingClear}
                        handleMultiSheetMetalMaterialChange={handleMultiSheetMetalMaterialChange}
                        handleMultiSheetMetalMaterialClear={handleMultiSheetMetalMaterialClear}
                        handleMultiThicknessChange={handleMultiThicknessChange}
                        handleMultiThicknessClear={handleMultiThicknessClear}
                        handleMultiMachiningMaterialChange={handleMultiMachiningMaterialChange}
                        handleMultiMachiningMaterialClear={handleMultiMachiningMaterialClear}
                        handleMultiStandardChange={handleMultiStandardChange}
                        handleMultiStandardClear={handleMultiStandardClear}

                        handleMultiClear={handleMultiClear}
                        handleMultiApply={handleMultiApply}

                        items={items}
                        partiallySelectedAssemblies={partiallySelectedAssemblies}
                        handlePartiallySelectAssembly={handlePartiallySelectAssembly}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        setSelectedItemsCount={setSelectedItemsCount}
                        handleRemoveItem={handleRemoveItem}
                        changeSheetMetalMaterial={changeSheetMetalMaterial}
                        changeMachiningMaterial={changeMachiningMaterial}
                        changeTubeCuttingMaterial={changeTubeCuttingMaterial}
                        changePartThickness={changePartThickness}
                        changePartQuantity={changePartQuantity}
                        changePartNote={changePartNote}
                        changePartColor={changePartColor}
                        changePartTolerance={changePartTolerance}
                        changePartRoughness={changePartRoughness}
                        changeManualPartTolerance={changeManualPartTolerance}
                        changeManualPartRoughness={changeManualPartRoughness}
                        changePartStandard={changePartStandard}
                        changePartNumberOfUniqueParts={changePartNumberOfUniqueParts}
                        changeMaterialCertificate={changeMaterialCertificate}
                        handleColorRemove={handleColorRemove}
                        showItem={showItem}
                        handleChangeItemType={handleChangeItemType}
                        onUploadClick={() => {setIsOpenBrowse(true)}}
                        adjustment={adjustment}
                        isOrderDetails={false}
                        handleSelectAssembly={handleSelectAssembly}
                        handleRemoveEntireAssembly={handleRemoveEntireAssembly}
                        changeAssemblyQuantity={changeAssemblyQuantity}
                        changeAssemblyWeldingPrice={changeAssemblyWeldingPrice}
                        changeAssemblyAssemblingPrice={changeAssemblyAssemblingPrice}
                        changeAssemblyPackagingPrice={changeAssemblyPackagingPrice}
                        changeAssemblyTransportationPrice={changeAssemblyTransportationPrice}
                        assemblyProgressMap={assemblyProgressMap}
                        openDocumentsView={openDocumentsView}
                        onCheck={onCheck}
                        bulkItem={!isDraft}

                        handleOpenPricesModal={handleOpenPricesModal}
                        changePrice={changePrice}
                        totalPrice={totalPrice}
                      />
                    }
                    {isDraft && stage === "shipping" &&
                      <OrderShippingStage
                        largeThreshold={largeThreshold}
                        user={user}
                        order={order}
                        proceedToConfirmation={proceedToConfirmation}
                        selectedDate={checkSelectedDate(selectedDate)}
                        handleDateChange={handleDateChange}
                        screens={screens}
                        isChangingDeliveryDate={isChangingDeliveryDate}
                        isChangingDraftStage={isChangingDraftStage}
                      />
                    }
                    {isDraft && stage === 'confirmation' &&
                      <OrderConfirmationStage
                        largeThreshold={largeThreshold}
                        user={user}
                        order={order}
                        needsManualQuote={needsManualQuote}
                        totalPrice={totalPrice}
                        parts={items}
                        showOtherItem={showOtherItem}
                        showDetailsItem={showDetailsItem}
                        isChangingDraftStage={isChangingDraftStage}
                        handleOpenDocsModal={handleOpenDocsModal}
                      />
                    }
                  </div>
                </Col>
                <MiDivider
                  orientation="vertical"
                  flexItem
                  className={"vertMiDiv"}
                  style={{
                    display: largeThreshold ? 'none' : 'block',
                  }}
                />
                <Col flex={"auto"} style={{display: largeThreshold ? 'none' : 'block'}}>
                  {isDraft ? (
                    <DraftOrderSticky
                      stage={stage}
                      returnToStage={returnToStage}
                      working={working}
                      processingPrice={processingPrice}
                      adjustment={adjustment}
                      handlePlaceOrder={handlePlaceOrder}
                      needsManualQuote={needsManualQuote}
                      numberOfManualParts={numberOfManualParts}
                      selectableItemsCount={selectableItemsCount}
                      proceedToShipping={proceedToShipping}
                      isChangingDraftStage={isChangingDraftStage}
                      isChangingDeliveryDate={isChangingDeliveryDate}
                      isChangingDraftName={isChangingDraftName}
                      totalPrice={totalPrice}
                      actionMap={actionMap}
                    />
                  ) : (
                    isProducer ?
                      <ProducerOrderSticky
                        items={items}
                        numOfItems={selectableItemsCount}
                        totalPrice={totalPrice || 0}
                        orderName={"Internal_Calculation"}
                      />
                        :
                      <BulkOrderSticky
                        isFetchingInternalArchived={isFetchingInternalArchived}
                        selectedCalc={selectedCalc}
                        searchingArchived={searchingArchived}
                        setSelectedCalc={setSelectedCalc}
                        searchArchivedCalculations={searchArchivedCalculations}
                        internalArchivedCalculations={internalArchivedCalculations}
                        form={form}
                      />
                    )
                  }
                </Col>
              </Row>
              }
            </div>
          </div>
        </Spin>

        <div
          className={"draftOrderOverlay"}
          style={{display: showOverlay ? "block" : "none"}}
        >
          <div className={"drawingButtonTooltip"}>
            <div className={"arrowDown"}/>
            <button
              className={"drawingButtonTooltipCloseButton"}
              onClick={() => closeTooltip()}
            >
              <CloseOutlined style={{ fontSize: 18 }}/>
            </button>

            <div className={"drawingButtonTooltipContent"}>
              <h2 style={{ fontSize: 16 }}>Enhance Your Quote with Additional Details</h2>
              <div style={{ fontSize: 13 }}>To provide you with the most accurate quote, we encourage you to upload any additional PDF drawings or Bill of Materials (BOM) lists.</div>
              <div>
                <Divider className={"lightDivider"} style={{margin: "6px 0"}}/>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                  <Checkbox onChange={(e) => {setDontShowOverlayAgain(e.target.checked)}}>
                    <span className={"drawingButtonTooltipCheck"}>Don't show this again.</span>
                  </Checkbox>
                  <Button
                    className={"drawingButtonTooltipConfirmButton"}
                    type={"primary"}
                    onClick={() => closeTooltip()}
                  >
                    I Understand
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ItemVisualisation
          isModelViewVisible={viewVisible}
          itemView={itemView}
          modalWidth={modalWidth}
          modalHeight={modalHeight}
          onCloseView={cancelShowItem}
          adjustment={isDraft ? order.adjustment : 0}
        />

        <OrderViewOtherModal
          viewOtherVisible={viewOtherVisible}
          closeOtherView={closeOtherView}
          item={otherItemView}
          showMaterialGroup={true}
          showPrice={false}
        />
        <DraftOrderAdjustmentModal
          showAdjustmentChangeModal={showAdjustmentChangeModal}
          handleAdjustmentConfirm={handleAdjustmentConfirm}
          order={order}
        />

        <OrderDragger
          beforeUpload={beforeUpload}
          handleFocus={handleFocus}
          dragEnterCondition={
            !isOpenBrowseRef.current
            && !documentViewVisibleRef.current
             && (isDraft ? (
              stageRef.current === "processing"
              && !isProceedingToShipping)
            : (
              !isArchiveModalVisibleRef.current
              && !allPricesModalVisibleRef.current
            ))
          }
        />

        <OrderDocumentViewModal
          isVisible={documentViewVisible}
          onCancel={() => {
            setDocumentViewItem(null)
            setDocumentViewVisible(false)
          }}
          addPartDrawing={addPartDrawing}
          handleRemovePartDrawing={handleRemovePartDrawing}
          uploadingDrawing={uploadingDrawing}
          documentViewItem={documentViewItem}
        />

        <DocumentsModal
          visible={additionalDocsVisible}
          handleCloseDocsModal={handleCloseDocsModal}
          additionalDocs={additionalDocs}
        />

        <BulkOrderArchiveModal
          isArchiveModalVisible={isArchiveModalVisible}
          setIsArchiveModalVisible={setIsArchiveModalVisible}
          isArchivingCalculation={isArchivingCalculation}
          archiveOrderForm={archiveOrderForm}
          handleArchiveOrder={handleArchiveOrder}
          toProcess={toProcess}
          allItemsCount={allItemsCount}
          totalPrice={totalPrice}
          existsZeroPrice={existsZeroPrice}
          items={items}
        />
        <ProducersPricesModal
          visible={allPricesModalVisible}
          modalItem={pricesModalItem}
          onClose={handleClosePricesModal}
          applyAdjustment={false}
          compMaxWidth={700}
        />

      </Layout.Content>
    </Layout>
  )
}

