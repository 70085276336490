import {Card, Checkbox, Col, Divider, Form, Modal, Row, Spin, Tooltip} from "antd";
import {RegionSelect} from "../../../utils/utility";
import React, {useEffect, useState} from "react";
import "./style.less";
import {InfoCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {seedData} from "../../../actions/defaultMaterialsActions";

export const SeedModal = (props) => {

  const {visible, handleClose, formRef, selectedTab} = props;

  const initialState = {
    "region": "asia",
    "seedSheetMetal": false,
    "seedSheetMetalMachine": false,
    "seedBending": false,
    "seedTubeCutting": false,
    "seedTubeCuttingMachine": false,
    "seedMilling": false,
    "seedMillingMachine": false,
    "seedLathing": false,
    "seedLathingMachine": false,
    "seedSimpleMachining": false,
    "seedSurfaceCoating": false,
    "seedCertFee": false
  }

  const [bendingDisabled, setBendingDisabled] = useState(true)
  const [formData, setFormData] = useState(initialState)

  const isFetchingSeedingData = useSelector(state => state.defaultMaterialsData.isFetchingSeedingData)
  const seedingData = useSelector(state => state.defaultMaterialsData.seedingData)
  const isSeedingData = useSelector(state => state.defaultMaterialsData.isSeedingData)

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      setFormData(initialState)
      formRef.setFieldsValue(initialState)
      setBendingDisabled(true)
    }
  }, [visible])

  const ParameterSetting = (props) => {
    
    const { 
      firstFieldName, onChangeFirst, firstLabel,
      secondVisible, secondFieldName, onChangeSecond,
      secondLabel, secondTooltipText
    } = props;
    
    return (
      <div className={"technologyCheckContainer"} style={{ display: "flex", alignItems: "center" }}>
        <Form.Item
          className={"materialFormItem"}
          name={firstFieldName}
          valuePropName={"checked"}
        >
          <Checkbox onChange={onChangeFirst}>
            {firstLabel}
          </Checkbox>
        </Form.Item>
        {secondVisible &&
          <div className={"technologyMachineSeedCheck"}>
            <Divider type={"vertical"} style={{
              height: "24px",
              margin: "0 8px 0 0",
              borderColor: "#838f94",
              position: "relative",
              top: 1
            }}/>
            <Form.Item
              className={"materialFormItem"}
              name={secondFieldName}
              valuePropName={"checked"}
            >
              <Checkbox onChange={onChangeSecond}>
                {secondLabel}
              </Checkbox>
            </Form.Item>
            <Tooltip
              title={secondTooltipText}
              color={"#656565"}
              placement={"right"}
            >
              <div style={{ display: 'flex', alignItems: "center" }}>
                <InfoCircleOutlined style={{ fontSize: '14px', color: "#aaa", position: "relative", left: -4, top: 1 }} />
              </div>
            </Tooltip>
          </div>
        }
      </div>
    )
  }
  
  return (
    <Modal
      title={"Parameter Loading Configuration"}
      centered={true}
      destroyOnClose={true}
      width={1000}
      visible={visible}
      bodyStyle={{ padding: "12px 18px" }}
      onOk={() => {
        const values = formRef.getFieldsValue()
        const params = {...initialState, ...values}
        if(!values.hasOwnProperty('seedSheetMetalMachine')) {
          params['seedSheetMetalMachine'] = values['seedSheetMetal']
        }
        if(!values.hasOwnProperty('seedMillingMachine')) {
          params['seedMillingMachine'] = values['seedMilling']
        }
        if(!values.hasOwnProperty('seedLathingMachine')) {
          params['seedLathingMachine'] = values['seedLathing']
        }
        if(!values.hasOwnProperty('seedTubeCuttingMachine')) {
          params['seedTubeCuttingMachine'] = values['seedTubeCutting']
        }
        dispatch(seedData({params: params, reloadTabKey: selectedTab})).then(() => {
          handleClose()
        })
      }}
      onCancel={() => {handleClose()}}
      okText={<span style={{ fontWeight: 600 }}>Load Parameters</span>}
      forceRender={true}
    >
      <React.Fragment>
        <Spin
          spinning={isFetchingSeedingData || isSeedingData}
          indicator={
            <div align={"center"}>
              <LoadingOutlined style={{ fontSize: 28, marginLeft: -14 }} spin />
            </div>
          }
        >
          <Form
            form={formRef}
            initialValues={formData}
            onValuesChange={(changedValues, allValues) => {
              setFormData(allValues)
            }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name={"region"}
                  style={{ marginBottom: 0 }}
                >
                  <RegionSelect
                    onChange={() => {}}
                    initialValue={"asia"}
                    label={"Select a Region to Load Parameter Preset:"}
                    style={{ marginBottom: 4 }}
                  />
                </Form.Item>
              </Col>
              <Divider style={{ margin: "6px 0 6px 0", width: "65%", minWidth: "0%", borderTopColor: "#555e63"}}/>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                      <div style={{ fontWeight: 500, fontSize: 22, color: "white", marginRight: 6 }}>
                        Available Technologies<span style={{ fontSize: 18, bottom: 1, position: "relative" }}>:</span>
                      </div>
                      <Divider style={{ margin: "6px 0 6px 0", width: "75%", minWidth: "0%", top: 1, position: "relative", borderTopColor: "#555e63"}}/>
                    </div>
                    <span className={"processDescription"} style={{ position: "relative", bottom: 4 }}>
                      Check the boxes of technologies that you want to load up. Loading up a technology means to use the default parameters of the selected region for that technology.<br/>
                      Note: Any existing parameters or data will not be overwritten.
                    </span>
                  </Col>
                </Row>
                <Row gutter={6}>
                  <Col span={12}>
                    <Card className={"technologyCard"}>
                      <div className={"technologyLabel"}>
                        Sheet Metal & Bending:
                      </div>
                      <ParameterSetting
                        firstFieldName={"seedSheetMetal"}
                        onChangeFirst={(value) => {
                          const checked = value.target.checked
                          setBendingDisabled(!checked)
                          if (checked) {
                            formRef.setFieldsValue({
                              seedBending: true
                            })
                            if (seedingData.sheetMetalMachineSeeded) {
                              formRef.setFieldsValue({
                                seedSheetMetalMachine: true
                              })
                            }
                          } else if (formRef.getFieldValue("seedBending")) {
                            formRef.setFieldsValue({
                              seedBending: false
                            })
                          }
                        }}
                        firstLabel={"Load Laser Cutting"}
                        secondVisible={seedingData.sheetMetalMachineSeeded && formData.seedSheetMetal}
                        secondFieldName={"seedSheetMetalMachine"}
                        onChangeSecond={() => {}}
                        secondLabel={"Create New Laser Cutting Machine"}
                        secondTooltipText={<div>
                          It appears you have a Laser Cutting machine configured.
                          <br/><br/>
                          Keep Checked:
                          <br/>
                          A new machine will be created using default settings for the selected region.
                          <br/><br/>
                          Uncheck:
                          <br/>
                          Use your existing Laser Cutting settings. Only missing parameters will be added.
                        </div>}
                      />
                      <div className={"technologyCheckContainer"}>
                        <Form.Item
                          className={"materialFormItem"}
                          name={"seedBending"}
                          valuePropName={"checked"}
                        >
                          <Checkbox
                            disabled={bendingDisabled}
                            style={{ marginLeft: 24 }}
                          >
                            <span style={bendingDisabled ? {color: "#aaa"} : {}}>
                              Load Bending
                            </span>
                          </Checkbox>
                        </Form.Item>
                        <Tooltip
                          title={<div>
                            To use this feature, you need to select Laser Cutting first.
                            <br/><br/>
                            Keep Checked:
                            <br/>
                            Use your existing Bending settings. Only missing parameters will be added.
                            <br/><br/>
                            Uncheck:
                            <br/>
                            Use your existing Bending settings.
                          </div>}
                          color={"#656565"}
                          placement={"right"}
                        >
                          <div style={{ display: 'flex', alignItems: "center" }}>
                            <InfoCircleOutlined style={{ fontSize: '14px', color: "#aaa", position: "relative", left: -4, top: 1 }} />
                          </div>
                        </Tooltip>
                      </div>
                    </Card>
                    {/*//#-----------------------------------------------------------------*/}
                    <Card className={"technologyCard"} style={{ marginTop: 6 }}>
                      <div className={"technologyLabel"}>
                        Tube Cutting (and Profiles):
                      </div>
                      <ParameterSetting
                        firstFieldName={"seedTubeCutting"}
                        onChangeFirst={(value) => {
                          if (value.target.checked && seedingData.tubeCuttingMachineSeeded) {
                            formRef.setFieldsValue({
                              seedTubeCuttingMachine: true
                            })
                          }
                        }}
                        firstLabel={"Load Tube Cutting"}
                        secondVisible={seedingData.tubeCuttingMachineSeeded && formData.seedTubeCutting}
                        secondFieldName={"seedTubeCuttingMachine"}
                        onChangeSecond={() => {}}
                        secondLabel={"Create New Tube Cutting Machine"}
                        secondTooltipText={<div>
                          It appears you have a Tube Cutting configured.
                          <br/><br/>
                          Keep Checked:
                          <br/>
                          A new machine will be created using default settings for the selected region.
                          <br/><br/>
                          Uncheck:
                          <br/>
                          Use your existing Tube Cutting settings. Only missing parameters will be added.
                        </div>}
                      />
                    </Card>
                    {/*//#-----------------------------------------------------------------*/}
                  </Col>
                  <Col span={12}>
                    <Card className={"technologyCard"}>
                      <div className={"technologyLabel"}>
                        Milling & Lathing:
                      </div>
                      <ParameterSetting
                        firstFieldName={"seedMilling"}
                        onChangeFirst={(value) => {
                          if (value.target.checked && seedingData.millingMachineSeeded) {
                            formRef.setFieldsValue({
                              seedMillingMachine: true
                            })
                          }
                        }}
                        firstLabel={"Load Milling"}
                        secondVisible={seedingData.millingMachineSeeded && formData.seedMilling}
                        secondFieldName={"seedMillingMachine"}
                        onChangeSecond={() => {}}
                        secondLabel={"Create New Milling Machine"}
                        secondTooltipText={<div>
                          It appears you have a Milling Machine configured.
                          <br/><br/>
                          Keep Checked:
                          <br/>
                          A new machine will be created using default settings for the selected region.
                          <br/><br/>
                          Uncheck:
                          <br/>
                          Use your existing Milling Machine settings. Only missing parameters will be added.
                        </div>}
                      />
                      <ParameterSetting
                        firstFieldName={"seedLathing"}
                        onChangeFirst={(value) => {
                          if (value.target.checked && seedingData.lathingMachineSeeded) {
                            formRef.setFieldsValue({
                              seedLathingMachine: true
                            })
                          }
                        }}
                        firstLabel={"Load Lathing"}
                        secondVisible={seedingData.lathingMachineSeeded && formData.seedLathing}
                        secondFieldName={"seedLathingMachine"}
                        onChangeSecond={() => {}}
                        secondLabel={"Create New Lathing Machine"}
                        secondTooltipText={<div>
                          It appears you have a Lathing Machine configured.
                          <br/><br/>
                          Keep Checked:
                          <br/>
                          A new machine will be created using default settings for the selected region.
                          <br/><br/>
                          Uncheck:
                          <br/>
                          Use your existing Lathing Machine settings. Only missing parameters will be added.
                        </div>}
                      />
                    </Card>
                    {/*//#-----------------------------------------------------------------*/}
                    <Card className={"technologyCard"} style={{ marginTop: 6 }}>
                      <div className={"technologyLabel"}>
                        Simple Machining, Surface Coating & Material Certificates:
                      </div>
                      <ParameterSetting
                        firstFieldName={"seedSimpleMachining"}
                        onChangeFirst={() => {}}
                        firstLabel={"Load Simple Machining"}
                        secondVisible={false}
                        secondFieldName={""}
                        onChangeSecond={() => {}}
                        secondLabel={""}
                        secondTooltipText={""}
                      />
                      <ParameterSetting
                        firstFieldName={"seedSurfaceCoating"}
                        onChangeFirst={() => {}}
                        firstLabel={"Load Surface Coating"}
                        secondVisible={false}
                        secondFieldName={""}
                        onChangeSecond={() => {}}
                        secondLabel={""}
                        secondTooltipText={""}
                      />
                      <ParameterSetting
                        firstFieldName={"seedCertFee"}
                        onChangeFirst={() => {}}
                        firstLabel={"Load Material Certificate Fees"}
                        secondVisible={false}
                        secondFieldName={""}
                        onChangeSecond={() => {}}
                        secondLabel={""}
                        secondTooltipText={""}
                      />
                    </Card>
                    {/*//#-----------------------------------------------------------------*/}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Spin>
      </React.Fragment>
    </Modal>
  )
}