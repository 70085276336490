import initialState from './initialState'
import {
  DEFAULT_MATERIALS_IS_ADDING_NEW_BEND,
  DEFAULT_MATERIALS_IS_DELETING_BENDS,
  DEFAULT_MATERIALS_IS_EDITING_BEND,
  DEFAULT_MATERIALS_IS_FETCHING_ALREADY_SEEDED,
  DEFAULT_MATERIALS_IS_FETCHING_BENDING_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_CUTTING_MACHINE_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_CUTTING_MACHINE_PARAMETERS,
  DEFAULT_MATERIALS_IS_FETCHING_LATHING_MACHINE_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_MACHINING_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_MACHINING_MATERIALS,
  DEFAULT_MATERIALS_IS_FETCHING_MATERIAL_CERTIFICATE_PRICES,
  DEFAULT_MATERIALS_IS_FETCHING_MATERIAL_PRICES,
  DEFAULT_MATERIALS_IS_FETCHING_MILLING_MACHINE_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_SIMPLE_MACHINING_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_SINGLE_MATERIAL_BENDS,
  DEFAULT_MATERIALS_IS_FETCHING_SURFACE_COATING_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_DATA,
  DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_MACHINE_PROPERTIES,
  DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_MATERIAL_PRICES,
  DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_STANDARD_MATERIAL_PRICES,
  DEFAULT_MATERIALS_IS_SEEDING_DATA,
  DEFAULT_MATERIALS_RELOAD_TAB,
  DEFAULT_MATERIALS_RESET_BENDS_DATA,
  DEFAULT_MATERIALS_SET_ALREADY_SEEDED_DATA,
  DEFAULT_MATERIALS_SET_ANODIZING_DATA,
  DEFAULT_MATERIALS_SET_BENDING_AVERAGE_PREPARATION_TIME,
  DEFAULT_MATERIALS_SET_BENDING_DATA,
  DEFAULT_MATERIALS_SET_BENDING_MAX_LENGTH,
  DEFAULT_MATERIALS_SET_BENDING_PREPARATION_PRICE,
  DEFAULT_MATERIALS_SET_BLASTING_DATA,
  DEFAULT_MATERIALS_SET_CHAMFERING_DATA,
  DEFAULT_MATERIALS_SET_CUTTING_MACHINE_DATA,
  DEFAULT_MATERIALS_SET_CUTTING_MACHINE_PARAMETER,
  DEFAULT_MATERIALS_SET_CUTTING_MACHINE_PARAMETERS,
  DEFAULT_MATERIALS_SET_CUTTING_MACHINE_SPEED,
  DEFAULT_MATERIALS_SET_CUTTING_MACHINE_TOLERANCE,
  DEFAULT_MATERIALS_SET_DEBURRING_DATA,
  DEFAULT_MATERIALS_SET_DEFAULT_TUBE_CUTTING_MACHINES,
  DEFAULT_MATERIALS_SET_DRILLING_DATA,
  DEFAULT_MATERIALS_SET_GALVANIC_ZINC_PLATING_DATA,
  DEFAULT_MATERIALS_SET_GALVANIZING_DATA,
  DEFAULT_MATERIALS_SET_HEAT_TREATMENT_DATA,
  DEFAULT_MATERIALS_SET_LATHING_GRADE_SPEED,
  DEFAULT_MATERIALS_SET_LATHING_MACHINE_DATA,
  DEFAULT_MATERIALS_SET_LATHING_MACHINE_ROUGHNESS,
  DEFAULT_MATERIALS_SET_LATHING_MACHINE_TOLERANCE,
  DEFAULT_MATERIALS_SET_MACHINING_DATA,
  DEFAULT_MATERIALS_SET_MACHINING_MATERIALS,
  DEFAULT_MATERIALS_SET_MATERIAL_CERTIFICATE_PRICES,
  DEFAULT_MATERIALS_SET_MATERIAL_PRICES,
  DEFAULT_MATERIALS_SET_MILLING_GRADE_SPEED,
  DEFAULT_MATERIALS_SET_MILLING_MACHINE_DATA,
  DEFAULT_MATERIALS_SET_MILLING_MACHINE_ROUGHNESS,
  DEFAULT_MATERIALS_SET_MILLING_MACHINE_TOLERANCE,
  DEFAULT_MATERIALS_SET_NICKEL_PLATING_DATA,
  DEFAULT_MATERIALS_SET_PAINTING_DATA,
  DEFAULT_MATERIALS_SET_POWDER_PAINTING_DATA,
  DEFAULT_MATERIALS_SET_PREPARATION_DATA,
  DEFAULT_MATERIALS_SET_SELECTED_REGION,
  DEFAULT_MATERIALS_SET_SIMPLE_MACHINING_DATA,
  DEFAULT_MATERIALS_SET_SINGLE_MATERIAL_BENDING_DATA,
  DEFAULT_MATERIALS_SET_SINGLE_MATERIAL_BENDS,
  DEFAULT_MATERIALS_SET_SURFACE_COATING_DATA,
  DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_PARAMETER,
  DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_PROPERTIES,
  DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_SPEED,
  DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_TOLERANCE,
  DEFAULT_MATERIALS_SET_TUBE_CUTTING_MATERIAL_PRICES,
  DEFAULT_MATERIALS_SET_TUBE_CUTTING_STANDARD_MATERIAL_PRICES,
  DEFAULT_MATERIALS_UPDATE_ASSEMBLY_MATERIAL_CERTIFICATE_PRICE,
  DEFAULT_MATERIALS_UPDATE_BASE_MATERIAL_CERTIFICATE_PRICE,
  DEFAULT_MATERIALS_UPDATE_MACHINING_MATERIAL_PRICE,
  DEFAULT_MATERIALS_UPDATE_MATERIAL_PRICE,
  DEFAULT_MATERIALS_UPDATE_STANDARD_TUBE_CUTTING_MATERIAL_PRICE,
  DEFAULT_MATERIALS_UPDATE_TUBE_CUTTING_MATERIAL_PRICE,
} from "../actions/actionTypes";

const defaultMaterialsReducer = (state = initialState.defaultMaterialsData, action) => {
  switch (action.type) {

    //#--------------CUTTING MACHINES--------------#
    case DEFAULT_MATERIALS_SET_SELECTED_REGION:
      return {
        ...state,
        selectedRegion: action.payload
      }
    case DEFAULT_MATERIALS_IS_FETCHING_CUTTING_MACHINE_DATA:
      return {
        ...state,
        isFetchingCuttingMachineData: action.payload
      }
    case DEFAULT_MATERIALS_SET_CUTTING_MACHINE_DATA:
      return {
        ...state,
        defaultCuttingMachine: action.payload
      }
    case DEFAULT_MATERIALS_IS_FETCHING_CUTTING_MACHINE_PARAMETERS:
      return {
        ...state,
        isFetchingCuttingMachineData: action.payload
      }
    case DEFAULT_MATERIALS_SET_CUTTING_MACHINE_PARAMETERS:
      return {
        ...state,
        defaultCuttingMachineData: action.payload
      }
    case DEFAULT_MATERIALS_SET_CUTTING_MACHINE_PARAMETER:
      return {
        ...state,
        defaultCuttingMachineData: {...state.defaultCuttingMachineData, ...action.payload}
      }
    case DEFAULT_MATERIALS_SET_CUTTING_MACHINE_TOLERANCE:
      return {
        ...state,
        defaultCuttingMachineData: {
          ...state.defaultCuttingMachineData,
          markup: {
            tolerance: {
              ...state.defaultCuttingMachineData.markup.tolerance,
              [action.payload.toleranceClass]: action.payload.toleranceMarkup
            }
          }
        }
      }
    case DEFAULT_MATERIALS_SET_CUTTING_MACHINE_SPEED:
      let tmp = [...state.defaultCuttingMachineData.tables]
      tmp[action.payload.tab].rows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        defaultCuttingMachineData: {...state.defaultCuttingMachineData, tables: tmp},
      }

    //#-----------------BENDING-----------------#
    case DEFAULT_MATERIALS_IS_FETCHING_BENDING_DATA:
      return {
        ...state,
        isFetchingDefaultBendingData: action.payload,
      }
    case DEFAULT_MATERIALS_SET_BENDING_DATA:
      return {
        ...state,
        defaultBendingData: action.payload,
      }
    case DEFAULT_MATERIALS_IS_FETCHING_SINGLE_MATERIAL_BENDS:
      return {
        ...state,
        isFetchingSingleMaterialDefaultBends: action.payload
      }
    case DEFAULT_MATERIALS_SET_SINGLE_MATERIAL_BENDING_DATA:
      return {
        ...state,
        bendingDefaultBendsData: action.payload
      }
    case DEFAULT_MATERIALS_SET_SINGLE_MATERIAL_BENDS:
      return {
        ...state,
        defaultBendingData: {
          ...state.defaultBendingData, bends: state.defaultBendingData.bends.map(item => {
            if (item.groupName === action.payload.groupName) {
              return action.payload
            }
            return item
          })
        }
      }
    case DEFAULT_MATERIALS_RESET_BENDS_DATA:
      return {
        ...state,
        bendingDefaultBendsData: []
      }
    case DEFAULT_MATERIALS_SET_BENDING_PREPARATION_PRICE:
      return {
        ...state,
        defaultBendingData: {...state.defaultBendingData, preparationPrice: action.payload},
      }
    case DEFAULT_MATERIALS_SET_BENDING_AVERAGE_PREPARATION_TIME:
      return {
        ...state,
        defaultBendingData: {...state.defaultBendingData, averagePreparationTime: action.payload},
      }
    case DEFAULT_MATERIALS_SET_BENDING_MAX_LENGTH:
      return {
        ...state,
        defaultBendingData: {...state.defaultBendingData, maxLength: action.payload},
      }
    case DEFAULT_MATERIALS_IS_ADDING_NEW_BEND:
      return {
        ...state,
        isAddingDefaultBend: action.payload
      }
    case DEFAULT_MATERIALS_IS_EDITING_BEND:
      return {
        ...state,
        isEditingDefaultBend: action.payload
      }
    case DEFAULT_MATERIALS_IS_DELETING_BENDS:
      return {
        ...state,
        isRemovingDefaultBend: action.payload
      }

    //#-----------------SIMPLE MACHINING-----------------#
    case DEFAULT_MATERIALS_IS_FETCHING_SIMPLE_MACHINING_DATA:
      return {
        ...state,
        isFetchingDefaultSimpleMachiningData: action.payload,
      }
    case DEFAULT_MATERIALS_SET_SIMPLE_MACHINING_DATA:
      return {
        ...state,
        defaultSimpleMachiningData: action.payload,
      }
    case DEFAULT_MATERIALS_SET_DEBURRING_DATA:
      return {
        ...state,
        defaultSimpleMachiningData: {
          ...state.defaultSimpleMachiningData,
          deburring: {
            ...state.defaultSimpleMachiningData.deburring,
            ...action.payload
          }
        },
      }
    case DEFAULT_MATERIALS_SET_CHAMFERING_DATA:
      return {
        ...state,
        defaultSimpleMachiningData: {
          ...state.defaultSimpleMachiningData,
          chamfering: {
            ...state.defaultSimpleMachiningData.chamfering,
            ...action.payload
          }
        },
      }
    case DEFAULT_MATERIALS_SET_DRILLING_DATA:
      return {
        ...state,
        defaultSimpleMachiningData: {
          ...state.defaultSimpleMachiningData,
          drilling: {
            ...state.defaultSimpleMachiningData.drilling,
            ...action.payload
          }
        },
      }
    case DEFAULT_MATERIALS_SET_PREPARATION_DATA:
      return {
        ...state,
        defaultSimpleMachiningData: {...state.defaultSimpleMachiningData, ...action.payload}
      }

    //#-----------------MACHINING-----------------#
    case DEFAULT_MATERIALS_IS_FETCHING_MACHINING_DATA:
      return {
        ...state,
        isFetchingDefaultMachiningData: action.payload,
      }
    case DEFAULT_MATERIALS_SET_MACHINING_DATA:
      return {
        ...state,
        defaultMachiningData: {...state.defaultMachiningData, ...action.payload},
        alreadyFetchedDefaultMachiningData: true
      }
    case DEFAULT_MATERIALS_IS_FETCHING_MILLING_MACHINE_DATA:
      return {
        ...state,
        isFetchingDefaultMillingMachineData: action.payload
      }
    case DEFAULT_MATERIALS_SET_MILLING_MACHINE_DATA:
      return {
        ...state,
        defaultMillingMachine: {...action.payload}
      }
    case DEFAULT_MATERIALS_IS_FETCHING_LATHING_MACHINE_DATA:
      return {
        ...state,
        isFetchingDefaultLathingMachineData: action.payload
      }
    case DEFAULT_MATERIALS_SET_LATHING_MACHINE_DATA:
      return {
        ...state,
        defaultLathingMachine: {...action.payload}
      }
    case DEFAULT_MATERIALS_SET_MILLING_GRADE_SPEED:
      let tmpMillRows = [...state.defaultMillingMachine.rows]
      tmpMillRows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        defaultMillingMachine: {...state.defaultMillingMachine, rows: tmpMillRows}
      }
    case DEFAULT_MATERIALS_SET_LATHING_GRADE_SPEED:
      let tmpLathRows = [...state.defaultLathingMachine.rows]
      tmpLathRows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        defaultLathingMachine: {...state.defaultLathingMachine, rows: tmpLathRows}
      }
    case DEFAULT_MATERIALS_SET_MILLING_MACHINE_ROUGHNESS:
      return {
        ...state,
        defaultMillingMachine: {
          ...state.defaultMillingMachine,
          markup: {
            ...state.defaultMillingMachine.markup,
            roughness: {
              ...state.defaultMillingMachine.markup.roughness,
              [action.payload.roughnessClass]: action.payload.roughnessMarkup
            }
          }
        }
      }
    case DEFAULT_MATERIALS_SET_LATHING_MACHINE_ROUGHNESS:
      return {
        ...state,
        defaultLathingMachine: {
          ...state.defaultLathingMachine,
          markup: {
            ...state.defaultLathingMachine.markup,
            roughness: {
              ...state.defaultLathingMachine.markup.roughness,
              [action.payload.roughnessClass]: action.payload.roughnessMarkup
            }
          }
        }
      }
    case DEFAULT_MATERIALS_SET_MILLING_MACHINE_TOLERANCE:
      return {
        ...state,
        defaultMillingMachine: {
          ...state.defaultMillingMachine,
          markup: {
            ...state.defaultMillingMachine.markup,
            tolerance: {
              ...state.defaultMillingMachine.markup.tolerance,
              [action.payload.toleranceClass]: action.payload.toleranceMarkup
            }
          }
        }
      }
    case DEFAULT_MATERIALS_SET_LATHING_MACHINE_TOLERANCE:
      return {
        ...state,
        defaultLathingMachine: {
          ...state.defaultLathingMachine,
          markup: {
            ...state.defaultLathingMachine.markup,
            tolerance: {
              ...state.defaultLathingMachine.markup.tolerance,
              [action.payload.toleranceClass]: action.payload.toleranceMarkup
            }
          }
        }
      }

    //#----------------TUBE CUTTING-----------------#
    case DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_DATA:
      return {
        ...state,
        isFetchingDefaultTubeCuttingData: action.payload,
      }
    case DEFAULT_MATERIALS_SET_DEFAULT_TUBE_CUTTING_MACHINES:
      return {
        ...state,
        defaultTubeCuttingMachines: action.payload
      }
    case DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_MACHINE_PROPERTIES:
      return {
        ...state,
        isFetchingDefaultTubeCuttingMachineData: action.payload
      }
    case DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_PROPERTIES:
      return {
        ...state,
        defaultTubeCuttingMachineData: action.payload
      }
    case DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_SPEED:
      let tmp2 = [...state.defaultTubeCuttingMachineData.tables]
      tmp2[action.payload.tab].rows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        defaultTubeCuttingMachineData: {...state.defaultTubeCuttingMachineData, tables: tmp2},
      }
    case DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_PARAMETER:
      return {
        ...state,
        defaultTubeCuttingMachineData: {...state.defaultTubeCuttingMachineData, ...action.payload}
      }
    case DEFAULT_MATERIALS_SET_TUBE_CUTTING_MACHINE_TOLERANCE:
      return {
        ...state,
        defaultTubeCuttingMachineData: {
          ...state.defaultTubeCuttingMachineData,
          markup: {
            tolerance: {
              ...state.defaultTubeCuttingMachineData.markup.tolerance,
              [action.payload.toleranceClass]: action.payload.toleranceMarkup
            }
          }
        }
      }

    //#---------------SURFACE COATING---------------#
    case DEFAULT_MATERIALS_IS_FETCHING_SURFACE_COATING_DATA:
      return {
        ...state,
        isFetchingDefaultSurfaceCoatingData: action.payload,
      }
    case DEFAULT_MATERIALS_SET_SURFACE_COATING_DATA:
      return {
        ...state,
        defaultSurfaceCoatingData: {...state.defaultSurfaceCoatingData, ...action.payload}
      }
    case DEFAULT_MATERIALS_SET_ANODIZING_DATA:
      return {
        ...state,
        defaultSurfaceCoatingData: {
          ...state.defaultSurfaceCoatingData,
          anodizing: {...state.defaultSurfaceCoatingData.anodizing, ...action.payload}
        },
      }
    case DEFAULT_MATERIALS_SET_BLASTING_DATA:
      return {
        ...state,
        defaultSurfaceCoatingData: {
          ...state.defaultSurfaceCoatingData,
          blasting: {...state.defaultSurfaceCoatingData.blasting, ...action.payload}
        },
      }
    case DEFAULT_MATERIALS_SET_GALVANIC_ZINC_PLATING_DATA:
      return {
        ...state,
        defaultSurfaceCoatingData: {
          ...state.defaultSurfaceCoatingData,
          galvanicZincPlating: {...state.defaultSurfaceCoatingData.galvanicZincPlating, ...action.payload}
        },
      }
    case DEFAULT_MATERIALS_SET_GALVANIZING_DATA:
      return {
        ...state,
        defaultSurfaceCoatingData: {
          ...state.defaultSurfaceCoatingData,
          galvanizing: {...state.defaultSurfaceCoatingData.galvanizing, ...action.payload}
        },
      }
    case DEFAULT_MATERIALS_SET_HEAT_TREATMENT_DATA:
      return {
        ...state,
        defaultSurfaceCoatingData: {
          ...state.defaultSurfaceCoatingData,
          heatTreatment: {...state.defaultSurfaceCoatingData.heatTreatment, ...action.payload}
        },
      }
    case DEFAULT_MATERIALS_SET_NICKEL_PLATING_DATA:
      return {
        ...state,
        defaultSurfaceCoatingData: {
          ...state.defaultSurfaceCoatingData,
          nickelPlating: {...state.defaultSurfaceCoatingData.nickelPlating, ...action.payload}
        },
      }
    case DEFAULT_MATERIALS_SET_PAINTING_DATA:
      return {
        ...state,
        defaultSurfaceCoatingData: {
          ...state.defaultSurfaceCoatingData,
          painting: {...state.defaultSurfaceCoatingData.painting, ...action.payload}
        },
      }
    case DEFAULT_MATERIALS_SET_POWDER_PAINTING_DATA:
      return {
        ...state,
        defaultSurfaceCoatingData: {
          ...state.defaultSurfaceCoatingData,
          powderPainting: {...state.defaultSurfaceCoatingData.powderPainting, ...action.payload}
        },
      }

    //#-----------------MATERIALS------------------#
    case DEFAULT_MATERIALS_IS_FETCHING_MATERIAL_PRICES:
      return {
        ...state,
        isFetchingDefaultMaterialPrices: action.payload
      }
    case DEFAULT_MATERIALS_SET_MATERIAL_PRICES:
      return {
        ...state,
        defaultMaterialPrices: action.payload
      }
    case DEFAULT_MATERIALS_UPDATE_MATERIAL_PRICE:
      let tmp3 = [...state.defaultMaterialPrices]
      tmp3[action.payload.tab].rows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        defaultMaterialPrices: [...tmp3]
      }
    case DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_MATERIAL_PRICES:
      return {
        ...state,
        isFetchingDefaultTubeCuttingMaterials: action.payload
      }
    case DEFAULT_MATERIALS_SET_TUBE_CUTTING_MATERIAL_PRICES:
      return {
        ...state,
        hasTubeCutting: true,
        defaultTubeCuttingMaterials: {
          availableMaterials: action.payload.availableMaterials,
          allAvailable: action.payload.allAvailable
        }
      }
    case DEFAULT_MATERIALS_IS_FETCHING_TUBE_CUTTING_STANDARD_MATERIAL_PRICES:
      return {
        ...state,
        isFetchingDefaultStandardTubeMaterialPrices: action.payload
    }
    case DEFAULT_MATERIALS_SET_TUBE_CUTTING_STANDARD_MATERIAL_PRICES:
      return {
        ...state,
        defaultStandardTubeMaterialPrices: action.payload.standardTubeMaterialPrices
      }
    case DEFAULT_MATERIALS_UPDATE_TUBE_CUTTING_MATERIAL_PRICE:
      let tmp4 = [...state.defaultTubeCuttingMaterials.availableMaterials]
      tmp4[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        defaultTubeCuttingMaterials: {...state.defaultTubeCuttingMaterials, availableMaterials: tmp4},
      }
    case DEFAULT_MATERIALS_UPDATE_STANDARD_TUBE_CUTTING_MATERIAL_PRICE:
      let tmp5 = [...state.defaultStandardTubeMaterialPrices]
      if(action.payload.type === 'circle') {
        tmp5[action.payload.tab].grades[action.payload.grade].rows[action.payload.row][action.payload.col] = action.payload.value
      } else {
        tmp5[action.payload.tab].grades[action.payload.grade].rows[action.payload.row][action.payload.col][action.payload.elem].price = action.payload.value
      }
      return {
        ...state,
        defaultStandardTubeMaterialPrices: [...tmp5],
      }
    case DEFAULT_MATERIALS_IS_FETCHING_MACHINING_MATERIALS:
      return {
        ...state,
        isFetchingDefaultMachiningMaterials: action.payload
      }
    case DEFAULT_MATERIALS_SET_MACHINING_MATERIALS:
      return {
        ...state,
        defaultMachiningMaterials: {
          availableMaterials: action.payload.rows,
          allAvailable: action.payload.allAvailable
        },
      }
    case DEFAULT_MATERIALS_UPDATE_MACHINING_MATERIAL_PRICE:
      let temp = [...state.defaultMachiningMaterials.availableMaterials]
      temp[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        defaultMachiningMaterials: {...state.defaultMachiningMaterials, availableMaterials: temp},
      }
    case DEFAULT_MATERIALS_IS_FETCHING_MATERIAL_CERTIFICATE_PRICES:
      return {
        ...state,
        isFetchingMaterialCertPrices: action.payload
      }
    case DEFAULT_MATERIALS_SET_MATERIAL_CERTIFICATE_PRICES:
      return {
        ...state,
        ...action.payload
      }
    case DEFAULT_MATERIALS_UPDATE_BASE_MATERIAL_CERTIFICATE_PRICE:
      return {
        ...state,
        baseMaterialCertFee: action.payload
      }
    case DEFAULT_MATERIALS_UPDATE_ASSEMBLY_MATERIAL_CERTIFICATE_PRICE:
      return {
        ...state,
        assemblyMaterialCertFee: action.payload
      }
    case DEFAULT_MATERIALS_IS_FETCHING_ALREADY_SEEDED:
      return {
        ...state,
        isFetchingSeedingData: action.payload
      }
    case DEFAULT_MATERIALS_SET_ALREADY_SEEDED_DATA:
      return {
        ...state,
        seedingData: action.payload
      }
    case DEFAULT_MATERIALS_IS_SEEDING_DATA:
      return {
        ...state,
        isSeedingData: action.payload
      }
    case DEFAULT_MATERIALS_RELOAD_TAB:
      return {
        ...state,
        reloadTabKey: action.payload
      }
    default:
      return state
  }
}

export default defaultMaterialsReducer