import React from "react";
import {Layout, Row, Menu, Button} from "antd";
import history from "../../../utils/history";
import { MaterialIcon } from "../../../assets/icons/material";
import { EditMaterialsIcon } from "../../../assets/icons/editMaterials";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import "./style.less";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {collapseSidebar, updateActiveSubmenuKeys} from "../../../actions/utilActions";
import PublicIcon from '@mui/icons-material/Public';

const SubMenu = Menu.SubMenu

let selectedField = '1';

function activeComponent(history) {
  const path = "/" + history.location.pathname.split('/')[1]
  switch (path) {
    case('/'):
      selectedField = '1';
      break;
    case('/employeeManagement'):
      selectedField = '2';
      break;
    case('/producersManagement'):
      selectedField = '3';
      break;
    case('/defaultParameters'):
      selectedField = '4';
      break;
    case('/pending-order'):
    case('/pending'):
      selectedField = '5';
      break;
    case('/awaiting-order'):
    case('/awaiting'):
      selectedField = '6';
      break;
    case('/processed-order'):
    case('/processed'):
      selectedField = '7';
      break;
    case('/materials'):
      selectedField = '8';
      break;
    case('/step-tips'):
    case('/calculation'):
      selectedField = '9';
      break
    case('/internal-customer-relations'):
      selectedField = '10';
      break;
    case('/company-settings'):
      selectedField = '11';
      break;
    default:
      break;
  }
  return selectedField;
}

const checkPermission = (user, permission) => {
  return (user?.role === "gelsoAdmin" || (user?.role === "gelsoSubAdmin" && user?.permissions?.includes(permission)))
}

export const Sidebar = (props) => {
  const activeSubmenuKeys = useSelector(state => state.util.activeSubmenuKeys);

  const {onLogout} = props;
  const {user} = props;

  const dispatch = useDispatch();

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const onCollapse = () => {
    dispatch(collapseSidebar(!isCollapsed));
  }

  return(
    <Layout.Sider
      style={{
        overflow: 'auto',
        minHeight: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 1000,
      }}
      collapsed={isCollapsed}
    >
      <Button
        className={"collapseButton"}
        style={{ left: isCollapsed ? '50px' : '190px'}}
        onClick={() => onCollapse()}
      >
        { isCollapsed ?
          <RightOutlined style={{ fontSize: 14, position: "relative", top: 1 }}/>
          :
          <LeftOutlined style={{ fontSize: 14, position: "relative", top: 1  }}/>
        }
      </Button>
      <Row style={{marginTop: '2rem', height: "100%"}}>
        <Menu
          className={"companyMenu"}
          theme="dark"
          mode="inline"
          selectedKeys = {[activeComponent(history)]}
          defaultOpenKeys={isCollapsed ? [] : activeSubmenuKeys}
        >
          <Menu.Item
            key="1"
            onClick={() => {
              history.push('/')
            }}
            title={<span className="nav-text">Dashboard</span>}
            style={{ paddingLeft: 22 }}
          >
            <a href="/" onClick={(e) => e.preventDefault()}>
              <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <DashboardOutlinedIcon style={{fontSize: 22}}/>
                <span
                  className={"menuLine"}
                  style={{ opacity: isCollapsed ? 0 : 1}}
                >Dashboard</span>
              </div>
            </a>
          </Menu.Item>
          {user?.role === "gelsoAdmin" &&
            <Menu.Item
              key="2"
              onClick={() => {
                history.push('/employeeManagement')
              }}
              title={<span className="nav-text">Employee Overview</span>}
              style={{ paddingLeft: 19 }}
            >
              <a href={"/employeeManagement"} onClick={(e) => e.preventDefault()}>
                <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%"}}>
                  <BadgeOutlinedIcon style={{fontSize: 22, position: "relative"}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Employee Overview</span>
                </div>
              </a>
            </Menu.Item>
          }
          {checkPermission(user, 'producers') &&
            <Menu.Item
              key="3"
              onClick={() => {
                history.push('/producersManagement')
              }}
              title={<span className="nav-text">Producers</span>}
              style={{ paddingLeft: 22 }}
            >
              <a href={"/producersManagement"} onClick={(e) => e.preventDefault()}>
                <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                  <PrecisionManufacturingIcon style={{fontSize: 22}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Producers</span>
                </div>
              </a>
            </Menu.Item>
          }
          {checkPermission(user, "materials") &&
          <Menu.Item
            key="4"
            onClick={(e) => {
              history.push('/defaultParameters')
            }}
            title={<span className="nav-text">Default Parameters</span>}
            style={{ paddingLeft: 21, display: "block" }}
          >
            <a href={"/defaultParameters"} onClick={(e) => e.preventDefault()}>
              <PublicIcon style={{fontSize: 22, position: "relative", right: 2, top: 6}}/>

              <span
                className={"menuLine"}
                style={{ marginLeft: 5, opacity: isCollapsed ? 0 : 1}}
              >Default Parameters</span>
            </a>
          </Menu.Item>
          }
          {checkPermission(user, "orders") &&
            <SubMenu
              key="sub1"
              className={`subMenuC${(!isCollapsed && !activeSubmenuKeys.includes("sub1") || isCollapsed) ? ' active' : ''}`}
              title={
                <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                  <PendingActionsIcon style={{fontSize: 21}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Pending Quotes</span>
                </div>
              }
              onTitleClick={() => {
                dispatch(updateActiveSubmenuKeys('sub1'));
              }}
            >
              <Menu.Item
                key="5"
                onClick={() => {
                  history.push('/pending')
                }}
              >
                <a href={"/pending"} onClick={(e) => e.preventDefault()}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{display: "flex", marginLeft: -2}}>
                      <AccessTimeIcon style={{fontSize: 20}}/>
                    </div>
                    <div style={{marginLeft: 7}}>
                      <span className="nav-text">Pending</span>
                    </div>
                  </div>
                </a>
              </Menu.Item>
              <Menu.Item
                key="6"
                onClick={() => {
                  history.push('/awaiting')
                }}>
                <a href={"/awaiting"} onClick={(e) => e.preventDefault()}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{display: "flex", marginLeft: -2}}>
                      <HourglassEmptyOutlinedIcon style={{fontSize: 20}}/>
                    </div>
                    <div style={{marginLeft: 7}}>
                      <span className="nav-text">Awaiting</span>
                    </div>
                  </div>
                </a>
              </Menu.Item>
            </SubMenu>
          }
          {checkPermission(user, "orders") &&
          <Menu.Item
            key="7"
            onClick={(e) => {
              history.push('/processed')
            }}
            title={<span className="nav-text">Processed Quotes</span>}
            style={{ paddingLeft: 21, display: "block" }}
          >
            <a href={"/processed"} onClick={(e) => e.preventDefault()}>
              <div align={'center'}
                   style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <InventoryOutlinedIcon style={{fontSize: 21}}/>
                <span
                  className={"menuLine"}
                  style={{ marginLeft: 9, opacity: isCollapsed ? 0 : 1}}
                >Processed Quotes</span>
              </div>
            </a>
          </Menu.Item>
          }
          {checkPermission(user, "materials") &&
            <Menu.Item
              key="8"
              onClick={(e) => {
                history.push('/materials')
              }}
              title={<span className="nav-text">Materials and Machines</span>}
              style={{ paddingLeft: 21, display: "block" }}
            >
              <a href={"/materials"} onClick={(e) => e.preventDefault()}>
                <MaterialIcon style={{fontSize: 20, position: "relative", right: 2, top: 1}}/>
                <span
                  className={"menuLine"}
                  style={{ marginLeft: 5, opacity: isCollapsed ? 0 : 1}}
                >Materials</span>
              </a>
            </Menu.Item>
          }
          {checkPermission(user, "bulk") &&
            <Menu.Item
              key="9"
              onClick={() => {
                history.push('/calculation')
              }}
              title={<span className="nav-text">Calculation</span>}
              style={{ paddingLeft: 21 }}
            >
              <a href={"/calculation"} onClick={(e) => e.preventDefault()}>
                <div align={'center'}
                     style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                  <WysiwygOutlinedIcon style={{fontSize: 22}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Calculation</span>
                </div>
              </a>
            </Menu.Item>
          }
          {checkPermission(user, "customers") &&
            <Menu.Item
              key="10"
              onClick={() => {
              history.push('/internal-customer-relations')
              }}
              title={<span className="nav-text">Customers</span>}
              style={{ paddingLeft: 20 }}
            >
              <a href={"/internal-customer-relations"} onClick={(e) => e.preventDefault()}>
                <div align={'center'}
                     style={{display: "flex", alignItems: "center", height: '100%', width: "100%"}}
                >
                  <PeopleAltOutlinedIcon style={{fontSize: 21, position: "relative"}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Customers</span>
                </div>
              </a>
            </Menu.Item>
          }
          {checkPermission(user, "settings") &&
            <Menu.Item
              key="11"
              onClick={() => {
                history.push('/company-settings')
              }}
              title={<span className="nav-text">Settings</span>}
              style={{ paddingLeft: 22 }}
            >
              <a href={"/company-settings"} onClick={(e) => e.preventDefault()}>
                <div align={'center'}
                     style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                  <SettingsOutlinedIcon style={{fontSize: 22}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Settings</span>
                </div>
              </a>
            </Menu.Item>
          }
        </Menu>
        <Menu
          theme="dark"
          mode="inline"
          style={{position: "absolute", bottom: 20 }}
        >
          <Menu.Item
            key="12"
            onClick={onLogout}
            title={<span className="nav-text">Sign out</span>}
            style={{ paddingLeft: 22 }}
          >
            <div align={'center'}
                 style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
              <LogoutOutlinedIcon style={{fontSize: 22}}/>
              <span
                className={"menuLine"}
                style={{ opacity: isCollapsed ? 0 : 1}}
              >Sign Out</span>
            </div>
          </Menu.Item>
        </Menu>
      </Row>
    </Layout.Sider>
  )
}

export default Sidebar
