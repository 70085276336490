import * as React from "react";
import {Card} from "antd";

const MaterialTableContainerNested = (props) => {

  const {tableText, xAxisText, yAxisText, isProducer=false, headerHeight=0} = props;

  const tableCardStyle = {
    borderRadius: 5,
    marginLeft: 25,
    marginRight: 10,
    height: `calc(100vh - 664px - ${headerHeight}px)`,
    minHeight: headerHeight > 0 ? 220 : 235
  }

  const tableCardBodyStyle = {
    padding: 0,
    height: `calc(100vh - 666px - ${headerHeight}px)`,
    minHeight: headerHeight > 0 ? 220 : 234
  }

  const producerCardStyle = {
    borderRadius: 5,
    marginLeft: 25,
    marginRight: 10,
    height: `calc(100vh - 722px - ${headerHeight}px)`,
    minHeight: 235
  }

  const producerCardBodyStyle = {
    padding: 0,
    height: `calc(100vh - 723px - ${headerHeight}px)`,
    minHeight: 234
  }

  return (
    <Card
      style={{marginRight: 10}}
      bodyStyle={{padding: "5px 5px 10px 10px"}}>
      <div align={"center"} style={{ fontSize: 13, fontWeight: 600, textDecoration: "underline", marginBottom: -8, marginLeft: 5}}>
        {tableText}
      </div>
      <div className={"materialTableYAxis"} style={{fontSize: 12}}>
        {yAxisText}
      </div>
      <div className={"materialTableXAxis"} style={{fontSize: 12}}>
        {xAxisText}
      </div>
      <Card className={"tableCardWrapper"}
            style={ isProducer ? producerCardStyle : tableCardStyle}
            bodyStyle={ isProducer ? producerCardBodyStyle : tableCardBodyStyle}>
        {props.children}
      </Card>
    </Card>
  )
}

export default MaterialTableContainerNested;